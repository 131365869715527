import { combineReducers } from '@reduxjs/toolkit';

import commonReducer from '@/redux/slice/common';
import commonListPageReducer from '@/redux/slice/commonListPageSlice'
import configReducer from '@/redux/slice/config'
import templateReducer from '@/redux/slice/template'

const rootReducer = combineReducers({
  commonReducer: commonReducer,
  commonListPageReducer: commonListPageReducer,
  configReducer: configReducer,
  templateReducer: templateReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
