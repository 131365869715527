import React, {useEffect, useState} from 'react';
import InputTextField from '@/component/common/InputTextField';
import MultipleSearchSelect from '@/component/common/MutipleSearchSelect';
import ButtonUpload from '@/component/common/ButtonUpload';
import {IUploadTemplate, IUploadTemplateErrorMessage} from '@/pages/templates/interface/UploadTemplate';
import {
  defaultUploadTemplateErrorMessage,
  defaultUploadTemplateForm
} from '@/component/templates/form/UploadTemplateForm';
import services from '@/services';
import {FILE_TYPE, HTTP_STATUS} from '@/constants';
import ErrorMessage from '@/component/common/ErrorMessage';
import {IUploadTemplateInfo} from '@/component/templates/interface/UploadTemplateInfo';
import {checkRequiredField} from '@/utils';
import {useDispatch, useSelector} from 'react-redux';
import {
  setIsAllowUpload,
  setTemplateData,
  setUploadTemplateName,
  setUploadTemplatePreviewFile,
  setUploadTemplateThumbnailFile,
  setUploadTemplateZipFile
} from '@/redux/slice/template';
import { RootState } from '@/redux/rootReducer';

const UploadTemplateInfo: React.FC<IUploadTemplateInfo> = ({
  isUserClickCancelButton,
  userClickSubmitButtonTimes,
}) => {
  const dispatch = useDispatch();
    
  const [formUploadTemplate, setFormUploadTemplate] = useState<IUploadTemplate>(defaultUploadTemplateForm);

  const [formUploadTemplateErrorMessage, setFormUploadTemplateErrorMessage] = useState<IUploadTemplateErrorMessage>(defaultUploadTemplateErrorMessage);

  const [zipFile, setZipFile] = useState<any>(null);
  const [isUploadingZipFile, setIsUploadingZipFile] = useState<boolean>(false);

  const [isUploadingJsonFile, setIsUploadingJsonFile] = useState<boolean>(false);
  const [jsonFileData, setJsonFileData] = useState<any>(null);
  const templateData = useSelector((state: RootState) => state.templateReducer.templateData);

  const handleChangeFormUploadTemplate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value} = event.target
    dispatch(setTemplateData({
      ...templateData,
      [name]: value
    }))
  }

  const handleSearchCategories = async (value: string, page = 1) => {
    try {
      const query: any = {limit: 10, orderBy: 'createdAt', page};
      if (value) query.search = value
      const res = await services.searchListCategory(query);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        return res;
      }

    } catch (err: any) {
      console.log('error', err);
      return [];
    }
  }

  const handleChangeSelectCategories = (category: string[]) => {
    dispatch(setTemplateData({
      ...templateData,
      category: category
    }))
  }

  const handleChangeFileZip = () => {
    setFormUploadTemplateErrorMessage((prev: any) => ({
      ...prev,
      zipFile: ''
    }));
    setIsUploadingZipFile(true);
  }

  const handleUploadFileZip = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name;
    
    if(!fileName.endsWith(FILE_TYPE.ZIP)) {
      setFormUploadTemplateErrorMessage((prev: any) => ({
        ...prev,
        zipFile: 'Wrong upload file format. Only accept zip file'
      }));
    }  else {
      setFormUploadTemplateErrorMessage((prev: any) => ({
        ...prev,
        zipFile: ''
      }));
      setZipFile(uploadFile);      
      dispatch(setUploadTemplateZipFile(uploadFile));
    }
    setIsUploadingZipFile(false);
  }

  const handleChangeFileJson = () => {
    setIsUploadingJsonFile(false);
    setFormUploadTemplateErrorMessage((prev: any) => ({
      ...prev,
      jsonFile: ''
    }));
    setIsUploadingJsonFile(true);
  }

  const handleReadJsonFile = (fileData: any) => {
    let result: any = {};
    Object.keys(fileData).forEach((key: string) => {
      const value = fileData[key];
      if (typeof value === 'string' || typeof value === 'number') {
        result[key] = value;
      } else {
        if (typeof value === 'object' && !Array.isArray(value)) {
          result = { ... result, ...handleReadJsonFile(value)}
        }
      }
    });
    return result;
  }

  const handleUploadFileJson = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name;

    if(!fileName.endsWith(FILE_TYPE.JSON)) {
      setFormUploadTemplateErrorMessage((prev: any) => ({
        ...prev,
        jsonFile: 'Wrong upload file format. Only accept json file'
      }));
    }  else {
      setFormUploadTemplateErrorMessage((prev: any) => ({
        ...prev,
        json: ''
      }));
      
      const fileReader = new FileReader();
      fileReader.readAsText(uploadFile, 'UTF-8');
      fileReader.onload = (e: any) => {
        const fileData = e.target?.result || '';
        const convertedJsonData = JSON.parse(fileData)
        const jsonContent = handleReadJsonFile(convertedJsonData);
        const id = jsonContent?.ID || jsonContent.id;

        delete jsonContent.name;
        delete jsonContent?.ID;

        setJsonFileData({...jsonContent, id});

        const payloadForm = {
          id,
          ...jsonContent,
          ...templateData,
          status: 1,
        }

        dispatch(setTemplateData(payloadForm))
      }
    }

    setIsUploadingJsonFile(false);
  }
  
  const handleClearData = () => {
    const defaultUploadForm = {...defaultUploadTemplateForm}
    setFormUploadTemplate(({
      ...formUploadTemplate,
      ...defaultUploadForm
    }));
    
    const defaultErrorForm = {...defaultUploadTemplateErrorMessage}
    setFormUploadTemplateErrorMessage(({
      ...formUploadTemplateErrorMessage,
      ...defaultErrorForm
    }));
    dispatch(setUploadTemplateZipFile(null));
    dispatch(setUploadTemplateThumbnailFile(null));
    dispatch(setUploadTemplatePreviewFile(null));
    dispatch(setTemplateData(null));
    dispatch(setUploadTemplateName(''));
    setZipFile(null);
    setIsUploadingZipFile(false);
    setJsonFileData(null);
    setIsUploadingJsonFile(false);
  }
  
  useEffect(() => {
    handleClearData();
  }, [isUserClickCancelButton]);
  
  const handleCheckValidateForm = (templateName: string, zipFile: any, jsonFileData: any) => {
    const errorMsg = {...defaultUploadTemplateErrorMessage};
    errorMsg.zipFile = checkRequiredField(zipFile)
    errorMsg.jsonFile = checkRequiredField(jsonFileData);
    errorMsg.name = checkRequiredField(templateName)


    return errorMsg;
  };

  const handleSubmitUserInfo = () => {
    const errorMessages = handleCheckValidateForm(templateData?.name, zipFile, jsonFileData);
    setFormUploadTemplateErrorMessage(errorMessages);
    
    let errorCount = 0;
    let key: keyof IUploadTemplateErrorMessage;
    for (key in errorMessages) {
      if (errorMessages[key] !== '') errorCount++
    }
    
    if (errorCount === 0) {
      dispatch(setUploadTemplateName(formUploadTemplate.name));
      dispatch(setIsAllowUpload(true))
    } else {
      dispatch(setIsAllowUpload(false))
    }
  };
  
  useEffect(() => {
    if (userClickSubmitButtonTimes > 0) {
      handleSubmitUserInfo();
    }
  }, [userClickSubmitButtonTimes]);


  return (
    <>
      <div className='template-item-container'>
        <div className='left-col'>
          Name:
        </div>
        <div className='right-col'>
          <InputTextField 
            handleChange={handleChangeFormUploadTemplate}
            inputType={'text'}
            value={templateData?.name}
            inputName={'name'} 
            inputPlaceholder={'Enter template name'}
            maxLength={150}
            minLength={2}
            isInputInvalid={!!formUploadTemplateErrorMessage.name}
          />
          <ErrorMessage errorMessage={formUploadTemplateErrorMessage.name} />
        </div>
      </div>
        
      {/*    Category*/}
      <div className='template-item-container'>
        <div className='left-col'>
          Category:
        </div>
        <div className='right-col'>
          <MultipleSearchSelect
            placeholder={'Enter category'}
            handleChange={handleChangeSelectCategories}
            fetchListOptions={handleSearchCategories}
            defaultValue={templateData?.category}
          />
        </div>
      </div>
          
      {/*    Zip file*/}
      <div>
        <div className='template-item-container'>
          <div className='left-col'>
            Zip file
          </div>
          <div className='right-col'>
            <div className='upload-icon'>
              <ButtonUpload
                handleChangeFile={handleChangeFileZip}
                handleUpload={handleUploadFileZip}
                showUploadList={isUploadingZipFile}
                showUploadButtonLabel={false}
              />
            </div>
            <ErrorMessage errorMessage={formUploadTemplateErrorMessage.zipFile} />
            {zipFile && (
              <div>
                {zipFile.name}
              </div>
            )}
          </div>
        </div>
          
      </div>

          
      {/*    JSON file*/}
      <div>
        <div className='template-item-container'>
          <div className='left-col'>
            JSON file
          </div>
          <div className='right-col'>
            <div className='upload-icon'>
              <ButtonUpload 
                handleChangeFile={handleChangeFileJson}
                handleUpload={handleUploadFileJson}
                showUploadList={isUploadingJsonFile}
                showUploadButtonLabel={false}
              />
            </div>

            <ErrorMessage errorMessage={formUploadTemplateErrorMessage.jsonFile} />
            {!isUploadingJsonFile && jsonFileData && (
              <>
                {Object.keys(jsonFileData).map((key: string) => {
                  return (
                    <div key={key} className='json-data-container'>
                      <div className='json-key'>
                        {key}
                      </div>
                      <div className='json-value'>{jsonFileData[key]}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadTemplateInfo;
