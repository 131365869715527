import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IUserInfo} from '@/redux/interface/common';
import initialState from '@/redux/constant/common';

export const common = createSlice({
  name: 'commonSlice',
  initialState: initialState,
  reducers: {
    setRouteAndNavigate(
      state,
      action: PayloadAction<{ keyPath: string; subKeyPath: string }>
    ) {
      state.keyPath = action.payload.keyPath;
      state.subKeyPath = action.payload.subKeyPath;
    },
    setUserInfo(state, action: PayloadAction<IUserInfo>) {
      state.userInfo = action.payload;
    },
    setIsUserAccessAnEditablePage(state, action: PayloadAction<boolean>) {
      state.isUserAccessAnEditablePage = action.payload
    },
    setShowLoadingIcon(state, action: PayloadAction<boolean>) {
      state.showLoadingIcon = action.payload
    },
    setHeaderPage(state, action: PayloadAction<string>) {
      state.headerPage = action.payload
    }
  },
});

export const { 
  setRouteAndNavigate, 
  setUserInfo, 
  setIsUserAccessAnEditablePage,
  setShowLoadingIcon,
  setHeaderPage
} = common.actions;

export default common.reducer;
