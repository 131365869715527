import React from 'react';
import {Modal} from 'antd';
import BaseButton from '@/component/common/Button';
import './PopupRedirectToDefaultPage.scss';
import {redirectToDefaultUrl} from '@/utils/auth';
import {IPopupRedirectToDefaultPage} from '@/component/common/interface/PopupRedirectToDefaultPage';

const HeaderModal = () => {
  return (<span className='header-modal'>Alert</span>)
}

const PopupRedirectToDefaultPage: React.FC<IPopupRedirectToDefaultPage> = ({userRole}) => {
  const handleRedirectToDefaultPage = () => {
    if (userRole) {
      redirectToDefaultUrl(userRole);
    }
  }
  return (
    <>
      <Modal
        title={<HeaderModal/>}
        centered
        closable={false}
        open={true}
        className='popup-redirect-default-page'
        footer={[
          <div key='1' className='modal-footer-btn'>
            <BaseButton 
              buttonLabel={'Redirect to my allowed page'}
              type='primary'
              handleClick={handleRedirectToDefaultPage}
            />
            
          </div>
        ]}
      >
        <div className='popup-content'>
          You have no permission to access this page! 
        </div>
      </Modal>
    </>
  );
};

export default PopupRedirectToDefaultPage;
