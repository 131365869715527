import React, {useState, useRef, useEffect} from 'react';
import { Select, Spin } from 'antd';
import InputTextField from '@/component/common/InputTextField';
import {ISelectSearchAndAddItem} from '@/component/common/interface/SelectSearchAndAddItem';
import './SelectSearchAndAddItem.scss';
import BaseButton from '@/component/common/Button';
import PlusIcon from '@/assets/icon/ic_add_item_select.svg';
import SelectedIcon from '@/assets/icon/ic_selected_item_dropdown.svg';
import ErrorMessage from '@/component/common/ErrorMessage';
import {HTTP_STATUS} from '@/constants';

const AddNewItemIcon = () => {
  return (<img src={PlusIcon} alt="plus-icon" className='icon'/>)
}

const SelectedItemIcon = () => {
  return (<img src={SelectedIcon} alt='selected-icon' className='checked-icon'/>)
}

const SelectSearchAndAddItem: React.FC<ISelectSearchAndAddItem> = ({
  isMultipleSelect = false,
  defaultValue,
  selectPlaceholder,
  handleChangeSelect,
  handleFetchListOptions,
  inputPlaceHolder,
  cancelButtonLabel = 'Cancel',
  submitButtonLabel = 'Add',
  handleAddNewItem,
  addNewItemLabel = 'Add new category',
  maxSelectedOption = 10,
  allowClear = true,
}) => {
  const { Option } = Select;
  
  const selectRef: any = useRef(null);
  console.log('defaultValue', defaultValue)
  
  const [showAddItemForm, setShowAddItemForm] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fetching, setFetching] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFetchingAllData, setIsFetchingAllData] = useState<boolean>(false);
  const [listItems, setListItems] = useState<any[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  const handleShowAddItemForm = () => {
    setShowAddItemForm(true);
  }
  

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }

  const handleCancelAddItem = () => {
    setShowAddItemForm(false);
    setInputValue('');
    setErrorMessage('');
  }

  const fetchListOptions = async (value: string) => {
    setSearchValue(value);
    setListItems([]);
    const res = await handleFetchListOptions(value);

    if (res.status === HTTP_STATUS.SUCCESS) {
      const data = res.data.data.items;

      if (data.length > 0) {
        const listIData: any = data.map((item: any) => ({
          label: item.name,
          value: item.id
        }));
        //

        setListItems(listIData);
      }

    }

  }

  const handleSubmitAddItem = async () => {
    setErrorMessage('');
    if(!inputValue) {
      setErrorMessage('This field is required');
    } else {
      await handleAddNewItem(inputValue);
      handleCancelAddItem();
    }
  }

  const onChange = async (value: any) => {
    if (isMultipleSelect && value.length <= maxSelectedOption || !isMultipleSelect ) {
      handleChangeSelect(value);
      const res = await handleFetchListOptions(value);

      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;

        if (data.length > 0) {
          const listIData: any = data.map((item: any) => ({
            label: item.name,
            value: item.id
          }));

          setListItems(listIData);
        }

      }
    }
  }

  const onFocus = async () => {
    if (!isFetchingAllData) {
      const res = await handleFetchListOptions('', 1);
      
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;
        if (data.length > 0) {
          const listIData: any = data.map((item: any) => ({
            label: item.name,
            value: item.id
          }));
          
          setListItems(listIData);

          const meta = res.data.data.meta;
          const current = meta.currentPage;
          const total =  meta.totalPages;
          setCurrentPage(current);
          setTotalPages(total);
          if (currentPage === totalPages) {
            setIsFetchingAllData(true);
          }
        }
        
      }
      
    }
  };

  const onPopupScroll =async (e: any) => {
    // console.log('selectRef', selectRef.current)
    // console.log('check run here', currentPage)
    const { target } = e;
    // console.log('target.scrollTop', target.scrollTop);
    // console.log('target.scrollHeight', target.scrollHeight);
    const condition = (target as any).scrollTop + (target as any).offsetHeight ===
          (target as any).scrollHeight;
    if (condition) {
      if (currentPage < totalPages) {
        setFetching(true);

        const res =  await handleFetchListOptions(searchValue, currentPage + 1);

        if (res.status === HTTP_STATUS.SUCCESS) {
          const data = res.data.data.items;

          if (data.length > 0) {
            setCurrentPage(currentPage + 1);
            
            const listIData: any = data.map((item: any) => ({
              label: item.name,
              value: item.id
            }));

            const newListData = listItems.concat(listIData);
            // setScrollHeight(target.scrollTop);

            setListItems(newListData);
            

          }
          
        }
        setFetching(false);
      }
    }
  };
  
  // useEffect(() => {
  //   if (selectRef.current) {
  //     console.log('selectRef.current', selectRef.current)
  //     const itemHeight = selectRef.current.scrollHeight / listItems.length;
  //     console.log('itemHeight', itemHeight)
  //     // selectRef.current.scrollTop = '10000';
  //     selectRef.current.getElementsByClassName('rc-virtual-list-holder')[0].scrollTop = scrollHeight;
  //
  //
  //     // console.log('selectRef.current.scrollTop', selectRef.current.scrollTop)
  //   }
  // }, [listItems])
    
  return (
    <div className='select-search-add-container'>
      <Select
        mode={isMultipleSelect ? 'multiple' : undefined}
        defaultValue={defaultValue || []}
        placeholder={selectPlaceholder}
        showSearch={true}
        size='large'
        onDropdownVisibleChange={handleCancelAddItem}
        onSearch={fetchListOptions}
        filterOption={false}
        onFocus={onFocus}
        onChange={onChange}
        allowClear={allowClear}
        className='select-search-add-item'
        onPopupScroll={onPopupScroll}
        onInputKeyDown={c => {
          if (c.keyCode === 13) {
            c.preventDefault();
            c.stopPropagation();
          }
        }}
        dropdownRender={(menu) => (
          <div className='add-item-container' ref={selectRef}>
            {menu}
            {fetching && (
              <span className='loading-select-icon'>
                <Spin size="small" />
              </span>
            )}
            {showAddItemForm
              ? (
                <form className={'add-item-form'}>
                  <InputTextField
                    value={inputValue}
                    handleChange={handleChangeInput}
                    inputName={'name'}
                    inputType={'text'}
                    inputPlaceholder={inputPlaceHolder}
                    isInputInvalid={!!errorMessage}
                    maxLength={150}
                    onPressEnter={c => {
                      if (c.keyCode === 13) {
                        c.preventDefault();
                        c.stopPropagation();
                      }
                    }}
                  />
                  <ErrorMessage errorMessage={errorMessage} />
                  <div className='add-item-buttons'>
                    <div className='cancel-btn'>
                      <BaseButton
                        buttonLabel={cancelButtonLabel}
                        handleClick={handleCancelAddItem}
                      />
                    </div>

                    <div className='submit-btn'>
                      <BaseButton
                        buttonLabel={submitButtonLabel}
                        handleClick={handleSubmitAddItem}
                        type={'primary'}
                        // htmlType={'submit'}
                      />
                    </div>
                  </div>
                </form>
              )
              : (
                <div onClick={handleShowAddItemForm} className='add-item-label'>
                  <AddNewItemIcon />
                  {addNewItemLabel}
                </div>
              )
            }
          </div>
        )}
        // options={listItems}
      >
        {listItems.map((item: {label: string, value: string}) => {
          return (
            <Option value={item.value} key={item.value} className='option-item'>
              {item.label}
              {!isMultipleSelect && ((defaultValue.length > 0 && defaultValue[0].value === item.value  ) || (defaultValue && defaultValue === item.value ))  && <SelectedItemIcon /> }
            </Option>
          );
        })}
      </Select>
    </div>

  );
};

export default SelectSearchAndAddItem;
