import React from 'react';
import ReactLoading from 'react-loading';
import './LoadingIcon.scss';

export default function LoadingIcon (): JSX.Element {
  return (
    <div className='loading-icon'>
      <ReactLoading type={'spin'} color={'#374151'} width={'60px'} height={'60px'}/>
    </div>
  );
}