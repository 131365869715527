import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackToListIcon from '@/assets/icon/ic_back_to_list.svg';
import {IBackToListPageButton} from '@/component/common/interface/BackToListPageButton';
import './BackToListPageButton.scss';


const BackToListPageButton: React.FC<IBackToListPageButton> = ({
  title,
  listPageUrl
}) => {
  const navigate = useNavigate();
  
  const BackToListPageIcon = () => {
    return <img src={BackToListIcon} alt="plus-icon" className='icon' onClick={navigateBackToListPage}/>;
  };
  
  const navigateBackToListPage = () => {
    navigate(`${listPageUrl}`)
  };
  
  return (
    <div className='back-to-list-page-button'>
      <BackToListPageIcon />
      <h1 className='title' onClick={navigateBackToListPage}>{title}</h1>
    </div>
  );
};

export default BackToListPageButton;