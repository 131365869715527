import React from 'react';
import { Layout } from 'antd';
import {useSelector} from 'react-redux';
import './HeaderPage.scss';
import {getCookie} from 'react-use-cookie';
import { RootState } from '@/redux/rootReducer';
const { Header } = Layout;

const HeaderPage: React.FC = () => {
  const keyPath = useSelector((state: RootState) => state.commonReducer.keyPath);
  const headerPage = useSelector((state: RootState) => state.commonReducer.headerPage);

  const userInfo = useSelector((state:RootState) => state.commonReducer.userInfo);
  
  const userFullName = userInfo.fullName || getCookie('user_fullname');

  return (
    <Header className={'header-page'}>
      <div className='page-name'>
        {headerPage}
      </div>
      <div className='user-info'>
        <span className='user-fullname'>
          {userFullName}
        </span>
      </div>
    </Header>
  );
};

export default HeaderPage;
