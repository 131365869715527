import React from 'react';
import { IBaseTable } from '@/component/common/interface/BaseTable';
import './BaseTable.scss';

import TableBodyRows from 'src/component/common/BaseTable/TableBodyRows';
import { Checkbox, Empty } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RootState } from '@/redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedIds,
} from '@/redux/slice/commonListPageSlice';
import { exitArrs } from '@/utils';
import loadingLogo from '@/assets/giphy.gif';

const BaseTable: React.FC<IBaseTable> = ({
  headers, 
  dataTable = [], 
  detailPageRoute,
  subKeyPath,
  isEditRowTable,
  allowSelection,
  isShowHeaderAction,
  isDraggableTable = false,
  showResendButton,
  showDeleteIcon,
  showEditIcon,
  type,
  isLoading ,
  isTableInDetailPage
}) => {
  const showActionColumn = !!(showEditIcon || showDeleteIcon);
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);
  const dispatch = useDispatch();
  
  const onChange = (e: CheckboxChangeEvent) => {
    const exits = exitArrs(dataTable, selectedIds)
    if (exits.length === dataTable.length) {
      dispatch(setSelectedIds([]))
    } else {
      const result = [...selectedIds, ...dataTable.map(item => item.id)]
      const arrs = result.filter((a: string, i: number) => result.findIndex((s: string) => a === s) === i)
      dispatch(setSelectedIds(arrs))
    }
  };

  const checkIntermediate = () => {
    const exits = exitArrs(dataTable, selectedIds)
    return exits.length > 0 && exits.length < dataTable.length
  }

  const checkCheckAll = () => {
    const exits  = exitArrs(dataTable, selectedIds)
    return exits.length === dataTable.length
  }
  const generateBodyTable = () => {
    if (isLoading) {
      return (
        <tbody className='table-body'>
          <tr>
            <td colSpan={headers.length + 1} className='row-no-data'>
              <img src={loadingLogo} alt='loading' style={{ width: '100px' }}/>
            </td>
          </tr>
        </tbody>
      )
    }

    return dataTable.length > 0 
      ? (
        <tbody className='table-body'>
          <TableBodyRows
            draggable={isDraggableTable && isShowHeaderAction}
            headers={headers}
            dataTable={dataTable}
            detailPageRoute={detailPageRoute}
            subKeyPath={subKeyPath}
            isEditRowTable={isEditRowTable}
            allowSelection={allowSelection}
            isShowHeaderAction={isShowHeaderAction}
            showResendButton={showResendButton}
            showDeleteIcon={showDeleteIcon}
            showEditIcon={showEditIcon}
            type={type}
            isTableInDetailPage={isTableInDetailPage}
          />
        </tbody>
      )
      : (
    
        <tbody className='table-body'>
          <tr>
            <td colSpan={headers.length + 1} className='row-no-data'>
              <Empty />
            </td>
          </tr>
        </tbody>
      ) 
  }


  return (
    <span className='base-table-container'>
      <table className='table'>
        <thead>
          <tr className='table-header-row'>
            {allowSelection && <th className='table-header-column' style={{ width: '150px'}}>
              <Checkbox onChange={onChange} indeterminate={checkIntermediate()} checked={checkCheckAll()}/> Select All
            </th>}
            {headers.map((itemHeader: any, index: number) => {
              return (
                <th 
                  key={index} 
                  className='table-header-column'
                  style={{maxWidth: `${itemHeader.width}`, wordWrap: 'break-word'}}
                  align={'left'}
                >
                  {itemHeader.label}
                </th>
              )
            })}
            {!isShowHeaderAction && showActionColumn && <th className='table-header-column action-column' align={'left'}>
              ACTION
            </th>}
          </tr>
        </thead>

        {generateBodyTable()}
      </table>
    </span>
  );
};

BaseTable.defaultProps = {
  isEditRowTable: true,
  detailPageRoute: '',
  subKeyPath: '',
  showResendButton: false,
  allowSelection: false,
  isShowHeaderAction: false,
  showDeleteIcon: true,
  showEditIcon: true,
  isLoading: false,
  isTableInDetailPage: false,
}

export default BaseTable;
