import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialCommonListPageState from '@/redux/constant/commonListPage';
import { INotification } from '@/component/common/interface/Notification';

export const commonListPage = createSlice({
  name: 'commonListPageSlice',
  initialState: initialCommonListPageState,
  reducers: {
    setSelectedId(state, action: PayloadAction<string>) {
      state.selectedId = action.payload
    },
    setSelectedIds(state, action: PayloadAction<string[]>) {
      state.selectedIds = action.payload
    },
    setSelectedUpdateId(state, action: PayloadAction<string>) {
      state.selectedUpdateId = action.payload
    },
    setIsUserClickEditIcon(state, action: PayloadAction<boolean>) {
      state.isUserClickEditIcon = action.payload
    },
    setSelectedItem(state, action: PayloadAction<any>) {
      state.selectedItem = action.payload
    },
    setShowDeleteModal(state, action: PayloadAction<boolean>) {
      state.showDeleteModal = action.payload
    },
    setDeletedItemName(state, action: PayloadAction<string>) {
      state.deletedItemName = action.payload  
    },
    setIsUserClickSubmitEditIcon(state, action: PayloadAction<boolean>) {
      state.isUserClickSubmitEditIcon = action.payload
    },
    setIsUserClickClickableColumn(state, action: PayloadAction<boolean>) {
      state.isUserClickClickableColumn = action.payload
    },
    setNoti(state, action: PayloadAction<INotification | null>) {
      state.noti = action.payload
    },
    setIsUserClickResendButton(state, action: PayloadAction<boolean>) {
      state.isUserClickResendButton = action.payload
    },
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload
    },
    setListReorderItems(state, action: PayloadAction<any[]>) {
      state.listReorderItems = action.payload
    }
  }
});

export const {
  setSelectedUpdateId,
  setSelectedId,
  setSelectedIds,
  setSelectedItem, 
  setShowDeleteModal,
  setDeletedItemName,
  setIsUserClickSubmitEditIcon,
  setIsUserClickClickableColumn,
  setNoti,
  setIsUserClickResendButton,
  setSearchValue,
  setListReorderItems
} = commonListPage.actions;

export default commonListPage.reducer