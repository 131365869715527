export const clean = (object: any) => {
  Object.keys(object).forEach(key => {
    if (object[key] === undefined || object[key] === null || object[key] === '') {
      delete object[key];
    }
  });
}

export const capitalizeFirstLetter = (word: string) =>  {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
  