import React, {useEffect, useState} from 'react';
import {HTTP_STATUS, STATUSES_LIST} from '@/constants';
import {
  IDetailCategoryEditMode,
  IDetailCategoryErrorMessages,
  IDetailCategoryItem
} from '@/pages/templates/interface/DetailCategoryPage';
import BaseTextArea from '@/component/common/TextArea';
import {defaultCategoryErrorMessage} from '@/component/templates/form/DetailCategoryForm';
import InputTextField from '@/component/common/InputTextField';
import SelectBox from '@/component/common/SelectBox';
import ErrorMessage from '@/component/common/ErrorMessage';
import services from '@/services';
import BaseButton from '@/component/common/Button';
import {checkMinLength, checkValidatePriority} from '@/utils';
import {setIsUserAccessAnEditablePage, setShowLoadingIcon} from '@/redux/slice/common';
import {useDispatch} from 'react-redux';
import MultipleSearchSelect from '@/component/common/MutipleSearchSelect';
import {IUpdateCategoryPayload} from '@/services/interface/category.interface';

const DetailCategoryEditMode: React.FC<IDetailCategoryEditMode> = ({
  id,
  item,
  isUserClickCancelButton,
  setIsUserClickCancelButton,
  setIsEditMode,
  getDetailCategoryById,
  setIsUpdateSuccess,
  handleCancel
}) => {
  const listStatuses = STATUSES_LIST;
  const dispatch = useDispatch();

  const getSelectedProperties = (data: {id: string, name: string}[]) => {
    return data.map((item: {id: string, name: string}) => ({label: item.name, value: item.id}))
  }
  
  const [categoryInfo, setCategoryInfo] = useState<IDetailCategoryItem>(item);
  const [selectedProperties, setSelectedProperties] = useState<any[]>(getSelectedProperties(item.properties));
  const [errorMessages, setErrorMessages] = useState<IDetailCategoryErrorMessages>(defaultCategoryErrorMessage);

  const generateCategoryStatus = (data: string) => {
    const result = listStatuses.find((status: {value: string, label: string}) => status.value === data);
    return result
  }

  const handleChangeFormCategory = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let inputValue = event.target.value;
    if (event.target.name === 'priority') {
      inputValue = event.target.value.replace(/\D/g, '');
    }
    setCategoryInfo((prev: IDetailCategoryItem) => ({
      ...prev,
      [event.target.name]: inputValue
    }))
  } 
  
  const handleSelectStatus = (status: any) => {
    setCategoryInfo((prev: IDetailCategoryItem) => ({
      ...prev,
      status: status
    }))
  }

  const handleChangeSelectProperties = (properties: string[]) => {
    setSelectedProperties(properties)
  }

  const handleSearchData = async (value: string) => {
    try {
      const query = {search: value, limit: 10};
      const res = await services.searchListProperties(query); 
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        return res;
      }
    } catch (err: any) {
      return [];
    }
  }


  const handleUpdateCategory = async (formData: IUpdateCategoryPayload) => {
    try {
      dispatch(setShowLoadingIcon(true));
      if (id) {
        const res = await services.updateCategory(id, formData);
        if (res && res.status === HTTP_STATUS.SUCCESS) {
          setIsEditMode(false);
          getDetailCategoryById();
          setIsUpdateSuccess(true);
          setIsUserClickCancelButton(false);
          dispatch(setIsUserAccessAnEditablePage(false));

        }
      }
      dispatch(setShowLoadingIcon(false));
    } catch (err: any) {
      const errorMsg = err.response.data.message;
      if (errorMsg.includes('duplicate') || errorMsg.includes('Duplicate')) {
        setErrorMessages({
          ...errorMessages,
          name: 'This category is already exists. Please try another name'
        }
        )
      }
      dispatch(setShowLoadingIcon(false));
    }
  }
  
  const handleCheckValidateDetailCategory =  (formData: IDetailCategoryItem) => {
    const errorMessages: IDetailCategoryErrorMessages = defaultCategoryErrorMessage;

    errorMessages.name = checkMinLength(formData.name, 2);
    errorMessages.priority = checkValidatePriority(formData.priority);
    return errorMessages;
  }
  
  const handleUpdateDetailCategory = () => {

    const errorMsg =  handleCheckValidateDetailCategory(categoryInfo);
    setErrorMessages({
      ...errorMessages,
      ...errorMsg
    });
    
    let errorCount = 0;
    let key: keyof IDetailCategoryErrorMessages;
    for (key in errorMsg) {
      if (errorMsg[key] !== '') errorCount ++;
    }

    let uploadProperties;
    if (typeof selectedProperties[0] === 'string') {
      uploadProperties = selectedProperties
    } else {
      uploadProperties = selectedProperties.map((item: any) => item.value);
    }

    if (errorCount === 0) {
      const uploadForm = {
        name: categoryInfo.name,
        description: categoryInfo.description,
        status: parseInt(categoryInfo.status),
        index: parseInt(categoryInfo.priority),
        props: uploadProperties
      };
      handleUpdateCategory(uploadForm)
    }
  }
  
  const handleResetErrorMessages = () => {
    const defaultErrorMessage = {
      name: '',
      priority: ''
    }
    setErrorMessages({
      ...errorMessages,
      ...defaultErrorMessage
    })
  }
  
  useEffect(() => {
    handleResetErrorMessages()
  }, [isUserClickCancelButton])

  return (
    <>
      <div className='header'>
        <div className='category-name'>
          <InputTextField
            value={categoryInfo.name}
            inputType={'text'}
            inputPlaceholder={'Enter category name'}
            inputName={'name'}
            handleChange={handleChangeFormCategory}
            isInputInvalid={!!errorMessages.name}
            maxLength={150}
            minLength={2}
          />
          <ErrorMessage errorMessage={errorMessages.name}/>
        </div>

        <div>
          <div className='submit-btn-container'>
            <div className='btn cancel-btn'>
              <BaseButton
                handleClick={handleCancel}
                buttonLabel={'Cancel'}
              />
            </div>

            <div className='btn'>
              <BaseButton
                handleClick={handleUpdateDetailCategory}
                buttonLabel={'Save'}
                type={'primary'}
              />
            </div>
          </div>
        </div>
      </div>
        
        
      <div className='detail-category-items'>
        <div className='detail-category-block'>
          <div className='category-item-container'>
            <div className='category-header'>
              Description :
            </div>
            <div className='category-item'>
              <BaseTextArea 
                handleChange={handleChangeFormCategory}
                name={'description'}
                placeholder={'Enter description'} 
                value={categoryInfo.description}
              />
            </div>
          </div>

          <div className='category-item-container'>
            <div className='category-header'>
              No:
            </div>
            <div className='category-item'>
              <InputTextField
                value={categoryInfo.priority}
                inputType={'text'}
                inputPlaceholder={'Enter no'}
                inputName={'priority'}
                handleChange={handleChangeFormCategory}
                isInputInvalid={!!errorMessages.priority}
              />
              <ErrorMessage errorMessage={errorMessages.priority}/>
            </div>
          </div>

          <div className='category-item-container'>
            <div className='category-header'>
              Template count:
            </div>
            <div className='category-item'>
              <InputTextField
                value={categoryInfo.templateCount}
                inputType={'text'}
                inputPlaceholder={'Enter no'}
                inputName={'templateCount'}
                handleChange={handleChangeFormCategory}
                disabled={true}
              />
            </div>
          </div>

        </div>

        <div className='detail-category-block'>
          <div className='category-item-container'>
            <div className='category-header'>
              Created at:
            </div>
            <div className='category-item'>
              <InputTextField
                value={categoryInfo.createdAt}
                inputType={'text'}
                inputPlaceholder={'Enter no'}
                inputName={'createdAt'}
                handleChange={handleChangeFormCategory}
                disabled={true}
              />
            </div>
          </div>


          <div className='category-item-container'>
            <div className='category-header'>
              Status:
            </div>
            <div className='category-item'>
              <SelectBox 
                listItems={listStatuses}
                handleChange={handleSelectStatus} 
                defaultValue={generateCategoryStatus(categoryInfo.status)}
                disabled={categoryInfo.templateCount === '0'}
              />
            </div>
          </div>
            
          <div className='category-item-container'>
            <div className='category-header'>
              Property:
            </div>
            <div className='category-item'>
              <MultipleSearchSelect
                placeholder={'Enter property'}
                defaultValue={selectedProperties}
                handleChange={handleChangeSelectProperties}
                fetchListOptions={handleSearchData}
              />
            </div>
          </div>

        </div>

      </div>

    </>
  );
};

export default DetailCategoryEditMode;