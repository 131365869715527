/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import ContentPageHeader from '@/component/common/ContentPageHeader';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import { MenuProps } from 'antd';
import './ContentAiListPage.scss';
import services from '@/services';
import { ICategoryListSingleItem } from '@/pages/templates/interface/CategoryListPage';
import {DIFFUSION_SAMPLER_MAP, HTTP_STATUS, SORT_ORDER} from '@/constants';
import {USER_ACTIONS, USER_RESOURCE} from '@/constants/roles';
import { convertDataFilterTags, removeDataFilterTags } from '@/utils';
import {cancelToken} from '@/http-common';
import BaseTable from '@/component/common/BaseTable';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {useSearchParams} from 'react-router-dom';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import useDebounce from '@/hooks/useDebounce';
import { IConfigFilter } from '@/component/common/interface/Filter';
import dayjs from 'dayjs';
import {setIsUserClickSubmitEditIcon, setSelectedIds, setNoti, setSelectedId, setSelectedUpdateId, setShowDeleteModal, setSelectedItem} from '@/redux/slice/commonListPageSlice';
import EditItemModal from '@/component/common/EditItemModal';
import { IUpdateMultipleCategoryStatusPayload } from '@/services/interface/category.interface';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';
import AiContentModal from '@/component/content/AiContentModal';
import { capitalizeFirstLetter, clean } from '@/utils/helper';
import ImgDefault from '@/assets/img_default.png';
import {setHeaderPage} from '@/redux/slice/common';
import usePreviousValue from '@/hooks/usePreviousValue';

const items: MenuProps['items'] = [
  {
    label: 'Change to Active',
    key: 'active',
  },
  {
    label: 'Change to Inactive',
    key: 'imactive',
  },
];

const configFilters: IConfigFilter[] = [
  {
    label: 'Number of Order',
    key: 'priority',
    type: 'range',
  },
  {
    label: 'Status',
    key: 'statuses',
    type: 'checkbox',
  },
  {
    label: 'Create Date',
    key: 'createdAt',
    type: 'range-date',
  }
]

const ContentAiListPage = () => {
  const dispatch = useDispatch();
  dispatch(setHeaderPage('App Content'));
  
  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);

  const [categoryListPermissions, setCategoryListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowHeaderAction, setIsShowHeaderAction] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [listData, setListData] = useState<ICategoryListSingleItem[]>([]);
  const [openĐrawer, setOpenDrawer] = useState<boolean>(false);
  const [showDeletesModal, setShowDeletesModal] = useState<boolean>(false);
  const [valueSelectedMenu, setValueSelectedMenu] = useState<string>();
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

 
  const [filterDatas, setFilterDatas] = useState({});
  const filterDatasJsonString = JSON.stringify(filterDatas);
  const [filterList, setFilterList] = useState<any>([]);
  const [queryData, setQueryData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);
  const [isNotiHasNotTemplate, setIsNotiHasNotTemplate] = useState<boolean>(false);
  const [editItemModalLabel, setEditItemModalLabel] = useState<string>('');
  
  const tableHeaders = [
    {
      label: 'PROVIDER',
      value: 'provider',
      width: '200px',
      editType: 'input'
    },
    {
      label: 'CATEGORY',
      value: 'category',
      width: '200px',
      editType: 'input',
      isCutText: true,
      maximumTextLength: 30
    },
    {
      label: 'Name',
      value: 'name',
      width: '200px',
      editType: 'input',
      isCutText: true,
    },
    {
      label: 'CONFIG',
      value: 'configContent',
      width: '600px',
    },
    {
      label: 'THUMBNAIL',
      value: 'thumbnailContent',
      width: '300px',         
      isImageColumn: true,
    },
    {
      label: 'STATUS',
      value: 'statusContent',
      width: '120px',
      isStatusColumn: true,
    },
  ]
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);

  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        });
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        });
        getListAIContent()
      }

    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  useEffect(() => {
    dispatch(setSelectedUpdateId(''))
    dispatch(setSelectedIds([]))
    dispatch(setSelectedItem(null))
  }, [])

  useEffect(() => {
    if (selectedItem?.id) {
      setShowModal(true)
    }
  }, [selectedItem])
  
  const updateCategoryStatus = async (status: string) => {
    const hasTemplate = await checkCategoryHasTemplate()
    if (hasTemplate) {
      setShowEditItemModal(true);
      setEditItemModalLabel('Are you sure you want to save this change?')
    } else {
      setShowEditItemModal(true);
      setIsNotiHasNotTemplate(true)
      setEditItemModalLabel('This category does not have any template yet. You cannot activate it.')
    }
  }

  const checkCategoryHasTemplate = async () => {
    const categories = await Promise.all(selectedIds.map( async (id) => {
      const res: any = await services.getCategory(id)
      return res.data.data
    }))
    return categories.every((category: any) => category.templateCount)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleChangeStatus = async () => {
    const hasTemplate = await checkCategoryHasTemplate()
    if (!hasTemplate) {
      setShowEditItemModal(false);
      return;
    }
    const payload: IUpdateMultipleCategoryStatusPayload = {
      categoryIds: selectedIds,
      status: valueSelectedMenu === 'active' ? 1 : 0, 
    } 
    try {
      const res = await services.updateMultipleCategory(payload);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        setShowEditItemModal(false);
        dispatch(setSelectedIds([]));
        getListAIContent()
        dispatch(setNoti({
          open: true,
          message: 'Update Status Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
    } catch (err: any) {
      const { message } = err?.response?.data
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Update Status Fail!',
      }))
    }
  }

  const handleFilter = () => {
    setOpenDrawer(true);
  };

  const handleSelect = () => {
    setIsShowHeaderAction(!isShowHeaderAction);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setValueSelectedMenu(e.key);
    updateCategoryStatus(e.key)
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(setSelectedItem(null))
  };
  
  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }
  
  const handleSubmitDelete = async () => {
    try {
      const res =  await services.deleteSingleCategory(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        getListAIContent()
        dispatch(setNoti({
          open: true,
          message: 'Delete Category Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
    } catch (err: any) {
      const { message } = err?.response?.data
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Delete Category Fail!',
      }))
    }
  }

  const getListAIContent = async () => {
    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit');
      const query = {
        search: searchValue.trim(),
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
      };
      const res = await services.getAIContents(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        const meta = res.data.data.meta;
        const listItems = data.items.map((item: any) => {
          return {
            ...item,
            category: item.imageTemplateCategory?.name,
            configContent : generateConfigContent(item.config),
            thumbnailContent: item.thumbnail,
            statusContent: item.status
          };
        });
        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
          totalPages: meta.totalPages
        })
      }
    } catch (err: any) {
      console.log('err', err);
    }
  };

  // const generateThumbnail = (thumbnail: string) => {
  //   return thumbnail ? <img src={thumbnail} width="200px" onError={({ currentTarget }) => {
  //     currentTarget.onerror = null;
  //     currentTarget.src=ImgDefault;
  //   }}/> : ''
  // }
  //
  // const generateStatusContent = (status: number) => {
  //   return (
  //     <div className='div-value'>
  //       <div className='dot' style={{ background: status === 1 ? '#74C059' : '' }}/>
  //       <label className='value'>{status === 1 ? 'Active' : 'Inactive'}</label>
  //     </div>
  //   )
  // }

  const generateConfigContent = (data: any) => {
    clean(data)
    return (
      <ul>
        {Object.keys(data).map((item: any, index: number) => {
          if (item === 'sampler') {
            return <li key={index}>{capitalizeFirstLetter(item)} : {DIFFUSION_SAMPLER_MAP.find((sampler: any) => sampler.value === data[item])?.label}</li>
          }
          return (
            <li key={index}>{capitalizeFirstLetter(item)} : {data[item]}</li>
          )
        })}
      </ul>
    )
  } 
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    dispatch(setSelectedIds([]))
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    setSearchParams(searchParams);
    setQueryData(query);
  }

  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemModal(true);
      setEditItemModalLabel('Are you sure you want to save this change?')
    }
  }
  
  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon])

  useEffect(() => {
    const permissions =  getListPermissionsByResource(userPermissions, USER_RESOURCE.CATEGORY);
    setCategoryListPermissions(permissions);
    getListAIContent();     
    return () => cancelToken()

  }, [queryData?.page, queryData?.limit, filterDatasJsonString]);

  //filter
  const submitFilter = async (values: any) => {
    const filters = Object.assign(values, {})
    if (filters.createdAtFrom) {
      filters.createdAtFrom = dayjs(filters.createdAtFrom).format('YYYY-MM-DD')
    }
    if (filters.createdAtTo) {
      filters.createdAtTo = dayjs(filters.createdAtTo).format('YYYY-MM-DD')
    }
    setFilterDatas({
      ...filters,
    })
    const filterListMap = convertDataFilterTags(configFilters, filters)
    const hasCaterofyFilter = configFilters.filter((c: IConfigFilter) => c.key === 'category')
    if (hasCaterofyFilter) {
      await Promise.all(filterListMap.map(async (item: any) => {
        if (item.key === 'categories') {
          const categoryMap = await Promise.all(item.value.map(async (it: any) => {
            const res = await services.getCategory(it)
            console.log('res.data.data', res.data.data);

            if (res && res.data.statusCode === HTTP_STATUS.SUCCESS) {
              it = res.data.data.name
            }
            return it
          }))
          item.value = categoryMap.join(', ')
        }
        return item;
      }))
    }
    setFilterList(filterListMap)
    setOpenDrawer(false)
  }

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }

  const handleCancelEdit = () => {
    setShowEditItemModal(false);
    setEditItemModalLabel('');
    setIsNotiHasNotTemplate(false)
    dispatch(setIsUserClickSubmitEditIcon(false));
  }
  
  const refreshList = () => {
    getListAIContent();
  }


  return (
    <div className="category-list-container">
      <ContentPageHeader header={'AI Content'} />
      <div>
        <div className="btn-container">
          <div className="flex-left">
            <div className="flex-left-input">
              <InputSearchField
                value={searchValue}
                inputPlaceholder={'Search...'}
                inputName={'search'}
                handleChange={handleChangeSearch}
                // handleClearSearch={handleClickSearch}
              />
            </div>
            {/* <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} /> */}
          </div>

          <div
            className={!isShowHeaderAction ? 'flex-right' : 'flex-right-custom'}
          >
            {/* {!isShowHeaderAction
                && !!(checkPermission(categoryListPermissions, USER_ACTIONS.EDIT) || checkPermission(categoryListPermissions, USER_ACTIONS.DELETE))
                && (
                  <div className="select-btn">
                    <BaseButton buttonLabel={'Select'} handleClick={handleSelect} className={selectedUpdateId ? 'disabled' : ''}/>
                  </div>
                )} */}

            {/* {isShowHeaderAction && (
              <Dropdown
                menu={menuProps}
                dropdownRender={(item: React.ReactNode) => (
                  <div className="custom-dropdown">{item}</div>
                )}
              >
                <Button
                  size="large"
                  style={{
                    backgroundColor: '#f7f7f7',
                    marginRight: 16,
                    width: 'max-content'
                  }}
                  className={!selectedIds.length ? 'disabled' : ''}
                >
                  <Space
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: '22px',
                    }}
                  >
                    Change Status
                    <DownOutlined
                      style={{
                        fontSize: 14,
                      }}
                    />
                  </Space>
                </Button>
              </Dropdown>
            )}
            {isShowHeaderAction && checkPermission(categoryListPermissions, USER_ACTIONS.DELETE) && (
              <BaseButton
                handleClick={() => setShowDeletesModal(true)}
                buttonLabel={'Delete'}
                type="secondary"
                className={!selectedIds.length ? 'disabled' : ''}
              />
            )} */}

            {/* {isShowHeaderAction && (
              <Button
                style={{
                  border: '1px solid #F37F13',
                  color: '#F37F13',
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 16,
                  height: 40,
                  lineHeight: '22px',
                  width: '30%',
                }}
                onClick={() => {
                  setIsShowHeaderAction(false);
                }}
              >
                Cancel
              </Button>
            )} */}
            {!isShowHeaderAction && checkPermission(categoryListPermissions, USER_ACTIONS.CREATE) && (
              <ButtonIcon
                buttonLabel={'Add AI Content'}
                handleClick={handleShowModal}
              />
            )}
          </div>
        </div>
        {/* {filterList.length > 0 && <div className='div-filter'>
          {filterList.map((item: any) => {
            return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
          })}
        </div>} */}
        <BaseTable
          headers={tableHeaders} 
          dataTable={listData}
          detailPageRoute={'category-detail'}
          allowSelection={isShowHeaderAction}
          isShowHeaderAction={isShowHeaderAction}
          showDeleteIcon={false}
          showEditIcon={checkPermission(categoryListPermissions, USER_ACTIONS.EDIT)}
          type='content-ai'
        />

        <BasePagination
          currentPage={paginationData.currentPage}
          itemsPerPage={paginationData.itemsPerPage}
          totalItems={paginationData.totalItems}
          totalPages={paginationData.totalPages}
          handleChangePage={handleChangePage}
        />

        <DeleteItemModal
          showModal={showDeleteModal}
          label={'Are you sure you want to remove this'}
          handleCancel={handleCancelDelete}
          handleSubmitDelete={handleSubmitDelete}
          deletedValueItem={selectedItem?.name}
          isDeleteSpecial={true}
          deletedValueType={'category'}
        />
      </div>
      {showModal && <AiContentModal
        showModal={showModal}
        handleCancel={handleCloseModal}
        refresh={refreshList}
      />}
    </div>
  );
};

export default ContentAiListPage;
