
import dayjs from 'dayjs';
const now = dayjs()

export const REGEX = {
  EMAIL_VALIDATE: /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

};
export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  DELETED: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  LENGTH_REQUIRED: 411,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export const CONTENT_TYPE = {
  JSON: 'application/json',
  JAVASCRIPT: 'application/javascript',
  XML: 'application/xml',
  ZIP: 'application/zip',
  PDF: 'application/pdf',
  SQL: 'application/sql',
  MPEG: 'audio/mpeg',
  OGG: 'audio/ogg',
  FORMDATA: 'multipart/form-data',
  CSS: 'text/css',
  HTML: 'text/html',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
}

export const FILE_TYPE = {
  JPG: '.jpg',
  JPEG: '.jpeg',
  PNG: '.png',

  GIF: '.gif',
    
  MP4: '.mp4',

  ZIP: '.zip',
  JSON: '.json'
}

export const SORT_ORDER = {
  DESC: 'desc',
  ASC: 'asc',
}

export const TEMPLATE_ORDER_PRIORITY = {
  WEB_PRIORITY: 'webPriority',
  IOS_PRIORITY: 'iosPriority',
  ANDROID_PRIORITY: 'androidPriority'
}

export const TEMPLATE_PLATFORM = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android'
}

export const STATUSES_LIST = [
  {value: '0', label: 'Inactive', color: '#DE2F17'},
  {value: '1', label: 'Active', color: '#74C059'}
];

export const GENDER_LIST = [
  {value: 0, label: 'Female'},
  {value: 1, label: 'Male'}
];

export const SUBSCRIPTION_LIST = [
  {value: 0, label: 'Free'},
  {value: 1, label: 'Ads'},
  {value: 2, label: 'Premium'},
  {value: 3, label: 'Ads or Premium'}
];


//DiffusionSamplerMap
export const DIFFUSION_SAMPLER_MAP = [
  {value: 0, label: 'Sampler ddim'},
  {value: 1, label: 'Sampler ddpm'},
  {value: 2, label: 'Sampler k euler'},
  {value: 3, label: 'Sampler k euler ancestral'},
  {value: 4, label: 'Sampler k heun'},
  {value: 5, label: 'Sampler k dpm 2'},
  {value: 6, label: 'Sampler k dpm 2 ancestral'},
  {value: 7, label: 'Sampler k lms'},
  {value: 8, label: 'Sampler k dpmpp 2s ancestral'},
  {value: 9, label: 'Sampler k dpmpp 2m'},
  {value: 10, label: 'Sampler k dpmpp sde'}
];

//Algorithym 
export const ALGORITHYM = [
  {value: 'stable-diffusion-v1', label: 'Diffusion V1'},
  {value: 'stable-diffusion-v1-5', label: 'Diffusion V1.5'},
  {value: 'stable-diffusion-512-v2-0', label: 'Diffusion 512 V2.0'},
  {value: 'stable-diffusion-768-v2-0', label: 'Diffusion 768 V2.0'},
  {value: 'stable-diffusion-512-v2-1', label: 'Diffusion 512 v2.1'},
  {value: 'stable-diffusion-768-v2-1', label: 'Diffusion 768 v2.1'},
  {value: 'stable-inpainting-v1-0', label: 'Inpainting V1.0'},
  {value: 'stable-inpainting-512-v2-0', label: 'Inpainting 512 V2.0'},
];

export const EFFECT_CONTENT_TABS = {
  EFFECT_LIST: 'effect-list',
  CATEGORY_EFFECT: 'category-effect'
}

export const TRANSITION_CONTENT_TABS = {
  TRANSITION_LIST: 'transition-list',
  CATEGORY_TRANSITION: 'category-transition'
}

export const EDITABLE_PATHS = ['detail', 'upload', 'create', 'update'];

export const TIMEOUT = 86400;

export const MIN_LENGTH_CATEGORY_NAME = 5

export const MIN_LENGTH_CATEGORY_DESC = 10

export const startTime = 'startTime';
export const from = 'from';
export const to = 'to';
export const active = 'active'
export const inactive = 'inactive'
export const endTime = 'endTime';
export const checked = 'checked';
export const text = 'text';
export const secondary = 'secondary';
export const primary = 'primary'
export const formatDateSever = 'YYYY-MM-DD';
export const messageErrorFrom = 'From must be smaller than To';
export const messageErrorStartTime =
  'Start time must be equal or smaller than end time';

export const initFormFilter = {
  inactive: true,
  startTime: now,
  endTime: now
};