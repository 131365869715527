import authRequest from '@/services/service';
import {IAddMemberForm} from '@/component/config/interface/AddMemeberModal';
import {IUpdateRoleUserForm} from '@/component/config/interface/UpdateUserRoleModal';

const inviteNewMember = async (payload: IAddMemberForm) => {
  const response =  await authRequest.post('users/invite', payload);
  return response;
}

const getListAllMembers = async (query: {search: string, page: number, limit: number}) => {
  const response =  await authRequest.get('users', {params : query});
  return response;
};

const deleteMember = async (id: string) => {
  const response = await authRequest.delete(`users/${id}`);
  return response;
};

const getListRoles = async () => {
  const response = await authRequest.get('roles');
  return response;
};

const updateUserRole = async (id: string, payload: IUpdateRoleUserForm) => {
  const response = await authRequest.put('roles/users', payload);
  return response;
}

const config = {
  inviteNewMember,
  getListAllMembers,
  deleteMember,
  getListRoles,
  updateUserRole
};

export default config;