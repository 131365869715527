import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import PublicRoutes from './routes/public/index';
import PrivateRoutes from './routes/private/index';
import NotFoundPage from './pages/notFound/index';
import VerifyEmailPage from '@/pages/auth/VerifyEmailPage';
import './App.scss';
import Notification from '@/component/common/Notification';
import { useSelector } from 'react-redux';
import { RootState } from './redux/rootReducer';

export default function App(): JSX.Element {
  const noti = useSelector((state: RootState) => state.commonListPageReducer.noti);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicRoutes />} />
        <Route path="/verify" element={<VerifyEmailPage />} />
        <Route path="/login/*" element={<PublicRoutes />} />
        <Route path="/*" element={<PrivateRoutes />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {noti?.open && <Notification
        open={noti?.open}
        type={noti?.type}
        message={noti?.message}
        icon={noti?.icon}
      />
      }
    </BrowserRouter>
  )
}


