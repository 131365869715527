/* eslint-disable no-unsafe-optional-chaining */
import React, {useEffect, useState} from 'react';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
  HTTP_STATUS,
  SORT_ORDER,
  STATUSES_LIST,
  TEMPLATE_ORDER_PRIORITY,
  TEMPLATE_PLATFORM,
  TRANSITION_CONTENT_TABS
} from '@/constants';
import BaseTable from '@/component/common/BaseTable';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import './index.scss';
import { cancelToken } from '@/http-common';
import services from '@/services';
import { convertDataFilterTags, formatDateTime, removeDataFilterTags } from '@/utils';
import useDebounce from '@/hooks/useDebounce';
import FilterBar from '@/component/common/FilterBar';
import AdvancedFilter from '@/layouts/advancedFilter';
import { IConfigFilter } from '@/component/common/interface/Filter';
import dayjs from 'dayjs';
import FilterItemList from '@/component/common/FilterItemList';
import { platformsOptions, statusOptions } from '@/component/constant/Filter';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import EditItemModal from '@/component/common/EditItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { setIsUserClickSubmitEditIcon, setNoti, setSelectedId, setSelectedIds, setSelectedUpdateId, setShowDeleteModal } from '@/redux/slice/commonListPageSlice';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import usePreviousValue from '@/hooks/usePreviousValue';

const configFilters: IConfigFilter[] = [
  {
    label: 'Categories',
    key: 'categories',
    type: 'mutiple-select',
    itemService: services.getListTransitionCategories
  },
  {
    label: 'Platform',
    key: 'platforms',
    type: 'checkbox',
    checkBoxOptions: platformsOptions
  },
]

export default function TransitionListContainer({activeKey = '', transitionCategoryId = ''} : {activeKey?: string, transitionCategoryId?: string}): JSX.Element {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);
  const [queryData, setQueryData] = useState<any>(null);
  const [filterDatas, setFilterDatas] = useState({});
  const filterDatasJsonString = JSON.stringify(filterDatas);
  const [searchParams, setSearchParams] = useSearchParams();
  const [listData, setListData] = useState<any>([]);
  const [filterList, setFilterList] = useState<any>([]);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);
  const [editItemModalLabel, setEditItemModalLabel] = useState<string>('');
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const platformPriority = searchParams.get('platform');
  const dispatch = useDispatch();
  const {id} = useParams();
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);
  
  const tableHeaders = id ? [
    {
      label: 'NO',
      value: 'no',
      width: '120px',
      editType: 'input',
      required: true,
      editable: true,
      maximumTextLength: 50
    },
    {
      label: 'TRANSITION NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      required: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 150
    },
    {
      label: 'PLATFORM',
      value: 'platform',
      width: '150px',
    },
    {
      label: 'CATEGORY',
      value: 'category',
      isMultipleValueColumn: true,
      multipleValueKey: 'category',
      width: '250px',
      editable: !id,
      required: true,
      editType: 'multipleSelect',
      itemService: services.getListTransitionCategories,
      maxValueLength: 1,
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'THUMBNAIL',
      value: 'thumbnail',
      width: '300px'
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      editType: 'singleSelect',
      listItems: STATUSES_LIST
    },
  ] : [
    {
      label: 'NO',
      value: 'no',
      width: '120px',
      editType: 'input',
    },
    {
      label: 'TRANSITION NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      required: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 150
    },
    {
      label: 'PLATFORM',
      value: 'platform',
      width: '150px',
    },
    {
      label: 'CATEGORY',
      value: 'category',
      isMultipleValueColumn: true,
      multipleValueKey: 'category',
      width: '250px',
      editable: !id,
      required: true,
      editType: 'multipleSelect',
      itemService: services.getListTransitionCategories,
      maxValueLength: 1,
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'THUMBNAIL',
      value: 'thumbnail',
      width: '300px'
    },
  ]

  
  useEffect(() => {
    setShowEditItemModal(false)
    dispatch(setSelectedIds([]))
    dispatch(setSelectedUpdateId(''));
  }, [activeKey])

  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        });
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        });
        getListTransition()
      }

    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );


  useEffect(() => {
    getListTransition();
    return () => cancelToken()
  }, [queryData?.page, queryData?.limit, filterDatasJsonString, platformPriority]);


  const getListTransition = async () => {
    setIsLoading(true)
    try {
      dispatch(setIsUserClickSubmitEditIcon(false));
      dispatch(setSelectedUpdateId(''));
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit');
      const query: any = {
        search: searchValue.trim(),
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas,
        orderBy: 'createdAt',
        orderType: SORT_ORDER.DESC
      };
      if (platformPriority || transitionCategoryId) {
        query.orderBy = 'createdAt'
        query.platforms = platformPriority ?  [platformPriority] : [`${activeKey}`];
      }
      const res = id ? await services.getListTransitionByCategories(id, query) : await services.getListTransitions(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;
        const meta = res.data.data.meta;
        const listItems = data.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          return {
            ...item,
            createdAt,
            category: item?.categories || [],
            status: id ? item[platformQuery()] : item.webStatus,
            thumbnail: generateThumbnail(item.thumbnailUrl),
            platform: generatePlatform(item),
            createdBy: item?.createdBy?.userName || '',
            no: transitionCategoryId ? item[platformPriority ? `${platformPriority}Priority` : TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY] : item?.webPriority
          }
        });
        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
          totalCount: meta.itemCount,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
    }
  }


  const generateThumbnail = (thumbnail: string) => {
    if (!thumbnail) return '';
    return <img src={thumbnail} width='204px' height='204px'/>
  }

  const generatePlatform = (data: any) => {
    const platforms = []
    if (data.webUrl) {
      platforms.push('Website')
    }
    if (data.iosUrl) {
      platforms.push('IOS')
    }
    if (data.androidUrl) {
      platforms.push('ANDROID')
    }
    return (
      <ul>
        {platforms.map((platform: string) => {
          return (
            <li key={platform}>
              {platform}
            </li>
          )
        })}
      </ul>
    )
    
    
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setSearchValue(value)
  };
  
  const handleFilter = () => {
    setOpenDrawer(true);
  };
  
  const handleAdd = () => {
    navigate('../transition/create');
  }
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      page: page,
      limit: pageSize,
    };
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    dispatch(setSelectedIds([]))
    dispatch(setSelectedUpdateId(''));
    setSearchParams(searchParams);
    setQueryData(query);
  }

  //filter
  const submitFilter = async (values: any) => {
    const filters = Object.assign(values, {})
    if (filters.createdAtFrom) {
      filters.createdAtFrom = dayjs(filters.createdAtFrom).toISOString()
    }
    if (filters.createdAtTo) {
      filters.createdAtTo = dayjs(filters.createdAtTo).toISOString()
    }
    setFilterDatas({
      ...filters,
    })
    const filterListMap = convertDataFilterTags(configFilters, filters)
    const hasCaterofyFilter = configFilters.filter((c: IConfigFilter) => c.key === 'category')
    if (hasCaterofyFilter) {
      await Promise.all(filterListMap.map(async (item: any) => {
        if (item.key === 'categories') {
          const categoryMap = await Promise.all(item.value.map(async (it: any) => {
            const res = await services.detailTransitionCategory(it)
            if (res && res.data.statusCode === HTTP_STATUS.SUCCESS) {
              it = res.data.data.name
            }
            return it
          }))
          item.value = categoryMap.join(', ')
        } else if (item.key === 'platform') {
          item.value = item.value.join(', ')
        }
        return item;
      }))
    }
    setFilterList(filterListMap)
    setOpenDrawer(false)
  }

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }

  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }

  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon]);
  
  const handleSubmitDelete =async () => {
    setIsLoadingSubmit(true)
    try {
      const res = await services.removeTransition(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        dispatch(setSelectedUpdateId(''));
        getListTransition();
        dispatch(setNoti({
          open: true,
          message: 'Delete Transition Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        type: 'error',
        open: true,
        message: message || 'Delete Fail!',
      }))
      dispatch(setShowDeleteModal(false));
      dispatch(setSelectedId(''));
    }
  }

  const platformQuery = () => {
    console.log('platformPriority', platformPriority);
    
    let result = `${TEMPLATE_PLATFORM.IOS}Status`;
    switch (platformPriority) {
    case TEMPLATE_PLATFORM.IOS:
      result = `${TEMPLATE_PLATFORM.IOS}Status`
      break;
    case TEMPLATE_PLATFORM.ANDROID:
      result = `${TEMPLATE_PLATFORM.ANDROID}Status`
      break;
    case TEMPLATE_PLATFORM.WEB:
      result = `${TEMPLATE_PLATFORM.WEB}Status`
      break;
    }
    return result;
  }
  
  const handleSubmitEditItemRow = async () => {
    setIsLoadingSubmit(true)
    const uploadPayload: any = {
      [platformPriority ? `${platformPriority}Priority` : TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY]: +selectedItem?.no,
      id: selectedUpdateId,
      name: selectedItem.name,
      categories: selectedItem.category,
      thumbnailUrl: selectedItem.thumbnailUrl,
    }
    if (id) {
      uploadPayload[platformQuery()] = +selectedItem[platformQuery()]
    }

    try {
      const res = await services.updateTransition(uploadPayload);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getListTransition();
        dispatch(setNoti({
          open: true,
          message: 'Updated Transition Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err.response.data
      dispatch(setNoti({
        open: true,
        type: 'error',
        message,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      setIsLoadingSubmit(false)
    }
    setShowEditItemModal(false);
  }
  
  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemModal(true);
      setEditItemModalLabel('Are you sure you want to save this change?')
    }
  }

  const handleCancelEdit = () => {
    setShowEditItemModal(false);
    setEditItemModalLabel('');
    dispatch(setIsUserClickSubmitEditIcon(false));
  }
  
  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }

  return (
    <div className='transition-list-container'>
      <div className="btn-container">
        <div className="flex-left">
          <div className="flex-left-input">
            <InputSearchField 
              value={searchValue} 
              inputPlaceholder={'Search...'} 
              inputName={'search'} 
              handleChange={handleChangeSearch}
            />
          </div>
          {!id && <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/>}
        </div>

        {!id && <div className='flex-right'>
          <ButtonIcon 
            handleClick={handleAdd} 
            buttonLabel={'Add New Transition'} 
          />
          
        </div>}
      </div>
      
      {filterList.length > 0 && <div className='div-filter'>
        {filterList.map((item: any) => {
          return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
        })}
      </div>}
      <BaseTable
        headers={tableHeaders} 
        dataTable={listData}
        detailPageRoute={'update'}
        subKeyPath={'transition'}
        allowSelection={false}
        isShowHeaderAction={false}
        showDeleteIcon={true}
        showEditIcon={true}
        type="content"
        isTableInDetailPage={!!id}
        isLoading={isLoading}
      />


      <AdvancedFilter
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        content={<FilterBar setOpenDrawer={setOpenDrawer} configFilters={configFilters} submitFilter={submitFilter} filterDatas={filterDatas} />}
      />
            
      <BasePagination
        currentPage={paginationData.currentPage}
        itemsPerPage={paginationData.itemsPerPage}
        totalItems={paginationData.totalCount}
        totalPages={paginationData.totalPages}
        handleChangePage={handleChangePage}
      />

      <DeleteItemModal 
        showModal={showDeleteModal} 
        label={'Are you sure you want to delete'}
        handleCancel={handleCancelDelete}
        handleSubmitDelete={handleSubmitDelete}
        deletedValueItem={selectedItem?.name}
        isDeleteSpecial={true}
        deletedValueType={'template'}
        isLoading={isLoadingSubmit}
      />
      {showEditItemModal && <EditItemModal 
        showModal={showEditItemModal} 
        label={editItemModalLabel}
        handleCancel={handleCancelEdit} 
        handleSubmitEdit={handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />}
    </div>
  );
}
