import React, {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import Index from '../../layouts/PublicLayout';
import {Routes, Route} from 'react-router-dom';

import LoginPage from '../../pages/auth/LoginPage';
import {useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import {redirectToDefaultUrl} from '@/utils/auth';

export default function PublicRoutes(): JSX.Element {
  const [cookies] = useCookies(['access_token']);
  const userRole = useSelector((state: RootState) => state.commonReducer.userInfo.userRole) || localStorage.getItem('user_role');

  const checkUserLoginOrNot = async () => {
    if (cookies.access_token && userRole) {
      redirectToDefaultUrl(userRole);
    }
  };

  useEffect(() => {
    checkUserLoginOrNot()
  }, [cookies.access_token]);

  const routes = [
    {
      path: '/',
      element: <LoginPage />
    },
  ];
    
  return (
    <Routes>
      <Route element={<Index />} >
        {routes.map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
}
