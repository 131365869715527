/* eslint-disable no-unsafe-optional-chaining */
import {useEffect, useMemo, useState} from 'react';
import {createSearchParams, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';
import Card from '@/component/common/Card';
import './index.scss'
import InputTextField from '@/component/common/InputTextField';
import SelectBoxCustom from '@/component/common/SelectBoxCustom';
import BaseButton from '@/component/common/Button';
import { Controller, useForm } from 'react-hook-form';
import FormItem from '@/component/common/FormItem';
import TransitionListPage from '@/pages/content/Transition/TransitionListPage';
import { Tabs, TabsProps } from 'antd';
import { HTTP_STATUS, TEMPLATE_PLATFORM, TRANSITION_CONTENT_TABS } from '@/constants';
import services from '@/services';
import { formatDateTime } from '@/utils';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import ErrorMessage from '@/component/common/ErrorMessage';

const STATUSES = [{ label: 'Active', value: 1 }, { label: 'Inactive', value: 0 }]

const TransitionCategoryCreateEdit = () => {
  const { control, handleSubmit, reset, formState:{ errors }, getValues } : any = useForm({
    defaultValues: {
      name: '',
      description: '',
      no: '',
      itemCount: 0,
      createdAt: '',
      createdBy: '',
      status: '',
    }
  });
  const [activeKey, setActiveKey] = useState<string>(TEMPLATE_PLATFORM.IOS);
  const { no, description, itemCount, createdAt, createdBy, status } = getValues();
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const {id} = useParams();
  const [dataDetail, setDataDetail] = useState();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  
  const isDetail = id && !isUpdate;

  useEffect(() => {
    if (id) {
      getDetailTransitionCategory()
    }
  }, [id])

  const getDetailTransitionCategory = async () => {
    //call api detail transition and set value form
    const res = await services.detailTransitionCategory(id)
    if (res.status === HTTP_STATUS.SUCCESS) {
      setDataDetail(res.data.data)
      
      initValues({
        ...res.data.data,
      })
    }
  }

  const initValues = (data: any) => {
    const createdAt = formatDateTime(data?.createdAt);
    reset((formValues : any) => ({
      ...formValues,
      ...data,
      createdBy: data?.createdBy?.userName,
      createdAt: createdAt,
      no: data.priority
    }))
  }

  
  const dispatch = useDispatch();

  dispatch(setHeaderPage(id ? 'Detail Transition Category' : 'Add New Transition Category'));


  const handleCancel = () => {
    setIsUpdate(false)
    initValues(dataDetail)
  }

  const onSubmit = async (data: any) => {
    setIsSubmiting(true)
    const body = {
      ...data,
      priority: +data.priority,
    }
    if (id) body.id = id
    try {
      const res = id ? await services.updateTransitionCategory(body) : await services.createTransitionCategory(body);
      if (res.status === HTTP_STATUS.SUCCESS) { 
        dispatch(setNoti({
          open: true,
          message: id ? 'Update Transtition Category Successfully!' : 'Add Transtition Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
        navigate('/content/transition?tab=category-transition')
      }
      setIsSubmiting(false)
    } catch (err: any) {
      const { message } = err?.response?.data;
      dispatch(setNoti({
        open: true,
        message: message || id ? 'Update Transtition Category Fail!' : 'Add Transtition Category Fail!',
        type: 'error'
      }))
      setIsSubmiting(false)
    }
  };

  const items: TabsProps['items'] = useMemo(() => [
    {
      key: TEMPLATE_PLATFORM.IOS,
      label: 'IOS',
    },
    {
      key: TEMPLATE_PLATFORM.ANDROID,
      label: 'ANDROID',
    },
    {
      key: TEMPLATE_PLATFORM.WEB,
      label: 'WEBSITE',
    },
  ], [activeKey]);



  const handleChangeTab = (key: string) => {
    setActiveKey(key)
    setSearchParams(createSearchParams({platform: key}))
  }

  
  return (
    <div className='transition-category-create-update'>
      <BackToListPageButton
        listPageUrl={'/content/transition?tab=category-transition'}
        title={'Transition Category'}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='content'>
          <Card style={{ width: '100%' }}>
            <div className='header'>
              <div className='header-content'>
                {!isUpdate 
                  ? (<span className='name'>{getValues('name')}</span>)
                  : (
                    <div className='name'>
                      <Controller 
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        render={({field: {onChange, value}}) => (
                          <InputTextField 
                            defaultValue={value}
                            inputName={'name'} 
                            inputType={'text'} 
                            inputPlaceholder={'Enter transition category name'}
                            handleChange={(event) => {
                              onChange(event.target.value)
                            }}
                            isInputInvalid={!!(errors?.name)}
                            maxLength={150}
                          />
                        )}            
                        name={'name'} 
                      />
                    </div>
                  )
                }
                <div className='div-action'>
                  {isUpdate && <div className='cancel-btn'>
                    <BaseButton handleClick={handleCancel} buttonLabel={'Cancel'} />
                  </div>}
                  {isUpdate && <div className='submit-btn-container'>
                    <BaseButton 
                      htmlType={'submit'}
                      buttonLabel={'Save'} 
                      type={'primary'}
                      className={isSubmiting ? 'disable' : ''}
                    />
                  </div>}
                  {isDetail && <div className='submit-btn-container'>
                    <BaseButton 
                      buttonLabel={'Edit'} 
                      type={'primary'}
                      handleClick={() => setIsUpdate(true)}
                    />
                  </div>}
                </div>
              </div>
              {errors?.name && <ErrorMessage errorMessage={errors.name.message} />}
            </div>
            <div className='block'>
              <div className='block-item'>
                <FormItem
                  label={'Description :'}
                >
                  {isDetail ? (
                    <span style={{ wordBreak: 'break-all' }}>{description}</span>
                  ) : (<>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <InputTextField 
                          defaultValue={value}
                          handleChange={(value) => {
                            onChange(value.target.value)
                          }} 
                          inputType={'text'} 
                          inputPlaceholder={'Description'}
                          inputName={'name'}
                        />
                      )}
                      name="description"
                    />
                  </>)}
                </FormItem>
                <FormItem
                  label={'No :'}
                >
                  {isDetail
                    ? (<span>{no}</span>)
                    : (<>
                      <Controller
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        render={({field: {onChange, value}}) => (
                          <InputTextField
                            value={value}
                            inputName={'no'}
                            inputType={'text'}
                            inputPlaceholder={'Enter priority'}
                            handleChange={(event) => {
                              const inputValue = event.target.value.replace(/\D/g, '');
                              onChange(inputValue)
                            }}
                            isInputInvalid={!!(errors?.priority)}
                            maxLength={150}
                          />
                        )}
                        name={'priority'}
                      />
                    </>)
                  }
                </FormItem>
                <FormItem
                  label={'Count :'}
                >
                  <span>{itemCount}</span>
                </FormItem>
              </div>
              <div className='block-item'>
                <FormItem
                  label={'Created at :'}
                >
                  <span>{createdAt}</span>
                </FormItem>
                <FormItem
                  label={'Created by :'}
                >
                  <span>{createdBy}</span>
                </FormItem>
                <FormItem
                  label={'Status :'}
                >
                  {isDetail ? (
                    <span>{STATUSES.find(item => item .value === status)?.label}</span>
                  ) : (<>
                    <Controller
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={({ field: { value, onChange } }) => (
                        <SelectBoxCustom 
                          defaultValue={value}
                          placeholder='Click to choose'
                          listItems={STATUSES}
                          handleChange={onChange}
                        />
                      )}
                      name="status"
                    />
                    {errors?.status && <p className='error-message'>{errors?.status?.message}</p>}
                  </>)}
                </FormItem>
              </div>
            </div>
          </Card>

          <Card style={{ width: '100%' }}>
            <p className='card-header'>Transition List</p>
            <Tabs 
              defaultActiveKey={TEMPLATE_PLATFORM.IOS}
              activeKey={activeKey}
              items={items}
              size='large' 
              className='tab-container' 
              onChange={handleChangeTab}
            />
            <TransitionListPage 
              transitionCategoryId={id}
              activeKey={activeKey}
            />
          </Card>
        </div>
      </form>
    </div>
  );
};

export default TransitionCategoryCreateEdit;