import authRequest from '@/services/service';
import {ICreateCategoryPayload, IUpdateCategoryPayload, IUpdateMultipleCategoryStatusPayload} from '@/services/interface/category.interface';
import { ISearchCategories } from '@/pages/templates/interface/CategoryListPage';

const getListCategory = async (query: any) => {
  const response = await authRequest.get('categories', {params : query});
  return response
};

const getCategory = async (id: string) => {
  const response = await authRequest.get(`categories/${id}`);
  return response;
};

const searchListCategory = async (data: ISearchCategories) => {
  const response = await authRequest.get('categories', {
    params: data,
  });
  return response;
};

const createCategory = async (payload: ICreateCategoryPayload) => {
  const response = await authRequest.post('categories', payload);
  return response;
};

const deleteSingleCategory = async (id: string) => {
  const response = await authRequest.delete(`categories/${id}`);
  return response;
}

const deleteMultipleCategory = async (body: any) => {
  const response = await authRequest.delete('categories', {data: body});
  return response;
}

const updateCategory = async (id: string, payload: IUpdateCategoryPayload) => {
  const response = await authRequest.put(`categories/${id}`, payload);
  return response;
}

const updateMultipleCategory = async (payload: IUpdateMultipleCategoryStatusPayload) => {
  const response = await authRequest.put('categories/status', payload);
  return response;
}

const getDetailCategory = async (id: string) => {
  const response = await authRequest.get(`categories/${id}`);
  return response;
}

const getListTemplatesByCategory = async (id: string, query: {orderBy: string, orderType: string, page: number, limit: number}) => {
  const response = await authRequest.get(`categories/${id}/templates`, {params : query});
  return response;
}

const searchListProperties = async (query: {search: string, limit: number}) => {
  const response = await authRequest.get('props', {params : query});
  return response;
}

const category = {
  getListCategory,
  createCategory,
  searchListCategory,
  deleteSingleCategory,
  getCategory,
  updateCategory,
  updateMultipleCategory,
  deleteMultipleCategory,
  getDetailCategory,
  getListTemplatesByCategory,
  searchListProperties
};

export default category;
