import React from 'react';

import './LoginPage.scss';
import BaseButton from '@/component/common/Button';
import { useGoogleLogin } from '@react-oauth/google';
import services from '@/services';
import {setCookie} from 'react-use-cookie';
import authRequest from '@/services/service';
import {setRouteAndNavigate, setUserInfo} from '@/redux/slice/common';
import {useDispatch} from 'react-redux';
import {HTTP_STATUS} from '@/constants';
import {redirectToDefaultUrl} from '@/utils/auth';

export default function LoginPage(): JSX.Element {
  const dispatch = useDispatch()

  const loginWithGoogle = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
    onSuccess: (codeResponse: any) => onLoginSuccess(codeResponse),
    onError: (error: any) => console.log('Login Failed:', error)
  });
  
  const onLoginSuccess = (codeResponse: any) => {
    handleLogin(codeResponse.access_token);
  }
  
  const handleLogin = async (accessToken: string) => {
    try {
      const payload = {
        access_token: accessToken,
        platform: 'google'
      }
      const response = await services.login(payload);
      if (response.data.data) {
        const data = response.data.data;

        const userAccessToken = data.token.accessToken;
        authRequest.defaults.headers['Authorization'] = `Bearer ${userAccessToken}`;

        const responseUser = await services.getUserRoleAndPermission();

        if (responseUser && responseUser.status === HTTP_STATUS.SUCCESS) {
          const userPermissions = responseUser.data.data.permissions;
          const userRole = responseUser.data.data.roleCode;

          const userInfo = {
            id: data.user.id,
            email: data.user.email,
            fullName: data.user.fullName,
            userName: data.user.userName,
            userPermissions,
            userRole
          };
          setCookie('access_token', userAccessToken);
          setCookie('user_fullname', userInfo.fullName);
          localStorage.setItem('user_role', userRole);
        
          dispatch(setUserInfo(userInfo));

          redirectToDefaultUrl(userRole);
        }


      }

    } catch (err: any) {
      console.log('err', err)
    }
  }

    
  return (
    <div className='login-container'>
      <div className="login-component">
        <div className='login-title'>
          Log in
        </div>
        
        <div className='login-btn'>
          <BaseButton
            handleClick={loginWithGoogle}
            buttonLabel={'Log in with Google Account'} 
            type='primary'
          />

        </div>
      </div>
    </div>
  );
}

