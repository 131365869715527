import React from 'react';
import CreateEditEffect from '@/component/content/Effect/CreateEditEffect';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';

export default function EditEffectPage(): JSX.Element {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('Effect Detail'));
  return (
    <CreateEditEffect />
  );
}