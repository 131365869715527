import React from 'react';
import { Row, Col } from 'antd';
import BackgroundLeft from '@/assets/image/img_background_left.svg';
import BackgroundRight from '@/assets/image/img_background_right.png'
import Logo from '@/assets/image/img_main_logo.svg'
import { Outlet } from 'react-router';
export default function PublicLayout (): JSX.Element {
  return (
    <Row style={{ height: '100vh' }}>
      <Col span={12}>
        <div
          style={{
            backgroundImage: `url(${BackgroundLeft})`,
            height: '100%'
          }}
        />
      </Col>
      <Col span={12}>
        <div
          style={{
            backgroundImage: `url(${BackgroundRight})`,
            height: '100%'
          }}
        >
          <Row style={{ height: '100%' }} justify="center" align="middle">
            <img
              style={{ position: 'absolute', top: '48px', left: '30px' }}
              src={Logo}
              alt=""
            />
            <Outlet />
          </Row>
        </div>
      </Col>
    </Row>
  );
}
