import {useRoutes} from 'react-router-dom';
import UserListPage from '@/pages/user/UserListPage';

export default function AppContentRoutes(): JSX.Element {
  const routes = [
    {
      path: '/users',
      element: <UserListPage />
    },
  ];

  const routing = useRoutes(routes);
  return (
    <>
      {routing}
    </>
  );
}