import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import {useDispatch, useSelector} from 'react-redux';
import Card from '@/component/common/Card';
import {Controller, useForm} from 'react-hook-form';
import FormItem from '@/component/common/FormItem';
import InputTextField from '@/component/common/InputTextField';
import ErrorMessage from '@/component/common/ErrorMessage';
import {Radio} from 'antd';
import SelectSearchAndAddItem from '@/component/common/SelectSearchAndAddItem';
import {FILE_TYPE, HTTP_STATUS, STATUSES_LIST} from '@/constants';
import BaseButton from '@/component/common/Button';
import CardHeader from '@/component/common/CardHeader';
import ButtonUpload from '@/component/common/ButtonUpload';
import TrashIcon from '@/component/icon/TrashIcon';
import UploadComponent from '@/component/common/Upload';
import './CreateEditEffect.scss'
import {setShowLoadingIcon} from '@/redux/slice/common';
import {RootState} from '@/redux/rootReducer';
import services from '@/services';
import {cancelToken} from '@/http-common';
import {setNoti} from '@/redux/slice/commonListPageSlice';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import {formatDateTime} from '@/utils';
import { uniqBy } from 'lodash';

export default function CreateEditEffect(): JSX.Element {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDetailPage = !!id;
  const isCreatePage = !id;
  
  const showLoadingIcon = useSelector((state: RootState) => state.commonReducer.showLoadingIcon);

  const {control, setError, handleSubmit, reset, formState: {errors, isSubmitted}, setValue, getValues} : any = useForm({
    defaultValues: {
      effectName: '',
      category: [],
      status: 1,
      iosFile: null,
      androidFile: null,
      webFile: null,
      platform: 'each',
    }
  });

  const {no, effectName, categoryName, category, status, platform, iosFile, webFile, androidFile, createAt, createdBy, thumbnailUrl} = getValues();
  
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState({});
  const [listEffectCategories, setListEffectCategories] = useState<any[]>([]);
  const [uUId, setUUId] = useState<string>('');
  const [abc, setAbc] = useState<any[]>([]);

  const getCreateEffectUUId = async () => {
    try {
      const res = await services.getUUId();
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        setUUId(res.data.data.id);
      }
    } catch (err: any) {
      console.log('error', err);
    }
  }

  const getDetailEffectData = async () => {
    try {
      if(id) {
        const res = await services.getDetailEffect(id);

        if (res && res.status === HTTP_STATUS.SUCCESS) {
          const selectedCategory = res?.data?.data?.categories?.map((item: any) => ({label: item.name, value: item.id}));
          const selectedCategoryName = res?.data?.data?.categories?.map((item: any) => item.name);

          const isAllPlatform = res.data.data?.webUrl && res.data.data?.webUrl?.indexOf('/all') !== -1
          reset((formValues: any) => ({
            ...formValues,
            ...res.data.data,
            no: res.data.data?.webPriority,
            effectName: res.data.data.name,
            status: res.data.data?.webStatus,
            createdBy: res.data.data.createdBy.userName,
            createdAt: formatDateTime(res.data.data.createdAt),
            webFile: res.data.data?.webUrl ? { fileName: res.data.data?.webUrl, fileUpload: { responseLink: res.data.data?.webUrl} } : '',
            iosFile: res.data.data?.iosUrl ? { fileName: res.data.data?.iosUrl, fileUpload: { responseLink: res.data.data?.iosUrl} } : '',
            androidFile: res.data.data?.androidUrl ? { fileName: res.data.data?.androidUrl, fileUpload: { responseLink: res.data.data?.androidUrl} } : '',
            thumbnail: res.data.data?.thumbnailUrl ? { src: res.data.data?.thumbnailUrl, fileUpload: { responseLink: res.data.data?.thumbnailUrl} } : '',
            category: selectedCategory,
            categoryName: selectedCategoryName,
            platform: isAllPlatform ? 'all' : 'each',
            sourceFile: isAllPlatform ? { fileName: res.data.data?.webUrl, fileUpload: { responseLink: res.data.data?.webUrl} } : {}
          }));
          setOriginalData({
            // ...originalData,
            ...res.data.data,
            no: res.data.data?.webPriority,
            effectName: res.data.data.name,
            status: res.data.data?.webStatus,
            createdBy: res.data.data.createdBy.userName,
            createdAt: formatDateTime(res.data.data.createdAt),
            webFile: res.data.data?.webUrl ? { fileName: res.data.data?.webUrl, fileUpload: { responseLink: res.data.data?.webUrl} } : {},
            iosFile: res.data.data?.iosUrl ? { fileName: res.data.data?.iosUrl, fileUpload: { responseLink: res.data.data?.iosUrl} } : {},
            androidFile: res.data.data?.androidUrl ? { fileName: res.data.data?.androidUrl, fileUpload: { responseLink: res.data.data?.androidUrl} } : {},
            thumbnail: res.data.data?.thumbnailUrl ? { src: res.data.data?.thumbnailUrl, fileUpload: { responseLink: res.data.data?.thumbnailUrl} } : {},
            category: selectedCategory,
            categoryName: selectedCategoryName,
            sourceFile: isAllPlatform ? { fileName: res.data.data?.webUrl, fileUpload: { responseLink: res.data.data?.webUrl} } : {}
          });

          if (res.data.data.categories.length) {
            const listItems = res.data.data.categories.map((item: any) => ({
              label: item.name,
              value: item.id
            }));
          }
        }
      }
    } catch (err: any) {
      console.log('error', err);
    }
  }
  
  useEffect(() => {
    if (isCreatePage) {
      getCreateEffectUUId();
    } else {
      getDetailEffectData();
    }
    return () => cancelToken();
  }, []);
  
  const onChangePlatformApply = (event: any) => {
    const value = event.target.value;
    setValue('platform', event.target.value, {shouldValidate: true});
    if (isCreatePage) {
      if (value === 'each') {
        setValue('sourceFile', '', {shouldValidate: false});
      } else {
        setValue('iosFile', '', {shouldValidate: false});
        setValue('webFile', '', {shouldValidate: false});
        setValue('androidFile', '', {shouldValidate: false});
      }
    }

  }
  
  const handleSearchEffectCategories = async (value: string, page = 1) => {
    // if (currentPage )
    try {
      const query: any = {limit: 10, orderBy: 'createdAt', page};
      if (value) {
        query.search = value;
      }
      const res =  await services.getListEffectCategories(query);
      return res;

    } catch (err: any) {
      console.log('err', err);
      return [];
    }
  }
  
  const handleSubmitAddNewEffectCategory = async (value: string) => {
    let result = false;
    try {
      const payload = {
        name: value.trim(),
        description: '',
        status: 0
      };
      const res =  await services.createEffectCategory(payload);
      if (res && res.status === HTTP_STATUS.CREATED) {
        await handleSearchEffectCategories('');
        result = true;
        dispatch(setNoti({
          open: true,
          message: 'Add Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
    } catch (err: any) {
      console.log('error', err);
      result = false;
      const errorMsg = err.response.data.message;
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: errorMsg || 'Add Category Fail!',
      }))
    }
    return result;
  }


  const handleCancelAddNewEffect = () => {
    if (isCreatePage) {
      navigate(-1);
    } else {
      setIsEditMode(false);
      reset((formValues: any) => ({
        ...formValues,
        ...originalData
      }));
    }
  }
  
  const showEditMode = () => {
    setIsEditMode(true);
  }
  
  const handleChangeFile = () => {
    console.log('file');
  }

  const getUploadFileLink = async (ext: string, platform: string) => {
    try {
      const query = { ext };
      const uploadId = id ? id : uUId;
      const res = await services.getUploadEffectFileLink(uploadId, platform, query);

      if(res && res.status === HTTP_STATUS.SUCCESS) {
        return res.data.data;
      }
    } catch (err: any) {
      console.log(err);
      return null;
    }
  }
  
  const handleUploadFile = async (file: any, key: string, platform: string) => {
    const uploadFile = file.file;
    const fileName = uploadFile.name;
    const lastIndexOfDot = fileName.lastIndexOf('.');
    const ext = fileName.substring(lastIndexOfDot + 1);

    const getUploadFileLinkRes = await getUploadFileLink(ext, platform);
    
    setValue(key, { fileName, file: uploadFile, fileUpload: getUploadFileLinkRes}, { shouldValidate: true })
  }
  
  const handleRemoveFile = (key: string) => {
    setValue(key, '', { shouldValidate: true });
    setError(key, {message: ''});
  }
  
  const handleChangeFileThumbnail = (file: any) => {
    // setError('thumbnail', {message: ''})
  }
  
  const getUploadThumbnailLink = async (ext: string) => {
    try {
      const query = { ext };
      const uploadId = id ? id : uUId;
      const res = await services.getUploadEffectThumbnailLink(uploadId, query);
      
      if(res && res.status === HTTP_STATUS.SUCCESS) {
        return res.data.data;
      }
    } catch (err: any) {
      console.log(err);
      return null;
    }
  }
  
  const handleUploadThumbnail = async (file: any) => {
    const uploadFile = file.file;
    const fileName = uploadFile.name;
    const fileSize = uploadFile.size;
    const lastIndexOfDot = fileName.lastIndexOf('.');
    const ext = fileName.substring(lastIndexOfDot + 1);
    const src = URL.createObjectURL(uploadFile);

    let error = ''
    if (!fileName.endsWith(FILE_TYPE.GIF)) {
      error = 'Wrong format file! Only accept GIF file.';
      setError('thumbnail', {message: error});
      setValue('thumbnail', '', { shouldValidate: false });

    } else if (fileSize > 500000) {
      error = `The ${fileName} is over maximum file size 500KB. Please try again`;
      setError('thumbnail', {message: error});
      setValue('thumbnail', '', { shouldValidate: false });

    } else {
      const getUploadThumbnailLinkRes = await getUploadThumbnailLink(ext);
      setValue('thumbnail', { file: uploadFile, src, fileUpload: getUploadThumbnailLinkRes}, { shouldValidate: true })
    }

  }
    
  const handleAddEditEffect = async (data: any) => {
    dispatch(setShowLoadingIcon(true));
    if (showLoadingIcon) {
      return;
    }


    if (data.platform === 'each') {
      if (!data.iosFile && !data.androidFile && !data.webFile) {
        dispatch(setShowLoadingIcon(false));
        return;
      }
    }

    try{
      const arrServices = [
        data?.iosFile?.fileUpload?.uploadLink && services.uploadVideoPreview(data.iosFile.fileUpload.uploadLink, data?.iosFile?.file),
        data?.androidFile?.fileUpload?.uploadLink && services.uploadVideoPreview(data.androidFile.fileUpload.uploadLink, data?.androidFile?.file),
        data?.webFile?.fileUpload?.uploadLink && services.uploadVideoPreview(data.webFile.fileUpload.uploadLink, data?.webFile?.file),
        data?.thumbnail?.fileUpload?.uploadLink && services.uploadVideoPreview(data.thumbnail.fileUpload.uploadLink, data?.thumbnail?.file),
        data?.sourceFile?.fileUpload?.uploadLink && services.uploadVideoPreview(data.sourceFile.fileUpload.uploadLink, data?.sourceFile?.file)
      ].filter((item: any) => item !== undefined);

      await Promise.all(arrServices)
        .then(async (value: any) => {

          const convertedUploadCategory = () => {
            console.log('errors', errors)
            let result;
            if (Array.isArray(data.category)) {
              result = data.category.map((item: any) => item.value);
            } else {
              result = [data.category]
            }

            return result;
          }

          const payload = {
            name: data.effectName.trim(),
            thumbnailUrl: data.thumbnail?.fileUpload?.responseLink,
            webUrl: platform === 'each' ? data.webFile?.fileUpload?.responseLink || null : data.sourceFile?.fileUpload?.responseLink,
            iosUrl: platform === 'each' ? data.iosFile?.fileUpload?.responseLink || null : data.sourceFile?.fileUpload?.responseLink,
            androidUrl: platform === 'each' ? data.androidFile?.fileUpload?.responseLink || null : data.sourceFile?.fileUpload?.responseLink,
            type: 'apng',
            id: uUId,
            webPriority: data.no ? parseInt(data.no) : '',
            iosPriority: data.no ? parseInt(data.no) : '',
            androidPriority: data.no ? parseInt(data.no) : '',
            categories: convertedUploadCategory()
          };


          let res;
          if (isCreatePage) {
            res = await services.createEffect(payload);
          } else if (id) {
            res = await services.updateEffect(id, payload);
          }

          if (res && (res.status === HTTP_STATUS.CREATED || res.status === HTTP_STATUS.SUCCESS)) {
            dispatch(setShowLoadingIcon(false));
            const labelSuccess = `${isCreatePage ? 'Create' : 'Update'} effect successfully`;
            dispatch(setNoti({
              open: true,
              message: labelSuccess,
              icon: <CheckSuccessIcon />,
            }));
            if (isCreatePage) {
              navigate(-1);
            } else {
              getDetailEffectData();
              setIsEditMode(false);
            }
          }
        })
        .catch((err) => {
          console.log('error', err);
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { message } = err?.response?.data;
          const labelFailed = `${isCreatePage ? 'Create' : 'Update'} failed`;

          dispatch(setShowLoadingIcon(false));
          dispatch(setNoti({
            open: true,
            message: message || labelFailed,
            type: 'error',
          }))
        });

    } catch (err: any) {
      console.log(err);
      dispatch(setShowLoadingIcon(false));
    }
  }
  
  return (
    <div className='create-edit-effect'>
      <BackToListPageButton
        listPageUrl={'/content/effect'}
        title={'Effect Content '}
      />
      <form onSubmit={handleSubmit(handleAddEditEffect)}>
        <div className="content">
          {/*  Infor*/}
          <div className="block">
            <Card>
              {id && (
                <FormItem label={'No'} isRequired={isEditMode}>
                  <span>{no}</span>
                </FormItem>
              )}

              <FormItem label={'Platform'}>
                {isDetailPage
                  ? (
                    <>
                      {iosFile?.fileName && <span className='tag-item'>IOS</span>}
                      {androidFile?.fileName && <span className='tag-item'>Android</span>}
                      {webFile?.fileName && <span className='tag-item'>Web</span>}
                    </>
                  )
                  : (
                    <>
                      <Controller 
                        control={control}
                        render={({field: {value}}) => (
                          <Radio.Group onChange={onChangePlatformApply} value={value}>
                            <Radio value={'each'}>Each Platform</Radio>
                            <Radio value={'all'}>All Platform</Radio>
                          </Radio.Group>
                        )} 
                        name='platform'
                      />
                    </>
                  )                  
                }
              </FormItem>

              <FormItem
                label={'Effect Name'}
                isRequired={!!(isCreatePage || id && isEditMode)}
              >
                {id && !isEditMode 
                  ? (<span className='effect-name'>{effectName}</span>)
                  : (
                    <>
                      <Controller 
                        control={control}
                        rules={{
                          required: 'This field is required',
                          // validate: (value) => { return !!value.trim()}
                        }}
                        render={({field: {onChange, value}}) => (
                          <InputTextField 
                            defaultValue={value}
                            value={value}
                            inputName={'name'} 
                            inputType={'text'} 
                            inputPlaceholder={'Enter effect name'}
                            handleChange={(event) => {
                              onChange(event.target.value)
                            }}
                            isInputInvalid={!!(errors?.effectName)}
                            maxLength={150}
                          />
                        ) } 
                        name={'effectName'} 
                      />
                      {errors?.effectName && <ErrorMessage errorMessage={errors.effectName.message} />}

                    </>
                  )
                }
              </FormItem>

              <FormItem 
                label={'Category'}    
                isRequired={!!(isCreatePage || id && isEditMode)}
              >
                {id && !isEditMode
                  ? (
                    <>
                      {categoryName && categoryName.map((item: any) => {
                        return (
                          <span key={item} className='tag-item'>{item}</span>
                        );
                      })
                      }
                    </>

                  )
                  : (
                    <>
                      <Controller
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        render={({field: {onChange, value}}) => (
                          <SelectSearchAndAddItem
                            defaultValue={value}
                            selectPlaceholder={'Select category'}
                            handleFetchListOptions={handleSearchEffectCategories}
                            handleChangeSelect={onChange}
                            inputPlaceHolder={'Enter new category'}
                            handleAddNewItem={handleSubmitAddNewEffectCategory}
                          />
                        )}
                        name={'category'}
                      />
                      {errors?.category && <ErrorMessage errorMessage={errors.category.message} />}
                    </>
                  )
                }
              </FormItem>

              {isDetailPage && (
                <FormItem label={'Created By'}>
                  <span>{getValues('createdBy')}</span>
                </FormItem>
              )}
                
              {isDetailPage && (
                <FormItem label={'Created At'}>
                  <span>{getValues('createdAt')}</span>
                </FormItem>
              )}

              <div className="btn-container">
                {(isCreatePage ||  isDetailPage && isEditMode) && (
                  <div className='cancel-btn'>
                    <BaseButton
                      buttonLabel={'Cancel'}
                      type={'button'}
                      handleClick={handleCancelAddNewEffect} 
                    />
                  </div>
                )}

                {(isCreatePage || isDetailPage && isEditMode)
                    && (
                      <div className='submit-btn'>
                        <BaseButton
                          buttonLabel={isCreatePage ? 'Upload' : 'Update'}
                          type={'primary'}
                          htmlType={'submit'}
                        />
                      </div>
                    )
                }
                  
                {isDetailPage && !isEditMode
                    && (
                      <div className='submit-btn'>
                        <BaseButton
                          buttonLabel={'Edit'} 
                          handleClick={showEditMode} 
                          type={'primary'} 
                        />
                      </div>
                    )
                }
              </div>
            </Card>
          </div>

          <div className='block block-column'>
            {/*   Source files */}
            <Card>
              <CardHeader 
                label={'Source'}  
                isRequired={!!(isCreatePage || id && isEditMode)}
              />
              <div className="content-file">
                {getValues('platform') === 'each'
                  ? (
                    <div>
                      <FormItem label={'IOS File'}>
                        <div className="form-item">
                          <Controller 
                            control={control}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('iosFile')?.fileName &&
                                  <span>{getValues('iosFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && (
                                    <>
                                      <ButtonUpload
                                        handleChangeFile={handleChangeFile}
                                        handleUpload={(value: any) => handleUploadFile(value, 'iosFile', 'ios')}
                                        showUploadList={false}
                                        showUploadButtonLabel={false}
                                      />
                                      {getValues('iosFile')?.fileName && <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('iosFile')}/>}
                                    </>
                                  )
                                  }
                                </div>
                              </div>
                            )} 
                            name={'iosFile'} 
                          />
                        </div>
                      </FormItem>
                      <FormItem label={'Android File'}>
                        <div className="form-item">
                          <Controller 
                            control={control}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('androidFile')?.fileName &&
                                  <span>{getValues('androidFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && (
                                    <>
                                      <ButtonUpload
                                        handleChangeFile={handleChangeFile}
                                        handleUpload={(value: any) => handleUploadFile(value, 'androidFile', 'android')}
                                        showUploadList={false}
                                        showUploadButtonLabel={false}
                                      />
                                      {getValues('androidFile')?.fileName && <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('androidFile')}/>}
                                    </>
                                  )
                                  }
                                </div>
                              </div>
                            )} 
                            name={'androidFile'}
                          />
                        </div>
                      </FormItem>
                      <FormItem label={'Web File'}>
                        <div className="form-item">
                          <Controller 
                            control={control}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('webFile')?.fileName &&
                                  <span>{getValues('webFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && (
                                    <>
                                      <ButtonUpload
                                        handleChangeFile={handleChangeFile}
                                        handleUpload={(value: any) => handleUploadFile(value, 'webFile', 'web')}
                                        showUploadList={false}
                                        showUploadButtonLabel={false}
                                      />
                                      {getValues('webFile')?.fileName && <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('webFile')}/>}
                                    </>
                                  )
                                  }
                                </div>
                              </div>
                            )} 
                            name={'webFile'}
                          />
                          {errors?.webFile && <ErrorMessage errorMessage={errors?.webFile?.message} />}

                        </div>
                      </FormItem>
                      {isSubmitted && !iosFile && !androidFile && !webFile 
                        && <ErrorMessage errorMessage={'You must upload at least one file'} />
                      }
                    </div>
                  )
                  : (
                    <div>
                      <FormItem label={'Source File'}>
                        <div className="form-item">
                          <Controller 
                            control={control}
                            rules={{
                              required: 'This field is required'
                            }}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('sourceFile')?.fileName &&
                                  <span>{getValues('sourceFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && (
                                    <>
                                      <ButtonUpload
                                        handleChangeFile={handleChangeFile}
                                        handleUpload={(value: any) => handleUploadFile(value, 'sourceFile', 'all')}
                                        showUploadList={false}
                                        showUploadButtonLabel={false}
                                      />
                                      {getValues('sourceFile')?.fileName &&
                                      <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('sourceFile')}/>
                                      }
                                    </>
                                  )
                                  }
                                </div>
                              </div>
                            )} 
                            name={'sourceFile'}
                          />
                        </div>
                      </FormItem>
                      {errors?.sourceFile
                        && <ErrorMessage errorMessage={errors?.sourceFile.message} />
                      }
                    </div>
                  )
                }
              </div>
            </Card>

            <Card>
              <div className='card-header'>
                <CardHeader label={'Thumbnail'} />
                {(isCreatePage || isDetailPage && isEditMode) 
                         && (
                           <Controller 
                             control={control}
                             rules={{
                               required: 'This field is required'
                             }}
                             render={() => (
                               <ButtonUpload
                                 label={'Upload'}
                                 handleChangeFile={handleChangeFileThumbnail}
                                 handleUpload={handleUploadThumbnail}
                                 showUploadList={false}
                               />
                             )}
                             name={'thumbnail'} 
                           />
                         )
                }
              </div>
              {errors?.thumbnail && <ErrorMessage errorMessage={errors?.thumbnail?.message} />}
              <div className='card-content'>
                {getValues('thumbnail')?.src ? (
                  <img src={getValues('thumbnail')?.src} className='thumbnail'/>
                ) : (
                  <UploadComponent
                    handleChange={handleChangeFileThumbnail}
                    handleUpload={handleUploadThumbnail}
                    desc='Accept formats : GIF with max size of 500KB.'
                  />
                )}
              </div>
            </Card>
          </div>


        </div>
      </form>
    </div>
  );
}