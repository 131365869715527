/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import {Space, Form, Modal} from 'antd';

import CloseModalIcon from '@/component/icon/CloseModalIcon';
import './index.scss';
import InputTextField from '@/component/common/InputTextField';
import SelectBoxCustom from '@/component/common/SelectBoxCustom';
import { ALGORITHYM, DIFFUSION_SAMPLER_MAP, FILE_TYPE, HTTP_STATUS } from '@/constants';
import ImgDefault from '@/assets/img_default.png';
import ButtonUpload from '@/component/common/ButtonUpload';
import BaseButton from '@/component/common/Button';
import services from '@/services';
import { clean } from '@/utils/helper';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';

const providers = [{ label: 'Dream AI', value: 'dreamAI'}]

const labelColSubFormItem = {span: 12, offset: 12}
const AiContentModal: React.FC<any> = ({
  showModal,
  handleCancel,
  refresh,
}) => {
  const [form] = Form.useForm();
  const [thumbnail, setThumbnail] = useState<{ thumbnailUrl: string, thumbnailFile: File | null, fileName: string}>({ thumbnailUrl: '', thumbnailFile: null, fileName: ''});
  const [thumbnailUpload, setThumbnailUpload] = useState<any>();
  const [hasProvider, setHasProvider] = useState<boolean>(false);
  const [aiCategories, setAiCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errWidthHeight, setErrWidthHeight] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const dispatch = useDispatch();
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  
  useEffect(() => {
    getPresignThumbnail();
    getAiCategories();
  }, [])
  
  useEffect(() => {
    if (selectedItem?.id) {
      initValue()
    }
  }, [selectedItem])

  const initValue = () => {
    if (selectedItem?.provider) {
      setHasProvider(true)
    }
    if (selectedItem?.thumbnail) {
      setThumbnail({
        thumbnailUrl: selectedItem.thumbnail,
        thumbnailFile: null,
        fileName: selectedItem.thumbnail,
      })
    }
    setName(selectedItem?.name)
    form.setFieldsValue({ ...selectedItem, ...selectedItem?.config, name: selectedItem?.name, imageTemplateCategory: selectedItem?.imageTemplateCategory?.id })
    console.log(form.getFieldsValue());
    
  }

  const getAiCategories = async () => {
    const res = await services.getCategories({ search: ''});
    if (res?.data?.data?.items) {
      setAiCategories(res?.data?.data?.items.map((item: any) => {
        return {
          value: item.id,
          label: item.name
        }
      }))
    }
  }

  const getPresignThumbnail = async () => {
    const res = await services.getPresignUrl();
    if (res.data?.data?.link) {
      setThumbnailUpload({
        link: res.data?.data?.link,
        path: res.data?.data?.path,
      })
    }
  }
  const onFinish = async (values: any) => {
    if (isLoading) return;
    if (!errWidthHeight) {
      setIsLoading(true)
      const body = {
        ...values,
        thumbnail: thumbnailUpload?.path,
        config: {
          text: values?.text,
          width: values?.width ? +values?.width : null,
          height: values?.height ?+values?.height : null,
          cfgScale: values?.cfgScale ? +values?.cfgScale : null,
          algorithym: values?.algorithym,
          samples: values?.samples,
          steps: values?.steps ? +values?.steps : null,
          sampler: values?.sampler ? +values?.sampler : null,
          seed: values?.seed ? +values?.seed : null,
          stepScheduleStart: values?.stepScheduleStart ? Number(values?.stepScheduleStart).toFixed(2) : null,
        }
      }


      clean(body.config)
      clean(body)

      if (thumbnail?.thumbnailFile) {
        await services.uploadVideoPreview(thumbnailUpload?.link, thumbnail?.thumbnailFile)
      } else {
        delete body.thumbnail
      }
      try {
        let res;
        if (selectedItem?.id) {
          res = await services.updateContentAi(selectedItem?.id, body);
        } else {
          res = await services.createContentAi(body);
        }
        if (res.status === HTTP_STATUS.SUCCESS) {
          dispatch(setNoti({
            open: true,
            message: selectedItem?.id ? 'Update AI Content Successfully!' : 'Add AI Content Successfully!',
            icon: <CheckSuccessIcon />,
          }))
          handleCancel()
          refresh();
        }

        setIsLoading(false)
      } catch (err: any) {
        const { message } = err?.response?.data
        setIsLoading(false)
        dispatch(setNoti({
          open: true,
          message: message || selectedItem?.id ? 'Update AI Content Fail!' : 'Add AI Content Fail!',
          type: 'error'
        }))
      }
    }
  };
      
  const handleChangeFileThumbnail = (file: any) => {
    console.log('change', file);
  }
  
  const handleUploadThumbnail = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name;
    if (!fileName.endsWith(FILE_TYPE.JPG)) {
      setThumbnail({ thumbnailUrl: '', thumbnailFile: null, fileName: '' });
      
      // add warning wrong file
      dispatch(setNoti({
        open: true,
        type: 'warning',
        message: 'Please upload file with type is JPG.'
      }))
    } else {
      const src = URL.createObjectURL(uploadFile);
      form.setFieldsValue({ thumbnail: src})
      setThumbnail({
        thumbnailUrl: src,
        thumbnailFile: uploadFile,
        fileName: fileName,
      });
    }
  }

  const changeForm = (changedValues: any, allValues: any) => {
    console.log('value', changedValues, allValues);
    const { height, width } = allValues
    if (height && width) {
      if (height * width > 589000 && height * width < 1048576) {
        setErrWidthHeight(false)
      } else {
        setErrWidthHeight(true)
      }
    } else {
      setErrWidthHeight(false)
    }
  }

  console.log('name', form?.getFieldValue('name'));
  

  return (
    <>
      <Modal
        width={1000}
        title={selectedItem?.id ? 'Edit AI Content' : 'Add AI Content'}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='add-category-modal-container'
        footer={null}
        // initialValues={{ imageTemplateCategory: {value: 'f0b5ef96-2bd4-4937-8f47-23a36703b026', label: 'Barca'} }}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          layout="vertical"
          form={form}
          onValuesChange={changeForm}
        >
          <div className='wrapper-form'>
            <div className='row'>
              <Form.Item
                label="Category"
                name="imageTemplateCategory"
                rules={[{ required: true, message: 'This field cannot be empty.' }]}
              >
                <div style={{ width: '100%' }}>
                  <SelectBoxCustom 
                    defaultValue={selectedItem?.imageTemplateCategory?.id || null}
                    listItems={aiCategories}
                    handleChange={(value: string) => {
                      form.setFieldValue('imageTemplateCategory', value)
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'This field cannot be empty.' }]}
              >
                <div style={{ width: '100%' }}>
                  <InputTextField 
                    value={name}
                    handleChange={(event) => {
                      setName(event.target.value)
                    }}
                    // value={form?.getFieldValue('name')} 
                    inputType={'text'} 
                    inputPlaceholder={'Name'}
                    inputName={'name'}
                  />
                </div>
              </Form.Item>
              <Form.Item
                label="Provider"
                name="provider"
                rules={[{ required: true, message: 'This field cannot be empty.' }]}
              >
                <SelectBoxCustom 
                  listItems={providers}
                  defaultValue={selectedItem?.provider || null}
                  handleChange={(value: string) => {
                    form.setFieldValue('provider', value)
                    if (value) setHasProvider(true)
                    else setHasProvider(false)
                  }}
                />
              </Form.Item>
              {hasProvider && <div className='div-provider'>
                <Form.Item
                  label="Text"
                  name="text"
                  labelCol={labelColSubFormItem}
                >
                  <div style={{ width: '100%' }}>
                    <InputTextField 
                      defaultValue={form?.getFieldValue('text')} 
                      inputType={'text'} 
                      inputPlaceholder={'Text'}
                      inputName={'name'}
                      maxLength={500}
                      minLength={0}
                    />
                  </div>
                </Form.Item>
                {errWidthHeight && <div className='err-mess'>Pixels không nằm trong khoảng cho phép (lớn hơn 589000 và nhỏ hơn 1048576)</div>}
                <Form.Item
                  label="Steps"
                  name="steps"
                  labelCol={labelColSubFormItem}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && (value > 150 || value < 10)) {
                          return Promise.reject('Steps chỉ được phép trong khoảng từ 10 -> 150');
                        }
                        return Promise.resolve();
                      },
                    }),
                    {pattern: new RegExp(/^[0-9]*$/), message: 'Steps chỉ có thể là số nguyên'}
                  ]}
                >
                  <div style={{ width: '100%' }}>
                    <InputTextField 
                      defaultValue={form?.getFieldValue('steps')} 
                      inputType={'number'} 
                      inputPlaceholder={'Steps'}
                      inputName={'name'}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label="Step Schedule Start"
                  name="stepScheduleStart"
                  labelCol={labelColSubFormItem}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value > 1) {
                          return Promise.reject('Step Schedule Start chỉ được phép trong khoảng từ 0 -> 1');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <div style={{ width: '100%' }}>
                    <InputTextField
                      defaultValue={form?.getFieldValue('stepScheduleStart')}
                      inputType={'number'} 
                      inputPlaceholder={'Step Schedule Start'}
                      inputName={'name'}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label="Config Scale"
                  name="cfgScale"
                  labelCol={labelColSubFormItem}
                  validateTrigger="onChange"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && (value > 20 || value < 0)) {
                          return Promise.reject('Config Scale phải nằm trong khoảng 0 -> 20');
                        }
                        return Promise.resolve();
                      },
                    }),
                    {pattern: new RegExp(/^[0-9]*$/), message: 'Config Scale chỉ có thể là số nguyên'}
                  ]}
                >
                  <div style={{ width: '100%' }}>
                    <InputTextField 
                      defaultValue={form?.getFieldValue('cfgScale')} 
                      inputType={'number'} 
                      inputPlaceholder={'Config Scale'}
                      inputName={'name'}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label="Algorithym"
                  name="algorithym"
                  labelCol={labelColSubFormItem}
                >
                  <SelectBoxCustom 
                    listItems={ALGORITHYM}
                    defaultValue={selectedItem?.config?.algorithym || null}
                    handleChange={(value: any) => {
                      form.setFieldsValue({ algorithym: value })
                    }}
                  />
                </Form.Item>
              </div>}
              {selectedItem?.id ? (
                <Form.Item
                  label="Status"
                  name="status"
                  labelCol={labelColSubFormItem}
                  rules={[{ required: true, message: 'This field cannot be empty.' }]}
                >
                  <SelectBoxCustom 
                    listItems={[{ label: 'Active', value: 1}, { label: 'Inactive', value: 0}]}
                    defaultValue={selectedItem?.status}
                    handleChange={(value: any) => {
                      form.setFieldsValue({ status: value })
                    }}
                  />
                </Form.Item>
              ) : (
                <div className='flex-row'>
                  <label className='label'>Status</label>
                  <div className='div-value'>
                    <div className='dot' />
                    <label className='value'>Inactive</label>
                  </div>
                </div>
              )}
            </div>
            <div className='row'>
              <Form.Item
                label={
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    Thumbnail
                    <ButtonUpload 
                      label={'Upload'}
                      handleChangeFile={handleChangeFileThumbnail}
                      handleUpload={handleUploadThumbnail}
                      showUploadList={false}
                      showUploadButtonLabel={true}
                    />
                  </div>
                }
                name="thumbnail"
                rules={[{ required: true, message: 'This field cannot be empty.' }]}
                className="form-thumbnail"
              >
                <img src={thumbnail?.thumbnailUrl || ImgDefault} style={{ width: '100%' }}  onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src=ImgDefault;
                }}/>
              </Form.Item>
            </div>
            
          </div>
          <div className='div-action'>
            <div className='cancel-btn'>
              <BaseButton handleClick={handleCancel} buttonLabel={'Cancel'} />
            </div>

            <div className='submit-btn-container'>
              <BaseButton 
                htmlType={'submit'}
                buttonLabel={selectedItem?.id ? 'Save' : 'Add'} 
                type={'primary'}
              />
            </div>
          </div>
         

          {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};

export default AiContentModal;
