import {IUploadTemplate, IUploadTemplateErrorMessage} from '@/pages/templates/interface/UploadTemplate';

export const defaultUploadTemplateForm: IUploadTemplate = {
  name: '',
  category: [],
  jsonData: ''
}

export const defaultUploadTemplateErrorMessage: IUploadTemplateErrorMessage = {
  name: '',
  zipFile: '',
  jsonFile: ''
}