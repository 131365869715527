import React from 'react';
import { Upload } from 'antd';
import UploadIcon from '@/assets/icon/ic_upload.svg';
import { IUpload } from '../interface/Upload';

const UploadComponent: React.FC<IUpload> = ({
  handleChange,
  handleUpload,
  title,
  desc,
}) => {
  return (
    <div className='upload'>
      <img src={UploadIcon} />
      <div className='title-upload'>{title || 'Upload GIF'}</div>
      <div className='desc'>{desc || 'Accept formats : MP4 with max size of 10MB.'}</div>
      <Upload 
        onChange={handleChange}
        customRequest={handleUpload}
        showUploadList={false}
        maxCount={1}
      >
        <div className='btn-upload'>Click here to Upload</div>
      </Upload>
    </div>
  );
};

export default UploadComponent;