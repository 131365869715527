import React, {useEffect, useMemo, useState} from 'react';
import {Tabs} from 'antd';
import type {TabsProps} from 'antd';
import TemplateListContainer from '@/component/templates/TemplateListContainer';
import ContentPageHeader from '@/component/common/ContentPageHeader/index';
import './Tabs.scss';
import {TEMPLATE_ORDER_PRIORITY} from '@/constants';
import {useSearchParams, createSearchParams} from 'react-router-dom';
import { ITemplateListContainer } from '../interface/TemplateListContainer';


const TemplateListTabsPane: React.FC<ITemplateListContainer> = ({ categoryId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState<string>(TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY);

  const queryPriority = searchParams.get('priority');

  const handleGetCurrentActiveTab = () => {
    setActiveKey(queryPriority || TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY);
  }
  
  useEffect(() => {
    handleGetCurrentActiveTab()
  }, [queryPriority])


  const items: TabsProps['items'] = useMemo(() => [
    {
      key: TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY,
      label: 'IOS',
    },
    {
      key: TEMPLATE_ORDER_PRIORITY.ANDROID_PRIORITY,
      label: 'ANDROID',
    },
    {
      key: TEMPLATE_ORDER_PRIORITY.WEB_PRIORITY,
      label: 'WEBSITE',
    },
  ], [activeKey]);



  const handleChangeTab = (key: string) => {
    setActiveKey(key)
    setSearchParams(createSearchParams({priority: key}))
  }

  return (
    <div className={'tab-pane'}>
      <ContentPageHeader header={'Template list'} />
      <Tabs 
        defaultActiveKey={TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY}
        activeKey={activeKey}
        items={items}
        size='large' 
        className='tab-container' 
        onChange={handleChangeTab}
      />
      <TemplateListContainer categoryId={categoryId}/>
    </div>
  )}

export default TemplateListTabsPane;