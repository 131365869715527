import {
  IDetailTemplateLErrorMessages,
  IDetailTemplateSingleItem
} from '@/pages/templates/interface/DetailTemplatePage';

export const defaultDetailTemplateForm: IDetailTemplateSingleItem = {
  name: '',
  categories: [],
  thumbnailUrl: '',
  videoPreviewUrl: '',
  webPriority: '0',
  iosPriority: '0',
  androidPriority: '0',
  webStatus: '0',
  iosStatus: '0',
  androidStatus: '0',
  duration: '',
  createdBy: '',
  createdDate: '',
  resolution: ''
}

export const defaultDetailTemplateErrorMessages: IDetailTemplateLErrorMessages = {
  name: '',
  iosPriority: '',
  androidPriority: '',
  webPriority: '',
}