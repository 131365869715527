import React from 'react';
import PlusIconButton from '../../../assets/icon/ic_btn_plus.svg';
import UploadIconButton from '../../../assets/icon/ic_btn_upload.svg';
import FilterIconButton from '../../../assets/icon/ic_btn_filter.svg';
import { Button } from 'antd';
import {IButtonIcon} from '../interface/ButtonIcon';
import './ButtonIcon.scss';

const PlusIcon = () => {
  return <img src={PlusIconButton} alt="plus-icon"  className='icon'/>;
}
  
const UploadIcon = () => {
  return <img src={UploadIconButton} alt="upload-icon"  className='icon'/>;
}
  
const FilterIcon = () => {
  return <img src={FilterIconButton} alt="filter-icon"  className='icon'/>;
}

const ButtonIcon: React.FC<IButtonIcon> = ({buttonLabel, handleClick, iconType}) => {
  
  const generateIcon = (iconType: string|undefined) => {
    if (iconType === 'upload') {
      return <UploadIcon />
    } else if (iconType === 'filter') {
      return <FilterIcon />
    } else return <PlusIcon />
  }
    
  return (
    <div className='btn-icon'>
      <Button
        size="large"
        className={iconType === 'filter' ? 'btn-secondary' : 'btn'}
        onClick={handleClick}
      >
        {generateIcon(iconType)}
        {buttonLabel}
      </Button>
    </div>
  );
};

ButtonIcon.defaultProps = {
  iconType: 'plus'
}

export default ButtonIcon;