import React from 'react';
import { Drawer } from 'antd';
import './index.scss'

interface IAdvancedFilter {
  openDrawer: boolean;
  setOpenDrawer: (x: boolean) => void;
  content: React.ReactElement;
}
const AdvancedFilter = ({ openDrawer, setOpenDrawer,content }: IAdvancedFilter) => {
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  return (
    <Drawer
      title="Advanced Filter"
      placement="right"
      closable={true}
      open={openDrawer}
      onClose={handleCloseDrawer}
      key="right"
      width={480}
      className='custom-drawer'
    >
      {content}
    </Drawer>
  );
};

export default AdvancedFilter;
