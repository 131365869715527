/* eslint-disable no-unsafe-optional-chaining */
import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';

import CloseIcon from '@/assets/icon/ic_close_modal.svg';
import './index.scss';
import {defaultAddCategoryForm} from '@/component/templates/form/AddCategoryForm';
import InputTextField from '@/component/common/InputTextField';
import BaseButton from '@/component/common/Button';
import { useDispatch } from 'react-redux';
import { ITransitionCategoryForm, ITransitionCategoryModal } from '../../interface/TransitionCategoryModal';
import { Controller, useForm } from 'react-hook-form';
import FormItem from '@/component/common/FormItem';
import services from '@/services';
import { HTTP_STATUS } from '@/constants';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import SelectBoxCustom from '@/component/common/SelectBoxCustom';
const STATUSES = [{ label: 'Active', value: 1 }, { label: 'Inactive', value: 0 }]

const TransitionCategoryModal: React.FC<ITransitionCategoryModal> = ({
  showModal,
  handleCancel,
  submitSuccess,
}) => {
  const [formCategory, setFormCategory] = useState<ITransitionCategoryForm>(defaultAddCategoryForm);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { control, handleSubmit, formState:{ errors, isSubmitted }, setValue, getValues } : any = useForm({
    defaultValues: {
      description: '',
      name: '',
      status: 0,
    }
  });
  
  const HeaderModal = () => {
    return (<span className='header-modal'>Add Transition Category</span>)
  }
  const CloseModalIcon = () => {
    return <img src={CloseIcon} alt='close-icon'/>
  }

  
  const onSubmit = async (data: any) => {
    console.log('data', data);
    try {
      const res = await services.createTransitionCategory(data);
      if (res.status === HTTP_STATUS.CREATED) {
        dispatch(setNoti({
          open: true,
          message: 'Add Transtition Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
        submitSuccess()
      }
    } catch(err: any) {
      const { message } = err?.response?.data;
      dispatch(setNoti({
        open: true,
        message: message || 'Add Transtition Category Fail!',
        type: 'error'
      }))
    }
  };
  
  const handleClearFormAddCategoryData = () => {
    if (!showModal) {
      setFormCategory(defaultAddCategoryForm)
    }
  }
  
  useEffect(() => {
    handleClearFormAddCategoryData()
  }, [showModal])
  
  return (
    <>
      <Modal
        title={<HeaderModal/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='add-category-modal-container'
        footer={null}
      >
        <form className='form' onSubmit={handleSubmit(onSubmit)}>

          <FormItem
            label={'Category Name'}
            direction='horizontal'
            isRequired={true}
          >
            <Controller
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, onChange } }) => (
                <InputTextField 
                  defaultValue={value}
                  handleChange={(value) => {
                    onChange(value.target.value)
                  }} 
                  inputType={'text'} 
                  inputPlaceholder={'Category Name'}
                  inputName={'name'}
                  minLength={2}
                  maxLength={150}
                  isInputInvalid={!!errors?.name?.message}
                  showCount={true}
                />
              )}
              name="name"
            />
            {errors?.name && <p className='error-message' style={{ marginBottom: 0 }}>{errors?.name?.message}</p>}

          </FormItem>
          <FormItem
            label={'Description'}
            direction='horizontal'
          >
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => (
                <InputTextField 
                  defaultValue={value}
                  handleChange={(value) => {
                    onChange(value.target.value)
                  }} 
                  inputType={'text'} 
                  inputPlaceholder={'Description'}
                  inputName={'name'}
                  maxLength={500}
                  showCount={true}
                />
              )}
              name="description"
            />
          </FormItem>
          <FormItem
            label={'Status'}
            direction='horizontal'
            isRequired={true}
          >
            <Controller
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { value, onChange } }) => (
                <SelectBoxCustom 
                  defaultValue={value}
                  placeholder='Click to choose'
                  listItems={STATUSES}
                  handleChange={onChange}
                />
              )}
              name="status"
            />
            {errors?.status && <p className='error-message'>{errors?.status?.message}</p>}
          </FormItem>
          <div key='1' className='modal-footer-btn'>
            <div className='cancel-btn'>
              <BaseButton
                buttonLabel={'Cancel'}
                handleClick={handleCancel}
              />
            </div>
            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={'Add Category'}
                htmlType={'submit'}
                type='primary'
                className={loading ? 'disabled' : ''}
              />
            </div>
          </div>
        </form>

      </Modal>
    </>
  );
};

export default TransitionCategoryModal;