import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import configInitialState from '@/redux/constant/config';

export const config = createSlice({
  name: 'configSlice',
  initialState: configInitialState,
  reducers: {
    setSelectedUserAccount(state, action: PayloadAction<{email: string, role: string}>) {
      state.selectedUserAccount = action.payload
    }
  }
});

export const { setSelectedUserAccount } = config.actions;

export default config.reducer;