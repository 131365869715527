import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {setIsUserAccessAnEditablePage, setRouteAndNavigate} from '@/redux/slice/common';

import { Layout, Menu } from 'antd';
import Logo from '../../assets/image/logo.svg';
import Expand from '../../assets/image/expand.svg';
import Collapse from '../../assets/image/collapse.svg';
import LogoutIconButton from '@/component/sidebar/LogoutIconButton';
import SidebarTemplateIcon from '@/component/icon/SidebarIcons/SidebarTemplateIcon';
import SidebarConfigIcon from '@/component/icon/SidebarIcons/SidebarConfigIcon';

import type { MenuProps } from 'antd';
import {ISidebar, MenuItem} from '../interface/sidebar';
import './Sidebar.scss';
import { RootState } from '@/redux/rootReducer';
import SidebarContentIcon from '@/component/icon/SidebarIcons/SidebarContentIcon';
import SidebarUserIcon from '@/component/icon/SidebarIcons/SidebarUserIcon';

const { Sider } = Layout;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  resource?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    resource
  } as MenuItem;
}

const Sidebar: React.FC<ISidebar> = ({isUserAllowedToAccessPage}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const keyPath = useSelector((state: RootState) => state.commonReducer.keyPath);

  const subKeyPath = useSelector((state: RootState) => state.commonReducer.subKeyPath);
  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  const isUserAccessAnEditablePage = useSelector((state: RootState) => state.commonReducer.isUserAccessAnEditablePage);

  let readAndCreatePermissions: {action: string, resource: string, licensed: boolean}[] = [];
  let uniqueResources: any[] = [];
  let createTemplatePermission: any = null;
  if (userPermissions) {
    createTemplatePermission = userPermissions.find((item: {action: string, resource: string, licensed: boolean}) => item.action === 'create' && item.resource === 'template')
    readAndCreatePermissions = userPermissions.filter((item: {action: string, resource: string, licensed: boolean}) => item.licensed);
    const resourcesArr = readAndCreatePermissions.map(((item: {action: string, resource: string, licensed: boolean}) => item.resource));
    uniqueResources = [...resourcesArr]
  }
    
  const listAllSidebarItems = [
    {
      itemsList: getItem(
        'Config', 
        'config', 
        'config',
        <SidebarConfigIcon selected={keyPath === 'config'} />, 
        [
          getItem('Admin Account', 'admin-account', 'user'),
          getItem('Role and Permissions', 'role-permissions', 'role'),
        ])
    },
    {
      itemsList: getItem(
        'Template',
        'template',
        'template',
        <SidebarTemplateIcon selected={keyPath === 'template'} />,
        [
          getItem('Category List', 'category-list', 'category'),
          getItem('Template List', 'template-list', 'template'),
          // getItem('Upload Template', 'upload-template', 'upload-template'),
          // getItem('Template Approval', 'template-approval', 'template-approval'),
        ]
      ),
    },
    {
      itemsList: getItem(
        'App content',
        'content',
        'content',
        <SidebarContentIcon selected={keyPath === 'content'} />,
        [
          getItem('AI Content', 'content-ai', 'ai'),
          getItem('Effect Content', 'effect', 'ai'),
          getItem('Transition Content', 'transition', 'ai'),
          getItem('Filter Content', 'filter', 'ai'),
        ]
      ),
    },
    {
      itemsList: getItem(
        'User Management',
        'user-management',
        'user-management',
        <SidebarUserIcon selected={keyPath === 'user-management'} />,
        [
          getItem('User List', 'users', 'user-management'),
        ]
      ),
    }

  ];
  
  const handleChoseItem: MenuProps['onClick'] = async (e) => {
    const path = e.keyPath[1];
    const subPath = e.key;

    const payload = {
      keyPath: path,
      subKeyPath: subPath
    }

    navigate(`/${path}/${subPath}`);
    if (!isUserAccessAnEditablePage) {
      dispatch(setRouteAndNavigate(payload));
      dispatch(setIsUserAccessAnEditablePage(false));
    }

  };


  const getSidebarItemsFromRole = () => {
    
    const selectedSidebarItems = listAllSidebarItems.map((item: any) => {
      const children = item.itemsList.children;

      const selectedChildren = children.filter((child: any) => {
        // return an array with child.key included in uniqueResources
        return true
      });
      delete item.itemsList.children;
      if (selectedChildren.length > 0) {
        item.itemsList.children = selectedChildren;
        if (createTemplatePermission?.licensed && item.itemsList.resource === 'template') {
          item.itemsList.children = [...item.itemsList.children, getItem('Upload Template', 'upload-template', 'upload-template')];
        }
      }
      return item;
    }).filter((item: any) => Object.prototype.hasOwnProperty.call(item.itemsList, 'children'));

    const sidebarItems = selectedSidebarItems.map((item: any) => item.itemsList);
    
    return isUserAllowedToAccessPage ? sidebarItems : [];

  }
  return (
    <Sider
      className="main-sidebar"
      trigger={null}
      collapsible
    >
      <div className={'main-sidebar-wrapper'}>
        <div className="main-sidebar-expanded">
          <img src={Logo} alt="logo-img" />
        </div>
        <Menu
          theme="dark"
          defaultOpenKeys={[keyPath]}
          className="custom-menu"
          selectedKeys={[subKeyPath]}
          mode="inline"
          items={getSidebarItemsFromRole()}
          onClick={handleChoseItem}
          expandIcon={({ isOpen }) =>
            isOpen ? (
              <img src={Collapse} alt="" width="12" height="12" />
            ) : (
              <img src={Expand} alt="" width="12" height="12" />
            )
          }
        />
      </div>

      <div className={'logout-btn'}>
        <LogoutIconButton />
      </div>
    </Sider>
  );
}

Sidebar.defaultProps = {
  isUserAllowedToAccessPage: true
}

export default Sidebar;