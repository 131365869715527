import {useRoutes} from 'react-router-dom';
import ContentAiListPage from '@/pages/content/ContentAiListPage';
import EffectContentListPage from '@/pages/content/Effect/EffectContentListPage';
import CreateEffectPage from '@/pages/content/Effect/CreateEffectPage';
import EditEffectPage from '@/pages/content/Effect/EditEffectPage';
import CreateEditTransition from '@/component/content/Transition/TransitionCreateEdit';
import TransitionTabs from '@/pages/content/Transition/TransitionTabs';
import TransitionCategoryCreateEdit from '@/component/content/Transition/TransitionCategoryCreateEdit';
import FilterContentPage from '@/pages/content/Filter/FilterContentListPage';
import CreateFilterPage from '@/pages/content/Filter/CreateFilterPage';
import EditFilterPage from '@/pages/content/Filter/EditFilterPage';
import DetailEffectCategoryPage from '@/pages/content/Effect/DetailEffectCategoryPage';

export default function AppContentRoutes(): JSX.Element {
  const routes = [
    {
      path: '/content-ai',
      element: <ContentAiListPage />
    },
    {
      path: '/effect',
      element: <EffectContentListPage />
    },
    {
      path: '/effect/create-effect',
      element: <CreateEffectPage />
    },
    {
      path: '/effect/effect-detail/:id',
      element: <EditEffectPage />
    },
    {
      path: '/effect/effect-category-detail/:id',
      element: <DetailEffectCategoryPage />
    },
    {
      path: '/transition',
      element: <TransitionTabs />
    },
    {
      path: '/transition/create',
      element: <CreateEditTransition />
    },
    {
      path: '/transition/update/:id',
      element: <CreateEditTransition />
    },
    {
      path: '/transition/transition-category/create',
      element: <TransitionCategoryCreateEdit />
    },
    {
      path: '/transition/transition-category/update/:id',
      element: <TransitionCategoryCreateEdit />
    },
    {
      path: '/filter',
      element: <FilterContentPage />
    },
    {
      path: '/filter/create-filter',
      element: <CreateFilterPage />
    },
    {
      path: '/filter/edit-filter/:id',
      element: <EditFilterPage />
    }
  ];

  const routing = useRoutes(routes);
  return (
    <>
      {routing}
    </>
  );
}