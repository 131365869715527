import React from 'react';
import { setCookie } from 'react-use-cookie';

import LogoutIcon from '../../../assets/icon/ic_logout.svg'
import {Button} from 'antd';
import './LogoutIconButton.scss';
import {useNavigate} from 'react-router-dom';
import {setUserInfo} from '@/redux/slice/common';
import {useDispatch} from 'react-redux';

function LogoutIconComponent () {
  return <img src={LogoutIcon} alt="logout-icon" className='icon' />;
}
const LogoutIconButton = () => {
  const navigate= useNavigate();
  const dispatch = useDispatch();

  const handleSignOut = () => {
    setCookie('access_token', '');
    setCookie('user_fullname', '');
    setCookie('user_permissions', '');
    setCookie('user_role', '');
    localStorage.setItem('user_role', '');

    navigate('/login');
    const defaultUserInfo = {
      id: '',
      email: '',
      fullName: '',
      userName: '',
      userPermissions: [],
      userRole: ''
    }
    dispatch(setUserInfo(defaultUserInfo));
  }
  
  return (
    <div className='btn-logout-icon'>
      <Button
        size="large"
        className='btn'
        onClick={handleSignOut}
      >
        Sign out
        <LogoutIconComponent />
      </Button>
    </div>
  );
};

export default LogoutIconButton;