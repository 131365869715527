import React from 'react';
import {ISelectBox} from '@/component/common/interface/SelectBox';
import { Select } from 'antd';
import './SelectBox.scss';

const SelectBox: React.FC<ISelectBox> = ({
  listItems,
  handleChange, 
  defaultValue,
  disabled,
  placeholder,
  isSelectInvalid,
  allowClear = true
}) => {
  return (
    <div className='select-box-container'>
      <Select
        size='large'
        value={defaultValue}
        defaultValue={defaultValue || null}
        options={listItems}
        className='select-box'
        onChange={handleChange}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder}
        status={isSelectInvalid ? 'error' : ''}
      />
    </div>
  );
};

SelectBox.defaultProps = {
  disabled: false,
  isSelectInvalid: false
}

export default SelectBox;