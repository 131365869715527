import React from 'react';
import { Button, Upload } from 'antd';
import UploadIconButton from '@/assets/icon/ic_btn_upload.svg';
import {IButtonUpload} from '@/component/common/interface/ButtonUpload';
import './ButtonUpload.scss';
import UploadFileTemplateIcon from '@/component/icon/UploadFileIcon';

const ButtonUpload: React.FC<IButtonUpload> = ({
  label,
  handleChangeFile, 
  handleUpload, 
  showUploadList,
  showUploadButtonLabel
}) => {
  const UploadIcon = () => {
    return <img src={UploadIconButton} alt="upload-icon"  className='icon'/>;
  }
  return (
    <div className='upload-btn'>
      <Upload 
        onChange={handleChangeFile}
        customRequest={handleUpload}
        showUploadList={showUploadList}
        maxCount={1}
      >
        {
          showUploadButtonLabel 
            ? <Button icon={<UploadIcon />} className='btn'>{label}</Button>
            : <UploadFileTemplateIcon />
        }
        
      </Upload>
    </div>
  )};

ButtonUpload.defaultProps = {
  label: '',
  showUploadButtonLabel: true
}

export default ButtonUpload;