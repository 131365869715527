import React from 'react';
import { Pagination } from 'antd';
import {IBasePagination, IGoFirstPageButton, IGoLastPageButton} from '@/component/common/interface/BasePagination';
import GoFirstPageIconButton from '@/assets/icon/ic_pagination_go_first.svg';
import GoLastPageIconButton from '@/assets/icon/ic_pagination_go_last.svg';
import SelectBox from '@/component/common/SelectBox';
import './BasePagination.scss';

const GoFirstPageButton: React.FC<IGoFirstPageButton> = ({
  handleClick,
  disabled
}) => {
  return (
    <button
      className='go-first-page-button'
      onClick={handleClick}
      disabled={disabled}>
      <img src={GoFirstPageIconButton} alt='' />
    </button>
  );
}

const GoLastPageButton: React.FC<IGoLastPageButton> = ({
  handleClick,
  disabled
}) => {
  return (
    <button
      className='go-last-page-button'
      onClick={handleClick}
      disabled={disabled}>
      <img src={GoLastPageIconButton} alt='' />
    </button>
  )
}

const BasePagination: React.FC<IBasePagination> = ({
  currentPage, 
  totalItems,
  itemsPerPage,
  totalPages,
  disabledPagination = false,
  handleChangePage
}) => {
  const pageSizeOptions = [
    {
      label: '10',
      value: '10'
    },
    {
      label: '15',
      value: '15'
    },
    {
      label: '20',
      value: '20'
    },
    {
      label: '50',
      value: '50'
    },
    {
      label: '100',
      value: '100'
    },
  ];

  const getFirstIndexOfList = () => {
    let result = 0 + (currentPage - 1)*itemsPerPage + 1;
    if (result > totalItems) result = totalItems;
    return result;
  };

  const getLastIndexOfList = () => {
    let result = (itemsPerPage - 1) + (currentPage - 1)*itemsPerPage + 1;
    if (result > totalItems) result = totalItems;
    return result;
  }

  const getDefaultPageSizeOption = () => {
    const result = pageSizeOptions.find((item) => item.value === itemsPerPage.toString());
    return result || pageSizeOptions[0]
  }

  const handleGoToFirstPage = () => {
    handleChangePage(1, itemsPerPage);
  }

  const handleGoToLastPage = () => {
    handleChangePage(totalPages, itemsPerPage);
  }

  const handleChangePageSize = (value: string) => {
    handleChangePage(currentPage, parseInt(value))
  }
    
  return (
    <div className='pagination'>
      <div className='pagination-buttons'>
        <GoFirstPageButton
          handleClick={handleGoToFirstPage}
          disabled={currentPage === 1 || disabledPagination}
        />
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          defaultCurrent={1}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handleChangePage}
          defaultPageSize={10}
          disabled={disabledPagination}
        />
        <GoLastPageButton
          handleClick={handleGoToLastPage}
          disabled={currentPage === totalPages || disabledPagination}
        />
      </div>

      <div className='show-size-change'>
        <p>
          Show {getFirstIndexOfList()} to {getLastIndexOfList()} of
          <span className='total-items'> {totalItems} items </span>
        </p>
        <div className='list-size-option'>
          <SelectBox
            defaultValue={getDefaultPageSizeOption()}
            listItems={pageSizeOptions}
            handleChange={(value: string) => handleChangePageSize(value)}
            disabled={disabledPagination}
            allowClear={false}
          />
        </div>
        <p>Items per page</p>
      </div>

    </div>

  )};

export default BasePagination;
