import React from 'react';
import {ISidebarConfigIcon} from '@/component/icon/interface/SidebarConfigIcon';

const SidebarConfigIcon: React.FC<ISidebarConfigIcon> = ({selected}) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1 7.56894C16.29 7.56894 15.55 6.28894 16.45 4.71894C16.97 3.80894 16.66 2.64894 15.75 2.12894L14.02 1.13894C13.23 0.668935 12.21 0.948936 11.74 1.73894L11.63 1.92894C10.73 3.49894 9.25 3.49894 8.34 1.92894L8.23 1.73894C7.78 0.948936 6.76 0.668935 5.97 1.13894L4.24 2.12894C3.33 2.64894 3.02 3.81894 3.54 4.72894C4.45 6.28894 3.71 7.56894 1.9 7.56894C0.86 7.56894 0 8.41893 0 9.46894V11.2289C0 12.2689 0.85 13.1289 1.9 13.1289C3.71 13.1289 4.45 14.4089 3.54 15.9789C3.02 16.8889 3.33 18.0489 4.24 18.5689L5.97 19.5589C6.76 20.0289 7.78 19.7489 8.25 18.9589L8.36 18.7689C9.26 17.1989 10.74 17.1989 11.65 18.7689L11.76 18.9589C12.23 19.7489 13.25 20.0289 14.04 19.5589L15.77 18.5689C16.68 18.0489 16.99 16.8789 16.47 15.9789C15.56 14.4089 16.3 13.1289 18.11 13.1289C19.15 13.1289 20.01 12.2789 20.01 11.2289V9.46894C20 8.42894 19.15 7.56894 18.1 7.56894ZM10 13.5989C8.21 13.5989 6.75 12.1389 6.75 10.3489C6.75 8.55894 8.21 7.09894 10 7.09894C11.79 7.09894 13.25 8.55894 13.25 10.3489C13.25 12.1389 11.79 13.5989 10 13.5989Z" fill={selected ? '#F37F13' : 'white'}/>
    </svg>
  );
};

export default SidebarConfigIcon;