import React from 'react';
import {setHeaderPage} from '@/redux/slice/common';
import {useDispatch} from 'react-redux';
import CreateEditFilter from '@/component/content/Filter/CreateEditFilter';

export default function EditFilterPage(): JSX.Element {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('Filter Detail'));

  return (
    <CreateEditFilter />
  );
}