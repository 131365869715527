import React from 'react';
import {IPageHeader} from '@/component/common/interface/PageHeader';
import './PageHeader.scss';

const ContentPageHeader: React.FC<IPageHeader> = ({header}) => {
  return (
    <div className='page-header'>
      <span className='header'>{header}</span>
    </div>
  );
};

export default ContentPageHeader;