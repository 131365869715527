/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import ContentPageHeader from '@/component/common/ContentPageHeader';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import BaseButton from '@/component/common/Button/index';
import { MenuProps, Space, Dropdown, Button } from 'antd';
import './CategoryListPage.scss';
import AddCategoryModal from '@/component/templates/AddCategoryModal/index';
import services from '@/services';
import { ICategoryListSingleItem } from '@/pages/templates/interface/CategoryListPage';
import {HTTP_STATUS, SORT_ORDER, STATUSES_LIST} from '@/constants';
import {USER_ACTIONS, USER_RESOURCE} from '@/constants/roles';
import { convertDataFilterTags, formatDateTime, removeDataFilterTags } from '@/utils';
import AdvancedFilter from '@/layouts/advancedFilter';
import { DownOutlined } from '@ant-design/icons';
import {cancelToken} from '@/http-common';
import BaseTable from '@/component/common/BaseTable';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {useSearchParams} from 'react-router-dom';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import useDebounce from '@/hooks/useDebounce';
import { IConfigFilter } from '@/component/common/interface/Filter';
import dayjs from 'dayjs';
import FilterBar from '@/component/common/FilterBar';
import FilterItemList from '@/component/common/FilterItemList';
import {setIsUserClickSubmitEditIcon, setSelectedIds, setNoti, setSelectedId, setSelectedUpdateId, setShowDeleteModal} from '@/redux/slice/commonListPageSlice';
import EditItemModal from '@/component/common/EditItemModal';
import { IUpdateMultipleCategoryStatusPayload } from '@/services/interface/category.interface';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';
import {setHeaderPage} from '@/redux/slice/common';
import usePreviousValue from '@/hooks/usePreviousValue';

const items: MenuProps['items'] = [
  {
    label: 'Change to Active',
    key: 'active',
  },
  {
    label: 'Change to Inactive',
    key: 'imactive',
  },
];

const configFilters: IConfigFilter[] = [
  {
    label: 'Number of Order',
    key: 'priority',
    type: 'range',
  },
  {
    label: 'Status',
    key: 'statuses',
    type: 'checkbox',
    checkBoxOptions: STATUSES_LIST
  },
  {
    label: 'Create Date',
    key: 'createdAt',
    type: 'range-date',
  }
]

const CategoryListPage = () => {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('Templates'));

  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);

  const [categoryListPermissions, setCategoryListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowHeaderAction, setIsShowHeaderAction] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [listData, setListData] = useState<ICategoryListSingleItem[]>([]);
  const [openĐrawer, setOpenDrawer] = useState<boolean>(false);
  const [showDeletesModal, setShowDeletesModal] = useState<boolean>(false);
  const [valueSelectedMenu, setValueSelectedMenu] = useState<string>();

 
  const [filterDatas, setFilterDatas] = useState({});
  const filterDatasJsonString = JSON.stringify(filterDatas);
  const [filterList, setFilterList] = useState<any>([]);
  const [queryData, setQueryData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const [showEditItemModal, setShowEditItemModal] = useState<any>({ open: false, label: ''});
  const [isNotiHasNotTemplate, setIsNotiHasNotTemplate] = useState<boolean>(false);
  // const [editItemModalLabel, setEditItemModalLabel] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = [
    {
      label: 'NO',
      value: 'no',
      width: '120px',
      editable: true,
      required: true,
      editType: 'input'
    },
    {
      label: 'CATEGORY NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      required: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 150
    },
    {
      label: 'DESCRIPTION',
      value: 'description',
      width: '615px',
      editable: true,
      editType: 'textarea',
      isCutText: true,
      required: false,
      maximumTextLength: 500
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'Template count',
      value: 'templateCount',
      width: '150px'
    },
    {
      label: 'Property',
      value: 'properties',
      multipleValueKey: 'properties',
      width: '150px',
      isMultipleValueColumn: true,
      editable: true,
      required: false,
      editType: 'multipleSelect',
      itemService: services.searchListProperties,
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      required: true,
      editType: 'singleSelect',
      listItems: STATUSES_LIST
    },
  ]
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);

  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        });
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        });
        getListAllCategories()
      }

    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  useEffect(() => {
    dispatch(setSelectedUpdateId(''))
    dispatch(setSelectedIds([]))
  }, [])
  
  const updateCategoryStatus = async (status: string) => {
    const hasTemplate = await checkCategoryHasTemplate()
    if (hasTemplate) {
      setShowEditItemModal({ open: true, label: 'Are you sure you want to save this change?' });
    } else {
      setIsNotiHasNotTemplate(true)
      setShowEditItemModal({ open: true, label: 'This category does not have any template yet. You cannot activate it.' });
    }
  }

  const checkCategoryHasTemplate = async () => {
    const categories = await Promise.all(selectedIds.map( async (id) => {
      const res: any = await services.getCategory(id)
      return res.data.data
    }))
    return categories.every((category: any) => category.templateCount)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleChangeStatus = async () => {
    const hasTemplate = await checkCategoryHasTemplate()
    if (!hasTemplate) {
      setShowEditItemModal({ open: false, label: '' });
      return;
    }
    setIsLoadingSubmit(true)
    const payload: IUpdateMultipleCategoryStatusPayload = {
      categoryIds: selectedIds,
      status: valueSelectedMenu === 'active' ? 1 : 0, 
    } 
    try {
      const res = await services.updateMultipleCategory(payload);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        setShowEditItemModal({ open: false, label: '' });
        dispatch(setSelectedIds([]));
        getListAllCategories()
        dispatch(setNoti({
          open: true,
          message: 'Update Status Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Update Status Fail!',
      }))
    }
  }

  const handleFilter = () => {
    setOpenDrawer(true);
  };

  const handleSelect = () => {
    setIsShowHeaderAction(!isShowHeaderAction);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setValueSelectedMenu(e.key);
    updateCategoryStatus(e.key)
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }
  
  const handleSubmitDelete = async () => {
    setIsLoadingSubmit(true)
    try {
      const res =  await services.deleteSingleCategory(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        getListAllCategories()
        dispatch(setNoti({
          open: true,
          message: 'Delete Category Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Delete Category Fail!',
      }))
    }
  }

  const getListAllCategories = async () => {
    setIsLoading(true)
    dispatch(setSelectedId(''));
    dispatch(setSelectedUpdateId(''))
    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit');
      const query = {
        search: searchValue.trim(),
        orderType: SORT_ORDER.ASC,
        orderBy: 'priority',
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas
      };
      const res = await services.getListCategory(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        const meta = res.data.data.meta;
        const listItems = data.items.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          let convertProperties: any[];
          if (item.props.length > 0) {
            convertProperties = item.props.map((property: any) => ({
              id: property.id,
              name: property.key
            }));
          } else convertProperties = item.props

          return {
            id: item.id,
            no: item.priority.toString(),
            createdAt,
            name: item.name,
            description: item.description,
            status: item.status,
            templateCount: item.templateCount,
            properties: convertProperties,
            createdBy: item?.createdBy?.userName || ''
          };
        });

        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.itemCount,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      console.log('err', err);
    }
  };
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    dispatch(setSelectedIds([]))
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    setSearchParams(searchParams);
    setQueryData(query);
  }

  const handleSubmitEditItemRow = async () => {
    setIsLoadingSubmit(true)
    const uploadForm = {
      name: selectedItem.name,
      description: selectedItem.description,
      status: parseInt(selectedItem.status),
      index: parseInt(selectedItem.no),
      props: selectedItem.properties
    }
    try {
      const res = await services.updateCategory(selectedUpdateId, uploadForm);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getListAllCategories();
        dispatch(setNoti({
          open: true,
          message: 'Updated Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        closeIcon: <></>,
        message: message || 'Updated Category Fail!',
        icon: <></>,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      // dispatch(setSelectedUpdateId(''));
    }
    setShowEditItemModal({ open: false, label: '' });
  }

  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemModal({ open: true, label: 'Are you sure you want to save this change?' });
    }
  }
  
  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon])

  useEffect(() => {
    const permissions =  getListPermissionsByResource(userPermissions, USER_RESOURCE.CATEGORY);
    setCategoryListPermissions(permissions);
    getListAllCategories();     
    return () => cancelToken()

  }, [queryData?.page, queryData?.limit, filterDatasJsonString]);

  //filter
  const submitFilter = async (values: any) => {
    const filters = Object.assign(values, {})
    if (filters.createdAtFrom) {
      filters.createdAtFrom = dayjs(filters.createdAtFrom).format('YYYY-MM-DD')
    }
    if (filters.createdAtTo) {
      filters.createdAtTo = dayjs(filters.createdAtTo).format('YYYY-MM-DD')
    }
    setFilterDatas({
      ...filters,
    })
    const filterListMap = convertDataFilterTags(configFilters, filters)
    const hasCaterofyFilter = configFilters.filter((c: IConfigFilter) => c.key === 'category')
    if (hasCaterofyFilter) {
      await Promise.all(filterListMap.map(async (item: any) => {
        if (item.key === 'categories') {
          const categoryMap = await Promise.all(item.value.map(async (it: any) => {
            const res = await services.getCategory(it)

            if (res && res.data.statusCode === HTTP_STATUS.SUCCESS) {
              it = res.data.data.name
            }
            return it
          }))
          item.value = categoryMap.join(', ')
        }
        return item;
      }))
    }
    setFilterList(filterListMap)
    setOpenDrawer(false)
  }

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }

  const removeCategories = async () => {
    setIsLoadingSubmit(true)
    const body = {
      categoryIds: selectedIds
    }
    try {
      const res =  await services.deleteMultipleCategory(body);
      if (res && res.status === HTTP_STATUS.DELETED) {
        setShowDeletesModal(false)
        dispatch(setSelectedIds([]));
        getListAllCategories()
        dispatch(setNoti({
          open: true,
          message: 'Delete Categories Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Delete Category Fail!',
      }))
    }
  }

  const handleCancelEdit = () => {
    setShowEditItemModal({ open: false, label: ''});
    setIsNotiHasNotTemplate(false)
    dispatch(setIsUserClickSubmitEditIcon(false));
  }
  
  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }


  return (
    <div className="category-list-container">
      <ContentPageHeader header={'Category list'} />
      <div>
        <div className="btn-container">
          <div className="flex-left">
            <div className="flex-left-input">
              <InputSearchField
                value={searchValue}
                inputPlaceholder={'Search...'}
                inputName={'search'}
                handleChange={handleChangeSearch}
                // handleClearSearch={handleClickSearch}
              />
            </div>
            <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/>
          </div>

          <div
            className={!isShowHeaderAction ? 'flex-right' : 'flex-right-custom'}
          >
            {!isShowHeaderAction
                && !!(checkPermission(categoryListPermissions, USER_ACTIONS.EDIT) || checkPermission(categoryListPermissions, USER_ACTIONS.DELETE))
                && (
                  <div className="select-btn">
                    <BaseButton buttonLabel={'Select'} handleClick={handleSelect} className={selectedUpdateId ? 'disabled' : ''}/>
                  </div>
                )}

            {isShowHeaderAction && (
              <Dropdown
                menu={menuProps}
                dropdownRender={(item: React.ReactNode) => (
                  <div className="custom-dropdown">{item}</div>
                )}
              >
                <Button
                  size="large"
                  style={{
                    backgroundColor: '#f7f7f7',
                    marginRight: 16,
                    width: 'max-content'
                  }}
                  className={!selectedIds.length ? 'disabled' : ''}
                >
                  <Space
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: '22px',
                    }}
                  >
                    Change Status
                    <DownOutlined
                      style={{
                        fontSize: 14,
                      }}
                    />
                  </Space>
                </Button>
              </Dropdown>
            )}
            {isShowHeaderAction && checkPermission(categoryListPermissions, USER_ACTIONS.DELETE) && (
              <BaseButton
                handleClick={() => setShowDeletesModal(true)}
                buttonLabel={'Delete'}
                type="secondary"
                className={!selectedIds.length ? 'disabled' : ''}
              />
            )}

            {isShowHeaderAction && (
              <Button
                style={{
                  border: '1px solid #F37F13',
                  color: '#F37F13',
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 16,
                  height: 40,
                  lineHeight: '22px',
                  width: '30%',
                }}
                onClick={() => {
                  setIsShowHeaderAction(false);
                }}
              >
                Cancel
              </Button>
            )}
            {!isShowHeaderAction && checkPermission(categoryListPermissions, USER_ACTIONS.CREATE) && (
              <ButtonIcon
                buttonLabel={'Add Category'}
                handleClick={handleShowModal}
              />
            )}
          </div>
        </div>
        {filterList.length > 0 && <div className='div-filter'>
          {filterList.map((item: any) => {
            return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
          })}
        </div>}
        <BaseTable
          headers={tableHeaders} 
          dataTable={listData}
          detailPageRoute={'category-detail'}
          allowSelection={isShowHeaderAction}
          isShowHeaderAction={isShowHeaderAction}
          showDeleteIcon={checkPermission(categoryListPermissions, USER_ACTIONS.DELETE)}
          showEditIcon={checkPermission(categoryListPermissions, USER_ACTIONS.EDIT)}
          type='category'
          isLoading={isLoading}
        />

        <BasePagination
          currentPage={paginationData.currentPage}
          itemsPerPage={paginationData.itemsPerPage}
          totalItems={paginationData.totalItems}
          totalPages={paginationData.totalPages}
          handleChangePage={handleChangePage}
        />

        <DeleteItemModal
          showModal={showDeleteModal}
          label={'Are you sure you want to remove this'}
          handleCancel={handleCancelDelete}
          handleSubmitDelete={handleSubmitDelete}
          deletedValueItem={selectedItem?.name}
          isDeleteSpecial={true}
          deletedValueType={'category'}
          isLoading={isLoadingSubmit}
        />
        <DeleteItemModal
          showModal={showDeletesModal}
          label={'Do you want to delete these items?'}
          handleCancel={() => setShowDeletesModal(false)}
          handleSubmitDelete={removeCategories}
          deletedValueItem={''}
          isDeleteSpecial={false}
          deletedValueType={''}
          isLoading={isLoadingSubmit}
        />
      </div>
      <AdvancedFilter
        openDrawer={openĐrawer}
        setOpenDrawer={setOpenDrawer}
        content={<FilterBar setOpenDrawer={setOpenDrawer} configFilters={configFilters} submitFilter={submitFilter} filterDatas={filterDatas} />}
      />
      {showModal && <AddCategoryModal
        showModal={showModal}
        handleCancel={handleCloseModal}
        getListAllCategories={getListAllCategories}
      />}
      <EditItemModal 
        showModal={showEditItemModal?.open} 
        label={showEditItemModal?.label}
        isNoti={isNotiHasNotTemplate}
        handleCancel={handleCancelEdit} 
        handleSubmitEdit={selectedIds?.length ? handleChangeStatus : handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />
    </div>
  );
};

export default CategoryListPage;
