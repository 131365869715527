/* eslint-disable no-unsafe-optional-chaining */
import React, {useEffect, useState} from 'react';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import {useParams} from 'react-router-dom';
import services from '@/services';
import './DetailTemplatePage.scss';
import DetailTemplateEditMode from '@/component/templates/DetailTemplatePage/DetailTemplateInfo/DetailTemplateEditMode';
import DetailTemplateReadOnlyMode from '@/component/templates/DetailTemplatePage/DetailTemplateInfo/DetailTemplateReadOnlyMode';
import BaseButton from '@/component/common/Button';
import {
  defaultDetailTemplateForm
} from '@/component/templates/form/EditDetailTemplate';
import {
  IDetailTemplateSingleItem
} from '@/pages/templates/interface/DetailTemplatePage';
import {HTTP_STATUS, STATUSES_LIST} from '@/constants';
import {USER_ACTIONS, USER_RESOURCE} from '@/constants/roles';
import {formatDateTime} from '@/utils';
import {cancelToken} from '@/http-common';
import DetailTemplateThumbnail from '@/component/templates/DetailTemplatePage/DetailTemplateThumbnail';
import DetailTemplatePreview from '@/component/templates/DetailTemplatePage/DetailTemplatePreview';
import {useDispatch, useSelector} from 'react-redux';
import {setHeaderPage, setIsUserAccessAnEditablePage, setShowLoadingIcon} from '@/redux/slice/common';
import { usePrompt } from '@/hooks/usePrompt';
import {RootState} from '@/redux/rootReducer';
import {setNoti} from '@/redux/slice/commonListPageSlice';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import {
  setIsAllowUpload,
  setTemplateData,
  setUploadTemplatePreviewFile,
  setUploadTemplateThumbnailFile
} from '@/redux/slice/template';
import initialUploadTemplateFiles from '@/redux/constant/template';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';

export default function DetailTemplatePage(): JSX.Element {
  const {id} = useParams();
  const dispatch = useDispatch();
  dispatch(setHeaderPage('Templates'));

  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);

  const thumbnail = useSelector((state: RootState) => state.templateReducer.thumbnail);
  const preview = useSelector((state: RootState) => state.templateReducer.preview);
  const templateData = useSelector((state: RootState) => state.templateReducer.templateData);
  const isAllowUpload = useSelector((state: RootState) => state.templateReducer.isAllowUpload);
  const showLoadingIcon = useSelector((state: RootState) => state.commonReducer.showLoadingIcon);

  const [templateListPermissions, setTemplateListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [itemData, setItemData] = useState<IDetailTemplateSingleItem>(defaultDetailTemplateForm);
  const [readOnlyItemData, setReadOnlyItemData] = useState<IDetailTemplateSingleItem>(defaultDetailTemplateForm);

  const [originalThumbnailUrl, setOriginalThumbnailUrl] = useState<string>('');
  const [uploadThumbnailLinkUrl, setUploadThumbnailLinkUrl] = useState<string>('');
  

  const [originalPreviewUrl, setOriginalPreviewUrl] = useState<string>('');
  const [uploadPreviewLinkUrl, setUploadPreviewLinkUrl] = useState<string>('');
  

  const [userClickSubmitButtonsTimes, setUserClickSubmitButtonsTimes] = useState<number>(0);

  usePrompt('All the data will be lost! Are you sure you want to leave?', isEditMode);

  const goBackButtonHandler = () => {
    if (window.confirm('All the data will be lost! Are you sure you want to leave?')) {
      history.back();
      dispatch(setIsUserAccessAnEditablePage(false));
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  }

  const onBackButtonEvent = (event: any) => {
    if (isEditMode) {
      event.preventDefault();
      goBackButtonHandler();
    }
  }

  useEffect(() => {
    if (isEditMode) {
      window.history.pushState(null, '', window.location.pathname);

    }
    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate', onBackButtonEvent)

  }, [isEditMode]);

  const getDetailTemplateById = async () => {
    try {
      if (id) {
        const res = await services.getDetailTemplate(id);

        //   set data for detail template
        if (res && res.status === HTTP_STATUS.SUCCESS) {
          const dataDetailTemplate = res.data.data;
          const categories = dataDetailTemplate.categories.map((item: any) => {
            return {
              id: item.id,
              name: item.name
            }
          });
          const item = {
            name: dataDetailTemplate.name,
            categories,
            templateVipType: dataDetailTemplate.templateVipType,
            thumbnailUrl: dataDetailTemplate.thumbnailUrl,
            videoPreviewUrl: dataDetailTemplate.videoPreviewUrl,
            webPriority: dataDetailTemplate.webPriority?.toString(),
            iosPriority: dataDetailTemplate.iosPriority?.toString(),
            androidPriority: dataDetailTemplate.androidPriority?.toString(),
            webStatus: dataDetailTemplate.webStatus?.toString(),
            iosStatus: dataDetailTemplate.iosStatus?.toString(),
            androidStatus: dataDetailTemplate.androidStatus?.toString(),
            duration: dataDetailTemplate.duration,
            createdBy: dataDetailTemplate.createdBy,
            createdDate: formatDateTime(dataDetailTemplate.createdDate),
            resolution: dataDetailTemplate.resolution
          };

          setItemData(item);
          setReadOnlyItemData(item);
          setOriginalThumbnailUrl(dataDetailTemplate.thumbnailUrl);
          setOriginalPreviewUrl(dataDetailTemplate.videoPreviewUrl);
        }
        
  
      }
    } catch (err: any) {
      console.log('error', err);
    }
  };

  const showEditMode = async () => {
    dispatch(setIsUserAccessAnEditablePage(true));

    try {
      if (id) {
        const data = await Promise.all([
          services.getUploadThumbnailUrl(id),
          services.getUploadVideoPreviewUrl(id)
        ]);
        
        const responseGetUploadThumbnailUrl = data[0];
        const responseGetUploadVideoPreviewUrl = data[1];
        
        //   set upload link thumbnail
        if (responseGetUploadThumbnailUrl && responseGetUploadThumbnailUrl.status === HTTP_STATUS.SUCCESS) {
          const dataGetUploadThumbnailUrl = responseGetUploadThumbnailUrl.data.data;

          const defaultUploadThumbnailLink = dataGetUploadThumbnailUrl.uploadLink;
          setUploadThumbnailLinkUrl(defaultUploadThumbnailLink);
        }
        
        //    set upload link preview
        if (responseGetUploadVideoPreviewUrl && responseGetUploadVideoPreviewUrl.status === HTTP_STATUS.SUCCESS) {
          const dataGetUploadVideoPreviewUrl = responseGetUploadVideoPreviewUrl.data.data;

          const defaultUploadPreviewLink = dataGetUploadVideoPreviewUrl.uploadLink;
          setUploadPreviewLinkUrl(defaultUploadPreviewLink);
        }
      }
    } catch (err: any) {
      console.log('error', err);
    }
    setIsEditMode(true);
  }

  const handleCancel = () => {
    setItemData(readOnlyItemData);
    setIsEditMode(false);
    dispatch(setIsUserAccessAnEditablePage(false));
  }

  useEffect(() => {
    const permissions =  getListPermissionsByResource(userPermissions, USER_RESOURCE.TEMPLATE);
    setTemplateListPermissions(permissions);
    getDetailTemplateById();
    return () => cancelToken()
  }, []);



  const handleSubmit = () => {
    setUserClickSubmitButtonsTimes((count: number) => count + 1);
  }

  const handleUpload = async () => {
    if (showLoadingIcon) {
      return
    }
    dispatch(setShowLoadingIcon(true));
    try {
      if (id) {
        let uploadThumbnailRes;
        if (thumbnail) {
          uploadThumbnailRes = await services.uploadThumbnailPreview(uploadThumbnailLinkUrl, thumbnail);
        }

        let uploadPreviewRes;
        if (preview) {
          uploadPreviewRes = await services.uploadVideoPreview(uploadPreviewLinkUrl, preview)
        }

        console.log('templateData', templateData)
      
        const uploadDataRes = await services.updateDetailTemplate(id, templateData);
          
        if (uploadDataRes && uploadDataRes.status === HTTP_STATUS.SUCCESS){
          if (thumbnail && uploadThumbnailRes && uploadThumbnailRes.status === HTTP_STATUS.SUCCESS
          || preview && uploadPreviewRes && uploadDataRes.status === HTTP_STATUS.SUCCESS) {
            dispatch(setTemplateData(initialUploadTemplateFiles.templateData));
            dispatch(setIsAllowUpload(false));
            dispatch(setUploadTemplateThumbnailFile(initialUploadTemplateFiles.thumbnail));
            setUserClickSubmitButtonsTimes(0);
            dispatch(setUploadTemplatePreviewFile(initialUploadTemplateFiles.preview));

            dispatch(setIsUserAccessAnEditablePage(false));
            dispatch(setShowLoadingIcon(false));
            dispatch(setNoti({
              open: true,
              message: 'Update Template Successfully! Please wait for 1 minute to get the updated thumbnail or video',
              icon: <CheckSuccessIcon />,
            }))
            await getDetailTemplateById();
            setIsEditMode(false);
          }

          dispatch(setTemplateData(initialUploadTemplateFiles.templateData));
          dispatch(setIsAllowUpload(false));
          dispatch(setUploadTemplateThumbnailFile(initialUploadTemplateFiles.thumbnail));
          setUserClickSubmitButtonsTimes(0);
          dispatch(setUploadTemplatePreviewFile(initialUploadTemplateFiles.preview));

          dispatch(setIsUserAccessAnEditablePage(false));
          dispatch(setShowLoadingIcon(false));
          dispatch(setNoti({
            open: true,
            message: 'Update Template Successfully!',
            icon: <CheckSuccessIcon />,
          }))
          await getDetailTemplateById();
          setIsEditMode(false);
        }

      }



    } catch (err: any) {
      const { message } = err?.response?.data
      console.log('err', err);
      dispatch(setShowLoadingIcon(false));
      dispatch(setNoti({
        open: true,
        message: message || 'Update Template Fail!',
        type: 'error',
      }))
    }
  };

  useEffect(() => {
    if (isAllowUpload){
      handleUpload();
    }
  }, [userClickSubmitButtonsTimes, isAllowUpload])
    
  return (
    <div>
      <BackToListPageButton
        listPageUrl={'/template/template-list'}
        title={'Template List'}
      />

      <div className='detail-template-page-container'>
        {/* Detail Template info   */}
        <div className='detail-template-info'>
          <div>
            {isEditMode
              ? 
              (<DetailTemplateEditMode 
                item={itemData}
                statusesList={STATUSES_LIST}
                userClickSubmitButtonsTimes={userClickSubmitButtonsTimes}
              />
              )
              : <DetailTemplateReadOnlyMode item={readOnlyItemData}/>
            }
          </div>

          {checkPermission(templateListPermissions, USER_ACTIONS.EDIT) && (
            <div className='btn-container'>
              {isEditMode
                ? (<div className='submit-btn-container'>
                  <div className='btn cancel-btn'>
                    <BaseButton
                      handleClick={handleCancel}
                      buttonLabel={'Cancel'}
                    />
                  </div>

                  <div className='btn'>
                    <BaseButton
                      handleClick={handleSubmit}
                      buttonLabel={'Save'}
                      type={'primary'}
                    />
                  </div>
                </div>)

                : (<div className='btn'>
                  <BaseButton
                    handleClick={showEditMode}
                    buttonLabel={'Edit'}
                    type={'primary'}
                  />
                </div>)
              }

            </div>)}
        </div>

        <div className='thumbnail-video-container'>
          {/*Detail Template Thumbnail*/}
          <DetailTemplateThumbnail
            isEditMode={isEditMode}
            originalThumbnailUrl={originalThumbnailUrl}
          />

          {/*    Detail Template Video*/}
          <DetailTemplatePreview
            isEditMode={isEditMode}
            originalPreviewUrl={originalPreviewUrl}
          />

        </div>
      </div>
    </div>
  );
}
