import ContentPageHeader from '@/component/common/ContentPageHeader';
import React, {useEffect, useState} from 'react';
import {Tabs} from 'antd';
import type {TabsProps} from 'antd';
import './index.scss';
import {TRANSITION_CONTENT_TABS} from '@/constants';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import TransitionListContainer from '../TransitionListPage';
import TransitionCategoryListPage from '../TransitionCategoryListPage';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';
import EffectListContainer from '@/component/content/Effect/EffectListContainer';
import CategoryEffectContainer from '@/component/content/Effect/CategoryEffectContainer';

export default function TransitionTabs(): JSX.Element {
  const dispatch = useDispatch();
  const {TabPane} = Tabs;

  dispatch(setHeaderPage('App Content'));
  
  const [activeKey, setActiveKey] = useState<string>(TRANSITION_CONTENT_TABS.TRANSITION_LIST);
  
  const [searchParams, setSearchParams] = useSearchParams();
  
  const queryTab = searchParams.get('tab');
  
  const handleGetCurrentActiveTab = () => {
    setActiveKey(queryTab || TRANSITION_CONTENT_TABS.TRANSITION_LIST);
  };
  
  useEffect(() => {
    handleGetCurrentActiveTab();
  }, [queryTab]);

  const handleChangeTab = (key: string) => {
    setActiveKey(key);
    setSearchParams(createSearchParams({tab: key}));
  };

  return (
    <div className='transition-content-tab-pane'>
      <ContentPageHeader header={'Transition Content'} />
      <Tabs 
        defaultActiveKey={TRANSITION_CONTENT_TABS.TRANSITION_LIST} 
        activeKey={activeKey}
        size='large'
        onChange={handleChangeTab}
        className='tab-container'
      >
        <TabPane tab="TRANSITION LIST" key={TRANSITION_CONTENT_TABS.TRANSITION_LIST}>
          {activeKey === TRANSITION_CONTENT_TABS.TRANSITION_LIST  && <TransitionListContainer activeKey={activeKey}/> }
        </TabPane>
        <TabPane tab="CATEGORY TRANSITION" key={TRANSITION_CONTENT_TABS.CATEGORY_TRANSITION}>
          {activeKey === TRANSITION_CONTENT_TABS.CATEGORY_TRANSITION  && <TransitionCategoryListPage activeKey={activeKey} /> }
        </TabPane>
        
      </Tabs>
    </div>
  );
}