import React from 'react';
import CategoryListPage from '../../pages/templates/CategoryListPage';
import TemplateApprovalPage from '../../pages/templates/TemplateApprovalPage';
import TemplateListPage from '../../pages/templates/TemplateListPage';
import {useRoutes} from 'react-router-dom';
import DetailTemplatePage from '@/pages/templates/DetailTemplatePage';
import DetailCategoryPage from '@/pages/templates/DetailCategoryPage';
import UploadTemplatePage from '@/pages/templates/UploadTemplatePage';

const TemplateRoutes = () => {
  const routes = [
    {
      path: '/category-list',
      element: <CategoryListPage />
    },
    {
      path: '/category-list/category-detail/:id',
      element: <DetailCategoryPage />
    },
    {
      path: '/template-list',
      element: <TemplateListPage />,
    }, 
    {
      path: '/template-list/template-detail/:id',
      element: <DetailTemplatePage />
    },
    {
      path: '/upload-template',
      element: <UploadTemplatePage />
    },
    {
      path: '/template-approval',
      element: <TemplateApprovalPage />
    },
  ];

  const routing = useRoutes(routes);
  return (
    <>
      {routing}
    </>
  );
};

export default TemplateRoutes;