import {IDetailCategoryErrorMessages, IDetailCategoryItem} from '@/pages/templates/interface/DetailCategoryPage';

export const defaultDetailCategoryForm: IDetailCategoryItem = {
  name: '',
  description: '',
  priority: '',
  createdAt: '',
  status: '',
  templateCount: '',
  properties: []
}

export const defaultCategoryErrorMessage: IDetailCategoryErrorMessages = {
  name: '',
  priority: '',
}