import React from 'react';
import AdminAccountPage from '../../pages/config/AdminAccountPage';
import {useRoutes} from 'react-router-dom';
import RoleAndPermissionsPage from '@/pages/config/RoleAndPermissionsPage';

export default function ConfigRoutes(): JSX.Element {
  const routes = [
    {
      path: '/admin-account',
      element: <AdminAccountPage />
    },
    {
      path: '/role-permissions',
      element: <RoleAndPermissionsPage />
    }
  ];
    
  const routing = useRoutes(routes);
  return (
    <>
      {routing}
    </>
  );
}