import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';

import CloseIcon from '@/assets/icon/ic_close_modal.svg';
import {defaultAddCategoryForm} from '@/component/templates/form/AddCategoryForm';
import InputTextField from '@/component/common/InputTextField';
import BaseTextArea from '@/component/common/TextArea';
import BaseButton from '@/component/common/Button';
import {ICreateCategoryPayload} from '@/services/interface/category.interface';
import services from '@/services';
import ErrorMessage from '@/component/common/ErrorMessage/index';
import {checkMinLength} from '@/utils';
import {HTTP_STATUS, STATUSES_LIST} from '@/constants';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import { useDispatch } from 'react-redux';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import {
  IAddEffectCategoryForm,
  IAddEffectCategoryModal
} from '@/component/content/interface/AddEffectCategoryModal';
import SelectBox from '@/component/common/SelectBox';
import './AddEffectCategoryModal.scss';

const HeaderModal = () => {
  return (<span className='header-modal'>Add Category</span>)
}
const CloseModalIcon = () => {
  return <img src={CloseIcon} alt='close-icon'/>
}

const AddEffectCategoryModal: React.FC<IAddEffectCategoryModal> = ({
  showModal,
  handleCancel,
  getListAllEffectCategories
}) => {
  const [formCategory, setFormCategory] = useState<IAddEffectCategoryForm>(defaultAddCategoryForm);
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const listStatuses = STATUSES_LIST;

  const handleChangeFormCategory = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let inputValue = event.target.value;
    if (event.target.name === 'priority') {
      inputValue = event.target.value.replace(/\D/g, '');
    }
    setFormCategory({
      ...formCategory,
      [event.target.name]: inputValue
    });
  };
  
  const handleValidateFormCategory = (name: string) => {
    const errorMessages  = checkMinLength(name, 2);
    return errorMessages;
  }
  
  const handleAddNewCategory = async (payload: ICreateCategoryPayload) => {
    if (loading) return;
    setLoading(true)
    try {
      const res =  await services.createEffectCategory(payload);
      if (res.status === HTTP_STATUS.CREATED) {
        await getListAllEffectCategories()
        await handleCancel()
        dispatch(setNoti({
          open: true,
          message: 'Add Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
    } catch (err: any) {
      const errorMsg = err.response.data.message
      if (errorMsg.includes('duplicate') || errorMsg.includes('Duplicate')) {
        setFormErrorMessage('This category is already exists. Please try another name')
      }
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: errorMsg || 'Add Category Fail!',
      }))
    }
    setLoading(false);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const errorMessages = handleValidateFormCategory(formCategory.name);

    setFormErrorMessage(errorMessages);

    const payload = {
      name: formCategory.name.trim(),
      description: formCategory.description.trim(),
      status: formCategory.status ? parseInt(formCategory.status): 0
    };

    if (!errorMessages) {
      handleAddNewCategory(payload);
    }

  };
  
  const handleClearFormAddCategoryData = () => {
    if (!showModal) {
      setFormCategory(defaultAddCategoryForm)
    }
  }

  const handleChangeSelectStatus = (value: string) => {
    setFormCategory({
      ...formCategory,
      status: value
    })
  }
  
  useEffect(() => {
    handleClearFormAddCategoryData()
  }, [showModal])
  
  return (
    <>
      <Modal
        title={<HeaderModal/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='add-effect-category-modal-container'
        footer={[
          <div key='1' className='modal-footer-btn'>
            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={'Add Category'}
                htmlType={'submit'}
                type='primary'
                handleClick={handleSubmit}
                className={loading ? 'disabled' : ''}
              />
            </div>
            <div className='cancel-btn'>
              <BaseButton
                buttonLabel={'Cancel'}
                handleClick={handleCancel}
              />
            </div>
            
          </div>
        ]}
      >
        <form className='form' onSubmit={handleSubmit}>
          <p className='label'>Category Name <span>*</span></p>
          <InputTextField 
            value={formCategory.name} 
            inputType={'text'} 
            inputPlaceholder={'example'}
            inputName={'name'}
            handleChange={handleChangeFormCategory}
            maxLength={150}
            minLength={2}
            showCount={true}
            isInputInvalid={formErrorMessage !== '' }
          />
          <ErrorMessage errorMessage={formErrorMessage}/>

          <p className='label'>Description</p>
          <BaseTextArea 
            handleChange={handleChangeFormCategory} 
            placeholder={'Example'} 
            value={formCategory.description}
            name={'description'}
            maxLength={500}
          />
          <div className='status-container'>
            <p className='label'>Status</p>
            <SelectBox
              listItems={listStatuses} 
              defaultValue={listStatuses[0]}
              handleChange={handleChangeSelectStatus}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddEffectCategoryModal;