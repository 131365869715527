import ContentPageHeader from '@/component/common/ContentPageHeader';
import React, {useEffect, useState} from 'react';
import {Tabs} from 'antd';
import './EffectContentTabs.scss';
import EffectListContainer from '@/component/content/Effect/EffectListContainer';
import CategoryEffectContainer from '@/component/content/Effect/CategoryEffectContainer';
import {EFFECT_CONTENT_TABS} from '@/constants';
import {createSearchParams, useSearchParams} from 'react-router-dom';

export default function EffectContentTabs(): JSX.Element {
  const {TabPane} = Tabs;
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState<string>(EFFECT_CONTENT_TABS.EFFECT_LIST);
  
  const queryTab = searchParams.get('tab');
  
  const handleGetCurrentActiveTab = () => {
    setActiveKey(queryTab || EFFECT_CONTENT_TABS.EFFECT_LIST);
  };
  
  useEffect(() => {
    handleGetCurrentActiveTab();
  }, [queryTab]);

  const handleChangeTab = (key: string) => {
    setActiveKey(key);
    setSearchParams(createSearchParams({tab: key}));
  };

  return (
    <div className='effect-content-tab-pane'>
      <ContentPageHeader header={'Effect Content'} />
      <Tabs 
        defaultActiveKey={EFFECT_CONTENT_TABS.EFFECT_LIST} 
        activeKey={activeKey}
        size='large'
        onChange={handleChangeTab}
        className='tab-container'
      >
        <TabPane tab="EFFECT LIST" key={EFFECT_CONTENT_TABS.EFFECT_LIST}>
          {activeKey === EFFECT_CONTENT_TABS.EFFECT_LIST  && <EffectListContainer /> }
        </TabPane>
        <TabPane tab="CATEGORY EFFECT" key={EFFECT_CONTENT_TABS.CATEGORY_EFFECT}>
          {activeKey === EFFECT_CONTENT_TABS.CATEGORY_EFFECT && <CategoryEffectContainer /> }
        </TabPane>

      </Tabs>
    </div>
  );
}