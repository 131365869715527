import React from 'react';
import {Input} from 'antd';
import IconSearchInput from '@/assets/icon/ic_search_input.svg';
import {IInputSearchField} from '../interface/InputSearchField';
import './InputSearchField.scss';

const InputSearchField: React.FC<IInputSearchField> = ({
  value,
  inputPlaceholder, 
  inputName, 
  handleChange, 
  minLength, 
  maxLength, 
  handleClickSearch
}) => {
  const SearchIcon = () => {
    return <img src={IconSearchInput} alt="icon-search" className='icon' />
  }
    
  return (
    <div className='input-search-field'>
      <div onClick={handleClickSearch} className='icon-search-container'>
        <SearchIcon />
      </div>
      <Input 
        className='input-search'
        size='large'
        type='text'
        placeholder={inputPlaceholder}
        value={value}
        onChange={handleChange}
        minLength={minLength}
        maxLength={maxLength}
        name={inputName}
      />
        
    </div>
  );
};

export default InputSearchField;