import {IAddCategoryErrorMessage, IAddCategoryForm} from '@/component/templates/interface/AddCategoryModal';

export const defaultAddCategoryForm: IAddCategoryForm = {
  name: '',
  priority: '',
  description: '',
  status: ''
}

export const defaultAddCategoryErrorMessage: IAddCategoryErrorMessage = {
  name: '',
}