export enum RoleCode {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  MARKETING = 'marketing',
  PRODUCT = 'product',
  CREATOR = 'creator',
}

export const ROLES_LIST = [
  {
    label: 'Admin',
    value: RoleCode.ADMIN
  },
  {
    label: 'Super Admin',
    value: RoleCode.SUPER_ADMIN
  },
  {
    label: 'Marketing',
    value: RoleCode.MARKETING
  },
  {
    label: 'Product',
    value: RoleCode.PRODUCT
  },
  {
    label: 'Creator',
    value: RoleCode.CREATOR
  }
]

export const USER_ACTIONS = {
  READ: 'read',
  EDIT: 'edit',
  CREATE: 'create',
  DELETE: 'delete',
}

export const USER_RESOURCE = {
  USER: 'user',
  ROLE: 'role',
  CATEGORY: 'category',
  TEMPLATE: 'template',
}

export const TABLE_ROLES_HEADER = [
  {
    label: 'read',
    value: 'read'
  },
  {
    label: 'create',
    value: 'create'
  },
  {
    label: 'update',
    value: 'update'
  },
  {
    label: 'delete',
    value: 'delete'
  },
]

export const TABLE_PERMISSIONS = [
  //   Admin
  {
    role: RoleCode.ADMIN,
    defaultUrl: 'config/admin-account',
    defaultKeyPath: 'config',
    permissions: [
      {
        feature: 'user account',
        read: true,
        create: true,
        update: true,
        delete: true,
        sidebarFeature: 'admin-account'
      },
      {
        feature: 'role',
        read: true,
        create: false,
        update: false,
        delete: false,
        sidebarFeature: 'role-permissions'
      },
      {
        feature: 'template',
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      {
        feature: 'category',
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      {
        feature: 'AI',
        read: true,
        create: true,
        update: true,
        delete: false,
      }
    ]
  },
  //   Super Admin
  {
    role: RoleCode.SUPER_ADMIN,
    defaultUrl: 'config/role-permissions',
    defaultKeyPath: 'config',
    permissions: [
      {
        feature: 'user account',
        read: true,
        create: true,
        update: true,
        delete: true,
        sidebarFeature: 'admin account'
      },
      {
        feature: 'role',
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      {
        feature: 'template',
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'category',
        read: false,
        create: false,
        update: false,
        delete: false,
      }, 
      {
        feature: 'AI',
        read: false,
        create: false,
        update: false,
        delete: false,
      }
    ]
  },
  //   Marketing
  {
    role: RoleCode.MARKETING,
    defaultUrl: 'template/category-list',
    defaultKeyPath: 'template',
    permissions: [
      {
        feature: 'user account',
        read: false,
        create: false,
        update: false,
        delete: false,
        sidebarFeature: 'admin account'
      },
      {
        feature: 'role',
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'template',
        read: true,
        create: true,
        update: true,
        delete: false,
      },
      {
        feature: 'category',
        read: true,
        create: true,
        update: true,
        delete: false,
      },
      {
        feature: 'AI',
        read: true,
        create: true,
        update: true,
        delete: false,
      }
    ]
  },
  //     Product
  {
    role: RoleCode.PRODUCT,
    defaultUrl: 'config/admin-account',
    defaultKeyPath: 'config',
    permissions: [
      {
        feature: 'user account',
        read: true,
        create: false,
        update: false,
        delete: false,
        sidebarFeature: 'admin account'
      },
      {
        feature: 'role',
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'template',
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'category',
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'AI',
        read: true,
        create: false,
        update: false,
        delete: false,
      }
    ]
  },
  //     Creator
  {
    role: RoleCode.CREATOR,
    defaultUrl: 'template/category-list',
    defaultKeyPath: 'template',
    permissions: [
      {
        feature: 'user account',
        read: false,
        create: false,
        update: false,
        delete: false,
        sidebarFeature: 'admin account'
      },
      {
        feature: 'role',
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'template',
        read: true,
        create: true,
        update: true,
        delete: false,
      },
      {
        feature: 'category',
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      {
        feature: 'AI',
        read: true,
        create: false,
        update: false,
        delete: false,
      }
    ]
  },
]