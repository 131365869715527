import authRequest from '@/services/service';
import {ICreateCategoryPayload, IUpdateCategoryPayload} from '@/services/interface/category.interface';

export const getUUId = async () => {
  const response = await authRequest.get('master/uuid');
  return response;
}

export const getListEffects = async (query: any) => {
  const response = await authRequest.get('effects', {params: query})  ;
  return response;
};

export const getDetailEffect = async (id: string) => {
  const response = await authRequest.get(`effects/${id}`);  
  return response;
}

export const getUploadEffectThumbnailLink = async (uuId: string, query: any) => {
  const response = await authRequest.get(`effects/${uuId}/upload-url/thumbnail`, {params: query});
  return response;
}

export const getUploadEffectFileLink = async (uuId: string, platform: string, query: any) => {
  const response = await authRequest.get(`effects/${uuId}/upload-url/source/${platform}`, {params: query});
  return response;
}

export const createEffect = async (payload: any) => {
  const response = await authRequest.post('effects', payload);
  return response;
}

export const updateEffect = async (id: string, payload: any) => {
  const response = await authRequest.put(`effects/${id}`, payload);
  return response;
}

export const deleteEffect = async (id: string) => {
  const response = await authRequest.delete(`effects/${id}`);
  return response;
}

export const getListEffectCategories = async (query: any) => {
  const response = await authRequest.get('effect-categories', {params: query});
  return response;
}

export const getDetailEffectCategory = async (id: string) => {
  const response = await authRequest.get(`effect-categories/${id}`);
  return response;
}

export const createEffectCategory = async (payload: ICreateCategoryPayload) => {
  const response = await authRequest.post('effect-categories', payload);
  return response;
}

export const updateEffectCategory = async (id: string, payload: IUpdateCategoryPayload) => {
  const response = await authRequest.put(`effect-categories/${id}`, payload);
  return response;
}

export const deleteEffectCategory = async (id: string) => {
  const response = await authRequest.delete(`effect-categories/${id}`);
  return response;
}

export const getEffectByEffectCategory = async (categoryId: string, query: any) => {
  const response = await authRequest.get(`effect-categories/${categoryId}/items`, {params: query});
  return response
}

const effects = {
  getUUId,
  getListEffects, 
  getDetailEffect,
  getUploadEffectThumbnailLink,
  getUploadEffectFileLink,
  createEffect,
  updateEffect,
  deleteEffect,
  getListEffectCategories,
  getDetailEffectCategory,
  createEffectCategory,
  updateEffectCategory,
  deleteEffectCategory,
  getEffectByEffectCategory
}

export default effects;