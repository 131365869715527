/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import {useDispatch} from 'react-redux';
import {setHeaderPage, setShowLoadingIcon} from '@/redux/slice/common';
import Card from '@/component/common/Card';
import './index.scss'
import InputTextField from '@/component/common/InputTextField';
import ButtonUpload from '@/component/common/ButtonUpload';
import SelectBoxCustom from '@/component/common/SelectBoxCustom';
import BaseButton from '@/component/common/Button';
import { Controller, useForm } from 'react-hook-form';
import { Radio, Tag } from 'antd';
import UploadComponent from '@/component/common/Upload';
import FormItem from '@/component/common/FormItem';
import CardHeader from '@/component/common/CardHeader';
import TrashIcon from '@/component/icon/TrashIcon';
import services from '@/services';
import { HTTP_STATUS, STATUSES_LIST } from '@/constants';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import dayjs from 'dayjs';
import SelectSearchAndAddItem from '@/component/common/SelectSearchAndAddItem';
import { uniqBy } from 'lodash';

const STATUSES = [
  {value: 0, label: 'Inactive', color: '#DE2F17'},
  {value: 1, label: 'Active', color: '#74C059'}
]
const CreateEditTransition = () => {
  const { control, handleSubmit, reset, setError,  formState:{ errors, isSubmitted }, setValue, getValues } : any = useForm({
    defaultValues: {
      name: '',
      categories: [],
      status: 1,
      iosUrl: '',
      androidUrl: '',
      webUrl: '',
      platform: 'each'
    }
  });
  const { categoryName, no, name, status, platform, iosUrl, androidUrl, webUrl, thumbnail, sourceFile, createdBy, createdAt } = getValues();
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [uuid, setUUID] = useState<string>('')
  const {id} = useParams();
  const [isSummiting, setIsSubmiting] = useState<boolean>(false);
  const [isAllPlatform, setIsAllPlatform] = useState<boolean>(false);
  const [dataTransition, setDataTransition] = useState<any>();
  // const [listTransitionCategories, setListTransitionCategories] = useState<any>([]);

  const isCreate = !id;
  const isDetail = id && !isUpdate;
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getDetailTransition()
    } else {
      getMasterUUID()
      // handleSearchTransitionCategories('', [])
    }
  }, [id])

  // useEffect(() => {
    
  // }, [])

  const getMasterUUID = async () => {
    const res = await services.getMasterUUID();
    if (res.status === HTTP_STATUS.SUCCESS) {
      setUUID(res.data.data.id)
    }
  }

  const getDetailTransition = async() => {
    const res = await services.detailTransition(id)
    if (res.status === HTTP_STATUS.SUCCESS) {
      const isAllPlatform = res.data.data?.webUrl && res.data.data?.webUrl?.indexOf('/all') !== -1
      setIsAllPlatform(isAllPlatform)
      setDataTransition(res.data.data)
      initValues(res.data.data, isAllPlatform)
      if (res.data.data.categories.length) {
        const listItems = res.data.data.categories.map((item: any) => ({
          label: item.name,
          value: item.id
        }));
        // const list = uniqBy([...listItems, ...listTransitionCategories], 'value')
        // handleSearchTransitionCategories('', list)
      }
    }
  }

  const initValues = (data: any,isAllPlatform: boolean) => {
    const selectedCategory = data?.categories?.map((item: any) => ({label: item.name, value: item.id}));
    reset((formValues : any) => ({
      ...formValues,
      ...data,
      no: data?.webPriority,
      status: data?.webStatus,
      platform: isAllPlatform ? 'all' : 'each',
      sourceFile: isAllPlatform ? { fileName: data?.webUrl, fileUpload: { responseLink: data?.webUrl} } : '',
      webUrl: data?.webUrl ? { fileName: data?.webUrl, fileUpload: { responseLink: data?.webUrl} } : '',
      iosUrl: data?.iosUrl ? { fileName: data?.iosUrl, fileUpload: { responseLink: data?.iosUrl} } : '',
      androidUrl: data?.androidUrl ? { fileName: data?.androidUrl, fileUpload: { responseLink: data?.androidUrl} } : '',
      thumbnail: data?.thumbnailUrl ? { src: data?.thumbnailUrl, fileUpload: { responseLink: data?.thumbnailUrl} } : '',
      // categories: data?.categories?.length ? data?.categories.map((item: any) => item.id) : '',
      categories: selectedCategory,
      categoryName: data?.categories?.length ? data?.categories.map((item: any) => item.name).join(', ') : '',
    }))
  }
  
  const dispatch = useDispatch();
  dispatch(setHeaderPage(id ? 'Transition Detail' : 'Add New Transition')) 


  const handleChangeFile = () => {

    // setFormUploadTemplateErrorMessage((prev: any) => ({
    //   ...prev,
    //   zipFile: ''
    // }));
    // setIsUploadingZipFile(true);
  }

  const handleUploadFile = async (option: any, key: string) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name;
    const fileExt = uploadFile.name.split('.')[uploadFile.name.split('.').length - 1]

    const payload = {
      id: id || uuid,
      ext: fileExt,
      platform: platform === 'each' ? key.replace('Url', '') : 'all'
    }
    const res = await services.getSourceFileUpload(payload);
    if (res.status === HTTP_STATUS.SUCCESS) {
      setValue(key, { fileName, file: uploadFile, fileUpload: res.data.data}, { shouldValidate: true })
    }
  }

  const handleChangeFilePreview = (file: any) => {
    // setUploadPreviewErrorMessage('');
    // setIsUploadingPreview(true);
  }
  
  const handleUploadPreview = async (option: any) => {
    const uploadFile = option.file;
    const { size } = option.file;

    let error = '';
    const fileExt = uploadFile.name.split('.')[uploadFile.name.split('.').length - 1]
    if (fileExt !== 'gif') {
      error = 'Wrong format file! Only accept GIF file.';
      setError('thumbnail', {message: error});
      setValue('thumbnail', '', { shouldValidate: false });

      return;
    }
    if (size > 500000) {
      error = `The ${uploadFile.name} is over maximum file size 500KB. Please try again`;
      setError('thumbnail', {message: error});
      setValue('thumbnail', '', { shouldValidate: false });

      return;
    }
    const src = URL.createObjectURL(uploadFile);

    const payload = {
      id: id || uuid,
      ext: fileExt,
    }

    const res = await services.getThumbnailUpload(payload);
    if (res.status === HTTP_STATUS.SUCCESS) {
      setValue('thumbnail', { file: uploadFile, src, fileUpload: res.data.data }, { shouldValidate: true })
    }
  }

  const handleCancel = () => {
    // setIsUpdate(false)
    // initValues(dataTransition, isAllPlatform)
    navigate('/content/transition')
  }

  const convertedUploadCategory = (categories: any) => {
    let result;
    if (Array.isArray(categories)) {
      result = categories.map((item: any) => item.value);
    } else {
      result = [categories]
    }

    return result;
  }

  const onSubmit = (data: any) => {
    dispatch(setShowLoadingIcon(true));
    if (data.platform === 'each') {
      if (!iosUrl && !androidUrl && !webUrl) {
        dispatch(setShowLoadingIcon(false));
        return;
      }
    }
    setIsSubmiting(true)
    const arrServices = [
      data.iosUrl?.fileUpload?.uploadLink && services.uploadVideoPreview(data.iosUrl.fileUpload?.uploadLink, data.iosUrl?.file),
      data.androidUrl?.fileUpload?.uploadLink && services.uploadVideoPreview(data.androidUrl.fileUpload?.uploadLink, data.androidUrl?.file),
      data.webUrl?.fileUpload?.uploadLink && services.uploadVideoPreview(data.webUrl.fileUpload?.uploadLink, data.webUrl?.file),
      data.sourceFile?.fileUpload?.uploadLink && services.uploadVideoPreview(data.sourceFile.fileUpload?.uploadLink, data.sourceFile?.file),
      data.thumbnail?.fileUpload?.uploadLink && services.uploadVideoPreview(data.thumbnail.fileUpload?.uploadLink, data.thumbnail?.file),
    ].filter(function( element ) {
      return element !== undefined;
    });
    Promise.all(arrServices).then(async (values) => {
      const payload = {
        ...data,
        id: id || uuid,
        name: data.name.trim(),
        webPriority: +data.no,
        iosPriority: +data.no,
        androidPriority: +data.no,
        androidStatus: data.status,
        iosStatus: data.status,
        webStatus: data.status,
        categories: convertedUploadCategory(data.categories),
        type: 'glsl',
        thumbnailUrl: thumbnail?.fileUpload ? thumbnail?.fileUpload?.responseLink : null,
        iosUrl: isAllPlatform ? sourceFile?.fileUpload && sourceFile?.fileUpload?.responseLink : iosUrl?.fileUpload ? iosUrl?.fileUpload?.responseLink : null,
        androidUrl: isAllPlatform ? sourceFile?.fileUpload && sourceFile?.fileUpload?.responseLink : androidUrl?.fileUpload ? androidUrl?.fileUpload?.responseLink :  null,
        webUrl: isAllPlatform ? sourceFile?.fileUpload && sourceFile?.fileUpload?.responseLink :  webUrl?.fileUpload ? webUrl?.fileUpload?.responseLink :null,
      }

      const res = id ? await services.updateTransition(payload) : await services.createTransition(payload);
      if (res.status === HTTP_STATUS.CREATED || res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setShowLoadingIcon(false));
        dispatch(setNoti({
          open: true,
          message: id ? 'Update Transition Successfully!' : 'Add Transition Successfully!',
          icon: <CheckSuccessIcon />,
        }))
        if (!id) {
          navigate('/content/transition')
        } else {
          getDetailTransition();
          setIsUpdate(false);
        }
      }
      
    }).catch((err: any) => {
      dispatch(setShowLoadingIcon(false));
      const { message, errorCode } = err?.response?.data;
      if (errorCode === 'ER_DUP_ENTRY') {
        setError('name', { type: 'custom', message: 'Transition name is exits' });
      } else {
        dispatch(setNoti({
          open: true,
          message: message || 'Fail!',
          type: 'error'
        }))
      }
      
    }).finally(() => {
      setIsSubmiting(false)
    });
  };

  const onChangePlatformApply = (event: any) => {
    setValue('platform', event.target.value, { shouldValidate: true })
    if (event.target.value === 'all') {
      setIsAllPlatform(true);
      setValue('sourceFile', '', {shouldValidate: false});

    } else {
      setIsAllPlatform(false);
      setValue('iosFile', '', {shouldValidate: false});
      setValue('webFile', '', {shouldValidate: false});
      setValue('androidFile', '', {shouldValidate: false});
    }
  }

  const removeFile = (key: string) => {
    console.log('keyyyyy', key);
    
    setValue(key, '', { shouldValidate: true })
    console.log('valuesss', getValues());
    
  }

  const handleSubmitAddNewEffectCategory = async (value: string) => {
    let result = false;
    try {
      const payload = {
        name: value.trim(),
        description: '',
        status: 0
      };
      const res =  await services.createTransitionCategory(payload);
      if (res && res.status === HTTP_STATUS.CREATED) {
        await handleSearchTransitionCategories('');
        result = true;
        dispatch(setNoti({
          open: true,
          message: 'Add Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
    } catch (err: any) {
      result = false;
      const errorMsg = err.response.data.message;
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: errorMsg || 'Add Category Fail!',
      }))
    }
    return result;
  }

  const handleSearchTransitionCategories = async (value: string, page = 1) => {
    try {
      const query: any = {limit: 10, orderBy: 'createdAt', page};
      if (value) query.search = value;
      const res =  await services.getListTransitionCategories(query);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;
        const listItems = data.map((item: any) => ({
          label: item.name,
          value: item.id
        }));

        // setListTransitionCategories(() => uniqBy([...listItems, ...list], 'value'))
        return res
      }

    } catch (err: any) {
      // setListTransitionCategories([]);
      return [];
    }
  }

  
  return (
    <div className='transition-create-update'>
      <BackToListPageButton
        listPageUrl={'/content/transition'}
        title={id ? 'Transition List' : 'Transition Content '}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='content'>
          <div className='block'>
            <Card>
              {id && <FormItem
                label={'No'}
              >
                <span>{no}</span>
              </FormItem>}
              <FormItem
                label={'Platform'}
              >
                {isDetail || isUpdate ? (
                  <>
                    {iosUrl?.fileName && <Tag>IOS</Tag>}
                    {androidUrl?.fileName && <Tag>Android</Tag>}
                    {webUrl?.fileName && <Tag>Web</Tag>}
                  </>
                  
                ) : (<>
                  <Controller
                    control={control}
                    render={({ field: { value } }) => (
                      <Radio.Group onChange={onChangePlatformApply} value={value}>
                        <Radio value={'each'}>Each Platform</Radio>
                        <Radio value={'all'}>All Platform</Radio>
                      </Radio.Group>
                    )}
                    name="platform"
                  />
                </>)}
              </FormItem>
              <FormItem
                label={'Transition Name'}
                isRequired={isCreate || isUpdate}
              >
                {id && !isUpdate ? (
                  <span className='text-elipsis'>{name}</span>
                ) : (
                  <>
                    <Controller
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputTextField 
                          defaultValue={value}
                          value={value}
                          handleChange={(value) => {
                            onChange(value.target.value)
                          }} 
                          maxLength={150}
                          inputType={'text'} 
                          inputPlaceholder={'Example : Die For You,I feel it coming, v..v '}
                          inputName={'name'}
                        />
                      )}
                      name="name"
                    />
                    {errors?.name && <p className='error-message'>{errors?.name?.message}</p>}
                  </>)}
              </FormItem>
              <FormItem
                label={'Category'}
                isRequired={isCreate || isUpdate}
              >
                {id && !isUpdate  ? (
                  <span>{categoryName}</span>
                ) : (<>
                  <Controller
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <SelectSearchAndAddItem
                        selectPlaceholder={'Select category'}
                        defaultValue={value}
                        // value={value}
                        handleFetchListOptions={handleSearchTransitionCategories}
                        handleChangeSelect={onChange}
                        inputPlaceHolder={'Enter new category'}
                        // listItems={listTransitionCategories}
                        handleAddNewItem={handleSubmitAddNewEffectCategory}
                      />
                    )}
                    name="categories"
                  />
                  {errors?.categories && <p className='error-message'>{errors?.categories?.message}</p>}
                </>)}
              </FormItem>
              {(isDetail || isUpdate)  && <FormItem
                label={'Created By'}
              >
                <span>{createdBy?.userName}</span>
              </FormItem>}
              {(isDetail || isUpdate) && <FormItem
                label={'Create Date'}
              >
                <span>{dayjs(createdAt).format('YYYY-MM-DD')}</span>
              </FormItem>}
              <div className='div-action'>
                <div className='cancel-btn'>
                  <BaseButton handleClick={handleCancel} buttonLabel={'Cancel'} />
                </div>

                {isCreate && <div className='submit-btn-container'>
                  <BaseButton 
                    htmlType={'submit'}
                    buttonLabel={'Upload'} 
                    className={isSummiting ? 'disabled' : ''}
                    type={'primary'}
                  />
                </div>}
                {isUpdate && <div className='submit-btn-container'>
                  <BaseButton 
                    htmlType={'submit'}
                    buttonLabel={'Save'} 
                    type={'primary'}
                    className={isSummiting ? 'disabled' : ''}
                  />
                </div>}
                {isDetail && <div className='submit-btn-container'>
                  <BaseButton 
                    buttonLabel={'Edit'} 
                    type={'primary'}
                    handleClick={() => setIsUpdate(true)}
                  />
                </div>}
              </div>
            </Card>
          </div>
          <div className='block block-column'>
            <Card>
              <CardHeader label={'Source'}/>
              <div className='content-file'>
                {getValues('platform') === 'each' && <FormItem
                  label={'IOS File'}
                >
                  <div className='form-item'>
                    <Controller
                      control={control}
                      render={() => (
                        <div className='file-platform'>
                          {getValues('iosUrl')?.fileName && <span>{getValues('iosUrl').fileName}</span>}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {(isCreate || isUpdate) && <ButtonUpload
                              handleChangeFile={handleChangeFile}
                              handleUpload={(value: any) => handleUploadFile(value, 'iosUrl')}
                              showUploadList={false}
                              showUploadButtonLabel={false}
                            />}
                            {(isCreate || isUpdate) && getValues('iosUrl')?.fileName && <TrashIcon style={{marginBottom: '4px'}} onClick={() => removeFile('iosUrl')}/>}
                          </div>
                        </div>
                        
                      )}
                      name="iosUrl"
                    />
                  </div>
                </FormItem>}
                {getValues('platform') === 'each' && <FormItem
                  label={'Android File'}
                >
                  <div className='form-item'>
                    <Controller
                      control={control}
                      render={() => (
                        <div className='file-platform'>
                          {getValues('androidUrl')?.fileName && <span>{getValues('androidUrl').fileName}</span>}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {(isCreate || isUpdate) && <ButtonUpload
                              handleChangeFile={handleChangeFile}
                              handleUpload={(value: any) => handleUploadFile(value, 'androidUrl')}
                              showUploadList={false}
                              showUploadButtonLabel={false}
                            />}
                            {(isCreate || isUpdate) && getValues('androidUrl')?.fileName && <TrashIcon style={{marginBottom: '4px'}} onClick={() => removeFile('androidUrl')}/>}
                          </div>
                        </div>
                        
                      )}
                      name="androidUrl"
                    />
                  </div>
                </FormItem>}
                {getValues('platform') === 'each' && <FormItem
                  label={'Web File'}
                >
                  <div className='form-item'>
                    <Controller
                      control={control}
                      render={() => (
                        <div className='file-platform'>
                          {getValues('webUrl')?.fileName && <span>{getValues('webUrl').fileName}</span>}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {(isCreate || isUpdate) && <ButtonUpload
                              handleChangeFile={handleChangeFile}
                              handleUpload={(value: any) => handleUploadFile(value, 'webUrl')}
                              showUploadList={false}
                              showUploadButtonLabel={false}
                            />}
                            {(isCreate || isUpdate) && getValues('webUrl')?.fileName && <TrashIcon style={{marginBottom: '4px'}} onClick={() => removeFile('webUrl')}/>}
                          </div>
                        </div>
                        
                      )}
                      name="webUrl"
                    />
                  </div>
                </FormItem>}
                {platform === 'each' && isSubmitted && !iosUrl && !androidUrl && !webUrl && <p className='error-message'>You must upload at least one source file</p>}
                {getValues('platform') === 'all' && <FormItem
                  label={'Source File'}
                  isRequired={isCreate || isUpdate}
                >
                  <div className='form-item'>
                    <Controller
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={() => (
                        <div className='file-platform'>
                          {sourceFile?.fileName && <span>{sourceFile.fileName}</span>}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {(isCreate || isUpdate) && <ButtonUpload
                              handleChangeFile={handleChangeFile}
                              handleUpload={(value: any) => handleUploadFile(value, 'sourceFile')}
                              showUploadList={false}
                              showUploadButtonLabel={false}
                            />}
                            {(isCreate || isUpdate) && getValues('sourceFile')?.fileName && <TrashIcon onClick={() => removeFile('sourceFile')}  style={{marginBottom: '4px'}}/>}
                          </div>
                        </div>
                        
                      )}
                      name="sourceFile"
                    />
                    {errors?.sourceFile && <p className='error-message'>{errors?.sourceFile?.message}</p>}
                  </div>
                </FormItem>}
              </div>
            </Card>
            <Card>
              <div className='card-header'>
                <CardHeader label={'Thumbnail'}/>
                {(isCreate || isUpdate) && <Controller
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={() => (
                    <ButtonUpload
                      label={'Upload'}
                      handleChangeFile={handleChangeFilePreview}
                      handleUpload={handleUploadPreview}
                      showUploadList={false}
                    />
                  )}
                  name="thumbnail"
                />}
              </div>
              {errors?.thumbnail && <p className='error-message'>{errors?.thumbnail?.message}</p>}
              <div className='card-content'>
                {thumbnail?.src ? (
                  <img src={thumbnail?.src} style={{ width: '100%', height: '100%' }}/>
                ) : (
                  <UploadComponent
                    handleChange={handleChangeFilePreview}
                    handleUpload={handleUploadPreview}
                    desc='Accept formats : GIF with max size of 500KB.'
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateEditTransition;