import React from 'react';
import TemplateListTabsPane from '@/component/templates/Tabs';
import {setHeaderPage} from '@/redux/slice/common';
import {useDispatch} from 'react-redux';

const TemplateListPage = () => {
  const dispatch = useDispatch();
  dispatch(setHeaderPage('Templates'));

  return (
    <div>
      <TemplateListTabsPane />
    </div>
  );
};

export default TemplateListPage;