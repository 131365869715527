/* eslint-disable no-unsafe-optional-chaining */
import React, {useEffect, useState} from 'react';

import UploadTemplateInfo from '@/component/templates/UploadTemplatePage/UploadTemplateInfo';
import './UploadTemplatePage.scss';
import UploadTemplateThumbnail from '@/component/templates/UploadTemplatePage/UploadTemplateThumbnail';
import UploadTemplatePreview from '@/component/templates/UploadTemplatePage/UploadTemplatePreview';
import BaseButton from '@/component/common/Button';
import UploadTemplateModal from '@/component/templates/UploadTemplatePage/UploadTemplateModal';
import services from '@/services';
import {cancelToken} from '@/http-common';
import {HTTP_STATUS} from '@/constants';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import {usePrompt} from '@/hooks/usePrompt';
import {setHeaderPage, setIsUserAccessAnEditablePage, setShowLoadingIcon} from '@/redux/slice/common';
export default function UploadTemplatePage (): JSX.Element {
  const dispatch = useDispatch();
  dispatch(setHeaderPage('Templates'));

  const zipFile = useSelector((state: RootState) => state.templateReducer.zipFile);
  const thumbnail = useSelector((state: RootState) => state.templateReducer.thumbnail);
  const preview = useSelector((state: RootState) => state.templateReducer.preview);
  const templateData = useSelector((state: RootState) => state.templateReducer.templateData);
  const isAllowUpload = useSelector((state: RootState) => state.templateReducer.isAllowUpload);

  const [isUserClickCancelButton, setIsUserClickCancelButton] = useState<boolean>(false);
  const [userClickSubmitButtonTimes, setUserClickSubmitButtonTimes] = useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isUploadTemplateSuccess, setIsUploadTemplateSuccess] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadModalLabel, setUploadModalLabel] = useState<string>('');
  const [uploadedTemplateId, setUploadedTemplateId] = useState<string>('');
  const [uploadTemplateFilesUrls, setUploadTemplateFilesUrls] = useState<{id: string, thumbnailUrl: string, previewUrl: string, zipUrl: string}>({
    id: '', thumbnailUrl: '', previewUrl: '', zipUrl: ''
  });
  
  usePrompt('All the data will be lost! Are you sure you want to leave?', true);

  const goBackButtonHandler = () => {
    if (window.confirm('All the data will be lost! Are you sure you want to leave?')) {
      history.back();
      dispatch(setIsUserAccessAnEditablePage(false));
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  }

  const onBackButtonEvent = (event: any) => {
    event.preventDefault();
    goBackButtonHandler();
  }
  
  
  useEffect(() => {
    dispatch(setIsUserAccessAnEditablePage(true));
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate', onBackButtonEvent)

  }, []);

  const handleCancel = () => {
    setIsUserClickCancelButton(!isUserClickCancelButton);
    setShowModal(false);
  }
  
  const reUploadTemplate = () => {
    setIsUserClickCancelButton(!isUserClickCancelButton);
    setShowModal(false);
  }

  const handleSubmit = () => {
    setUserClickSubmitButtonTimes((count: number) => count + 1);
  }
  
  const handleUpload = async () => {
    setIsLoading(true);
    dispatch(setShowLoadingIcon(true));
    try {
      const uploadResData = await Promise.all([
        services.uploadThumbnailPreview(uploadTemplateFilesUrls.thumbnailUrl, thumbnail),
        services.uploadVideoPreview(uploadTemplateFilesUrls.previewUrl, preview),
        services.uploadZipFile(uploadTemplateFilesUrls.zipUrl, zipFile)
      ]);
      const uploadThumbnailRes = uploadResData[0];
      const uploadPreviewRes = uploadResData[1];
      const uploadZipFileRes = uploadResData[2];
      
      if (uploadThumbnailRes && uploadThumbnailRes.status === HTTP_STATUS.SUCCESS
        && uploadPreviewRes && uploadPreviewRes.status === HTTP_STATUS.SUCCESS
        && uploadZipFileRes && uploadZipFileRes.status === HTTP_STATUS.SUCCESS
      ) {
        const payload = {
          ...templateData,
          categories: templateData.category,
          id: uploadedTemplateId
        }
        const res =  await services.uploadTemplateInfo(payload);
        if (res && res.status === HTTP_STATUS.SUCCESS) {
          setIsUploadTemplateSuccess(true);
          setUploadModalLabel(`View “${templateData?.name}” Detail`);
        }
      }
      setIsLoading(false);
      dispatch(setShowLoadingIcon(false));
    } catch(err: any) {
      const { message } = err?.response?.data;
      setIsLoading(false)
      setIsUploadTemplateSuccess(false);
      setUploadModalLabel(message || 'Something wrong, please try again.');
      dispatch(setShowLoadingIcon(false));
    }
    setShowModal(true);
  }
  
  useEffect(() => {
    if(thumbnail && preview && zipFile && isAllowUpload) {
      handleUpload()
    }
  }, [userClickSubmitButtonTimes, isAllowUpload])
  
  const handleCloseModal = () => {
    setShowModal(false);
  }
  
  const handleNavigateToDetailTemplatePage = () => {
    window.location.href = `/template/template-list/template-detail/${uploadedTemplateId}`;
  }
  
  const getAllUploadTemplateFilesUrls = async (id: string) => {
    try {
      const res = await services.getUploadTemplateUrls(id);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        setUploadedTemplateId(data.id);
        setUploadTemplateFilesUrls({
          ...uploadTemplateFilesUrls,
          thumbnailUrl: data.thumbnailUrl.uploadLink,
          previewUrl: data.previewUrl.uploadLink,
          zipUrl: data.zipUrl.uploadLink
        })
      }
    } catch (err: any) {
      console.log('err', err)
    }
  }
  
  useEffect(() => {
    if (templateData?.ID || templateData?.id) {
      getAllUploadTemplateFilesUrls(templateData?.ID || templateData?.id)
    }
  }, [templateData]);
    
  return (
    <div className='upload-template-page-container'>
      {/*  Upload Template Info*/}
      <div className='upload-template-info'>
        <UploadTemplateInfo
          isUserClickCancelButton={isUserClickCancelButton}
          userClickSubmitButtonTimes={userClickSubmitButtonTimes}
        />
        <div className='upload-template-btn-container'>
          <div className='submit-btn-container'>
            <div className='cancel-btn'>
              <BaseButton handleClick={handleCancel} buttonLabel={'Clear'} />
            </div>

            <div className='submit-btn-container'>
              <BaseButton 
                handleClick={handleSubmit}
                buttonLabel={'Upload Template'} 
                type={'primary'}
                className={isLoading ? 'disabled' : ''}
              />
            </div>
          </div>
        </div>
      </div>

      {/*  Upload Template Thumbnail + Preview*/}
      <div className='upload-template-thumbnail-video'>
        {/*  Upload Thumbnail*/}
        <UploadTemplateThumbnail 
          isUserClickCancelButton={isUserClickCancelButton}
          userClickSubmitButtonTimes={userClickSubmitButtonTimes}
        />

        {/*  Upload Preview*/}
        <UploadTemplatePreview 
          isUserClickCancelButton={isUserClickCancelButton}
          userClickSubmitButtonTimes={userClickSubmitButtonTimes}
        />
      </div>

      {showModal &&(
        <UploadTemplateModal 
          isUploadSuccess={isUploadTemplateSuccess}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleNavigateToDetailTemplatePage={handleNavigateToDetailTemplatePage}
          handleUploadNewTemplate={reUploadTemplate}
          label={uploadModalLabel}
          newUploadedTemplateUrl={`/template/template-list/template-detail/${uploadedTemplateId}`}
        />)
      }
    </div>
  );
}
