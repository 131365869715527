import {ILoginFormPayload} from '@/services/interface/auth.interface';
import authRequest from '@/services/service';

const login = async (payload: ILoginFormPayload) => {
  const response = await authRequest.post('oauth2/authenticate', payload);
  return response;
}

const verifyEmail = async (payload: {token: string}) => {
  const response = await authRequest.post('auth/verify', payload);
  return response;
}

const resendVerifyEmail = async (id: string) => {
  const response = await authRequest.post(`users/invite/${id}`);
  return response;
}

const getUserRoleAndPermission = async () => {
  const response = await authRequest.get('auth/me');
  return response;
}

const auth = { login, verifyEmail, resendVerifyEmail, getUserRoleAndPermission };

export default auth;