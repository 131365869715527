import authRequest from '@/services/service';
import axios from 'axios';
import {IDetailTemplateFormUpload, IUpdateMultipleTemplateStatusPayload} from '@/pages/templates/interface/DetailTemplatePage';
import {ISwapTemplatesPayload, IUploadTemplatePayload} from '@/pages/templates/interface/UploadTemplate';

const getListTemplates = async (query: {orderBy: string, orderType: string, page: number, limit: number}) => {
  const response = await authRequest.get('templates', {params : query});
  return response;
}

const getListTemplatesByCategory = async (categoryId: string, query: {orderBy: string, orderType: string, page: number, limit: number}) => {
  const response = await authRequest.get(`categories/${categoryId}/templates`, {params : query});
  return response;
}

const getDetailTemplate = async (id: string) => {
  const response = await authRequest.get(`templates/${id}`);
  return response;
};

const getUploadTemplateUrls = async (id: string) => {
  const response = await authRequest.get(`templates/upload-url/${id}`);
  return response;
}

const getUploadThumbnailUrl = async (id: string) => {
  const response = await authRequest.get(`templates/${id}/upload-url/thumbnail`);
  return response
}

const getUploadVideoPreviewUrl = async (id: string) => {
  const response = await authRequest.get(`templates/${id}/upload-url/preview`);
  return response
}

const uploadThumbnailPreview = async (url: string, file: any, XAmzTagging?: string) => {
  let response;
  if (XAmzTagging) {
    const headers = {
      'x-amz-tagging': XAmzTagging,
      'Content-Type': 'image/jpeg'
    }
    response = await axios.put(`${url}`, file, {headers} );
  } else {
    response = await axios.put(`${url}`, file);
  }

  return response;
}

const uploadVideoPreview = async (url: string, file: any, XAmzTagging?: string) => {
  let response;
  if (XAmzTagging) {
    const headers = {
      'x-amz-tagging': XAmzTagging,
      'Content-Type': 'image/jpeg'
    }
    response = await axios.put(`${url}`, file, {headers} );
  } else {
    response = await axios.put(`${url}`, file);
  }

  return response;
}

const uploadZipFile = async (url: string, file: any, XAmzTagging?: string) => {
  let response;
  if (XAmzTagging) {
    const headers = {
      'x-amz-tagging': XAmzTagging
    }
    response = await axios.put(`${url}`, file, {headers} );
  } else {
    response = await axios.put(`${url}`, file);
  }

  return response;
}

const updateDetailTemplate = async (id: string, payload: IDetailTemplateFormUpload) => {
  const response = await authRequest.put(`templates/${id}`, payload);
  return response;
}
const updateDetailCategoryTemplate = async (id: string, categoryId: string,  payload: IDetailTemplateFormUpload) => {
  const response = await authRequest.put(`templates/${id}/category/${categoryId}`, payload);
  return response;
}

const updateMultipleTemplate = async (payload: IUpdateMultipleTemplateStatusPayload) => {
  const response = await authRequest.put('templates/status', payload);
  return response;
}

const deleteSingleTemplate = async (id: string) => {
  const response =  await authRequest.delete(`templates/${id}`);
  return response;
}

const deleteMultipleTemplate = async (payload: { templateIds: string[] }) => {
  const response =  await authRequest.delete('templates', {data: payload});
  return response;
}

const uploadTemplateInfo = async (payload: IUploadTemplatePayload) => {
  const response = await authRequest.post('templates', payload);
  return response;
}

const swapTemplates = async (payload: ISwapTemplatesPayload) => {
  const response = await authRequest.put('templates/swap-template', payload);
  return response;
}

const template = { 
  getListTemplatesByCategory,
  getListTemplates, 
  getDetailTemplate, 
  getUploadTemplateUrls,
  getUploadThumbnailUrl, 
  uploadThumbnailPreview,
  getUploadVideoPreviewUrl,
  uploadVideoPreview,
  uploadZipFile,
  updateDetailTemplate,
  updateDetailCategoryTemplate,
  deleteSingleTemplate,
  deleteMultipleTemplate,
  updateMultipleTemplate,
  uploadTemplateInfo,
  swapTemplates
};

export default template;
