import auth from './auth.service';
import category from '@/services/category.service';
import config from '@/services/config.service';
import template from '@/services/template.service';
import content from './content.service';
import transition from './transition.service';
import effects from '@/services/effect.service';
import user from './user.service';

const services = {
  ...auth,
  ...config,
  ...category,
  ...template,
  ...content,
  ...transition,
  ...effects,
  ...user,
};

export default services;
