import React from 'react';
import {IDetailCategoryReadOnlyMode} from '@/pages/templates/interface/DetailCategoryPage';
import {STATUSES_LIST} from '@/constants';
import BaseButton from '@/component/common/Button';

const DetailCategoryReadOnlyMode: React.FC<IDetailCategoryReadOnlyMode> = ({item, showEditMode, showEditButton}) => {
  const generateCategoryStatus = (data: string) => {
    const listStatuses = STATUSES_LIST;
    const result = listStatuses.find((status: {value: string, label: string}) => status.value === data);
    return result?.label
  }
    
  return (
    <>
      <div className='header'>
        <div className='category-name'>
          {item.name}
        </div>

        {showEditButton && (
          <div>
            <div className='btn'>
              <BaseButton
                handleClick={showEditMode}
                buttonLabel={'Edit'}
                type={'primary'}
              />
            </div>
          </div>
        ) 
        }
      </div>

      <div className='detail-category-items'>
        <div className='detail-category-block'>
          <div className='category-item-container'>
            <div className='category-header'>
              Description :
            </div>
            <div className='category-item'>
              {item.description}
            </div>
          </div>
            
          <div className='category-item-container'>
            <div className='category-header'>
              No:
            </div>
            <div className='category-item'>
              {item.priority}
            </div>
          </div>

          <div className='category-item-container'>
            <div className='category-header'>
              Template count:
            </div>
            <div className='category-item'>
              {item.templateCount}
            </div>
          </div>

        </div>

        <div className='detail-category-block'>
          <div className='category-item-container'>
            <div className='category-header'>
              Created at:
            </div>
            <div className='category-item'>
              {item.createdAt}
            </div>
          </div>


          <div className='category-item-container'>
            <div className='category-header'>
              Status:
            </div>
            <div className='category-item'>
              {generateCategoryStatus(item.status)}
            </div>
          </div>
            
          <div className='category-item-container'>
            <div className='category-header'>
              Property:
            </div>
            <div className='category-item'>
              {item.properties.length > 0 && (
                <div className='properties-container'>
                  {item.properties.map((property: {id: string, name: string}) => {
                    return (
                      <div key={property.id} className='property-item'>{property.name}</div>
                    );
                  })}  
                </div>
              )}
            </div>
          </div>

        </div>

      </div>

    </>
  );
};

DetailCategoryReadOnlyMode.defaultProps = {
  showEditButton: true
}

export default DetailCategoryReadOnlyMode;