import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';
import {useNavigate} from 'react-router-dom';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {STATUSES_LIST} from '@/constants';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import BaseTable from '@/component/common/BaseTable';
import BasePagination from '@/component/common/Pagination';
import ContentPageHeader from '@/component/common/ContentPageHeader';
export default function FilterContentPage(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(setHeaderPage('App Content'));

  const [searchValue, setSearchValue] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);

  const tableHeaders = [
    {
      label: 'NO',
      value: 'no',
      width: '120px',
    },
    {
      label: 'FILTER NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      required: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 30
    },
    {
      label: 'PLATFORM',
      value: 'platform',
      width: '150px',
    },
    {
      label: 'CATEGORY',
      value: 'category',
      isMultipleValueColumn: true,
      multipleValueKey: 'category',
      width: '250px',
      editable: true,
      required: false,
      editType: 'multipleSelect',
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'THUMBNAIL',
      value: 'thumbnailContent',
      width: '300px'
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      required: true,
      editType: 'singleSelect',
      listItems: STATUSES_LIST
    },
  ]

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setSearchValue(value)
  };
  
  const handleFilter = () => {
    setOpenDrawer(true);
  };
  
  const handleAddNewFilter = () => {
    navigate('./create-filter');
  }
  
  const handleChangePage = async (page: number, pageSize: number) => {
    console.log('page', page)
  }

  return (
    <>
      <ContentPageHeader header={'Filter Content'} />
      <div className='effect-list-container'>
        <div className="btn-container">
          <div className="flex-left">
            <div className="flex-left-input">
              <InputSearchField 
                value={searchValue} 
                inputPlaceholder={'Search...'} 
                inputName={'search'} 
                handleChange={handleChangeSearch}
              />
            </div>
            <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/>
          </div>

          <div className='flex-right'>
            <ButtonIcon 
              handleClick={handleAddNewFilter} 
              buttonLabel={'Add New Filter'}
            />
          
          </div>
        </div>
      

        <BaseTable
          headers={tableHeaders} 
          dataTable={[]}
          detailPageRoute={'effect-detail'}
          allowSelection={false}
          isShowHeaderAction={false}
          showDeleteIcon={false}
          showEditIcon={true}
          type="template"
          isLoading={isLoading}
        />
            
        <BasePagination
          currentPage={paginationData.currentPage}
          itemsPerPage={paginationData.itemsPerPage}
          totalItems={paginationData.totalItems}
          totalPages={paginationData.totalPages}
          handleChangePage={handleChangePage}
        />
      </div>

    </>
  );}