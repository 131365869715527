import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import {useDispatch, useSelector} from 'react-redux';
import Card from '@/component/common/Card';
import {Controller, useForm} from 'react-hook-form';
import FormItem from '@/component/common/FormItem';
import InputTextField from '@/component/common/InputTextField';
import ErrorMessage from '@/component/common/ErrorMessage';
import {Radio} from 'antd';
import SelectSearchAndAddItem from '@/component/common/SelectSearchAndAddItem';
import SelectBox from '@/component/common/SelectBox';
import {HTTP_STATUS, STATUSES_LIST} from '@/constants';
import BaseButton from '@/component/common/Button';
import CardHeader from '@/component/common/CardHeader';
import ButtonUpload from '@/component/common/ButtonUpload';
import TrashIcon from '@/component/icon/TrashIcon';
import UploadComponent from '@/component/common/Upload';
import './CreateEditFilter.scss'
import {setShowLoadingIcon} from '@/redux/slice/common';
import {RootState} from '@/redux/rootReducer';
import services from '@/services';
import {cancelToken} from '@/http-common';

export default function CreateEditFilter(): JSX.Element {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDetailPage = !!id;
  const isCreatePage = !id;

  const showLoadingIcon = useSelector((state: RootState) => state.commonReducer.showLoadingIcon);

  const {control, handleSubmit, formState: {errors, isSubmitted}, setValue, getValues} : any = useForm({
    defaultValues: {
      effectName: '',
      category: [],
      status: 1,
      iosFile: '',
      androidFile: '',
      webFile: '',
      platform: 'each',
    }
  });

  const {no, effectName, category, status, platform, iosFile, webFile, androidFile, createAt, createdBy, thumbnailUrl} = getValues();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showAddItemForm, setShowAddItemForm] = useState<boolean>(false);
  const [inputAddItemValue, setInputAddItemValue] = useState<string>('');
  const onChangePlatformApply = (event: any) => {
    setValue('platform', event.target.value, {shouldValidate: true});
  }

  const handleShowAddItemForm = () => {
    setShowAddItemForm(true);
  }

  const handleChangeInputAddItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputAddItemValue(event.target.value);
  }

  const handleCancelAddItem = () => {
    setShowAddItemForm(false);
  }

  const handleSubmitAddItem = () => {
    console.log('inputValue', inputAddItemValue);
  }

  const getSelectedStatusValue = (selectedValue: string) => {
    const selectedItem = STATUSES_LIST.find((item: {value: string, label: string, color: string}) => item.value === selectedValue);
    return selectedItem || STATUSES_LIST[0]
  }

  const handleCancelAddNewEffect = () => {
    navigate(-1);
  }

  const showEditMode = () => {
    setIsEditMode(true);
  }

  const handleChangeFile = () => {
    console.log('file')
  }

  const handleUploadFile = (file: any, key: string) => {
    const uploadFile = file.file;
    const fileName = uploadFile.name;
    console.log('key', key, getValues(), file);

    setValue(key, { fileName, file: uploadFile}, { shouldValidate: true })
  }

  const handleRemoveFile = (key: string) => {
    setValue(key, '', { shouldValidate: true })
  }

  const handleChangeFileThumbnail = (file: any) => {
    console.log('file')
  }

  const handleUploadThumbnail = (file: any) => {
    const uploadFile = file.file;
    const fileName = uploadFile.name;
    const fileSize = uploadFile.size;
    const src = URL.createObjectURL(uploadFile);

    setValue('thumbnail', { file: uploadFile, src }, { shouldValidate: true })
  }

  const handleAddEditEffect = async (data: any) => {
    const payload = {
      name: data.effectName,
      // thumbnailUrl: 'http://localhost:3000/content/content-ai',
      // webUrl: 'http://localhost:3000/content/content-ai',
      // iosUrl: 'http://localhost:3000/content/content-ai',
      // androidUrl: 'http://localhost:3000/content/content-ai',
      // renderUrl: 'http://localhost:3000/content/content-ai',
      androidStatus: 0,
      iosStatus: 0,
      webStatus: 0,
      type: 'apng'
    }
    dispatch(setShowLoadingIcon(true));
    if (showLoadingIcon) {
      return;
    }
    try {
      let res;
      if (isCreatePage) {
        res = await services.createEffect(payload);
      } else if (id) {
        res = await services.updateEffect(id, payload);
      }

      console.log('res', res);
    } catch (err: any) {
      console.log(err);
    }
    dispatch(setShowLoadingIcon(false));
  }

  return (
    <div className='create-edit-filter'>
      <BackToListPageButton
        listPageUrl={'/content/effect-content'}
        title={'Effect Content '}
      />
      <form onSubmit={handleSubmit(handleAddEditEffect)}>
        <div className="content">
          {/*  Infor*/}
          <div className="block">
            <Card>
              {id && (
                <FormItem label={'No'} isRequired={isEditMode}>
                  {id && !isDetailPage
                    ? (<span>{no}</span>)
                    : (
                      <>
                        <Controller
                          control={control}
                          render={({field: {value, onChange}}) => (
                            <InputTextField
                              inputName={'no'}
                              inputType={'text'}
                              inputPlaceholder={'Enter no'}
                              handleChange={(event) => {
                                onChange(event.target.value)
                              }}
                              isInputInvalid={!!errors.no.message}
                            />
                          )}
                          name='no'
                        />
                        {errors?.no && <ErrorMessage errorMessage={errors.no.message} />}
                      </>
                    )
                  }
                </FormItem>
              )}

              <FormItem label={'Platform'}>
                {id && !isEditMode
                  ? (<span>{platform}</span>)
                  : (
                    <>
                      <Controller
                        control={control}
                        render={({field: {value}}) => (
                          <Radio.Group onChange={onChangePlatformApply} value={value}>
                            <Radio value={'each'}>Each Platform</Radio>
                            <Radio value={'all'}>All Platform</Radio>
                          </Radio.Group>
                        )}
                        name='platform'
                      />
                    </>
                  )
                }
              </FormItem>

              <FormItem
                label={'Effect Name'}
                isRequired={!!(isCreatePage || id && isEditMode)}
              >
                {id && !isEditMode
                  ? (<span>{effectName}</span>)
                  : (
                    <>
                      <Controller
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        render={({field: {onChange, value}}) => (
                          <InputTextField
                            defaultValue={value}
                            inputName={'name'}
                            inputType={'text'}
                            inputPlaceholder={'Enter effect name'}
                            handleChange={(event) => {
                              onChange(event.target.value)
                            }}
                          />
                        ) }
                        name={'effectName'}
                      />
                      {errors?.effectName && <ErrorMessage errorMessage={errors.effectName.message} />}

                    </>
                  )
                }
              </FormItem>

              <FormItem
                label={'Category'}
                isRequired={!!(isCreatePage || id && isEditMode)}
              >
                {id && !isEditMode
                  ? (<span>{category}</span>)
                  : (
                    <>
                      {/*<Controller*/}
                      {/*  control={control}*/}
                      {/*  // rules={{*/}
                      {/*  //   required: 'This field is required'*/}
                      {/*  // }}*/}
                      {/*  render={({field: {onChange, value}}) => (*/}
                      {/*    <SelectSearchAndAddItem*/}
                      {/*      selectPlaceholder={'Select category'}*/}
                      {/*      inputValue={inputAddItemValue}*/}
                      {/*      inputPlaceHolder={'Enter new category'}*/}
                      {/*      inputName={'category'}*/}
                      {/*      handleChangeInput={handleChangeInputAddItem}*/}
                      {/*      showAddItemForm={showAddItemForm}*/}
                      {/*      listItems={[]}*/}
                      {/*      handleChangeSearchSelect={onChange}*/}
                      {/*      cancelButtonLabel={'Cancel'}*/}
                      {/*      handleCancelAddItem={handleCancelAddItem}*/}
                      {/*      submitButtonLabel={'Add'}*/}
                      {/*      handleSubmitAddItem={handleSubmitAddItem}*/}
                      {/*      addNewItemLabel={'Add new category'}*/}
                      {/*      handleShowAddItemForm={handleShowAddItemForm}*/}
                      {/*      // isInputInvalid={!!errors.category.message}*/}
                      {/*    />*/}
                      {/*  )}*/}
                      {/*  name={'category'}*/}
                      {/*/>*/}
                      {/*{errors?.no && <ErrorMessage errorMessage={errors.category.message} />}*/}
                    </>
                  )
                }
              </FormItem>

              <FormItem label={'Status'}>
                {id && !isEditMode
                  ? (<span>{status}</span>)
                  : (
                    <>
                      <Controller
                        control={control}
                        render={({field: {onChange, value}}) => (
                          <SelectBox
                            listItems={STATUSES_LIST}
                            handleChange={onChange}
                            defaultValue={getSelectedStatusValue(value)}
                          />
                        )}
                        name={'status'}
                      />
                    </>
                  )
                }
              </FormItem>

              <div className="btn-container">
                {isCreatePage && (
                  <div className='cancel-btn'>
                    <BaseButton
                      buttonLabel={'Cancel'}
                      handleClick={handleCancelAddNewEffect}
                    />
                  </div>
                )}

                {(isCreatePage || isDetailPage && isEditMode)
                    && (
                      <div className='submit-btn'>
                        <BaseButton
                          buttonLabel={isCreatePage ? 'Upload' : 'Update'}
                          type={'primary'}
                          htmlType={'submit'}
                        />
                      </div>
                    )
                }

                {isDetailPage && !isEditMode
                    && (
                      <div className='submit-btn'>
                        <BaseButton
                          buttonLabel={'Edit'}
                          handleClick={showEditMode}
                          type={'primary'}
                        />
                      </div>
                    )
                }
              </div>
            </Card>
          </div>

          <div className='block block-column'>
            {/*   Source files */}
            <Card>
              <CardHeader label={'Source'}/>
              <div className="content-file">
                {getValues('platform') === 'each'
                  ? (
                    <div>
                      <FormItem label={'IOS File'}>
                        <div className="form-item">
                          <Controller
                            control={control}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('iosFile')?.fileName &&
                                  <span>{getValues('iosFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && <ButtonUpload
                                    handleChangeFile={handleChangeFile}
                                    handleUpload={(value: any) => handleUploadFile(value, 'iosFile')}
                                    showUploadList={false}
                                    showUploadButtonLabel={false}
                                  />
                                  }
                                  {getValues('iosFile')?.fileName && <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('iosFile')}/>}
                                </div>
                              </div>
                            )}
                            name={'iosFile'}
                          />
                        </div>
                      </FormItem>
                      <FormItem label={'Android File'}>
                        <div className="form-item">
                          <Controller
                            control={control}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('androidFile')?.fileName &&
                                  <span>{getValues('androidFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && <ButtonUpload
                                    handleChangeFile={handleChangeFile}
                                    handleUpload={(value: any) => handleUploadFile(value, 'androidFile')}
                                    showUploadList={false}
                                    showUploadButtonLabel={false}
                                  />
                                  }
                                  {getValues('androidFile')?.fileName && <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('androidFile')}/>}
                                </div>
                              </div>
                            )}
                            name={'androidFile'}
                          />
                        </div>
                      </FormItem>
                      <FormItem label={'Web File'}>
                        <div className="form-item">
                          <Controller
                            control={control}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('webFile')?.fileName &&
                                  <span>{getValues('webFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && <ButtonUpload
                                    handleChangeFile={handleChangeFile}
                                    handleUpload={(value: any) => handleUploadFile(value, 'webFile')}
                                    showUploadList={false}
                                    showUploadButtonLabel={false}
                                  />
                                  }
                                  {getValues('webFile')?.fileName && <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('webFile')}/>}
                                </div>
                              </div>
                            )}
                            name={'webFile'}
                          />
                        </div>
                      </FormItem>
                      {isSubmitted && !iosFile && !androidFile && !webFile
                        && <ErrorMessage errorMessage={'You must upload at least one file'} />
                      }
                    </div>
                  )
                  : (
                    <div>
                      <FormItem label={'Source File'}>
                        <div className="form-item">
                          <Controller
                            control={control}
                            rules={{
                              required: 'This field is required'
                            }}
                            render={() => (
                              <div className='file-platform'>
                                {getValues('sourceFile')?.fileName &&
                                  <span>{getValues('sourceFile').fileName}</span>
                                }
                                <div className='upload-file-icons'>
                                  {(isCreatePage || isDetailPage && isEditMode)
                                  && <ButtonUpload
                                    handleChangeFile={handleChangeFile}
                                    handleUpload={(value: any) => handleUploadFile(value, 'sourceFile')}
                                    showUploadList={false}
                                    showUploadButtonLabel={false}
                                  />
                                  }
                                  {getValues('sourceFile')?.fileName &&
                                    <TrashIcon style={{marginBottom: '4px', cursor: 'pointer'}} onClick={() => handleRemoveFile('sourceFile')}/>
                                  }
                                </div>
                              </div>
                            )}
                            name={'sourceFile'}
                          />
                        </div>
                      </FormItem>
                      {errors?.sourceFile
                        && <ErrorMessage errorMessage={errors?.sourceFile.message} />
                      }
                    </div>
                  )
                }
              </div>
            </Card>

            <Card>
              <div className='card-header'>
                <CardHeader label={'Thumbnail'} />
                {(isCreatePage || isDetailPage && isEditMode)
                         && (
                           <Controller
                             control={control}
                             rules={{
                               required: 'This field is required'
                             }}
                             render={() => (
                               <ButtonUpload
                                 label={'Upload'}
                                 handleChangeFile={handleChangeFileThumbnail}
                                 handleUpload={handleUploadThumbnail}
                                 showUploadList={false}
                               />
                             )}
                             name={'thumbnail'}
                           />
                         )
                }
              </div>
              {errors?.thumbnail && <ErrorMessage errorMessage={errors?.thumbnail?.message} />}
              <div className='card-content'>
                {getValues('thumbnail')?.src ? (
                  <img src={getValues('thumbnail')?.src} className='thumbnail'/>
                ) : (
                  <UploadComponent
                    handleChange={handleChangeFileThumbnail}
                    handleUpload={handleUploadThumbnail}
                  />
                )}
              </div>
            </Card>
          </div>


        </div>
      </form>
    </div>
  );
}