import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useNavigate, Routes, Route} from 'react-router-dom';

import ConfigComponent from '../config';
import TemplateComponent from '../templates';
import ContentComponent from '../content';
import UserComponent from '../user'

import Index from '@/layouts/PrivateLayout';
import {Layout} from 'antd';
import Sidebar from '@/layouts/sidebar';
import PopupRedirectToDefaultPage from '@/component/common/PopupRedirectToDefaultPage';
import services from '@/services';
import {HTTP_STATUS} from '@/constants';
import {setRouteAndNavigate, setUserInfo} from '@/redux/slice/common';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import HeaderPage from '@/layouts/headerPage';
import ContentPage from '@/layouts/contentPage';
import LoadingIcon from '@/component/common/LoadingIcon';

export default function PrivateRoutes(): JSX.Element {
  const [cookies] = useCookies(['access_token']);
  const navigate= useNavigate();
  const dispatch = useDispatch();

  const pathName = window.location.pathname;

  const userInfo = useSelector((state: RootState) => state.commonReducer.userInfo)

  const [isUserCanAccessRoute, setIsUserCanAccessRoute] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const handleGetUserRoleAndPermissions = async () => {
    try {
      const responseUser = await services.getUserRoleAndPermission();
      
      if (responseUser && responseUser.status === HTTP_STATUS.SUCCESS) {
        const userPermissions = [...responseUser.data.data.permissions, {action: 'read', resource: 'content', licensed: true}, {action: 'read', resource: 'user-management', licensed: true}];
        const userRole = responseUser.data.data.roleCode;
        
        dispatch(setUserInfo({
          ...userInfo,
          userPermissions,
          userRole
        }));
        
        const readAndCreatePermissions = userPermissions.filter((item: {action: string, resource: string, licensed: boolean}) => item.licensed);
        const resourcesArr = readAndCreatePermissions.map(((item: {action: string, resource: string, licensed: boolean}) => item.resource));
        const uniqueResources = [...resourcesArr];
        const createTemplatePermission = userPermissions.find((item: {action: string, resource: string, licensed: boolean}) => item.action === 'create' && item.resource === 'template')

        checkAccessPagePermission(uniqueResources, createTemplatePermission);
      }
    } catch (err: any) {
      console.log('error:', err);
      setIsLoaded(true);
    }
  };

  const checkUserLoginOrNot = () => {
    if (!cookies.access_token) {
      navigate('/login');
    }
  };

  useEffect(() => {
    checkUserLoginOrNot()
  }, [cookies.access_token]);
  
  const routes = [
    {
      path: '/config/*',
      resource: 'config',
      element: <ConfigComponent />
    },
    {
      path: '/template/*',
      resource: 'template',
      element: <TemplateComponent/>
    },
    {
      path: '/content/*',
      resource: 'content',
      element: <ContentComponent/>
    },
    {
      path: '/user-management/*',
      resource: 'user-management',
      element: <UserComponent/>
    }
  ];
  
  const checkAccessPagePermission = (uniqueResources: any, createTemplatePermission: any) => {
    const slashIndexes: number[] = [];

    for (let i = 0; i < pathName.length; i++) {
      if (pathName[i] == '/') {
        slashIndexes.push(i);
      }
    }

    const secondSlashIndex = slashIndexes[1];
    let thirdSlashIndex = 0;

    const defaultKeyPath = pathName.slice(1, secondSlashIndex);
    let defaultSubKeyPath = '';
    if (slashIndexes.length > 2) {
      thirdSlashIndex = slashIndexes[2];
      defaultSubKeyPath = pathName.slice(secondSlashIndex + 1, thirdSlashIndex);
    } else {
      defaultSubKeyPath = pathName.slice(secondSlashIndex + 1);
    }
    
    dispatch(setRouteAndNavigate({
      keyPath: defaultKeyPath,
      subKeyPath: defaultSubKeyPath
    }));


    if(pathName === '/config/admin-account' && uniqueResources.includes('user')
    || pathName === '/config/role-permissions' && uniqueResources.includes('role')
    ) {
      setIsUserCanAccessRoute(true);
    } 
    else if (uniqueResources.includes(defaultKeyPath) && pathName === '/template/upload-template' && createTemplatePermission.licensed
        || uniqueResources.includes(defaultKeyPath) && pathName !== '/template/upload-template'
    ) {
      setIsUserCanAccessRoute(true);
    }
    else setIsUserCanAccessRoute(false);
    setIsLoaded(true);
  };
  
  useEffect(() => {
    handleGetUserRoleAndPermissions();
  }, [pathName]);
  


  return (
    <>
      {isLoaded && (
        <>
          {isUserCanAccessRoute
            ? (
              <Routes >
                <Route element={<Index />} >
                  {routes.map(route => (
                    <Route key={route.path} path={route.path} element={route.element} />
                  ))}
                </Route>
              </Routes>
            )
            : (
              <Layout className='private-layout'>
                <div className='sidebar-wrapper'>
                  <Sidebar isUserAllowedToAccessPage={false}/>
                </div>
                <Layout>
                  <PopupRedirectToDefaultPage userRole={userInfo.userRole} />
                </Layout>
              </Layout>
            )
          }
        </>
      )}
    </>
  );
}
