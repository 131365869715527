import React from 'react';
import EditItemModalIcon from '@/assets/icon/ic_edit_item_modal.svg';
import {Modal} from 'antd';
import CloseModalIcon from '@/component/icon/CloseModalIcon';
import BaseButton from '@/component/common/Button';
import {IEditItemModal} from '@/component/common/interface/EditItemModal';

const HeaderModal = () => {
  return (<img src={EditItemModalIcon} alt="edit-icon"/>)
}

const EditItemModal: React.FC<IEditItemModal> = ({
  showModal,
  label,
  handleCancel,
  handleSubmitEdit,
  isLoading = false,
  isNoti =  false,
}) => {
  return (
    <>
      <Modal
        width={400}
        title={<HeaderModal/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='delete-item-modal-container'
        footer={isNoti ? [
          <div key='1' className='modal-footer-btn'>
            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={'Ok'}
                handleClick={handleCancel}
                type='primary'
              />
            </div>
          </div>
        ] : [
          <div key='1' className='modal-footer-btn'>
            <div className='cancel-btn'>
              <BaseButton
                buttonLabel={'No'}
                handleClick={handleCancel}
              />
            </div>
            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={'Yes'}
                handleClick={handleSubmitEdit}
                type='primary'
                className={isLoading ? 'disabled' : ''}
              />
            </div>
          </div>
        ]}
      >
        <div className='label-container'>
          <div className='default-label'>
            {label}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditItemModal;