import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  setIsUserClickClickableColumn,
  setSelectedId, setSelectedItem,
  setSelectedIds,
  setSelectedUpdateId,
  setShowDeleteModal, setIsUserClickResendButton,
} from '@/redux/slice/commonListPageSlice';
import './index.scss'

import {ITableReadOnlyRow} from '@/component/common/interface/TableReadOnlyRow';
import {ITableMultipleDataColumn} from '@/component/common/interface/TableDataCategories';

import DeleteTableRowIcon from '@/component/icon/DeleteTableRowIcon';
import EditTableRowIcon from '@/component/icon/EditTableRowIcon';
import {STATUSES_LIST, SUBSCRIPTION_LIST} from '@/constants';
import { RootState } from '@/redux/rootReducer';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {formatLongString} from '@/utils';
import BaseButton from '@/component/common/Button';

export const getColumnClassName = (isBlueText = false) => {
  if (isBlueText) {
    return 'blue-text'
  } else return 'normal-text'
}

export const generateStatusColumnData = (data = '') => {
  const listStatuses = STATUSES_LIST;

  const result = {
    data: '',
    dotColor: ''
  }
  const specificStatus =  listStatuses.find((item: {value: string, label: string, color: string}) => item.value == data);
  result.data = specificStatus?.label || listStatuses[0].label;
  result.dotColor = specificStatus?.color || listStatuses[0].color
  
  return result;
}

export const TableMultipleDataColumn: React.FC<ITableMultipleDataColumn> = ({
  itemsData,
  isFullTextTag = false
}) => {
  let columnData
  if (itemsData && itemsData.length > 2 && !isFullTextTag) {
    columnData = [
      itemsData[0].label ? itemsData[0].label : itemsData[0].name ,
      itemsData[1].label ? itemsData[1].label : itemsData[1].name,
      `+${itemsData.length - 2}`
    ];
  } else columnData = itemsData?.map((item: any) => item.label ? item.label : item.name) || [];
  
  return (
    <>
      {columnData.length > 0 && (
        columnData.map((item: string) => {
          return (
            <span key={item} className='item-category'>
              {item}
            </span>
          );
        })
      )}
    </>
  )
}
const TableReadOnlyRow: React.FC<ITableReadOnlyRow> = ({
  id,
  headers,
  item,
  detailPageRoute,
  subKeyPath,
  isEditRowTable,
  allowSelection ,
  isShowHeaderAction ,
  showResendButton,
  showDeleteIcon,
  showEditIcon,
  type,
  isTableInDetailPage
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const handleClickDeleteItem = () => {
    dispatch(setSelectedId(id));
    dispatch(setShowDeleteModal(true))
    dispatch(setSelectedItem(item))
  }
  
  const handleClickEditItem = () => {
    if (type === 'content-ai') {
      dispatch(setSelectedItem(item))
    } else {
      dispatch(setSelectedUpdateId(id))
    }
  }
  
  const navigateToDetailPage = (id: string, clickable= false) => {
    if (detailPageRoute && clickable) {
      if(isTableInDetailPage && subKeyPath) {
        navigate(`../${subKeyPath}/${detailPageRoute}/${id}`);
      }
      else {
        navigate(`${detailPageRoute}/${id}`);
      }
    }
  }
  
  const handleUserClickClickableColumn = (clickable: boolean) => {
    if (clickable) {
      dispatch(setIsUserClickClickableColumn(true));
      dispatch(setSelectedId(id));
    }
  }
  
  const generateColumnData = (headerColumnValue: any, itemData: any) => {
    
    let result: any = '';
    if (headerColumnValue.value === 'templateVipType') {
      result = SUBSCRIPTION_LIST.find(s => s.value == itemData[headerColumnValue.value])?.label
    } else if (headerColumnValue.isCutText) {
      result = formatLongString(itemData[headerColumnValue.value], headerColumnValue.maximumTextLength)
    } else if (headerColumnValue.isMultipleValueColumn) {
      return (<TableMultipleDataColumn itemsData={item[headerColumnValue.value]} isFullTextTag={headerColumnValue.isFullTextTag}/>)
    } else {
      result = itemData[headerColumnValue.value];
    }

    return result;
  }
    
  const onChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    if (checked) {
      dispatch(setSelectedIds([...selectedIds, id]))
    } else {
      const ids = selectedIds.filter((selectedId: string) => selectedId !== id)
      dispatch(setSelectedIds(ids))
    }
  };
  
  const handleResend = () => {
    dispatch(setSelectedId(id));
    dispatch(setIsUserClickResendButton(true));
  }
  
  return (
    <>
      {allowSelection && <td className='table-body-column' align='center'>
        <Checkbox onChange={onChange} checked={selectedIds.includes(id)}/>
      </td>}
      {headers.map((itemHeaderColumn, indexHeader: number) => {
        return (
          <td
            key={indexHeader} 
            className='table-body-column'
            style={{maxWidth: `${itemHeaderColumn.width}`, wordWrap: 'break-word'}}
          >
            {itemHeaderColumn.isImageColumn && (
              <img src={item[itemHeaderColumn.value]} alt='thumbnail' className='image'/>
            )}

            {itemHeaderColumn.isStatusColumn
              ? (
                <span className='table-status-column'>
                  <span 
                    className='dot'
                    style={{background: generateStatusColumnData(item[itemHeaderColumn.value]).dotColor }}
                  />
                  {generateStatusColumnData(item[itemHeaderColumn.value]).data}
                </span>
              )
              : (
                <>
                  {(!itemHeaderColumn.isImageColumn) && (
                    <span className={getColumnClassName(itemHeaderColumn.hasBlueText)}
                      onClick={() => navigateToDetailPage(item.id, itemHeaderColumn.clickable)}>
                      <span
                        className={itemHeaderColumn.clickable ? 'column-clickable' : ''}
                        onClick={() => handleUserClickClickableColumn(itemHeaderColumn.clickable)}
                      >
                        {generateColumnData(itemHeaderColumn, item)}
                      </span>
                    </span>
                    
                  )}
                </>
              )
            }
            
          </td>
        )
      })}
      {!isShowHeaderAction && <td className='table-body-column'>
        <span className="action-column" style={{ justifyContent: showResendButton ? 'flex-end' : 'flex-start'}}>
          {showResendButton && !item.isActive 
              && (
                <BaseButton 
                  handleClick={handleResend}
                  buttonLabel={'Resend'}  
                  type={'primary'}
                />
              ) 
          }
          {isEditRowTable && showEditIcon && <span
            className={`edit-icon ${selectedUpdateId && selectedUpdateId !== id ? 'disabled' : ''}`}
            onClick={() => handleClickEditItem()}
          >
            <EditTableRowIcon/>
          </span>}
          {showDeleteIcon && <span
            className={'delete-icon'}
            onClick={() => handleClickDeleteItem()}
          >
            <DeleteTableRowIcon/>
          </span> }
        </span>
      </td>}
    </>
  );
};

TableReadOnlyRow.defaultProps = {
  showResendButton: false,
  subKeyPath: '',
  allowSelection: false,
  isShowHeaderAction: false,
  showDeleteIcon: true,
  showEditIcon: true,
  isTableInDetailPage: false,
}

export default TableReadOnlyRow;
