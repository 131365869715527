/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import BaseButton from '@/component/common/Button';
import { MenuProps, Space, Dropdown, Button } from 'antd';
import services from '@/services';
import { ICategoryListSingleItem } from '@/pages/templates/interface/CategoryListPage';
import {HTTP_STATUS, SORT_ORDER, STATUSES_LIST} from '@/constants';
import {USER_ACTIONS, USER_RESOURCE} from '@/constants/roles';
import { convertDataFilterTags, formatDateTime, removeDataFilterTags } from '@/utils';
import { DownOutlined } from '@ant-design/icons';
import {cancelToken} from '@/http-common';
import BaseTable from '@/component/common/BaseTable';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {useSearchParams} from 'react-router-dom';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import useDebounce from '@/hooks/useDebounce';
import FilterItemList from '@/component/common/FilterItemList';
import {setIsUserClickSubmitEditIcon, setSelectedIds, setNoti, setSelectedId, setSelectedUpdateId, setShowDeleteModal} from '@/redux/slice/commonListPageSlice';
import EditItemModal from '@/component/common/EditItemModal';
import { IUpdateMultipleCategoryStatusPayload } from '@/services/interface/category.interface';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';
import AddEffectCategoryModal from '@/component/content/Effect/AddEffectCategoryModal';
import usePreviousValue from '@/hooks/usePreviousValue';

const items: MenuProps['items'] = [
  {
    label: 'Change to Active',
    key: 'active',
  },
  {
    label: 'Change to Inactive',
    key: 'imactive',
  },
];

export default function CategoryEffectContainer (): JSX.Element {
  const dispatch = useDispatch();

  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);

  const [categoryListPermissions, setCategoryListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowHeaderAction, setIsShowHeaderAction] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [listData, setListData] = useState<ICategoryListSingleItem[]>([]);
  const [showDeletesModal, setShowDeletesModal] = useState<boolean>(false);
  const [valueSelectedMenu, setValueSelectedMenu] = useState<string>();


  const [filterDatas, setFilterDatas] = useState({});
  const filterDatasJsonString = JSON.stringify(filterDatas);
  const [filterList, setFilterList] = useState<any>([]);
  const [queryData, setQueryData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const [showEditEffectCategoryModal, setShowEditEffectCategoryModal] = useState<any>({ open: false, label: ''});
  const [isNotiHasNotTemplate, setIsNotiHasNotTemplate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = [
    {
      label: 'NO',
      value: 'no',
      width: '120px',
      editable: true,
      required: true,
      editType: 'input',
    },
    {
      label: 'CATEGORY NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 150,
      required: true
    },
    {
      label: 'DESCRIPTION',
      value: 'description',
      width: '615px',
      editable: true,
      editType: 'textarea',
      isCutText: true,
      maximumTextLength: 500,
      required: false
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'COUNT',
      value: 'count',
      width: '150px'
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      editType: 'singleSelect',
      listItems: STATUSES_LIST,
      required: false

    },
  ]
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);

  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        })
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        });
        getListAllEffectCategories()
      }

    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  useEffect(() => {
    dispatch(setSelectedUpdateId(''))
    dispatch(setSelectedIds([]))
  }, [])

  const updateCategoryStatus = async (status: string) => {
    const hasTemplate = await checkCategoryHasTemplate()
    if (hasTemplate) {
      setShowEditEffectCategoryModal({ open: true, label: 'Are you sure you want to save this change?' });
    } else {
      setIsNotiHasNotTemplate(true)
      setShowEditEffectCategoryModal({ open: true, label: 'This category does not have any template yet. You cannot activate it.' });
    }
  }

  const checkCategoryHasTemplate = async () => {
    const categories = await Promise.all(selectedIds.map( async (id) => {
      const res: any = await services.getCategory(id)
      return res.data.data
    }))
    return categories.every((category: any) => category.templateCount)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleChangeStatus = async () => {
    const hasTemplate = await checkCategoryHasTemplate()
    if (!hasTemplate) {
      setShowEditEffectCategoryModal({ open: false, label: '' });
      return;
    }
    setIsLoadingSubmit(true)
    const payload: IUpdateMultipleCategoryStatusPayload = {
      categoryIds: selectedIds,
      status: valueSelectedMenu === 'active' ? 1 : 0,
    }
    try {
      const res = await services.updateMultipleCategory(payload);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setSelectedIds([]));
        getListAllEffectCategories()
        dispatch(setNoti({
          open: true,
          message: 'Update Status Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Update Status Fail!',
      }))
      setShowEditEffectCategoryModal({ open: false, label: '' });
    }
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setValueSelectedMenu(e.key);
    updateCategoryStatus(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }

  const handleSubmitDelete = async () => {
    setIsLoadingSubmit(true)
    try {
      const res =  await services.deleteEffectCategory(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        getListAllEffectCategories()
        dispatch(setNoti({
          open: true,
          message: 'Delete Category Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Delete Category Fail!',
      }))
    }
  }

  const getListAllEffectCategories = async () => {
    setIsLoading(true)
    dispatch(setSelectedId(''));
    dispatch(setSelectedUpdateId(''))
    setShowEditEffectCategoryModal({ open: false, label: '' });

    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit');
      const query = {
        search: searchValue.trim(),
        orderType: SORT_ORDER.DESC,
        orderBy: 'createdAt',
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas
      };
      const res = await services.getListEffectCategories(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        const meta = res.data.data.meta;
        const listItems = data.items.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          return {
            id: item.id,
            no: item.priority.toString(),
            createdAt,
            name: item.name,
            description: item.description,
            status: item.status,
            count: item.itemCount,
            createdBy: item?.createdBy?.userName || ''
          };
        });

        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.itemCount,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      console.log('err', err);
    }
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    dispatch(setSelectedIds([]))
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    setSearchParams(searchParams);
    setQueryData(query);
  }

  const handleSubmitEditItemRow = async () => {
    setIsLoadingSubmit(true)
    console.log('selectedItem', selectedItem)
    const uploadForm: any = {
      name: selectedItem.name,
      description: selectedItem.description,
      status: parseInt(selectedItem.status),
      priority: parseInt(selectedItem.no),
      props: selectedItem.properties
    }
    try {
      const res = await services.updateEffectCategory(selectedUpdateId, uploadForm);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getListAllEffectCategories();
        dispatch(setNoti({
          open: true,
          message: 'Updated Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        closeIcon: <></>,
        message: message || 'Updated Category Fail!',
        icon: <></>,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      // dispatch(setSelectedUpdateId(''));
    }
    setShowEditEffectCategoryModal({ open: false, label: '' });
  }

  const onshowEditEffectCategoryModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditEffectCategoryModal({ open: true, label: 'Are you sure you want to save this change?' });
    }
  }

  useEffect(() => {
    onshowEditEffectCategoryModal()
  }, [isUserClickSubmitEditIcon])

  useEffect(() => {
    const permissions =  getListPermissionsByResource(userPermissions, USER_RESOURCE.CATEGORY);
    setCategoryListPermissions(permissions);
    getListAllEffectCategories();
    return () => cancelToken()

  }, [queryData?.page, queryData?.limit, filterDatasJsonString]);

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }


  const handleCancelEdit = () => {
    setShowEditEffectCategoryModal({ open: false, label: ''});
    setIsNotiHasNotTemplate(false)
    dispatch(setIsUserClickSubmitEditIcon(false));
  }

  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }


  return (
    <div className="category-list-container">
      <div>
        <div className="btn-container">
          <div className="flex-left">
            <div className="flex-left-input">
              <InputSearchField
                value={searchValue}
                inputPlaceholder={'Search...'}
                inputName={'search'}
                handleChange={handleChangeSearch}
                // handleClearSearch={handleClickSearch}
              />
            </div>
          </div>

          <div
            className={!isShowHeaderAction ? 'flex-right' : 'flex-right-custom'}
          >
            
            {/*{!isShowHeaderAction && checkPermission(categoryListPermissions, USER_ACTIONS.CREATE) && (*/}
            {/*  <ButtonIcon*/}
            {/*    buttonLabel={'Add Category'}*/}
            {/*    handleClick={handleShowModal}*/}
            {/*  />*/}
            {/*)}*/}
              
            <ButtonIcon
              buttonLabel={'Add New Category'}
              handleClick={handleShowModal}
            />

          </div>
        </div>
        {filterList.length > 0 && <div className='div-filter'>
          {filterList.map((item: any) => {
            return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
          })}
        </div>}
        <BaseTable
          headers={tableHeaders}
          dataTable={listData}
          detailPageRoute={'effect-category-detail'}
          allowSelection={isShowHeaderAction}
          isShowHeaderAction={isShowHeaderAction}
          // showDeleteIcon={checkPermission(categoryListPermissions, USER_ACTIONS.DELETE)}
          showDeleteIcon={true}
          // showEditIcon={checkPermission(categoryListPermissions, USER_ACTIONS.EDIT)}
          showEditIcon={true}
          type='category'
          isLoading={isLoading}
        />

        <BasePagination
          currentPage={paginationData.currentPage}
          itemsPerPage={paginationData.itemsPerPage}
          totalItems={paginationData.totalItems}
          totalPages={paginationData.totalPages}
          handleChangePage={handleChangePage}
        />

        <DeleteItemModal
          showModal={showDeleteModal}
          label={'Are you sure you want to remove this'}
          handleCancel={handleCancelDelete}
          handleSubmitDelete={handleSubmitDelete}
          deletedValueItem={selectedItem?.name}
          isDeleteSpecial={true}
          deletedValueType={'category'}
          isLoading={isLoadingSubmit}
        />
      </div>
      {showModal && <AddEffectCategoryModal
        showModal={showModal}
        handleCancel={handleCloseModal}
        getListAllEffectCategories={getListAllEffectCategories}
      />}
      <EditItemModal
        showModal={showEditEffectCategoryModal?.open}
        label={showEditEffectCategoryModal?.label}
        isNoti={isNotiHasNotTemplate}
        handleCancel={handleCancelEdit}
        handleSubmitEdit={selectedIds?.length ? handleChangeStatus : handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />
    </div>
  );
}