/* eslint-disable no-unsafe-optional-chaining */
import React, {useState, useEffect, useMemo} from 'react';
import {ITemplateListContainer} from '@/component/templates/interface/TemplateListContainer';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import './TemplateListContainer.scss';
import services from '@/services';
import {
  HTTP_STATUS,
  SORT_ORDER,
  STATUSES_LIST,
  SUBSCRIPTION_LIST,
  TEMPLATE_ORDER_PRIORITY,
  TEMPLATE_PLATFORM
} from '@/constants';
import {USER_ACTIONS, USER_RESOURCE} from '@/constants/roles';
import {convertDataFilterTags, formatDateTime, removeDataFilterTags} from '@/utils';
import {cancelToken} from '@/http-common';
import BaseTable from '@/component/common/BaseTable';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import {setIsUserClickSubmitEditIcon, setNoti, setSelectedIds, setSelectedId, setSelectedUpdateId, setShowDeleteModal} from '@/redux/slice/commonListPageSlice';
import {IDetailTemplateSingleItem, IUpdateMultipleTemplateStatusPayload} from '@/pages/templates/interface/DetailTemplatePage';
import {useSearchParams} from 'react-router-dom';
import AdvancedFilter from '@/layouts/advancedFilter';
import FilterBar from '@/component/common/FilterBar';
import { IConfigFilter } from '@/component/common/interface/Filter';
import dayjs from 'dayjs';
import FilterItemList from '@/component/common/FilterItemList';
import useDebounce from '@/hooks/useDebounce';
import EditItemModal from '@/component/common/EditItemModal';
import BaseButton from '@/component/common/Button';
import { Dropdown, Button, Space, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';
import usePreviousValue from '@/hooks/usePreviousValue';

const items: MenuProps['items'] = [
  {
    label: 'Change to Active',
    key: 'active',
  },
  {
    label: 'Change to Inactive',
    key: 'imactive',
  },
];

const configFilters: IConfigFilter[] = [
  {
    label: 'Number of Order',
    key: 'priority',
    type: 'range',
  },
  {
    label: 'Status',
    key: 'statuses',
    type: 'checkbox',
    checkBoxOptions: STATUSES_LIST
  },
  {
    label: 'Create Date',
    key: 'createdAt',
    type: 'range-date',
  },
  {
    label: 'Category',
    key: 'categories',
    type: 'mutiple-select',
    itemService: services.getListCategory
  }
]

const TemplateListContainer: React.FC<ITemplateListContainer> = ({ categoryId }) => {
  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const listReorderItems = useSelector((state: RootState) => state.commonListPageReducer.listReorderItems);

  const [templateListPermissions, setTemplateListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [listData, setListData] = useState<IDetailTemplateSingleItem[]>([]);
  const [queryData, setQueryData] = useState<any>(null);
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [filterDatas, setFilterDatas] = useState({});
  const [filterList, setFilterList] = useState<any>([]);


  const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);
  const [editItemModalLabel, setEditItemModalLabel] = useState<string>('');
  const [isShowHeaderAction, setIsShowHeaderAction] = useState<boolean>(false);
  const [valueSelectedMenu, setValueSelectedMenu] = useState<string>();
  const [showDeletesModal, setShowDeletesModal] = useState<boolean>(false);
  const [isNotiHasNotTemplate, setIsNotiHasNotTemplate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const queryPriority = searchParams.get('priority');
  const filterDatasJsonString = JSON.stringify(filterDatas);

  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = useMemo(() => {
    return categoryId ? [
      {
        label: 'NO',
        value: 'no',
        width: '120px',
        editable: true,
        required: true,
        editType: 'input',
        maximumTextLength: 50
      },
      {
        label: 'TEMPLATE NAME',
        value: 'name',
        hasBlueText: true,
        clickable: true,
        width: '324px',
        editable: true,
        required: true,
        editType: 'input',
        maximumTextLength: 150
      },
      {
        label: 'DURATION',
        value: 'duration',
        width: '120px'
      },
      {
        label: 'RESOLUTION',
        value: 'resolution',
        width: '150px'
      },
      // {
      //   label: 'CATEGORY',
      //   value: 'category',
      //   isCategoryColumn: true,
      //   width: '250px',
      //   editable: true,
      //   editType: 'multipleSelect'
      // },
      {
        label: 'SUBSCRIPTION',
        value: 'templateVipType',
        width: '150px',
        editType: 'singleSelect',
        editable: true,
        required: true,
        listItems: SUBSCRIPTION_LIST
      },
      {
        label: 'CREATED BY',
        value: 'createdBy',
        width: '150px'
      },
      {
        label: 'CREATED AT',
        value: 'createdAt',
        width: '220px'
      },
      {
        label: 'STATUS',
        value: 'status',
        width: '120px',
        isStatusColumn: true,
        editable: true,
        required: true,
        editType: 'singleSelect',
        listItems: STATUSES_LIST
      },
    ] : [
      {
        label: 'NO',
        value: 'no',
        width: '120px',
        editable: true,
        required: true,
        editType: 'input',
        maximumTextLength: 50
      },
      {
        label: 'TEMPLATE NAME',
        value: 'name',
        hasBlueText: true,
        clickable: true,
        width: '324px',
        editable: true,
        required: true,
        editType: 'input',
        maximumTextLength: 150
      },
      {
        label: 'DURATION',
        value: 'duration',
        width: '120px'
      },
      {
        label: 'RESOLUTION',
        value: 'resolution',
        width: '150px'
      },
      {
        label: 'CATEGORY',
        value: 'category',
        isMultipleValueColumn: true,
        multipleValueKey: 'category',
        width: '250px',
        editable: true,
        required: false,
        editType: 'multipleSelect',
        itemService: services.getListCategory
      },
      {
        label: 'SUBSCRIPTION',
        value: 'templateVipType',
        width: '150px',
        editType: 'singleSelect',
        editable: true,
        required: true,
        listItems: SUBSCRIPTION_LIST
      },
      {
        label: 'CREATED BY',
        value: 'createdBy',
        width: '150px'
      },
      {
        label: 'CREATED AT',
        value: 'createdAt',
        width: '220px'
      },
      {
        label: 'STATUS',
        value: 'status',
        width: '120px',
        isStatusColumn: true,
        editable: true,
        required: true,
        editType: 'singleSelect',
        listItems: STATUSES_LIST
      },
    ]
  }, [categoryId]) 


  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setValueSelectedMenu(e.key);
    updateTemplateStatus()
  };

  const updateTemplateStatus = async () => {
    setShowEditItemModal(true);
    setEditItemModalLabel('Are you sure you want to save this change?')
  }

  const handleSelect = () => {
    setIsShowHeaderAction(!isShowHeaderAction);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleChangeStatus = async () => {
    const payload: IUpdateMultipleTemplateStatusPayload = {
      templateIds: selectedIds,
      [`${platformQuery()}Status`]: valueSelectedMenu === 'active' ? 1 : 0, 
    } 
    if (categoryId) {
      payload.categoryId = categoryId
    }
    try {
      const res = await services.updateMultipleTemplate(payload);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        setShowEditItemModal(false);
        dispatch(setSelectedIds([]));
        getTemplatesByOrder()
        dispatch(setNoti({
          open: true,
          message: 'Update Status Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
    } catch (err: any) {
      const { message } = err?.response?.data;
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Update Status Fail!',
      }))
    }
  }

  const removeTemplates = async () => {
    setIsLoadingSubmit(true)
    const body: { templateIds: string[] } = {
      templateIds: selectedIds
    }
    try {
      const res =  await services.deleteMultipleTemplate(body);
      if (res && res.status === HTTP_STATUS.DELETED) {
        setShowDeletesModal(false)
        dispatch(setSelectedIds([]));
        dispatch(setSelectedUpdateId(''));
        getTemplatesByOrder()
        dispatch(setNoti({
          open: true,
          message: 'Delete Templates Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Delete Templates Fail!',
      }))
    }
  }

  const dispatch = useDispatch();
    
  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        });
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        })
        getTemplatesByOrder()
      }

    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  }
  
  const handleFilter = () => {
    setOpenDrawer(true);
  };
  
  const platformQuery = () => {
    let result = TEMPLATE_PLATFORM.IOS;
    switch (queryPriority) {
    case TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY:
      result = TEMPLATE_PLATFORM.IOS
      break;
    case TEMPLATE_ORDER_PRIORITY.ANDROID_PRIORITY:
      result = TEMPLATE_PLATFORM.ANDROID
      break;
    case TEMPLATE_ORDER_PRIORITY.WEB_PRIORITY:
      result = TEMPLATE_PLATFORM.WEB
      break;
    }
    return result;
  }
  
  const getTemplatesByOrder = async () => {
    setIsLoading(true)
    console.log('filterDatas', filterDatas);
    dispatch(setSelectedId(''));
    dispatch(setSelectedUpdateId(''))
    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit')
      const query: any = {
        search: searchValue.trim(),
        orderBy: queryPriority || TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY,
        platform: platformQuery(),
        orderType: SORT_ORDER.ASC,
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas
      };
      const res =  categoryId ? await services.getListTemplatesByCategory(categoryId, query) : await services.getListTemplates(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;
        const meta = res.data.data.meta;
        const listItems = data.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          return {
            id: item.id,
            no: item[queryPriority || TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY].toString(),
            name: item.name,
            duration: `${item.duration}s`,
            resolution: item.resolution || '',
            category: item.categories,
            createdBy: item?.createdBy?.userName || '',
            createdAt,
            status: item[`${platformQuery()}Status`].toString(),
            thumbnailUrl: item.thumbnailUrl,
            videoPreviewUrl: item.videoPreviewUrl,
            webPriority: item.webPriority.toString(),
            templateVipType: item.templateVipType,
            iosPriority: item.iosPriority.toString(),
            androidPriority: item.androidPriority.toString(),
          }
        });
        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
          totalCount: meta.itemCount,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      console.log('error', err);
      setIsLoading(false)
    }
  }
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderBy: queryPriority || TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY,
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    dispatch(setSelectedIds([]))
    dispatch(setSelectedUpdateId(''));
    setSearchParams(searchParams);
    setQueryData(query);
  }
  
  useEffect(() => {
    const permissions =  getListPermissionsByResource(userPermissions, USER_RESOURCE.TEMPLATE);
    setTemplateListPermissions(permissions);
    getTemplatesByOrder();
    return () => cancelToken()
  }, [queryData?.page, queryData?.limit, filterDatasJsonString, categoryId]);

  useEffect(() => {
    dispatch(setSelectedUpdateId(''))
    if (!searchValue) {
      getTemplatesByOrder();
      return;
    }
    // setFilterDatas({})
    setSearchValue('')
  }, [queryPriority]);
  
  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }
  
  const handleSubmitDelete =async () => {
    setIsLoadingSubmit(true)
    try {
      const res = await services.deleteSingleTemplate(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        dispatch(setSelectedUpdateId(''));
        getTemplatesByOrder();
        dispatch(setNoti({
          open: true,
          message: 'Delete Template Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        type: 'error',
        open: true,
        message: message || 'Delete Fail!',
      }))
      dispatch(setShowDeleteModal(false));
      dispatch(setSelectedId(''));
    }
  }
  
  const handleSubmitEditItemRow = async () => {
    if (selectedItem?.no && +selectedItem?.no > paginationData.totalItems) {
      setShowEditItemModal(false);
      dispatch(setNoti({
        open: true,
        type: 'warning',
        message: `No is only from 1 to ${paginationData.totalItems}`
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      return;
    }
    setIsLoadingSubmit(true)
    const uploadPayload: any = {
      name: selectedItem.name,
      [`${platformQuery()}Status`]: parseInt(selectedItem[`${platformQuery()}Status`]),
      templateVipType: parseInt(selectedItem.templateVipType),
      webPriority: parseInt(selectedItem.webPriority),
      iosPriority: parseInt(selectedItem.iosPriority),
      androidPriority: parseInt(selectedItem.androidPriority),
      thumbnailUrl: selectedItem.thumbnailUrl,
      videoPreviewUrl: selectedItem.videoPreviewUrl,
      categories: selectedItem.category,
    }
    if (categoryId) delete uploadPayload.categories
    uploadPayload[queryPriority || TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY] = selectedItem.no ? parseInt(selectedItem.no, 10) : ''

    try {
      const res = categoryId ? await services.updateDetailCategoryTemplate(selectedUpdateId, categoryId, uploadPayload) :  await services.updateDetailTemplate(selectedUpdateId, uploadPayload);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getTemplatesByOrder();
        dispatch(setNoti({
          open: true,
          message: 'Updated Template Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err.response.data
      dispatch(setNoti({
        open: true,
        type: 'error',
        message,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      setIsLoadingSubmit(false)
    }
    setShowEditItemModal(false);
  }
  
  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemModal(true);
      setEditItemModalLabel('Are you sure you want to save this change?')
    }
  }
  
  useEffect(() => {
    dispatch(setSelectedUpdateId(''))
    dispatch(setSelectedIds([]))
  }, [])

  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon]);


  //filter
  const submitFilter = async (values: any) => {
    const filters = Object.assign(values, {})
    if (filters.createdAtFrom) {
      filters.createdAtFrom = dayjs(filters.createdAtFrom).toISOString()
    }
    if (filters.createdAtTo) {
      filters.createdAtTo = dayjs(filters.createdAtTo).toISOString()
    }
    setFilterDatas({
      ...filters,
    })
    const filterListMap = convertDataFilterTags(configFilters, filters)
    const hasCaterofyFilter = configFilters.filter((c: IConfigFilter) => c.key === 'category')
    if (hasCaterofyFilter) {
      await Promise.all(filterListMap.map(async (item: any) => {
        if (item.key === 'categories') {
          const categoryMap = await Promise.all(item.value.map(async (it: any) => {
            const res = await services.getCategory(it)
            if (res && res.data.statusCode === HTTP_STATUS.SUCCESS) {
              it = res.data.data.name
            }
            return it
          }))
          item.value = categoryMap.join(', ')
        }
        return item;
      }))
    }
    console.log('filterListMap', filterListMap);
    
    setFilterList(filterListMap)
    setOpenDrawer(false)
  }

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }
  
  const handleCancelEdit = () => {
    setShowEditItemModal(false);
    setEditItemModalLabel('');
    dispatch(setIsUserClickSubmitEditIcon(false));
  }
  
  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }

  const handleSwapTemplate = async () => {
    try {
      const payload = {
        templateIds: listReorderItems,
        platformPriority: queryPriority? queryPriority : TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY
      }
      const res = await services.swapTemplates(payload);
    } catch (err: any) {
      console.log('error', err);
    }
  }


  return (
    <div className='template-list-container'>
      <div className='btn-container'>
        <div className='flex-left'>
          <div className='flex-left-input'>
            <InputSearchField
              value={searchValue}
              inputPlaceholder={'Search...'}
              inputName={'search'} 
              handleChange={handleChangeSearch} 
              // handleClearSearch={handleClickSearch}
            />
          </div>
          <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/>
        </div>

        <div
          className={!isShowHeaderAction ? 'flex-right' : 'flex-right-custom'}
        >
          {!isShowHeaderAction 
              && !!(checkPermission(templateListPermissions, USER_ACTIONS.EDIT) || checkPermission(templateListPermissions, USER_ACTIONS.DELETE))
              && (
                <div className="select-btn">
                  <BaseButton buttonLabel={'Select'} handleClick={handleSelect} className={selectedUpdateId ? 'disabled' : ''}/>
                </div>
              )}

          {isShowHeaderAction && (
            <Dropdown
              menu={menuProps}
              dropdownRender={(item: React.ReactNode) => (
                <div className="custom-dropdown">{item}</div>
              )}
            >
              <Button
                size="large"
                style={{
                  backgroundColor: '#f7f7f7',
                  marginRight: 16,
                  width: 'max-content'
                }}
                className={!selectedIds.length ? 'disabled' : ''}
              >
                <Space
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: '22px',
                  }}
                >
                  Change Status
                  <DownOutlined
                    style={{
                      fontSize: 14,
                    }}
                  />
                </Space>
              </Button>
            </Dropdown>
          )}
          {isShowHeaderAction && checkPermission(templateListPermissions, USER_ACTIONS.DELETE) && (
            <BaseButton
              handleClick={() => setShowDeletesModal(true)}
              buttonLabel={'Delete'}
              type="secondary"
              className={!selectedIds.length ? 'disabled' : ''}
            />
          )}
          {isShowHeaderAction && (
            <Button
              style={{
                border: '1px solid #F37F13',
                color: '#F37F13',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
                height: 40,
                lineHeight: '22px',
                width: '30%',
              }}
              onClick={() => {
                setIsShowHeaderAction(false);
              }}
            >
              Cancel
            </Button>
          )}

          {/*{isShowHeaderAction && (*/}
          {/*  <BaseButton buttonLabel={'Update'} handleClick={handleSwapTemplate} type={'primary'} />*/}
          {/*)}*/}
        </div>
      </div>
      {filterList.length > 0 && <div className='div-filter'>
        {filterList.map((item: any) => {
          return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
        })}
      </div>}

      <BaseTable
        headers={tableHeaders} 
        dataTable={listData}
        detailPageRoute={'template-detail'}
        subKeyPath={'template-list'}
        allowSelection={isShowHeaderAction}
        isShowHeaderAction={isShowHeaderAction}
        isDraggableTable={true}
        showDeleteIcon={checkPermission(templateListPermissions, USER_ACTIONS.DELETE)}
        showEditIcon={checkPermission(templateListPermissions, USER_ACTIONS.EDIT)}
        type="template"
        isLoading={isLoading}
        isTableInDetailPage={!!categoryId}
      />

      <BasePagination
        currentPage={paginationData.currentPage}
        itemsPerPage={paginationData.itemsPerPage}
        totalItems={paginationData.totalCount}
        totalPages={paginationData.totalPages}
        handleChangePage={handleChangePage}
      />
        
      <DeleteItemModal 
        showModal={showDeleteModal} 
        label={'Are you sure you want to delete'}
        handleCancel={handleCancelDelete}
        handleSubmitDelete={handleSubmitDelete}
        deletedValueItem={selectedItem?.name}
        isDeleteSpecial={true}
        deletedValueType={'template'}
        isLoading={isLoadingSubmit}
      />
      <DeleteItemModal
        showModal={showDeletesModal}
        label={'Do you want to delete these items?'}
        handleCancel={() => setShowDeletesModal(false)}
        handleSubmitDelete={removeTemplates}
        deletedValueItem={''}
        isDeleteSpecial={false}
        deletedValueType={''}
        isLoading={isLoadingSubmit}
      />
      <AdvancedFilter
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        content={<FilterBar setOpenDrawer={setOpenDrawer} configFilters={configFilters} submitFilter={submitFilter} filterDatas={filterDatas} />}
      />
        
      <EditItemModal 
        showModal={showEditItemModal} 
        label={editItemModalLabel}
        isNoti={isNotiHasNotTemplate}
        handleCancel={handleCancelEdit} 
        handleSubmitEdit={selectedIds?.length ? handleChangeStatus : handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />
    </div>
  );
};

export default TemplateListContainer;
