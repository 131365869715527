import React from 'react';
import { Input } from 'antd';
import {IBaseTextArea} from '@/component/common/interface/BaseTextArea';

const { TextArea } = Input;

const BaseTextArea: React.FC<IBaseTextArea> = ({
  showCount,
  rows, 
  placeholder, 
  minLength,
  maxLength, 
  value, 
  handleChange,
  name}) => {
  return (
    <>
      <TextArea
        showCount={showCount}
        rows={rows}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        value={value}
        onChange={handleChange}
        name={name}
      />
    </>
  );
};

BaseTextArea.defaultProps = {
  showCount: true,
  rows: 4,
  minLength: 1,
  maxLength: 500
}

export default BaseTextArea;