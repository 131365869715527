import { ICommonState } from '../interface/common';
import { getCookie } from 'react-use-cookie';
import {getDefaultKeyPathFromRole} from '@/utils/auth';
export const getInitialKeyPath = (currentPathName = '') => {
  const pathName = currentPathName || window.location.pathname;
  const userRole = getCookie('user_role');

  const slashIndexes: number[] = [];

  for (let i = 0; i < pathName.length; i++) {
    if (pathName[i] == '/') {
      slashIndexes.push(i);
    }
  }

  const secondSlashIndex = slashIndexes[1];
  let thirdSlashIndex = 0;
  let defaultSubKeyPath = '';
  let defaultKeyPath = pathName.slice(1, secondSlashIndex);
  if (defaultKeyPath === 'login' || !defaultKeyPath) {
    defaultKeyPath = getDefaultKeyPathFromRole(userRole) || 'config';
  }

  if (slashIndexes.length > 2) {
    thirdSlashIndex = slashIndexes[2];
    defaultSubKeyPath = pathName.slice(secondSlashIndex + 1, thirdSlashIndex);
  } else {
    defaultSubKeyPath = pathName.slice(secondSlashIndex + 1);
  }

  return {
    defaultKeyPath,
    defaultSubKeyPath,
  };
};

const initialState: ICommonState = {
  keyPath: getInitialKeyPath().defaultKeyPath,
  subKeyPath: getInitialKeyPath().defaultSubKeyPath,
  userInfo: {
    id: '',
    email: '',
    fullName: '',
    userName: '',
    userPermissions: [],
    userRole: ''
  },
  isUserAccessAnEditablePage: false,
  showLoadingIcon: false,
  headerPage: ''
};
export default initialState;
