import React, {useEffect, useState} from 'react';
import ButtonUpload from '@/component/common/ButtonUpload';
import ErrorMessage from '@/component/common/ErrorMessage';
import {IDetailTemplatePreview} from '@/pages/templates/interface/DetailTemplatePage';
import {FILE_TYPE} from '@/constants';
import ReactPlayer from 'react-player'
import {setUploadTemplatePreviewFile} from '@/redux/slice/template';
import {useDispatch} from 'react-redux';

const DetailTemplatePreview: React.FC<IDetailTemplatePreview> = ({
  isEditMode, 
  originalPreviewUrl,
}) => {
  const dispatch = useDispatch();
  const [isUploadingPreview, setIsUploadingPreview] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [uploadPreviewErrorMessage, setUploadPreviewErrorMessage] = useState<string>('');

  const setDefaultPreviewUrl = () => {
    if (isEditMode) {
      setPreviewUrl(originalPreviewUrl)
    } else {
      setIsUploadingPreview(false);
      setPreviewUrl('');
      setUploadPreviewErrorMessage('');
    }
  }
  
  useEffect(() => {
    setDefaultPreviewUrl();
  }, [isEditMode])


  const handleChangeFilePreview = (file: any) => {
    setUploadPreviewErrorMessage('');
    setIsUploadingPreview(true);
  }
  
  const handleUploadPreview = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name;
    const fileSize = uploadFile.size;
    const maxSizeOfFile = 10000000;
    
    if (!fileName?.toLowerCase().endsWith(FILE_TYPE.MP4)) {
      setPreviewUrl('');
      setUploadPreviewErrorMessage(`The ${fileName} is wrong format preview file. Please try again`)
      dispatch(setUploadTemplatePreviewFile(null));
    } else if (fileSize > maxSizeOfFile) {
      setPreviewUrl('');
      setUploadPreviewErrorMessage(`The ${fileName} is over maximum file size 10MB. Please try again`)
      dispatch(setUploadTemplatePreviewFile(null));
    } else {
      const src = URL.createObjectURL(uploadFile);
      setPreviewUrl(src);
      dispatch(setUploadTemplatePreviewFile(uploadFile))
    }
    setIsUploadingPreview(false);
  }

  
  return (
    <div className='detail-template-video'>
      <div className='detail-template-video-header'>
        <div className='header'>Preview Video</div>
        {isEditMode && (
          <div className='upload-btn'>
            <ButtonUpload
              label={'Upload'}
              handleChangeFile={handleChangeFilePreview}
              handleUpload={handleUploadPreview}
              showUploadList={isUploadingPreview}
            />
          </div>
        )}
      </div>

      <div>
        {isEditMode
          ? (
            <>
              {!isUploadingPreview && (
                <>
                  {previewUrl
                    ? (
                      <div>
                        <ReactPlayer url={previewUrl} controls={true} width={'100%'} height={'90%'}/>
                      </div>
                    )
                    : (
                      <>
                        <ErrorMessage errorMessage={uploadPreviewErrorMessage}/>
                      </>
                    )
                  }
                </>
              )}
            </>
          )
          : (
            <>
              {!isUploadingPreview && originalPreviewUrl && (
                <div>
                  <ReactPlayer url={originalPreviewUrl} controls={true} width={'100%'} height={'90%'}/>
                </div>
              )}</>
          )
        }


      </div>

    </div>

  );
};

export default DetailTemplatePreview;