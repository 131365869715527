import React from 'react';
import {IDetailTemplateReadOnlyMode} from '@/pages/templates/interface/DetailTemplatePage';
import './DetailTemplateReadOnlyMode.scss';
import {STATUSES_LIST, SUBSCRIPTION_LIST} from '@/constants';

const DetailTemplateReadOnlyMode: React.FC<IDetailTemplateReadOnlyMode> = ({
  item
}) => {
  const convertStatus = (status: any) => {
    const specificStatus = STATUSES_LIST.find((item: {value: string, label: string}) => item.value == status);
      
    return specificStatus?.label || STATUSES_LIST[0].label;
  };
  
  const convertSubscription = (templateVipType: number | undefined) => {
    const specificSubcription = SUBSCRIPTION_LIST.find((item: {value: number, label: string}) => item.value == templateVipType);
      
    return specificSubcription?.label || SUBSCRIPTION_LIST[0].label;
  };
    
  return (
    <div>
      <div className='detail-template-info-read-only'>
        <div className='detail-item-container'>
          <div className='left-col'>Name:</div>
          <div className='right-col'>{item.name}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Web Status:</div>
          <div className='right-col'>{convertStatus(item.webStatus)}</div>
        </div>

        <div className='detail-item-container'>
          <div className='left-col'>IOS Status:</div>
          <div className='right-col'>{convertStatus(item.iosStatus)}</div>
        </div>

        <div className='detail-item-container'>
          <div className='left-col'>Android Status:</div>
          <div className='right-col'>{convertStatus(item.androidStatus)}</div>
        </div>

        <div className='detail-item-container'>
          <div className='left-col'>Subscription:</div>
          <div className='right-col'>{convertSubscription(item.templateVipType)}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Resolution:</div>
          <div className='right-col'>{item.resolution}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Duration:</div>
          <div className='right-col'>{item.duration}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Category:</div>
          <div className='category-container'>
            {item.categories.map((item: {id: string, name: string}) => {
              return (
                <div key={item.id} className='category-item'>{item.name}</div>
              );
            })}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Created by:</div>
          <div className='right-col'>{item.createdBy?.userName || ''}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Created date:</div>
          <div className='right-col'>{item.createdDate}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Web priority</div>
          <div className='right-col'>{item.webPriority}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>IOS priority</div>
          <div className='right-col'>{item.iosPriority}</div>
        </div>
          
        <div className='detail-item-container'>
          <div className='left-col'>Android priority</div>
          <div className='right-col'>{item.androidPriority}</div>
        </div>
      </div>
    </div>
  );
};

export default DetailTemplateReadOnlyMode;