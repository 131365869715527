import React from 'react';
import {TABLE_PERMISSIONS, TABLE_ROLES_HEADER} from '@/constants/roles';
import {IRoleAndPermissionTable} from '@/component/config/interface/RoleAndPermissionTable';
import CheckTableIcon from '@/component/icon/CheckTableIcon';
import './RoleAndPermissionTable.scss';

const headers = TABLE_ROLES_HEADER;
const permissions = TABLE_PERMISSIONS;

const RoleAndPermissionTable: React.FC<IRoleAndPermissionTable> = ({role}) => {
  const specificPermissionsBasedFromRole = permissions.find((item: any) => item.role === role);
  const specificPermissions = specificPermissionsBasedFromRole?.permissions;

  return (
    <div className='permission-table-container'>
      <table className='table'>
        {/*    headers*/}
        <thead>
          <tr className='table-header-row'>
            <th className='header-row-column'/>
            {headers.map((header: {label: string, value: string}) => {
              return (
                <th key={header.value} className='header-row-column'>
                  {header.label}
                </th>
              );
            })}
          </tr>
        </thead>
        {/*    body*/}
        <tbody className='table-body'>
          {specificPermissions?.map((item: any, index: number) => {
            return (
              <tr key={index} className='table-body-row'>
                <td className='table-body-column'>
                  {item.feature}
                </td>

                {headers.map((headerItem: {label: string, value: string}) => {
                  return (
                    <td key={headerItem.value} className='table-body-column'>
                      {item[headerItem.value] && (<CheckTableIcon />)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RoleAndPermissionTable;