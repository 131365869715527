import React, {useEffect, useState} from 'react';
import ButtonUpload from '@/component/common/ButtonUpload';
import {FILE_TYPE} from '@/constants';
import ErrorMessage from '@/component/common/ErrorMessage';
import {IUploadTemplateThumbnail} from '@/component/templates/interface/UploadTemplateThumbnail';
import {useDispatch} from 'react-redux';
import {setUploadTemplateThumbnailFile} from '@/redux/slice/template';

const UploadTemplateThumbnail: React.FC<IUploadTemplateThumbnail> = ({
  isUserClickCancelButton,
  userClickSubmitButtonTimes,
}) => {
  const dispatch = useDispatch();
    
  const [isUploadingThumbnail, setIsUploadingThumbnail] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [uploadThumbnailErrorMessage, setUploadThumbnailErrorMessage] = useState<string>('');

  const handleChangeFileThumbnail = (file: any) => {
    setUploadThumbnailErrorMessage('');
    setIsUploadingThumbnail(true);
  }
  
  const handleUploadThumbnail = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name as string;
    const fileSize = uploadFile.size;
    
    if (!fileName.toLowerCase().endsWith(FILE_TYPE.JPG)) {
      setThumbnailUrl('');     
      setUploadThumbnailErrorMessage(`The ${fileName} is wrong format thumbnail file. Please try again`)
    } else if (fileSize > 800000) {
      setThumbnailUrl('');
      setUploadThumbnailErrorMessage(`The ${fileName} is over maximum file size 800KB. Please try again`)
    } else {
      const src = URL.createObjectURL(uploadFile);
      setThumbnailUrl(src);
      dispatch(setUploadTemplateThumbnailFile(uploadFile))

    }
    setIsUploadingThumbnail(false);
  }
  
  const handleClearFile = () => {
    setIsUploadingThumbnail(false);
    setThumbnailUrl('');
    setUploadThumbnailErrorMessage('');
    dispatch(setUploadTemplateThumbnailFile(null))
  }
  
  useEffect(() => {
    handleClearFile()
  }, [isUserClickCancelButton]);
  
  const handleSubmit = () => {
    if (!thumbnailUrl) {
      setUploadThumbnailErrorMessage('This field is required');
    }
  }
    
  useEffect(() => {
    if (userClickSubmitButtonTimes > 0) {
      handleSubmit()
    }
  }, [userClickSubmitButtonTimes])
  
  return (
    <div className='upload-template-thumbnail'>
      <div className='upload-template-thumbnail-header'>
        <div className='header'>
          Thumbnail
        </div>
        <ButtonUpload 
          label={'Upload'}
          handleChangeFile={handleChangeFileThumbnail}
          handleUpload={handleUploadThumbnail}
          showUploadList={isUploadingThumbnail}
        />
      </div>

      <ErrorMessage errorMessage={uploadThumbnailErrorMessage}/>
      {thumbnailUrl && (
        <div className='thumbnail'>
          <img src={`${thumbnailUrl}`} alt="thumbnail" className='image'/>
        </div>
      )}
    </div>
  );
};

export default UploadTemplateThumbnail;