import authRequest from './service';

const getListTransitions = async (query: any) => {
  const response = await authRequest.get('transitions', {params : query});
  return response;
}

const removeTransition = async (id: string) => {
  const response = await authRequest.delete(`transitions/${id}`);
  return response;
}

const getMasterUUID = async () => {
  const response = await authRequest.get('master/uuid');
  return response;
}

const getThumbnailUpload = async (payload: any) => {
  const response = await authRequest.get(`transitions/${payload.id}/upload-url/thumbnail?ext=${payload.ext}`);
  return response;
}

const getSourceFileUpload = async (payload: any) => {
  const response = await authRequest.get(`transitions/${payload.id}/upload-url/source/${payload.platform}?ext=${payload.ext}`);
  return response;
}

const createTransition = async (payload: any) => {
  const response = await authRequest.post('transitions', payload);
  return response;
}

const detailTransition = async (id: any) => {
  const response = await authRequest.get(`transitions/${id}`);
  return response;
}

const updateTransition = async (payload: any) => {
  const response = await authRequest.put(`transitions/${payload.id}`, payload);
  return response;
}

const getListTransitionCategories = async (query: any) => {
  const response = await authRequest.get('transition-categories', {params : query});
  return response;
}

const getListTransitionByCategories = async (id: string, query: any) => {
  const response = await authRequest.get(`transition-categories/${id}/items`, {params : query});
  return response;
}

const removeTransitionCategory = async (id: string) => {
  const response = await authRequest.delete(`transition-categories/${id}`);
  return response;
}

const createTransitionCategory = async (payload: any) => {
  const response = await authRequest.post('transition-categories', payload);
  return response;
}

const detailTransitionCategory = async (id: any) => {
  const response = await authRequest.get(`transition-categories/${id}`);
  return response;
}

const updateTransitionCategory = async (payload: any) => {
  const response = await authRequest.put(`transition-categories/${payload.id}`, payload);
  return response;
}


const transition = { 
  getListTransitions,
  removeTransition,
  updateTransition,
  getMasterUUID,
  getThumbnailUpload,
  getSourceFileUpload,
  createTransition,
  detailTransition,
  getListTransitionCategories,
  removeTransitionCategory,
  createTransitionCategory,
  detailTransitionCategory,
  updateTransitionCategory,
  getListTransitionByCategories,
};
  
export default transition;