import React, {useEffect, useState} from 'react';
import ButtonUpload from '@/component/common/ButtonUpload';
import {FILE_TYPE} from '@/constants';
import ErrorMessage from '@/component/common/ErrorMessage';
import ReactPlayer from 'react-player';
import {IUploadTemplatePreview} from '@/component/templates/interface/UploadTemplatePreview';
import {useDispatch} from 'react-redux';
import {setUploadTemplatePreviewFile} from '@/redux/slice/template';

const UploadTemplatePreview: React.FC<IUploadTemplatePreview> = ({
  isUserClickCancelButton,
  userClickSubmitButtonTimes,
}) => {
  const dispatch = useDispatch();
    
  const [isUploadingPreview, setIsUploadingPreview] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [uploadPreviewErrorMessage, setUploadPreviewErrorMessage] = useState<string>('');

  const handleChangeFilePreview = (file: any) => {
    setUploadPreviewErrorMessage('');
    setIsUploadingPreview(true);
  }
  
  const handleUploadPreview = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name;
    const fileSize = uploadFile.size;
    console.log('fileName', fileName);
    
    if (!fileName?.toLowerCase().endsWith(FILE_TYPE.MP4)) {
      setPreviewUrl('');
      setUploadPreviewErrorMessage(`The ${fileName} is wrong format preview file. Please try again`)
    } else if (fileSize > 10000000) {
      setPreviewUrl('');
      setUploadPreviewErrorMessage(`The ${fileName} is over maximum file size 10MB. Please try again`)
    } else {
      const src = URL.createObjectURL(uploadFile);
      setPreviewUrl(src);
      dispatch(setUploadTemplatePreviewFile(uploadFile))
    }
    setIsUploadingPreview(false);
  }
  
  const handleClearFile = () => {
    setIsUploadingPreview(false);
    setPreviewUrl('');
    setUploadPreviewErrorMessage('');
    dispatch(setUploadTemplatePreviewFile(null));
  }
  
  useEffect(() => {
    handleClearFile()
  }, [isUserClickCancelButton]);
  
  const handleSubmit = () => {
    if (!previewUrl) {
      setUploadPreviewErrorMessage('This field is required');
    }
  }
    
  useEffect(() => {
    if (userClickSubmitButtonTimes > 0) {
      handleSubmit()
    }
  }, [userClickSubmitButtonTimes])

  return (
    <div className='upload-template-preview'>
      <div className='upload-template-thumbnail-header'>
        <div className='header'>
          Preview Video
        </div>
        <ButtonUpload
          label={'Upload'}
          handleChangeFile={handleChangeFilePreview}
          handleUpload={handleUploadPreview}
          showUploadList={isUploadingPreview}
        />
      </div>
        
      <ErrorMessage errorMessage={uploadPreviewErrorMessage}/>
      {previewUrl && (
        <div>
          <ReactPlayer url={previewUrl} controls={true} width={'100%'} height={'90%'}/>
        </div>
      )}
    </div>
  );
};

export default UploadTemplatePreview;