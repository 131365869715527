/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import ContentPageHeader from '@/component/common/ContentPageHeader';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import BaseButton from '@/component/common/Button/index';
import { MenuProps, Space, Dropdown, Button } from 'antd';
import './index.scss';
import services from '@/services';
import {GENDER_LIST, HTTP_STATUS, SORT_ORDER, STATUSES_LIST} from '@/constants';
import {USER_ACTIONS} from '@/constants/roles';
import { convertDataFilterTags, formatDateTime, removeDataFilterTags } from '@/utils';
import AdvancedFilter from '@/layouts/advancedFilter';
import { DownOutlined } from '@ant-design/icons';
import {cancelToken} from '@/http-common';
import BaseTable from '@/component/common/BaseTable';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import useDebounce from '@/hooks/useDebounce';
import { IConfigFilter } from '@/component/common/interface/Filter';
import dayjs from 'dayjs';
import FilterBar from '@/component/common/FilterBar';
import FilterItemList from '@/component/common/FilterItemList';
import {setIsUserClickSubmitEditIcon, setSelectedIds, setNoti, setSelectedId, setSelectedUpdateId} from '@/redux/slice/commonListPageSlice';
import EditItemModal from '@/component/common/EditItemModal';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import {checkPermission} from '@/utils/permission';
import {setHeaderPage} from '@/redux/slice/common';
import { platformSocialOptions, statusOptions } from '@/component/constant/Filter';
import usePreviousValue from '@/hooks/usePreviousValue';

const items: MenuProps['items'] = [
  {
    label: 'Change to Active',
    key: 'active',
  },
  {
    label: 'Change to Inactive',
    key: 'imactive',
  },
];

const configFilters: IConfigFilter[] = [
  {
    label: 'Platform',
    key: 'platforms',
    type: 'checkbox',
    checkBoxOptions: platformSocialOptions
  },
  {
    label: 'Create Date',
    key: 'createdAt',
    type: 'range-date',
  },
  {
    label: 'Status',
    key: 'statuses',
    type: 'checkbox',
    checkBoxOptions: statusOptions
  },
]

const UserListPage = () => {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('User Management'));

  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);

  const [categoryListPermissions, setCategoryListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowHeaderAction, setIsShowHeaderAction] = useState<boolean>(false);
  const [listData, setListData] = useState<any>([]);
  const [openĐrawer, setOpenDrawer] = useState<boolean>(false);
  const [valueSelectedMenu, setValueSelectedMenu] = useState<string>();

 
  const [filterDatas, setFilterDatas] = useState({});
  const filterDatasJsonString = JSON.stringify(filterDatas);
  const [filterList, setFilterList] = useState<any>([]);
  const [queryData, setQueryData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const [showEditItemModal, setShowEditItemModal] = useState<any>({ open: false, label: ''});
  const [isNotiHasNotTemplate, setIsNotiHasNotTemplate] = useState<boolean>(false);
  // const [editItemModalLabel, setEditItemModalLabel] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = [
    {
      label: 'NAME',
      value: 'userName',
      width: '200px',
    },
    {
      label: 'EMAIL',
      value: 'email',
      hasBlueText: true,
      clickable: true,
      width: '300px',
    },
    {
      label: 'PLATFORM',
      value: 'platform',
      width: '200px',
    },
    {
      label: 'COUNTRY',
      value: 'country',
      width: '150px'
    },
    {
      label: 'AGE',
      value: 'age',
      width: '100px'
    },
    {
      label: 'GENDER',
      value: 'gender',
      width: '150px'
    },
    {
      label: 'CREATED DATE',
      value: 'createdAt',
      width: '200px',
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      required: true,
      editType: 'singleSelect',
      listItems: STATUSES_LIST
    },
  ]
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);

  // Effect for API call
  useEffect(() => {
    if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
      setQueryData({
        ...queryData,
        page: 1
      });
      handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
      setPaginationData({
        ...paginationData,
        ...defaultBasePagination
      });
      getListAllUsers()
    }
  }, [debouncedSearchTerm])
  useEffect(() => {
    dispatch(setSelectedUpdateId(''))
    dispatch(setSelectedIds([]))
  }, [])
  
  const updateUserStatus = async () => {
    setShowEditItemModal({ open: true, label: 'Are you sure you want to save this change?' });
  }

  const checkCategoryHasTemplate = async () => {
    const categories = await Promise.all(selectedIds.map( async (id) => {
      const res: any = await services.getCategory(id)
      return res.data.data
    }))
    return categories.every((category: any) => category.templateCount)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleChangeStatus = async () => {
    setIsLoadingSubmit(true)
    const payload: any = {
      userIds: selectedIds,
      status: valueSelectedMenu === 'active' ? 1 : 0, 
    } 
    try {
      const res = await services.updateUsersStatus(payload);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        setShowEditItemModal({ open: false, label: '' });
        dispatch(setSelectedIds([]));
        getListAllUsers()
        dispatch(setNoti({
          open: true,
          message: 'Update User Status Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Update User Status Fail!',
      }))
    }
  }

  const handleFilter = () => {
    setOpenDrawer(true);
  };

  const handleSelect = () => {
    setIsShowHeaderAction(!isShowHeaderAction);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setValueSelectedMenu(e.key);
    updateUserStatus()
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };


  
  

  const getListAllUsers = async () => {
    setIsLoading(true)
    dispatch(setSelectedId(''));
    dispatch(setSelectedUpdateId(''))
    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit');
      const query = {
        search: searchValue || '',
        orderType: SORT_ORDER.ASC,
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas
      };
      const res = await services.getListUser(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        const meta = res.data.data.meta;
        const listItems = data.items.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          return {
            ...item,
            id: item.id,
            gender: GENDER_LIST.find(it => it.value == item.gender)?.label,
            createdAt,
          };
        });

        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.itemCount,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      console.log('err', err);
    }
  };
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    dispatch(setSelectedIds([]))
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    setSearchParams(searchParams);
    setQueryData(query);
  }

  const handleSubmitEditItemRow = async () => {
    setIsLoadingSubmit(true)
    const uploadForm = {
      id: selectedUpdateId,
      status: parseInt(selectedItem.status),
    }
    try {
      const res = await services.updateUserStatus(uploadForm);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getListAllUsers();
        dispatch(setNoti({
          open: true,
          message: 'Updated User Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        closeIcon: <></>,
        message: message || 'Updated User Fail!',
        icon: <></>,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      // dispatch(setSelectedUpdateId(''));
    }
    setShowEditItemModal({ open: false, label: '' });
  }

  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemModal({ open: true, label: 'Are you sure you want to save this change?' });
    }
  }
  
  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon])

  useEffect(() => {
    getListAllUsers();     
    return () => cancelToken()

  }, [queryData?.page, queryData?.limit, filterDatasJsonString]);

  //filter
  const submitFilter = async (values: any) => {
    const filters = Object.assign(values, {})
    if (filters.createdAtFrom) {
      filters.createdAtFrom = dayjs(filters.createdAtFrom).format('YYYY-MM-DD')
    }
    if (filters.createdAtTo) {
      filters.createdAtTo = dayjs(filters.createdAtTo).format('YYYY-MM-DD')
    }
    setFilterDatas({
      ...filters,
    })
    const filterListMap = convertDataFilterTags(configFilters, filters)
    const hasCaterofyFilter = configFilters.filter((c: IConfigFilter) => c.key === 'category')
    if (hasCaterofyFilter) {
      await Promise.all(filterListMap.map(async (item: any) => {
        if (item.key === 'categories') {
          const categoryMap = await Promise.all(item.value.map(async (it: any) => {
            const res = await services.getCategory(it)
            if (res && res.data.statusCode === HTTP_STATUS.SUCCESS) {
              it = res.data.data.name
            }
            return it
          }))
          item.value = categoryMap.join(', ')
        }
        return item;
      }))
    }
    setFilterList(filterListMap)
    setOpenDrawer(false)
  }

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }


  const handleCancelEdit = () => {
    setShowEditItemModal({ open: false, label: ''});
    setIsNotiHasNotTemplate(false)
    dispatch(setIsUserClickSubmitEditIcon(false));
  }
  
  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }


  return (
    <div className="user-list-container">
      <ContentPageHeader header={'User list'} />
      <div>
        <div className="btn-container">
          <div className="flex-left">
            <div className="flex-left-input">
              <InputSearchField
                value={searchValue}
                inputPlaceholder={'Search...'}
                inputName={'search'}
                handleChange={handleChangeSearch}
                // handleClearSearch={handleClickSearch}
              />
            </div>
            <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/>
          </div>

          <div
            className={!isShowHeaderAction ? 'flex-right' : 'flex-right-custom'}
          >
            {!isShowHeaderAction && 
            <div className="select-btn">
              <BaseButton buttonLabel={'Select'} handleClick={handleSelect} className={selectedUpdateId ? 'disabled' : ''}/>
            </div>
            }

            {isShowHeaderAction && (
              <Dropdown
                menu={menuProps}
                dropdownRender={(item: React.ReactNode) => (
                  <div className="custom-dropdown">{item}</div>
                )}
              >
                <Button
                  size="large"
                  style={{
                    backgroundColor: '#f7f7f7',
                    marginRight: 16,
                    width: 'max-content'
                  }}
                  className={!selectedIds.length ? 'disabled' : ''}
                >
                  <Space
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: '22px',
                    }}
                  >
                    Change Status
                    <DownOutlined
                      style={{
                        fontSize: 14,
                      }}
                    />
                  </Space>
                </Button>
              </Dropdown>
            )}

            {isShowHeaderAction && (
              <Button
                style={{
                  border: '1px solid #F37F13',
                  color: '#F37F13',
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 16,
                  height: 40,
                  lineHeight: '22px',
                  width: '30%',
                }}
                onClick={() => {
                  setIsShowHeaderAction(false);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
        {filterList.length > 0 && <div className='div-filter'>
          {filterList.map((item: any) => {
            return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
          })}
        </div>}
        <BaseTable
          headers={tableHeaders} 
          dataTable={listData}
          detailPageRoute={'category-detail'}
          allowSelection={isShowHeaderAction}
          isShowHeaderAction={isShowHeaderAction}
          showDeleteIcon={false}
          showEditIcon={checkPermission(categoryListPermissions, USER_ACTIONS.EDIT)}
          type='user'
          isLoading={isLoading}
        />

        <BasePagination
          currentPage={paginationData.currentPage}
          itemsPerPage={paginationData.itemsPerPage}
          totalItems={paginationData.totalItems}
          totalPages={paginationData.totalPages}
          handleChangePage={handleChangePage}
        />
      </div>
      <AdvancedFilter
        openDrawer={openĐrawer}
        setOpenDrawer={setOpenDrawer}
        content={<FilterBar setOpenDrawer={setOpenDrawer} configFilters={configFilters} submitFilter={submitFilter} filterDatas={filterDatas} />}
      />
      <EditItemModal 
        showModal={showEditItemModal?.open} 
        label={showEditItemModal?.label}
        isNoti={isNotiHasNotTemplate}
        handleCancel={handleCancelEdit} 
        handleSubmitEdit={selectedIds?.length ? handleChangeStatus : handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />
    </div>
  );
};

export default UserListPage;
