import React from 'react';
import { Layout } from 'antd';
import {Outlet} from 'react-router';
import './ContentPage.scss';
import {useLocation} from 'react-router-dom';
import {EDITABLE_PATHS} from '@/constants';

const { Content } = Layout;

const ContentPage: React.FC = () => {
  const location= useLocation();
  const pathName = location.pathname;
  const editablePathsArr = EDITABLE_PATHS;

  const isEditablePage = editablePathsArr.some((path: string) => pathName.includes(path));
  return (
    <Content className='content-page'>
      {isEditablePage
        ? (
          <div className='content-detail-page-container'>
            <Outlet />
          </div>
        )
        : (
          <div className='content-container'>
            <Outlet />
          </div>
        )
      }

    </Content>
  );
};

export default ContentPage;