import React from 'react';
import {ISidebarConfigIcon} from '@/component/icon/interface/SidebarConfigIcon';

const SidebarContentIcon: React.FC<ISidebarConfigIcon> = ({selected}) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.64461 4.80525C8.78245 5.14875 7.94632 5.55435 7.14283 6.01881C6.33746 6.48236 5.5675 7.00483 4.83927 7.58203C4.57783 9.41728 4.57783 11.2803 4.83927 13.1156C5.5675 13.6928 6.33746 14.2153 7.14283 14.6788C7.94569 15.1435 8.78123 15.5493 9.64283 15.8931C10.5044 15.5493 11.3399 15.1435 12.1428 14.6788C12.9482 14.2153 13.7182 13.6928 14.4464 13.1156C14.5777 12.1992 14.6434 11.2746 14.6428 10.3488C14.6435 9.42307 14.578 8.49845 14.4467 7.58206C13.7184 7.00483 12.9483 6.48232 12.1428 6.01885C11.3405 5.55448 10.5056 5.14889 9.64461 4.80525ZM9.78031 12.8213C9.69288 12.8578 9.59456 12.8581 9.50694 12.822C9.41934 12.786 9.34961 12.7166 9.31315 12.6292L8.8096 11.4203H6.90459L6.40103 12.6292C6.35191 12.747 6.24368 12.8296 6.11712 12.8459C5.99057 12.8623 5.8649 12.8099 5.78747 12.7084C5.71005 12.607 5.69261 12.4719 5.74174 12.3542L7.52746 8.06847C7.58294 7.93544 7.71295 7.84876 7.85711 7.84876C8.00127 7.84876 8.13128 7.93544 8.18676 8.06847L9.97248 12.3542C10.0089 12.4416 10.0092 12.5399 9.97317 12.6276C9.93714 12.7152 9.86775 12.7849 9.78031 12.8213ZM13.2143 8.20599C13.2143 8.40322 13.0544 8.56315 12.8571 8.56315H12.1428V12.1346H12.8571C13.0543 12.1346 13.2143 12.2945 13.2143 12.4917C13.2143 12.689 13.0544 12.8489 12.8571 12.8489H10.7142C10.517 12.8489 10.3571 12.689 10.3571 12.4917C10.3571 12.2945 10.517 12.1346 10.7142 12.1346H11.4285V8.56311H10.7142C10.517 8.56311 10.3571 8.40322 10.3571 8.20595C10.3571 8.00872 10.517 7.8488 10.7142 7.8488H12.8571C13.0544 7.84883 13.2143 8.00872 13.2143 8.20599Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 5.39989C13.109 5.75129 13.6988 6.1348 14.2671 6.54882C14.08 5.61308 13.798 4.69887 13.4257 3.82025C12.4759 3.93424 11.5401 4.1445 10.6328 4.44776C11.2709 4.73348 11.8941 5.05125 12.5 5.39989Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1021 3.13852C12.2032 1.41282 10.9843 0.348877 9.64287 0.348877C8.30144 0.348877 7.08288 1.41282 6.18396 3.13852C7.3681 3.30614 8.52921 3.60858 9.64468 4.03994C10.7591 3.60891 11.9191 3.30647 13.1021 3.13852Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.20251 10.706H8.5118L7.85714 9.13458L7.20251 10.706Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.6071 9.72598C18.2101 8.85052 18.5687 7.83012 18.6461 6.76989C18.6207 6.76989 18.5968 6.7774 18.5711 6.7774C17.7821 6.7774 17.1425 6.13781 17.1425 5.34883C17.1439 5.02945 17.2532 4.71994 17.4529 4.47061C16.6757 3.95597 15.5397 3.71312 14.1786 3.76455C14.6215 4.87074 14.9354 6.02432 15.1139 7.20241C16.0381 7.94617 16.8746 8.7928 17.6071 9.72598Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.714274 6.77751C0.688575 6.77751 0.66462 6.7711 0.639282 6.77001C0.716611 7.83017 1.07535 8.85047 1.67857 9.72573C2.41086 8.79282 3.24696 7.94629 4.17072 7.20252C4.34979 6.0244 4.66401 4.87086 5.10715 3.76467C3.74679 3.71396 2.61145 3.95753 1.83392 4.47073C2.03303 4.72022 2.14192 5.02973 2.14284 5.34895C2.14284 6.13789 1.50325 6.77751 0.714274 6.77751Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M18.5715 6.06313C18.9659 6.06313 19.2857 5.74334 19.2857 5.34885C19.2857 4.95436 18.9659 4.63457 18.5715 4.63457C18.177 4.63457 17.8572 4.95436 17.8572 5.34885C17.8572 5.74334 18.177 6.06313 18.5715 6.06313Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.1811 10.3489C16.6101 9.58862 15.964 8.88783 15.2525 8.2571C15.3227 8.95213 15.3576 9.65029 15.3571 10.3489C15.3567 11.0475 15.321 11.7457 15.25 12.4407C15.9623 11.81 16.6093 11.1092 17.1811 10.3489Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.78581 5.39992C7.39252 5.05089 8.01665 4.73309 8.65581 4.44776C7.74758 4.14437 6.81085 3.93414 5.86011 3.82025C5.48775 4.69887 5.20581 5.61308 5.01868 6.54882C5.58696 6.1349 6.17686 5.75139 6.78581 5.39992Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 15.2978C11.8933 15.6469 11.2692 15.9647 10.63 16.25C11.5382 16.5534 12.475 16.7636 13.4257 16.8775C13.7981 15.9989 14.08 15.0847 14.2671 14.1489C13.6988 14.5629 13.109 14.9464 12.5 15.2978Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5715 13.9203C18.5972 13.9203 18.6211 13.9264 18.6465 13.9278C18.5689 12.8676 18.2102 11.8472 17.6072 10.9717C16.875 11.9048 16.0389 12.7515 15.115 13.4953C14.9359 14.6734 14.6217 15.827 14.1786 16.9332C15.5386 16.9842 16.674 16.7403 17.4518 16.2271C17.2527 15.9776 17.1438 15.6681 17.1429 15.3489C17.1429 14.5599 17.7825 13.9203 18.5715 13.9203Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.18359 17.5592C7.08251 19.2849 8.30143 20.3488 9.64287 20.3488C10.9843 20.3488 12.2029 19.2849 13.1018 17.5592C11.9182 17.3914 10.7577 17.089 9.64287 16.6578C8.52786 17.0889 7.36724 17.3914 6.18359 17.5592Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M18.5715 16.0631C18.9659 16.0631 19.2857 15.7433 19.2857 15.3489C19.2857 14.9544 18.9659 14.6346 18.5715 14.6346C18.177 14.6346 17.8572 14.9544 17.8572 15.3489C17.8572 15.7433 18.177 16.0631 18.5715 16.0631Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M0.714283 16.0631C1.10877 16.0631 1.42857 15.7433 1.42857 15.3489C1.42857 14.9544 1.10877 14.6346 0.714283 14.6346C0.319796 14.6346 0 14.9544 0 15.3489C0 15.7433 0.319796 16.0631 0.714283 16.0631Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.10461 10.3489C2.6754 11.1093 3.32153 11.8101 4.03318 12.4407C3.89271 11.0497 3.89271 9.64808 4.03318 8.2571C3.32173 8.88783 2.67563 9.58862 2.10461 10.3489Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.67857 10.972C1.07548 11.8473 0.716709 12.8676 0.639282 13.9278C0.664652 13.9278 0.688575 13.9203 0.714274 13.9203C1.50325 13.9203 2.14284 14.5598 2.14284 15.3488C2.14149 15.6682 2.03211 15.9777 1.83247 16.227C2.61033 16.7403 3.7464 16.9849 5.1057 16.9335C4.66286 15.8271 4.349 14.6734 4.17036 13.4953C3.24683 12.7514 2.41086 11.9049 1.67857 10.972Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M0.714283 6.06313C1.10877 6.06313 1.42857 5.74334 1.42857 5.34885C1.42857 4.95436 1.10877 4.63457 0.714283 4.63457C0.319796 4.63457 0 4.95436 0 5.34885C0 5.74334 0.319796 6.06313 0.714283 6.06313Z" fill={selected ? '#F37F13' : 'white'}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.78581 15.2978C6.17682 14.9464 5.58696 14.5628 5.01868 14.1488C5.20581 15.0846 5.48775 15.9988 5.86011 16.8774C6.80993 16.7634 7.74567 16.5531 8.65298 16.2499C8.0149 15.9642 7.39174 15.6464 6.78581 15.2978Z" fill={selected ? '#F37F13' : 'white'}/>
    </svg>
  );
};

export default SidebarContentIcon;