/* eslint-disable no-unsafe-optional-chaining */
import React, {useEffect, useState} from 'react';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {HTTP_STATUS, SORT_ORDER, STATUSES_LIST, TRANSITION_CONTENT_TABS} from '@/constants';
import BaseTable from '@/component/common/BaseTable';
import {checkPermission} from '@/utils/permission';
import {USER_ACTIONS} from '@/constants/roles';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import './index.scss';
import AddCategoryModal from '@/component/templates/AddCategoryModal';
import TransitionCategoryModal from '@/component/content/Transition/TransitionCategoryModal';
import { cancelToken } from '@/http-common';
import services from '@/services';
import { formatDateTime } from '@/utils';
import { setIsUserClickSubmitEditIcon, setNoti, setSelectedId, setSelectedIds, setSelectedUpdateId, setShowDeleteModal } from '@/redux/slice/commonListPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '@/hooks/useDebounce';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import EditItemModal from '@/component/common/EditItemModal';
import { RootState } from '@/redux/rootReducer';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import usePreviousValue from '@/hooks/usePreviousValue';
export default function TransitionCategoryListPage({activeKey} : {activeKey: string}): JSX.Element {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [queryData, setQueryData] = useState<any>(null);
  const [filterDatas, setFilterDatas] = useState({});
  const filterDatasJsonString = JSON.stringify(filterDatas);
  const [searchParams, setSearchParams] = useSearchParams();
  const [listData, setListData] = useState<any>([]);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const [showEditItemCategoryModal, setShowEditItemCategoryModal] = useState<boolean>(false);
  const [editItemModalLabel, setEditItemModalLabel] = useState<string>('');
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = [
    {
      label: 'NO',
      value: 'priority',
      width: '120px',
      editable: true,
      required: true,
      editType: 'input'
    },
    {
      label: 'CATEGORY NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      required: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 150
    },
    {
      label: 'DESCRIPTION',
      value: 'description',
      width: '400px',
      editable: true,
      editType: 'textarea',
      isCutText: true,
      maximumTextLength: 500,
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'COUNT',
      value: 'count',
      width: '160px'
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      required: true,
      editType: 'singleSelect',
      listItems: STATUSES_LIST
    },
  ]
  const dispatch = useDispatch();

  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        });
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        });
        getListTransitionCategory()
      }


    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    setShowEditItemCategoryModal(false)
    dispatch(setSelectedIds([]))
    dispatch(setSelectedUpdateId(''));
  }, [activeKey])

  useEffect(() => {
    getListTransitionCategory();
    return () => cancelToken()
  }, [queryData?.page, queryData?.limit, filterDatasJsonString]);


  const getListTransitionCategory = async () => {
    setIsLoading(true)
    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit')
      const query: any = {
        search: searchValue.trim(),
        orderType: SORT_ORDER.DESC,
        orderBy: 'createdAt',
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas
      };
      const res = await services.getListTransitionCategories(query);
      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;
        const meta = res.data.data.meta;
        const listItems = data.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          return {
            ...item,
            createdAt,
            count: item.itemCount,
            createdBy: item?.createdBy?.userName || ''
          }
        });
        setListData(listItems);

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
          totalCount: meta.itemCount,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
    }
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setSearchValue(value)
  };
  
  const handleFilter = () => {
    setOpenDrawer(true);
  };
  
  const handleAdd = () => {
    setShowModal(true)
  }
  
  const handleChangePage = async (page: number, pageSize: number) => {
    console.log('page', page)
    const query = {
      page: page,
      limit: pageSize,
    };
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    dispatch(setSelectedIds([]))
    dispatch(setSelectedUpdateId(''));
    setSearchParams(searchParams);
    setQueryData(query);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const submitSuccess = () => {
    setShowModal(false);
    getListTransitionCategory();
  }

  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }

  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon]);
  
  const handleSubmitDelete =async () => {
    setIsLoadingSubmit(true)
    try {
      const res = await services.removeTransitionCategory(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        dispatch(setSelectedUpdateId(''));
        getListTransitionCategory();
        dispatch(setNoti({
          open: true,
          message: 'Delete Transition Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        type: 'error',
        open: true,
        message: message || 'Delete Fail!',
      }))
      dispatch(setShowDeleteModal(false));
      dispatch(setSelectedId(''));
    }
  }
  
  const handleSubmitEditItemRow = async () => {
    setIsLoadingSubmit(true)
    const uploadPayload: any = {
      priority: +selectedItem.priority,
      id: selectedUpdateId,
      name: selectedItem.name,
      description: selectedItem.description,
      status: +selectedItem.status,
    }
    try {
      const res = await services.updateTransitionCategory(uploadPayload);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getListTransitionCategory();
        dispatch(setNoti({
          open: true,
          message: 'Updated Transition Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      const { message } = err.response.data
      dispatch(setNoti({
        open: true,
        type: 'error',
        message,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
      setIsLoadingSubmit(false)
    }
    setShowEditItemCategoryModal(false);
  }
  
  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemCategoryModal(true);
      setEditItemModalLabel('Are you sure you want to save this change?')
    }
  }

  const handleCancelEdit = () => {
    setShowEditItemCategoryModal(false);
    setEditItemModalLabel('');
    dispatch(setIsUserClickSubmitEditIcon(false));
  }
  
  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }

  if (activeKey !== TRANSITION_CONTENT_TABS.CATEGORY_TRANSITION) {
    return <></>
  }
  return (
    <div className='transition-list-container'>
      <div className="btn-container">
        <div className="flex-left">
          <div className="flex-left-input">
            <InputSearchField 
              value={searchValue} 
              inputPlaceholder={'Search...'} 
              inputName={'search'} 
              handleChange={handleChangeSearch}
            />
          </div>
          {/* <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/> */}
        </div>

        <div className='flex-right'>
          <ButtonIcon 
            handleClick={handleAdd} 
            buttonLabel={'Add New Category'} 
          />
          
        </div>
      </div>
      

      <BaseTable
        headers={tableHeaders} 
        dataTable={listData}
        detailPageRoute={'transition-category/update'}
        subKeyPath={'transition'}
        allowSelection={false}
        isShowHeaderAction={false}
        showDeleteIcon={true}
        showEditIcon={true}
        type="category"
        isLoading={isLoading}
      />

      <DeleteItemModal 
        showModal={showDeleteModal} 
        label={'Are you sure you want to delete'}
        handleCancel={handleCancelDelete}
        handleSubmitDelete={handleSubmitDelete}
        deletedValueItem={selectedItem?.name}
        isDeleteSpecial={true}
        deletedValueType={'template'}
        isLoading={isLoadingSubmit}
      />

      {showEditItemCategoryModal && <EditItemModal 
        showModal={showEditItemCategoryModal} 
        label={editItemModalLabel}
        handleCancel={handleCancelEdit} 
        handleSubmitEdit={handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />}
            
      <BasePagination
        currentPage={paginationData.currentPage}
        itemsPerPage={paginationData.itemsPerPage}
        totalItems={paginationData.totalCount}
        totalPages={paginationData.totalPages}
        handleChangePage={handleChangePage}
      />
      {showModal && <TransitionCategoryModal
        showModal={showModal}
        handleCancel={handleCloseModal}
        submitSuccess={submitSuccess}
      />}
    </div>
  );
}
