import React from 'react';
import { ICard } from '../interface/Card';
import './index.scss'

const Card: React.FC<ICard> = ({ children, style }) => {
  return (
    <div className='card' style={style}>
      {children}
    </div>
  );
};

export default Card;