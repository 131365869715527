import authRequest from '@/services/service';

const getPresignUrl = async () => {
  const response = await authRequest.get('ai-contents/presign');
  return response;
}

const createContentAi = async (payload: any) => {
  const response = await authRequest.post('ai-contents', payload);
  return response;
}

const updateContentAi = async (id: string, payload: any) => {
  const response = await authRequest.put(`ai-contents/image-template/${id}`, payload);
  return response;
}

const getAIContents = async (payload: any) => {
  const response = await authRequest.get('ai-contents/image-template', {
    params: payload
  });
  return response;
}

const getAIContent = async (id: string) => {
  const response = await authRequest.get(`ai-contents/image-template${id}`);
  return response;
}

const getCategories = async (payload: any) => {
  const response = await authRequest.get('ai-contents/category', { params: payload });
  return response;
}


const content = { 
  getPresignUrl,
  createContentAi,
  getAIContents,
  getAIContent,
  getCategories,
  updateContentAi,
};

export default content;
