import React, {useState, useEffect, useRef} from 'react';

import TableReadOnlyRow from '@/component/common/BaseTable/TabelReadOnlyRow';
import TableEditRow from '@/component/common/BaseTable/TableEditRow';
import {ITableBodyRows} from '@/component/common/interface/TableBodyRows';
import { RootState } from '@/redux/rootReducer';
import {useDispatch, useSelector} from 'react-redux';
import {setListReorderItems} from '@/redux/slice/commonListPageSlice';

const TableBodyRows: React.FC<ITableBodyRows> = ({
  headers,
  dataTable,
  detailPageRoute,
  subKeyPath,
  isEditRowTable,
  allowSelection = false,
  isShowHeaderAction = false,
  draggable = false,
  showResendButton,
  showDeleteIcon,
  showEditIcon,
  type,
  isTableInDetailPage
}) => {
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);
  const dispatch = useDispatch();

  const draggingItem : any = useRef();
  const dragOverItem : any = useRef();

  const [ tableList, setTableList ] = useState<any[]>([]);

  useEffect(() => {
    setTableList(dataTable)
  }, [ dataTable.toString() ]);

  const handleDragStart = (event: any, index: number) => {
    draggingItem.current = index;
  }

  const handleDrop = (event: any, index: number) => {
    dragOverItem.current = index;
    const listCopy = [ ...tableList ];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;
    setTableList(listCopy);
    const listOrderItems = listCopy.map((item: any) => item.id)
    dispatch(setListReorderItems(listOrderItems));
  }

  useEffect(() => {
    setTableList(dataTable)
  }, [ isShowHeaderAction ]);

  return (
    <>
      {tableList.map((item: any, index: number) => (
        <tr
          key={index}
          draggable={false}
          onDragStart={(event: any) => handleDragStart(event, index)}
          onDragOver={(event: any) => event.preventDefault()}
          onDrop={(event: any) => handleDrop(event, index)}
        >
          {
            selectedUpdateId === item.id
              ? (
                <TableEditRow
                  id={item.id}
                  headers={headers}
                  item={item}
                  allowSelection={allowSelection}
                  isShowHeaderAction={isShowHeaderAction}
                  showDeleteIcon={showDeleteIcon}
                  type={type}
                />
              )
              : (
                <TableReadOnlyRow
                  id={item.id}
                  headers={headers}
                  item={item}
                  detailPageRoute={detailPageRoute}
                  subKeyPath={subKeyPath}
                  isEditRowTable={isEditRowTable}
                  allowSelection={allowSelection}
                  isShowHeaderAction={isShowHeaderAction}
                  showResendButton={showResendButton}
                  showDeleteIcon={showDeleteIcon}
                  showEditIcon={showEditIcon}
                  type={type}
                  isTableInDetailPage={isTableInDetailPage}
                />
              )
          }
        </tr>
      ))}
    </>
  );
};

TableBodyRows.defaultProps = {
  detailPageRoute: '',
  subKeyPath: '',
  showResendButton: false,
  showDeleteIcon: true,
  showEditIcon: true,
  isTableInDetailPage: false
}

export default TableBodyRows;
