import React, {useEffect} from 'react';
import Logo from '../../../component/icon/LogoIcon'
import BaseButton from '@/component/common/Button';
import {useSearchParams} from 'react-router-dom';
import services from '@/services';
import {HTTP_STATUS} from '@/constants';
import {setCookie} from 'react-use-cookie';
import authRequest from '@/services/service';
import {setUserInfo} from '@/redux/slice/common';
import {useDispatch, useSelector} from 'react-redux';
import './VerifyEmailPage.scss';
import {useCookies} from 'react-cookie';
import {redirectToDefaultUrl} from '@/utils/auth';
import {RootState} from '@/redux/rootReducer';

export default function VerifyEmailPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const token = searchParams.get('token');
  
  const [cookies] = useCookies(['access_token']);
  const userRole = useSelector((state: RootState) => state.commonReducer.userInfo.userRole) || localStorage.getItem('user_role');   

  const checkUserLoginOrNot = async () => {
    if (cookies.access_token && userRole) {
      redirectToDefaultUrl(userRole);

    }
  };

  useEffect(() => {
    checkUserLoginOrNot()
  }, [cookies.access_token]);
  const handleVerifyEmail = async () => {
    if (token) {
      const payload = { token };
        
      try {
        const res = await services.verifyEmail(payload);
        if (res && res.status ===  HTTP_STATUS.SUCCESS) {
          const data = res.data.data;

          const userAccessToken = data.token.accessToken;
          authRequest.defaults.headers['Authorization'] = `Bearer ${userAccessToken}`;

          const responseUser = await services.getUserRoleAndPermission();

          if (responseUser && responseUser.status === HTTP_STATUS.SUCCESS) {
            const userPermissions = responseUser.data.data.permissions;
            const userRole = responseUser.data.data.roleCode;

            const userInfo = {id: data.user.id,
              email: data.user.email,
              fullName: data.user.fullName,
              userName: data.user.userName,
              userPermissions,
              userRole
            };
            setCookie('access_token', userAccessToken);
            setCookie('user_fullname', userInfo.fullName);
            localStorage.setItem('user_role', userRole);

            dispatch(setUserInfo(userInfo));

            redirectToDefaultUrl(userRole);
          }
            
        }
      } catch (err: any) {
        console.log('error', err);
      }
    }
    

  }
    
  return (
    <div className='verify-email-container'>
      <Logo />
      <div className='title'>
        <h1>Click button to verify</h1>
        <BaseButton handleClick={handleVerifyEmail} buttonLabel={'Verify'} type={'primary'} />
      </div>
    </div>
  );
}