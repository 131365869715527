import {IUploadTemplateFiles} from '@/redux/interface/template';

const initialUploadTemplateFiles: IUploadTemplateFiles = {
  zipFile: null,
  thumbnail: null,
  preview: null,
  templateData: null,
  isAllowUpload: false,
  uploadTemplateName: ''
}

export default initialUploadTemplateFiles;