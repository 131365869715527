import React from 'react';
import {Input, InputProps} from 'antd';
import {IInputTextField} from '../interface/InputTextField';
import './InputTextField.scss';

const InputTextField: React.FC<IInputTextField & InputProps> = ({
  showCount,
  isInputInvalid,
  value,
  inputType, 
  inputPlaceholder, 
  inputName,
  handleChange, 
  minLength, 
  maxLength,
  disabled,
  bordered = true,
  defaultValue,
  onPressEnter
}) => {
  return (
    <div className="input-text-field">
      <Input 
        size='large'
        status={isInputInvalid ? 'error' : ''}
        type={inputType}
        placeholder={inputPlaceholder}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        minLength={minLength}
        maxLength={maxLength}
        name={inputName}
        showCount={showCount}
        disabled={disabled}
        bordered={bordered}
        onPressEnter={onPressEnter}
      />
    </div>
  );
};

InputTextField.defaultProps = {
  showCount: false,
  isInputInvalid: false,
  minLength: 1,
  maxLength: 255,
  disabled: false
}

export default InputTextField;