import React from 'react';
import EffectContentTabs from '@/component/content/Effect/EffectContentTabs';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';

export default function EffectContentListPage(): JSX.Element {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('App Content'));
  return (
    <div>
      <EffectContentTabs />
    </div>
  );
}