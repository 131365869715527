import {TABLE_PERMISSIONS} from '@/constants/roles';

const rolesList = TABLE_PERMISSIONS;
export const getDefaultUrlFromRole = (userRole: string) => {
  const specificRole = rolesList.find((role: any) => role.role === userRole);
  const defaultUrl = specificRole?.defaultUrl;
  return defaultUrl;
}

export const redirectToDefaultUrl = (userRole: string) => {
  const defaultUrl = getDefaultUrlFromRole(userRole);
  window.location.href = `../${defaultUrl}`
}

export const getDefaultKeyPathFromRole = (userRole: string) => {
  const specificRole = rolesList.find((role: any) => role.role === userRole);
  const defaultKeyPath = specificRole?.defaultKeyPath;
  return defaultKeyPath;
}