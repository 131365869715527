import authRequest from '@/services/service';

const getListUser = async (query: any) => {
  const response = await authRequest.get('end-users', {params : query});
  return response
};

const getDetailUser = async (id: string) => {
  const response = await authRequest.get(`end-users/${id}`);
  return response
};

const updateUserStatus = async (payload: any) => {
  const response = await authRequest.put(`end-users/status/${payload.id}`, payload);
  return response
};

const updateUsersStatus = async (payload: any) => {
  const response = await authRequest.put('end-users/status', payload);
  return response
};

const user = {
  getListUser,
  getDetailUser,
  updateUserStatus,
  updateUsersStatus,
};

export default user;
