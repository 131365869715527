import {ICommonListPage} from '@/redux/interface/commonListPage';

const initialCommonListPageState: ICommonListPage = {
  selectedId: '',
  selectedIds: [],
  selectedUpdateId: '',
  isUserClickEditIcon: false,
  selectedItem: null,
  deletedItemName: '',
  showDeleteModal: false,
  isUserClickSubmitEditIcon: false,
  isUserClickClickableColumn: false,
  noti: null,
  isUserClickResendButton: false,
  searchValue: '',
  selectedItems: [],
  listReorderItems: []
}

export default initialCommonListPageState;
