import React from 'react';
import { ISidebarTemplateIcon } from '../../interface/SidebarTemplateIcon';

const SidebarUserIcon: React.FC<ISidebarTemplateIcon> = ({selected}) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 2.34888C6.38 2.34888 4.25 4.47888 4.25 7.09888C4.25 9.66888 6.26 11.7489 8.88 11.8389C8.96 11.8289 9.04 11.8289 9.1 11.8389C9.12 11.8389 9.13 11.8389 9.15 11.8389C9.16 11.8389 9.16 11.8389 9.17 11.8389C11.73 11.7489 13.74 9.66888 13.75 7.09888C13.75 4.47888 11.62 2.34888 9 2.34888Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M14.08 14.4989C11.29 12.6389 6.73996 12.6389 3.92996 14.4989C2.65996 15.3489 1.95996 16.4989 1.95996 17.7289C1.95996 18.9589 2.65996 20.0989 3.91996 20.9389C5.31996 21.8789 7.15996 22.3489 8.99996 22.3489C10.84 22.3489 12.68 21.8789 14.08 20.9389C15.34 20.0889 16.04 18.9489 16.04 17.7089C16.03 16.4789 15.34 15.3389 14.08 14.4989Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M19.99 7.68888C20.15 9.62888 18.77 11.3289 16.86 11.5589C16.85 11.5589 16.85 11.5589 16.84 11.5589H16.81C16.75 11.5589 16.69 11.5589 16.64 11.5789C15.67 11.6289 14.78 11.3189 14.11 10.7489C15.14 9.82888 15.73 8.44888 15.61 6.94888C15.54 6.13888 15.26 5.39888 14.84 4.76888C15.22 4.57888 15.66 4.45888 16.11 4.41888C18.07 4.24888 19.82 5.70888 19.99 7.68888Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M21.99 16.9389C21.91 17.9089 21.29 18.7489 20.25 19.3189C19.25 19.8689 17.99 20.1289 16.74 20.0989C17.46 19.4489 17.88 18.6389 17.96 17.7789C18.06 16.5389 17.47 15.3489 16.29 14.3989C15.62 13.8689 14.84 13.4489 13.99 13.1389C16.2 12.4989 18.98 12.9289 20.69 14.3089C21.61 15.0489 22.08 15.9789 21.99 16.9389Z" fill={selected ? '#F37F13' : 'white'}/>
    </svg>

  );
};

export default SidebarUserIcon;