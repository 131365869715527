/* eslint-disable no-unsafe-optional-chaining */
import React, {useState, useEffect} from 'react';
import ContentPageHeader from '@/component/common/ContentPageHeader';
import './AdminAccountPage.scss'
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import AddMemberModal from '@/component/config/AddMemberModal';
import services from '@/services';
import {IMemberListSingleItem} from '@/pages/config/interface/AdminAccount';
import {HTTP_STATUS, SORT_ORDER} from '@/constants';
import { USER_ACTIONS, USER_RESOURCE } from '@/constants/roles';
import {formatDateTime, formatLongString, upperCaseFirstCharacter} from '@/utils';
import {cancelToken} from '@/http-common';
import BaseTable from '@/component/common/BaseTable';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import {
  setIsUserClickClickableColumn,
  setIsUserClickResendButton,
  setNoti,
  setSelectedId,
  setShowDeleteModal
} from '@/redux/slice/commonListPageSlice';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import useDebounce from '@/hooks/useDebounce';
import {useSearchParams} from 'react-router-dom';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';
import UpdateUserRoleModal from '@/component/config/UpdateUserRoleModal';
import {ISelectedAccount} from '@/component/config/interface/UpdateUserRoleModal';
import {defaultSelectedAccount} from '@/component/templates/form/UpdateUserRole';
import {setHeaderPage} from '@/redux/slice/common';
import usePreviousValue from '@/hooks/usePreviousValue';

export default function AdminAccountPage(): JSX.Element {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('Config'));

  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  const updateRoleUserPermission = userPermissions.find((permission: {action: string, resource: string, licensed: boolean}) => permission.action === 'edit' && permission.resource === 'role');
  
  const isUserClickClickableColumn = useSelector((state: RootState) => state.commonListPageReducer.isUserClickClickableColumn);
  const isUserClickResendButton = useSelector((state: RootState) => state.commonListPageReducer.isUserClickResendButton);

  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);

  const [searchValue, setSearchValue] = useState<string>('');
  const [showModalAddAccountModal, setShowAddAccountModal] = useState<boolean>(false);
  const [showUpdateRoleUserModal, setShowUpdateRoleUserModal] = useState<boolean>(false);
  const [listData, setListData] = useState<IMemberListSingleItem[]>([]);
  // const [listRoles, setListRoles] = useState<{label: string, value: string}[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<ISelectedAccount>(defaultSelectedAccount);
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);
  const [queryData, setQueryData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userListPermissions, setUserListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = [
    {
      label: 'name',
      value: 'userName',
      width: '352px'
    },
    {
      label: 'email',
      value: 'email',
      hasBlueText: true,
      width: '352px'
    },
    {
      label: 'role',
      value: 'role',
      clickable: true,
      width: '352px'
    },
    {
      label: 'created at',
      value: 'createdAt',
      width: '352px'
    }
  ];
  
  // Effect for API call
  useEffect(
    () => {
      if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
        setQueryData({
          ...queryData,
          page: 1
        });
        handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
        setPaginationData({
          ...paginationData,
          ...defaultBasePagination
        });
        getListMembers()
      }

    }, [debouncedSearchTerm] ) // Only call effect if debounced search term changes);


  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleClickSearch = () => {
    console.log('value', searchValue);
  };
  
  const handleCloseAddAccountModal = () => {
    setShowAddAccountModal(false);
  }
  
  const handleCloseUpdateRoleUserModal = () => {
    setShowUpdateRoleUserModal(false);
    dispatch(setIsUserClickClickableColumn(false));
    dispatch(setSelectedId(''));
  }

  const addSuccess = (message: string) => {
    dispatch(setNoti({
      open: true,
      message: message,
      icon: <CheckSuccessIcon />,
    }))
  }
  
  const handleShowModal = () => {
    setShowAddAccountModal(true);
  };
  
  const getListMembers = async () => {
    setIsLoading(true)
    const queryPage = searchParams.get('page');
    const queryLimit = searchParams.get('limit')
    const query = {
      search: searchValue.trim(),
      page: queryData?.page || queryPage || defaultBasePagination.currentPage,
      limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
    }
    try {
      const res = await services.getListAllMembers(query);
      console.log('res1', res);

      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;
        const listItems = data.map((item: any) => {
          const createdAt = formatDateTime(item.createdAt);
          const userName = formatLongString(item.userName, 100);

          const convertedRoleLabel = item.roles.map((role: {name: string, code: string}) => role.name);

          return {
            id: item.id,
            createdAt,
            userName,
            email: item.email,
            role: convertedRoleLabel,
            roleValue: item.roles,
            isActive: item.isActive
          }
        });
        setListData(listItems);
        
        const meta = res.data.data.meta;

        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
          totalPages: meta.totalPages
        })
      }
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      console.log('error', err);
    }
  }
  
  // const getListRolesUser = async () => {
  //   try {
  //     const res = await services.getListRoles();
  //     if (res && res.status === HTTP_STATUS.SUCCESS) {
  //       const data = res.data.data;
  //       const convertedData = data.map((item: {id: string, code: string}) => ({
  //         label: upperCaseFirstCharacter(item.code),
  //         value: item.id
  //       }));
  //       setListRoles(convertedData);
  //     }
  //   } catch (err: any) {
  //     console.log('error', err);
  //   }
  // };
  
  useEffect(() => {
    const permissions = getListPermissionsByResource(userPermissions, USER_RESOURCE.USER);
    setUserListPermissions(permissions);
    console.log('permissions', permissions)
    getListMembers();
    // getListRolesUser();
    return () => cancelToken()

  }, [queryData?.page, queryData?.limit]);
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    setSearchParams(searchParams);
    setQueryData(query)
  }

  const handleUpdateUserRole = async () => {
    if (isUserClickClickableColumn) {
      const selectedAccount = listData.find((item: any) => item.id === selectedId);
      if (selectedAccount) {
        setSelectedAccount((prev: any) => ({
          ...prev,
          email: selectedAccount.email,
          role: selectedAccount.roleValue,
          userName: selectedAccount.userName
        }))
        setShowUpdateRoleUserModal(true);
      }
    }
  }
  
  useEffect(() => {
    handleUpdateUserRole()
  }, [isUserClickClickableColumn])
    
  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }
  
  const handleSubmitDelete = async () => {
    try {
      const res =  await services.deleteMember(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        getListMembers();
        dispatch(setNoti({
          open: true,
          message: 'Delete Account Successful!',
          icon: <RemoveSuccessIcon />,
        }))
      }
    } catch (err: any) {
      console.log('error', err);
    }
  }

  const handleResendVerifyEmail = async () => {
    try {
      console.log('selectedId', selectedId)
      const res = await services.resendVerifyEmail(selectedId);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setNoti({
          open: true,
          message: 'Resend Email Successfully!',
          icon: <CheckSuccessIcon />,
        }))
      }
    } catch (err: any) {
      console.log('error', err);
      const { message } = err?.response?.data
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Resend Email Fail!',
      }))
    }
    dispatch(setIsUserClickResendButton(false));
  }
  
  useEffect(() => {
    if (isUserClickResendButton) {
      handleResendVerifyEmail();
    }
  }, [isUserClickResendButton])
  
  return (
    <div className='admin-account-page'>
      <ContentPageHeader header={'Admin Account'} />
        
      <div>
        <div className="btn-container">
          <div className="flex-left">
            <div className="flex-left-input">
              <InputSearchField
                value={searchValue}
                inputPlaceholder={'Search...'}
                inputName={'search'}
                handleChange={handleChangeSearch}
                handleClickSearch={handleClickSearch}
                handleClearSearch={handleClickSearch}
              />
            </div>
          </div>

          {checkPermission(userListPermissions, USER_ACTIONS.CREATE) && (
            <div>
              <div className='flex-right'>
                <ButtonIcon
                  buttonLabel={'Add Member'}
                  handleClick={handleShowModal}
                />
              </div>
            </div>)}
        </div>
      </div>
        
      <BaseTable
        headers={tableHeaders}
        dataTable={listData}
        isEditRowTable={false}
        showResendButton={checkPermission(userListPermissions, USER_ACTIONS.CREATE)}
        showEditIcon={false}
        showDeleteIcon={checkPermission(userListPermissions, USER_ACTIONS.DELETE)}
        type='account'
        isLoading={isLoading}
      />

      <BasePagination
        currentPage={paginationData.currentPage}
        itemsPerPage={paginationData.itemsPerPage}
        totalItems={paginationData.totalItems}
        totalPages={paginationData.totalPages}
        handleChangePage={handleChangePage}
      />

      {checkPermission(userListPermissions, USER_ACTIONS.CREATE)
          && showModalAddAccountModal
          && (
            <AddMemberModal
              showModal={showModalAddAccountModal}
              handleCancel={handleCloseAddAccountModal}
              getListMembers={getListMembers}
              addSuccess={addSuccess}
            />
          )}

      {updateRoleUserPermission
          && showUpdateRoleUserModal
          && (
            <UpdateUserRoleModal
              id={selectedId}
              showModal={showUpdateRoleUserModal}
              handleCancel={handleCloseUpdateRoleUserModal}
              getListMembers={getListMembers}
              addSuccess={addSuccess}
              selectedAccount={selectedAccount}
            />
          )
      }

      <DeleteItemModal
        showModal={showDeleteModal}
        label={'Are you sure you want to remove this'}
        handleCancel={handleCancelDelete}
        handleSubmitDelete={handleSubmitDelete}
        deletedValueItem={selectedItem?.email}
        isDeleteSpecial={true}
        deletedValueType={'account'}
      />
    </div>
  );
}
