import React from 'react';
import { Select } from 'antd';
import './SelectBox.scss';

const SelectBoxCustom: React.FC<any> = ({
  listItems,
  handleChange, 
  defaultValue,
  placeholder,
  disabled
}) => {
  const dValue = defaultValue !== null ? defaultValue : null
  console.log('dValue', dValue);
  
  return (
    <div className='select-box-container'>
      <Select
        size='large'
        defaultValue={dValue}
        options={listItems}
        placeholder={placeholder}
        className='select-box'
        onChange={handleChange}
        disabled={disabled}
        allowClear
      />
    </div>
  );
};

SelectBoxCustom.defaultProps = {
  disabled: false,
}

export default SelectBoxCustom;