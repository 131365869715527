import React, {useEffect, useState} from 'react';

import DeleteTableRowIcon from '@/component/icon/DeleteTableRowIcon';
import SubmitTableRowIcon from '@/component/icon/SubmitTableRowIcon';
import CancelSubmitTableRowIcon from '@/component/icon/CancelSubmitTableRowIcon';
import InputTextField from '@/component/common/InputTextField';
import SelectBox from '@/component/common/SelectBox';
import {
  setIsUserClickSubmitEditIcon, setSearchValue,
  setSelectedId, setSelectedIds, setSelectedItem,
  setSelectedUpdateId,
  setShowDeleteModal
} from '@/redux/slice/commonListPageSlice';
import {useDispatch, useSelector} from 'react-redux';
import {ITableEditRow} from '@/component/common/interface/TableEditRow';
import ErrorMessage from '../../ErrorMessage';
import MultipleSearchSelect from '@/component/common/MutipleSearchSelect';
import {HTTP_STATUS, STATUSES_LIST, SUBSCRIPTION_LIST, TEMPLATE_ORDER_PRIORITY, TEMPLATE_PLATFORM} from '@/constants';
import BaseTextArea from '@/component/common/TextArea';
import {checkRequiredField, formatLongString} from '@/utils';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RootState } from '@/redux/rootReducer';
import { useSearchParams } from 'react-router-dom';
import {TableMultipleDataColumn} from '@/component/common/BaseTable/TabelReadOnlyRow';
import category from '@/services/category.service';
import { clean } from '@/utils/helper';

const TableEditRow: React.FC<ITableEditRow> = ({
  id,
  headers,
  item,
  allowSelection,
  isShowHeaderAction,
  showDeleteIcon,
  type,
}) => {
  const getSelectedEditCategoriesItems = (selectedCategories: any[]) => {
    if (selectedCategories) {
      const result = selectedCategories.map((item: any) => ({
        value: item.id ? item.id : item.value,
        label: item.name ? item.name : item.label
      }));
      return result;
    } else return [];
  };

  const dispatch = useDispatch()
  const [editItem, setEditItem] = useState<any>(item);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [selectedMultipleValues, setSelectedMultipleValues] = useState<any>(getSelectedEditCategoriesItems(item.category));
  const selectedIds = useSelector((state: RootState) => state.commonListPageReducer.selectedIds);

  const multipleValueKeyArr = headers.filter((headerItem: any) => headerItem.multipleValueKey).map((item: any) => item.multipleValueKey);

  const [listMultipleValueArr, setListMultipleValueArr] = useState<any[]>(multipleValueKeyArr.map((key: string) => ({
    key,
    items: item[key]
  })));

  const [searchParams] = useSearchParams();
  
  // useEffect(() => {
  //   if (item?.category) {
  //     setEditItem({
  //       ...editItem,
  //       category: item.category.map((item: any) => item.id)
  //     })
  //   }
  // }, [item?.category])
  
  const convertSelectedItems = (selectedItems: {id: string, name: string}[]) => {
    let result: any []
    if (selectedItems.length > 0) {
      result = selectedItems.map((item: {id: string, name: string}) => ({label: item.name, value: item.id}));
    } else result = [];
    return result;
  };
  
  const getSelectedData = (key: string) => {
    const specificData = listMultipleValueArr.find((value: {key: string, items: any[]}) => value.key === key)?.items;
    const result = convertSelectedItems(specificData);
    return result;
  }

  // useEffect(() => {
  //   if (item?.category) {
  //     setEditItem({
  //       ...editItem,
  //       category: item.category.map((item: any) => item.id)
  //     })
  //   } else if (item?.properties) {
  //     setEditItem({
  //       ...editItem,
  //       properties: item.properties.map((item: any) => item.id)
  //     })
  //   }
  // }, [item?.category, item?.properties])
    
  const handleSearchData = async (value: string, column: string, service: any, page = 1) => {
    try {
      const query: any = {limit: 10, orderBy: 'createdAt', page};
      if (value) query.search = value;
      const res = await service(query);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        return res
      }

    } catch (err: any) {
      setListMultipleValueArr([]);
      return []
    }
  }
  
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (event.target.name === 'no') {
      inputValue = event.target.value.replace(/\D/g, '');
    }
    setEditItem({
      ...editItem,
      [event.target.name]: inputValue
    })

  }
  
  const handleChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setEditItem({
      ...editItem,
      [event.target.name]: inputValue
    })
  }
  
  const handleChangeSelectData = (value: string[]) => {
    setSelectedMultipleValues(value)
  }
  
  
  const handleChangeSelect = (value: string, column: string) => {
    let field = column
    if (type === 'template' && column === 'status') {
      field = platformQuery()
    }

    if (type === 'content' && column === 'status') {
      field = platformContentQuery()
    }

    setEditItem({
      ...editItem,
      [field]: value
    })
  }
  const queryPriority = searchParams.get('priority');
  const platform = searchParams.get('platform');
  
  const platformQuery = () => {
    let result = `${TEMPLATE_PLATFORM.IOS}Status`;
    switch (queryPriority) {
    case TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY:
      result = `${TEMPLATE_PLATFORM.IOS}Status`
      break;
    case TEMPLATE_ORDER_PRIORITY.ANDROID_PRIORITY:
      result = `${TEMPLATE_PLATFORM.ANDROID}Status`
      break;
    case TEMPLATE_ORDER_PRIORITY.WEB_PRIORITY:
      result = `${TEMPLATE_PLATFORM.WEB}Status`
      break;
    }
    return result;
  }

  const platformContentQuery = () => {
    let result = `${TEMPLATE_PLATFORM.IOS}Status`;
    switch (platform) {
    case TEMPLATE_PLATFORM.IOS:
      result = `${TEMPLATE_PLATFORM.IOS}Status`
      break;
    case TEMPLATE_PLATFORM.ANDROID:
      result = `${TEMPLATE_PLATFORM.ANDROID}Status`
      break;
    case TEMPLATE_PLATFORM.WEB:
      result = `${TEMPLATE_PLATFORM.WEB}Status`
      break;
    }
    return result;
  }

  const getDefaultSelectValue = (value: string, listItems: any[]) => {
    const defaultValue = listItems.find((item: any) => item.value == value);
    return defaultValue;
  }

  const handleClickDeleteItem = () => {
    dispatch(setSelectedId(id));
    dispatch(setShowDeleteModal(true))
    dispatch(setSelectedItem(editItem))
  }
  
  const handleClickCancelEditItem = () => {
    dispatch(setSelectedUpdateId(''))
    dispatch(setSelectedItem(null))
  }
  
  const handleClickSubmitEditIcon = () => {
    const keyValidates = headers.filter(header => header.editable && header.required)
    
    const errorMsg: any = {};
    keyValidates.forEach(item => {
      if (item.value === 'templateVipType' || item.value === 'status') {
        if (editItem[item.value] < 0 || editItem[item.value] === null || editItem[item.value] === '')
          errorMsg[item.value] = 'This field cannot be empty.'
      } else if (item.value === 'category') {
        if (!selectedMultipleValues.length)
          errorMsg[item.value] = 'This field cannot be empty.'
      } else {
        errorMsg[item.value] = checkRequiredField(editItem[item.value])
      }
    })

    let uploadCategory;

    if (typeof selectedMultipleValues[0] === 'string') {
      uploadCategory = selectedMultipleValues;
    } else {
      uploadCategory = selectedMultipleValues.map((item: any) => item.value)
    }

    editItem.category = selectedMultipleValues;
    const uploadItem = {
      ...editItem,
      category: uploadCategory
    }

    clean(errorMsg)
    setErrorMessage(errorMsg);
    if (!Object.keys(errorMsg).length) {
      dispatch(setSelectedUpdateId(id));
      dispatch(setIsUserClickSubmitEditIcon(true));
      dispatch(setSelectedItem(uploadItem))
    }
  }
  
  const onChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    if (checked) {
      dispatch(setSelectedIds([...selectedIds, id]))
    } else {
      const ids = selectedIds.filter((selectedId: string) => selectedId !== id)
      dispatch(setSelectedIds(ids))
    }
  };

  const getColumnClassName = (isBlueText = false) => {
    if (isBlueText) {
      return 'blue-text'
    } else return 'normal-text'
  }
  
  const generateStatusColumnData = (data = '') => {
    const listStatuses = STATUSES_LIST;
    const result = {
      data: '',
      dotColor: ''
    }
    const specificStatus =  listStatuses.find((item: {value: string, label: string, color: string}) => item.value == data);
    result.data = specificStatus?.label || listStatuses[0].label;
    result.dotColor = specificStatus?.color || listStatuses[0].color
    
    return result;
  }
  
  const generateColumnData = (headerColumnValue: any, itemData: any) => {
    let result: any = '';
    if (headerColumnValue.value === 'templateVipType') {
      result = SUBSCRIPTION_LIST.find(s => s.value == itemData[headerColumnValue.value])?.label
    } else if (headerColumnValue.isCutText) {
      result = formatLongString(itemData[headerColumnValue.value], headerColumnValue.maximumTextLength)
    } else if (headerColumnValue.isMultipleValueColumn) {
      return (<TableMultipleDataColumn itemsData={item[headerColumnValue.value]} isFullTextTag={headerColumnValue.isFullTextTag}/>)
    } else {
      result = itemData[headerColumnValue.value];
    }

    return result;
  }

  return (
    <>
      {allowSelection && <td className='table-body-column' align='center'>
        <Checkbox onChange={onChange} checked={selectedIds.includes(id)}/>
      </td>}
      {headers.map((itemHeaderColumn, indexHeader: number) => {
        return (
          <td
            key={indexHeader}
            className={'table-body-column'}
            style={{maxWidth: `${itemHeaderColumn.width}`, wordWrap: 'break-word'}}
          >
            {itemHeaderColumn.editable
              ? (
                <>
                  {itemHeaderColumn.editType === 'input' && itemHeaderColumn.value === 'no' && (
                    <>
                      <InputTextField 
                        value={editItem[itemHeaderColumn.value]}
                        inputType={'text'} 
                        inputPlaceholder={`Enter ${itemHeaderColumn.value}`}
                        inputName={itemHeaderColumn.value} 
                        handleChange={handleChangeInput}
                        isInputInvalid={!!errorMessage?.no}
                        maxLength={itemHeaderColumn.maximumTextLength}
                      />
                      <ErrorMessage errorMessage={errorMessage?.no}/>
                    </>
                  )}

                  {itemHeaderColumn.editType === 'input' && itemHeaderColumn.value !== 'no' && (
                    <>
                      <InputTextField
                        value={editItem[itemHeaderColumn.value]}
                        inputType={'text'}
                        inputPlaceholder={`Enter ${itemHeaderColumn.value}`}
                        inputName={itemHeaderColumn.value}
                        handleChange={handleChangeInput}
                        isInputInvalid={!!errorMessage?.name}
                        maxLength={itemHeaderColumn.maximumTextLength}
                      />
                      <ErrorMessage errorMessage={errorMessage?.[itemHeaderColumn.value]}/>
                    </>
                  )}
                    
                  {itemHeaderColumn.editType === 'singleSelect' && (
                    <>
                      <SelectBox
                        defaultValue={getDefaultSelectValue(editItem[itemHeaderColumn.value], itemHeaderColumn.listItems)}
                        listItems={itemHeaderColumn.listItems}
                        handleChange={(value: string) => handleChangeSelect(value, itemHeaderColumn.value)}
                        disabled={item.templateCount === 0}
                      />
                    </>
                  )}

                  {itemHeaderColumn.editType === 'textarea' && (
                    <BaseTextArea 
                      handleChange={handleChangeTextArea} 
                      name={itemHeaderColumn.value} 
                      placeholder={`Enter ${itemHeaderColumn.value}`} 
                      value={editItem[itemHeaderColumn.value]}
                    />
                  )}

                  {itemHeaderColumn.editType === 'multipleSelect' && (
                    <>
                      <MultipleSearchSelect
                        placeholder={`Enter ${itemHeaderColumn.value}`}
                        defaultValue={selectedMultipleValues}
                        handleChange={handleChangeSelectData}
                        fetchListOptions={(value: string, page?: number) => handleSearchData(value, itemHeaderColumn.value, itemHeaderColumn.itemService, page)}
                        maxSelectedOption={itemHeaderColumn.maxValueLength}
                        isSelectInvalid={!!errorMessage?.category}
                      />
                      <ErrorMessage errorMessage={errorMessage?.category}/>
                    </>
                  )}
                </>
              )
              : (
                <>
                  {itemHeaderColumn.isStatusColumn
                    ? (
                      <span className='table-status-column'>
                        <span 
                          className='dot'
                          style={{background: generateStatusColumnData(item[itemHeaderColumn.value]).dotColor }}
                        />
                        {generateStatusColumnData(item[itemHeaderColumn.value]).data}
                      </span>
                    )
                    : (
                      <>
                        {itemHeaderColumn.isImageColumn
                          ? (
                            <img src={item[itemHeaderColumn.value]} alt='thumbnail' className='image'/>
                          )
                          : (
                            <span className='normal-text'>
                              {/*{itemHeaderColumn.value === 'templateVipType' ? SUBSCRIPTION_LIST.find(s => s.value == editItem[itemHeaderColumn.value])?.label : editItem[itemHeaderColumn.value]}*/}
                              {generateColumnData(itemHeaderColumn, item)}

                            </span>
                          )
                        }
                      </>

                    )
                  }
                </>
              )
            }
          </td>
        );
      })}
      {!isShowHeaderAction && <td className='table-body-column'>
        <span className="action-column">
          <span className='submit-cancel-btn'>
            <span className='submit-icon' onClick={() => handleClickSubmitEditIcon()}>
              <SubmitTableRowIcon />
            </span>
            <span onClick={() => handleClickCancelEditItem()} className='cancel-submit-icon'>
              <CancelSubmitTableRowIcon />
            </span>
          </span>

          {showDeleteIcon && <span
            className={'delete-icon'}
            onClick={() => handleClickDeleteItem()}
          >
            <DeleteTableRowIcon/>
          </span>}
        </span>


      </td>}
    </>
  );
};

TableEditRow.defaultProps = {
  isShowHeaderAction: false,
  showDeleteIcon: true
}

export default TableEditRow;
