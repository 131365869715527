import React, {useState} from 'react';
import {Modal} from 'antd';

import './UpdateUserRoleModal.scss'
import BaseButton from '@/component/common/Button';
import ErrorMessage from '@/component/common/ErrorMessage';

import services from '@/services';
import {HTTP_STATUS} from '@/constants';
import CloseModalIcon from '@/component/icon/CloseModalIcon';
import {IUpdateRoleUserModal} from '@/component/config/interface/UpdateUserRoleModal';
import MultipleSearchSelect from '@/component/common/MutipleSearchSelect';

const HeaderModal = () => {
  return (<span className='header-modal'>Role(s)</span>)
}

const UpdateUserRoleModal: React.FC<IUpdateRoleUserModal> = ({
  id,
  showModal,
  handleCancel,
  getListMembers,
  selectedAccount,
  addSuccess
}) => {
  const getSelectedUserRole = (roles: {name: string, code: string}[]) => {
    return roles.map((role: {name: string, code: string}) => role.name);
  };
  
  const [formUpdateUserRole, setFormUpdateUserRole] = useState<string[]>(getSelectedUserRole(selectedAccount.role));
  const [updateUserRoleErrorMessage, setUpdateUserRoleErrorMessage] = useState<string>('');

  const getListRolesUser = async () => {
    try {
      const res = await services.getListRoles();
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        return res;
      }
    } catch (err: any) {
      console.log('error', err);
      return [];
    }
  }; 
  
  const handleSelectRole = (value: string[]) => {
    if (value.length < 2){
      setFormUpdateUserRole(value);
    }
  }

  const handleUpdateUserRole = async (formData: string[]) => {
    try {
      const payload = {
        rolesId: formData,
        userId: id
      }
      const res =  await services.updateUserRole(id, payload);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        await getListMembers();
        await handleCancel();
        addSuccess('Update role user successfully!');
      }
    } catch (err: any) {
      console.log('error', err)
    }
  };

  const handleSubmit = () => {
    setUpdateUserRoleErrorMessage('');
    if (formUpdateUserRole.length === 0) {
      setUpdateUserRoleErrorMessage('This field cannot be empty.');
    } else {
      handleUpdateUserRole(formUpdateUserRole)
    }
  };

  return (
    <>
      <Modal
        title={<HeaderModal/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='update-user-role-modal-container'
        footer={[
          <div key='1' className='modal-footer-btn'>
            <div className='cancel-btn'>
              <BaseButton
                buttonLabel={'Cancel'}
                handleClick={handleCancel}
              />
            </div>

            <div className='submit-btn'>
              <BaseButton
                buttonLabel={'Update Role'}
                handleClick={handleSubmit}
                type='primary'
              />
            </div>
          </div>
        ]}
      >
        <div className='form'>
          <p className='username'>{selectedAccount.userName}</p>
          <p className='label'>{selectedAccount.email}</p>

          <div className='update-role-select'>
            <p className='label'>Role <span>*</span>  </p>

            <MultipleSearchSelect
              fetchListOptions={getListRolesUser}
              placeholder={'Enter role'}
              defaultValue={formUpdateUserRole}
              handleChange={handleSelectRole}
              isSelectInvalid={!!updateUserRoleErrorMessage}
            />
            <ErrorMessage errorMessage={updateUserRoleErrorMessage}/></div>

        </div>
      </Modal>
    </>  );
};

export default UpdateUserRoleModal;