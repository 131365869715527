import React, {useState, useMemo} from 'react';
import {Select, Spin} from 'antd';
import {IMultipleSearchSelect} from '@/component/common/interface/MultipleSearchSelect';
import './MultipleSearchSelect.scss';
import {HTTP_STATUS} from '@/constants';

const MultipleSearchSelect: React.FC<IMultipleSearchSelect> = ({
  placeholder, 
  defaultValue,
  handleChange,
  fetchListOptions,
  isSelectInvalid,
  maxSelectedOption = 10
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');

  const [fetching, setFetching] = useState<boolean>(false);
  const [isFetchingAllData, setIsFetchingAllData] = useState<boolean>(false);
  const [listItems, setListItems] = useState<any[]>([]);
  
  const onFocus = async () => {
    if (!isFetchingAllData) {
      const res = await fetchListOptions('', 1);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        let data;
        if (res.data.data.items) {
          data = res.data.data.items;
        } else {
          data = res.data.data;
        }
        if (data.length > 0) {
          const listIData: any = data.map((item: any) => ({
            label: item.name ? item.name : item.key,
            value: item.id
          }));
          
          setListItems(listIData);

          if (res.data.data.meta) {
            const meta = res.data.data.meta;
            const current = meta.currentPage;
            const total =  meta.totalPages;
            setCurrentPage(current);
            setTotalPages(total);
            if (currentPage === totalPages) {
              setIsFetchingAllData(true);
            }
          } else setIsFetchingAllData(true)

        }
        
      }
      
    }
  };
  
  const onSearch = async (value: string) => {
    setSearchValue(value);
    setListItems([]);

    const res = await fetchListOptions(value);

    if (res.status === HTTP_STATUS.SUCCESS) {
      const data = res.data.data.items;

      if (data.length > 0) {
        const listIData: any = data.map((item: any) => ({
          label: item.name,
          value: item.id
        }));

        setListItems(listIData);
      }

    }
  }

  const onChange = async (value: any) => {
    if (value.length <= maxSelectedOption ) {
      handleChange(value);

      const res = await fetchListOptions('');

      if (res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data.items;

        if (data.length > 0) {
          const listIData: any = data.map((item: any) => ({
            label: item.name,
            value: item.id
          }));

          setListItems(listIData);
        }

      }
    }
  };

  const onBlur = () => {
    setSearchValue('');
    setListItems([]);
    setIsFetchingAllData(false);
  }
  
  const onPopupScroll =async (e: any) => {
    console.log('onPopupScroll')
    // console.log('selectRef', selectRef.current)
    // console.log('check run here', currentPage)
    const { target } = e;
    // console.log('target.scrollTop', target.scrollTop);
    // console.log('target.scrollHeight', target.scrollHeight);
    const condition = (target as any).scrollTop + (target as any).offsetHeight ===
          (target as any).scrollHeight;
    if (condition) {
      if (currentPage < totalPages) {
        setFetching(true);

        const res =  await fetchListOptions(searchValue, currentPage + 1);

        if (res.status === HTTP_STATUS.SUCCESS) {
          const data = res.data.data.items;

          if (data.length > 0) {
            setCurrentPage(currentPage + 1);
            
            const listIData: any = data.map((item: any) => ({
              label: item.name,
              value: item.id
            }));

            const newListData = listItems.concat(listIData);
            // setScrollHeight(target.scrollTop);

            setListItems(newListData);
            

          }
          
        }
        setFetching(false);
      }
    }
  };


  return (
    <div className='multiple-search-select'>
      <Select
        mode="multiple"
        size='large'
        allowClear={true}
        defaultValue={defaultValue}
        className='select-box'
        onChange={onChange}
        placeholder={placeholder}
        options={listItems}
        onSearch={onSearch}
        filterOption={false}
        onBlur={onBlur}
        value={defaultValue}
        status={isSelectInvalid ? 'error' : ''}
        onFocus={onFocus}
        onPopupScroll={onPopupScroll}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {fetching && (
              <span className='loading-select-icon'>
                <Spin size="small" />
              </span>
            )}
          </div>
        )}
      />
    </div>
  );
};

MultipleSearchSelect.defaultProps = {
  isSelectInvalid: false
}

export default MultipleSearchSelect;
