import { CloseOutlined } from '@ant-design/icons';
import './index.scss';
const FilterItemList: React.FC<{ label: string, value: string, removeFilter: () => void}> = ({ label, value, removeFilter }) => {

  return (
    <div className='filter-item'>
      <div className='label'>{label}: </div>
      {Array.isArray(value)
        ? (
          <>
            {value.length > 0 && value.map((item: string, index: number) => {
              return (
                <div key={index} className='value multiple-value'>{ (index ? ', ' : '') + item}</div>
              );
            })
            }
          </>
        )
        : (<div className='value'>{value}</div>)
      }
      
      <CloseOutlined className='ic-close' onClick={removeFilter}/>
    </div>
  );
};

export default FilterItemList;
