import React, {useState, useEffect} from 'react';
import {
  IDetailTemplateEditMode,
  IDetailTemplateLErrorMessages,
  IDetailTemplateSingleItem
} from '@/pages/templates/interface/DetailTemplatePage';
import InputTextField from '@/component/common/InputTextField';
import SelectBox from '@/component/common/SelectBox';
import MultipleSearchSelect from '@/component/common/MutipleSearchSelect';
import ErrorMessage from '@/component/common/ErrorMessage';
import './DetailTemplateEditMode.scss'
import {defaultDetailTemplateErrorMessages} from '@/component/templates/form/EditDetailTemplate';
import {checkMinLength, checkRequiredField, checkValidatePriority} from '@/utils';
import services from '@/services';
import {HTTP_STATUS, MIN_LENGTH_CATEGORY_NAME, SUBSCRIPTION_LIST} from '@/constants';

import { useDispatch } from 'react-redux';
import {setIsAllowUpload, setTemplateData} from '@/redux/slice/template';

const DetailTemplateEditMode: React.FC<IDetailTemplateEditMode> = ({
  item,
  statusesList,
  userClickSubmitButtonsTimes
}) => {
  const convertSelectedCategories = (selectedCategories: {id: string, name: string}[]) => {
    const result = selectedCategories.map((item: {id: string, name: string}) => ({value: item.id, label: item.name}));
    return result;
  };

  console.log('item', item)

  const [templateInfo, setTemplateInfo] = useState<IDetailTemplateSingleItem>(item);
  const [selectedCategoriesValues, setSelectedCategoriesValues] = useState<any[]>(convertSelectedCategories(item.categories));
  const [errorMessages, setErrorMessages] = useState<IDetailTemplateLErrorMessages>(defaultDetailTemplateErrorMessages);
  const dispatch = useDispatch();

  console.log('selectedCategoriesValues', selectedCategoriesValues)

  const getSelectedStatus = (status: string) => {
    const result = statusesList.find((item: {label: string, value: string}) => item.value === status);
    return result;
  }

  const getSelectedSubscription = (type: number | undefined) => {
    const result = SUBSCRIPTION_LIST.find((item: {label: string, value: number}) => item.value == type);
    return result;
  }
  
  const handleChangeFormTemplateInfo = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let inputValue = event.target.value;
    if (event.target.name.includes('Priority')) {
      inputValue = event.target.value.replace(/\D/g, '');
    }
    setTemplateInfo({
      ...templateInfo,
      [event.target.name]: inputValue
    });
  };
  
  const handleSelectStatus = (value: any, key: string) => {
    setTemplateInfo({
      ...templateInfo,
      [key]: value
      // status: status
    })
  }
  const handleSelectSubscription = (type: any) => {
    setTemplateInfo({
      ...templateInfo,
      templateVipType: type
    })
  }
  
  const handleChangeSelectCategories = (category: string[]) => {
    console.log('category selected', category)
    setSelectedCategoriesValues(category);
  }
  
  const handleCheckValidateDetailTemplate = (formData: IDetailTemplateSingleItem) => {
    const errorMessages: IDetailTemplateLErrorMessages = defaultDetailTemplateErrorMessages;

    errorMessages.name = checkRequiredField(formData.name);
    errorMessages.iosPriority = checkValidatePriority(formData.iosPriority);
    errorMessages.androidPriority = checkValidatePriority(formData.androidPriority);
    errorMessages.webPriority = checkValidatePriority(formData.webPriority);

    return errorMessages;
  };

  const onSubmit = () => {
    if (userClickSubmitButtonsTimes !== 0) {
      const errorMsg = handleCheckValidateDetailTemplate(templateInfo);
      setErrorMessages((prev: IDetailTemplateLErrorMessages) => ({
        ...prev,
        ...errorMsg
      }));
      let errorCount = 0;
      let key: keyof IDetailTemplateLErrorMessages;
      for (key in errorMsg) {
        if (errorMsg[key] !== '') errorCount ++;
      }

      if (errorCount === 0) {
        let uploadCategoriesValues;
        if (typeof selectedCategoriesValues[0] === 'string') {
          uploadCategoriesValues = selectedCategoriesValues
        } else {
          uploadCategoriesValues = selectedCategoriesValues.map((item: any) => item.value);
        }

        const formUpload = {
          name: templateInfo.name.trim(),
          webStatus: parseInt(templateInfo.webStatus),
          iosStatus: parseInt(templateInfo.iosStatus),
          androidStatus: parseInt(templateInfo.androidStatus),
          webPriority: parseInt(templateInfo.webPriority),
          iosPriority: parseInt(templateInfo.iosPriority),
          templateVipType: templateInfo.templateVipType,
          androidPriority: parseInt(templateInfo.androidPriority),
          thumbnailUrl: templateInfo.thumbnailUrl,
          videoPreviewUrl: templateInfo.videoPreviewUrl,
          categories: uploadCategoriesValues
        };

        dispatch(setTemplateData(formUpload));
        dispatch(setIsAllowUpload(true));
      }

    }
  }
  
  useEffect(() => {
    onSubmit();
  }, [userClickSubmitButtonsTimes]);

  const handleSearchCategories = async (value: string, page = 1) => {
    try {
      const query: any = {limit: 10, orderBy: 'createdAt', page};
      if (value) query.search = value
      const res = await services.searchListCategory(query);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        return res;
      }
        
    } catch (err: any) {
      return [];
    }
  }

  return (
    <div>
      <div className='detail-template-info-edit-mode'>
        <div className={`detail-item-container ${errorMessages.name ? 'align-items-start' : 'align-items-center'}`}>
          <div className='left-col'>Name:</div>
          <div className='right-col'>
            <InputTextField
              value={templateInfo.name}
              inputType={'text'}
              inputPlaceholder={'Enter template name'}
              inputName={'name'}
              handleChange={handleChangeFormTemplateInfo}
              maxLength={150}
              isInputInvalid={!!errorMessages.name}
            />
            <ErrorMessage errorMessage={errorMessages.name}/>
          </div>

        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Web Status:</div>
          <SelectBox listItems={statusesList} handleChange={(value: string) => handleSelectStatus(value, 'webStatus')} defaultValue={getSelectedStatus(item.webStatus)}/>
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>IOS Status:</div>
          <SelectBox listItems={statusesList} handleChange={(value: string) => handleSelectStatus(value, 'iosStatus')} defaultValue={getSelectedStatus(item.iosStatus)}/>
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Android Status:</div>
          <SelectBox listItems={statusesList} handleChange={(value: string) => handleSelectStatus(value, 'androidStatus')} defaultValue={getSelectedStatus(item.androidStatus)}/>
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Subscription:</div>
          <SelectBox listItems={SUBSCRIPTION_LIST} handleChange={handleSelectSubscription} defaultValue={getSelectedSubscription(templateInfo.templateVipType)}/>
        </div>
          
        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Resolution:</div>
          <InputTextField
            value={templateInfo.resolution}
            inputType={'text'}
            inputPlaceholder={'Enter resolution name'}
            inputName={'resolution'}
            handleChange={handleChangeFormTemplateInfo}
            disabled={true}
          />
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Duration:</div>
          <InputTextField
            value={templateInfo.duration}
            inputType={'text'}
            inputPlaceholder={'Enter duration name'}
            inputName={'duration'}
            handleChange={handleChangeFormTemplateInfo}
            disabled={true}
          />
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Category:</div>
          <MultipleSearchSelect
            placeholder={'Enter category'}
            defaultValue={selectedCategoriesValues}
            handleChange={handleChangeSelectCategories}
            fetchListOptions={handleSearchCategories}
          />
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Created by:</div>
          <InputTextField
            value={templateInfo.createdBy?.userName || ''}
            inputType={'text'}
            inputPlaceholder={'Enter created name'}
            inputName={'createdBy'}
            handleChange={handleChangeFormTemplateInfo}
            disabled={true}
          />
        </div>

        <div className='detail-item-container align-items-center'>
          <div className='left-col'>Created date:</div>
          <InputTextField
            value={templateInfo.createdDate}
            inputType={'text'}
            inputPlaceholder={'Enter created name'}
            inputName={'createdDate'}
            handleChange={handleChangeFormTemplateInfo}
            disabled={true}
          />
        </div>

        <div className={`detail-item-container ${errorMessages.webPriority ? 'align-items-start' : 'align-items-center'}`}>
          <div className='left-col'>Web priority:</div>
          <div className={'right-col'}>
            <InputTextField
              value={templateInfo.webPriority}
              inputType={'text'}
              inputPlaceholder={'Enter Web priority'}
              inputName={'webPriority'}
              handleChange={handleChangeFormTemplateInfo}
              isInputInvalid={!!errorMessages.webPriority}
            />
            <ErrorMessage errorMessage={errorMessages.webPriority}/>
          </div>
        </div>

        <div className={`detail-item-container ${errorMessages.iosPriority ? 'align-items-start' : 'align-items-center'}`}>
          <div className='left-col' >IOS priority:</div>
          <div className='right-col'>
            <InputTextField
              value={templateInfo.iosPriority}
              inputType={'text'}
              inputPlaceholder={'Enter IOS priority'}
              inputName={'iosPriority'}
              handleChange={handleChangeFormTemplateInfo}
              isInputInvalid={!!errorMessages.iosPriority}
            />
            <ErrorMessage errorMessage={errorMessages.iosPriority}/>
          </div>
        </div>

        <div className={`detail-item-container ${errorMessages.androidPriority ? 'align-items-start' : 'align-items-center'}`}>
          <div className='left-col'>Android priority:</div>
          <div className="right-col">
            <InputTextField
              value={templateInfo.androidPriority}
              inputType={'text'}
              inputPlaceholder={'Enter Android priority'}
              inputName={'androidPriority'}
              handleChange={handleChangeFormTemplateInfo}
              isInputInvalid={!!errorMessages.androidPriority}
            />
            <ErrorMessage errorMessage={errorMessages.androidPriority}/>
          </div>

        </div>
      </div>
    </div>

  );
};

export default DetailTemplateEditMode;