import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import services from '@/services';
import {HTTP_STATUS} from '@/constants';
import {USER_ACTIONS, USER_RESOURCE} from '@/constants/roles';
import {cancelToken} from '@/http-common';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import './DetailCategoryPage.scss';
import {defaultDetailCategoryForm} from '@/component/templates/form/DetailCategoryForm';
import {IDetailCategoryItem} from '@/pages/templates/interface/DetailCategoryPage';

import DetailCategoryEditMode from '@/component/templates/DetailCategoryPage/DetailCategoryEditMode';
import DetailCategoryReadOnlyMode from '@/component/templates/DetailCategoryPage/DetailCategoryReadOnlyMode';
import {formatDateTime} from '@/utils';

import TabsPane from '@/component/templates/Tabs';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import {useDispatch, useSelector} from 'react-redux';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import {usePrompt} from '@/hooks/usePrompt';
import {setHeaderPage, setIsUserAccessAnEditablePage} from '@/redux/slice/common';
import {RootState} from '@/redux/rootReducer';
import {checkPermission, getListPermissionsByResource} from '@/utils/permission';

export default function DetailCategoryPage(): JSX.Element {
  const {id} = useParams();
  const dispatch = useDispatch();
  
  dispatch(setHeaderPage('Category'));

  const userPermissions =  useSelector((state: RootState) => state.commonReducer.userInfo.userPermissions);
  
  const [categoryListPermissions, setCategoryListPermissions] = useState<{action: string, resource: string, licensed: boolean}[]>([]);
  const [itemData, setItemData] = useState<IDetailCategoryItem>(defaultDetailCategoryForm);
  const [originalData, setOriginalData] = useState<IDetailCategoryItem>(defaultDetailCategoryForm);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [isUserClickCancelButton, setIsUserClickCancelButton] = useState<boolean>(false);

  usePrompt('All the data will be lost! Are you sure you want to leave?', isEditMode);

  const goBackButtonHandler = () => {
    if (window.confirm('All the data will be lost! Are you sure you want to leave?')) {
      history.back();
      dispatch(setIsUserAccessAnEditablePage(false));
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  }

  const onBackButtonEvent = (event: any) => {
    if (isEditMode) {
      event.preventDefault();
      goBackButtonHandler();
    }
  }

  useEffect(() => {
    if (isEditMode) {
      window.history.pushState(null, '', window.location.pathname);

    }
    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate', onBackButtonEvent)

  }, [isEditMode]);
  
  const getDetailCategoryById = async () => {
    try {
      if (id) {
        const responseGetDetailCategory = await services.getDetailCategory(id);
        if (responseGetDetailCategory && responseGetDetailCategory.status === HTTP_STATUS.SUCCESS) {
          const data = responseGetDetailCategory.data.data;
          let convertProperties: any[];
          if (data.props.length > 0) {
            convertProperties = data.props.map((property: any) => ({
              id: property.id,
              name: property.key
            }));
          } else convertProperties = data.props
            
          const item = {
            name: data.name,
            description: data.description,
            priority: data.priority.toString(),
            createdAt: formatDateTime(data.createdAt),
            status: data.status.toString(),
            templateCount: data.templateCount.toString(),
            properties: convertProperties
          };
          setItemData(item);
          setOriginalData(item);
        }
      }
    } catch (err: any) {
      console.log('error', err);
    }
  }
  
  useEffect(() => {
    const permissions =  getListPermissionsByResource(userPermissions, USER_RESOURCE.CATEGORY);
    setCategoryListPermissions(permissions);
    getDetailCategoryById();
      
    return () => cancelToken();
  }, [])
    
  const handleCancel = () => {
    setItemData(originalData);
    setIsEditMode(false);
    setIsUserClickCancelButton(true);
    dispatch(setIsUserAccessAnEditablePage(false));
  }
  
  const showEditMode = () => {
    setIsEditMode(true);
    setIsUserClickCancelButton(false);
    dispatch(setIsUserAccessAnEditablePage(true));
  }
  
  const handleShowToastMessages = () => {
    if (isUpdateSuccess) {
      dispatch(setNoti({
        open: true,
        message: 'Updated Category Successfully!',
        icon: <CheckSuccessIcon />,
      }))
      setIsUpdateSuccess(false)
    }
  }
  
  useEffect(() => {
    handleShowToastMessages()
  }, [isUpdateSuccess])

  return (
    <div>
      <BackToListPageButton
        listPageUrl={'/template/category-list'}
        title={'Category List'}
      />
      <div className='detail-category-container'>
        <div className='detail-category-info'>
          {isEditMode 
            ? (
              <DetailCategoryEditMode
                id={id}
                item={itemData}
                isUserClickCancelButton={isUserClickCancelButton}
                setIsUserClickCancelButton={setIsUserClickCancelButton}
                setIsEditMode={setIsEditMode}
                setIsUpdateSuccess={setIsUpdateSuccess}
                getDetailCategoryById={getDetailCategoryById}
                handleCancel={handleCancel}
              />
            ) 
            : (
              <DetailCategoryReadOnlyMode 
                item={originalData}
                showEditMode={showEditMode}
                showEditButton={checkPermission(categoryListPermissions, USER_ACTIONS.EDIT)}
              />
            )
          }
        </div>
        <div className='detail-category-info'>
          <TabsPane categoryId={id} /> 
        </div>
      </div>
    </div>
  );
}
