import React from 'react';
import './index.scss';
import { IFormItem } from '../interface/FormItem';

const FormItem: React.FC<IFormItem> = ({ 
  label, 
  children,
  isRequired = false, 
  direction = 'vertical' 
}) => {
  return (
    <div className='item' style={{ flexDirection: direction === 'vertical' ? 'row' : 'column' }}>
      <label className='label' style={{ width: direction === 'vertical' ? '20%' : '100%' }}>
        {label} {isRequired && <label className='required'>*</label>}
      </label>
      <div className='form-item' style={{ width: direction === 'vertical' ? '80%' : '100%' }}>
        {children}
      </div>
    </div>
  );
};

export default FormItem;
