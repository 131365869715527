
/**
 * Prompts a user when they exit the page
 */

import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {getInitialKeyPath} from '@/redux/constant/common';
import {setIsUserAccessAnEditablePage, setRouteAndNavigate} from '@/redux/slice/common';

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const newArgs = {...args};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newPathName = newArgs[0].pathname
      const result = confirmExit();
      if (result !== false && newPathName) {
        const newKeyPath = getInitialKeyPath(newPathName).defaultKeyPath;
        const newSubKeyPath = getInitialKeyPath(newPathName).defaultSubKeyPath;
        const payload = {
          keyPath: newKeyPath,
          subKeyPath: newSubKeyPath
        };
        dispatch(setRouteAndNavigate(payload));
        dispatch(setIsUserAccessAnEditablePage(false));

        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(message: string, when = true) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  });

  const confirmExit = () => {
    const confirm = window.confirm(message);
    return confirm;
  };
  useConfirmExit(confirmExit, when);
}