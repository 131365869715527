import './index.scss'
import React from 'react';

const CardHeader = ({ label, isRequired }: { label: string, isRequired?: boolean}) => {
  return (
    <div className='title-card'>
      {label}
      {isRequired && <label className='required'>*</label>}
    </div>
  );
};

CardHeader.defaultProps = {
  isRequired: false
}

export default CardHeader;