import React from 'react';
import {Modal} from 'antd';
import BaseButton from '@/component/common/Button';
import CloseModalIcon from '@/component/icon/CloseModalIcon';
import UploadModalFailedIcon from '@/component/icon/UploadModalFailedIcon';
import UploadModalSuccessIcon from '@/component/icon/UploadModalSuccessIcon';
import {
  IUploadTemplateModal, IUploadTemplateModalHeader
} from '@/component/templates/interface/UploadTemplateModal';
import './UploadTemplateModal.scss';

const HeaderModal: React.FC<IUploadTemplateModalHeader> = ({isUploadSuccess}) => {
  return (
    <>
      {isUploadSuccess 
        ? <UploadModalSuccessIcon />
        : <UploadModalFailedIcon />
      }
    </>
  );
}

const UploadTemplateModal: React.FC<IUploadTemplateModal> = ({
  isUploadSuccess,
  showModal,
  handleCloseModal,
  handleUploadNewTemplate,
  label,
  newUploadedTemplateUrl
}) => {
    
  const onCancel = () => {
    if (isUploadSuccess) {
      handleUploadNewTemplate()
    } else {
      handleCloseModal()
    }
  }
  
  const reUpload = () => {
    if (isUploadSuccess) {
      handleUploadNewTemplate()
    } else {
      handleCloseModal()
    }
  }
    
  return (
    <>
      <Modal
        title={<HeaderModal isUploadSuccess={isUploadSuccess}/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={onCancel}
        className='upload-template-modal-container'
        footer={[
          <div key='1' className='modal-footer-btn'>
            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={isUploadSuccess ? 'Upload More' : 'Try Again'}
                htmlType={'submit'}
                type='primary'
                handleClick={reUpload}
              />
            </div>
          </div>
        ]}
      >
        <div className='label-container'>
          <div className='default-label'>
            {isUploadSuccess ? 'Upload successfully' : 'Upload failed to the system'}
          </div>
          <a 
            className={`sub-label ${isUploadSuccess ? 'sub-label-success' : 'sub-label-failed'}`} 
            href={newUploadedTemplateUrl}
            target={'_blank'} rel="noreferrer"
          >
            {label}
          </a>
        </div>
      </Modal>
    </>

  );
};

export default UploadTemplateModal;