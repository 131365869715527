import React, {useEffect, useState} from 'react';
import InputSearchField from '@/component/common/InputSearchField';
import ButtonIcon from '@/component/common/ButtonIcon';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {HTTP_STATUS, SORT_ORDER, STATUSES_LIST, TEMPLATE_ORDER_PRIORITY, TEMPLATE_PLATFORM} from '@/constants';
import BaseTable from '@/component/common/BaseTable';
import {checkPermission} from '@/utils/permission';
import {USER_ACTIONS} from '@/constants/roles';
import BasePagination from '@/component/common/Pagination';
import {IDefaultPagination} from '@/component/common/interface/BasePagination';
import {defaultBasePagination} from '@/component/common/constants/BasePagination';
import './EffectListContainer.scss';
import services from '@/services';
import {cancelToken} from '@/http-common';
import {IEffectContentSingleItem} from '@/pages/content/Effect/interface/EffectContentListPage';
import AdvancedFilter from '@/layouts/advancedFilter';
import FilterBar from '@/component/common/FilterBar';
import {IConfigFilter} from '@/component/common/interface/Filter';
import useDebounce from '@/hooks/useDebounce';
import {platformsOptions} from '@/component/constant/Filter';
import {convertDataFilterTags, formatDateTime, removeDataFilterTags} from '@/utils';
import {
  setIsUserClickSubmitEditIcon,
  setNoti,
  setSelectedId,
  setSelectedIds, setSelectedUpdateId,
  setShowDeleteModal
} from '@/redux/slice/commonListPageSlice';
import DeleteItemModal from '@/component/common/DeleteItemModal';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import RemoveSuccessIcon from '@/component/icon/RemoveIcon';
import EditItemModal from '@/component/common/EditItemModal';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import FilterItemList from '@/component/common/FilterItemList';
import {IEffectListContainer} from '@/component/content/interface/EffectListContainer';
import usePreviousValue from '@/hooks/usePreviousValue';

const EffectListContainer: React.FC<IEffectListContainer> = ({
  effectCategoryId = '',
  getDetailEffectCategoryData,
  activeKey = ''
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showDeleteModal = useSelector((state: RootState) => state.commonListPageReducer.showDeleteModal);
  const selectedId = useSelector((state: RootState) => state.commonListPageReducer.selectedId);
  const selectedItem = useSelector((state: RootState) => state.commonListPageReducer.selectedItem);
  const selectedUpdateId = useSelector((state: RootState) => state.commonListPageReducer.selectedUpdateId);
  const isUserClickSubmitEditIcon = useSelector((state: RootState) => state.commonListPageReducer.isUserClickSubmitEditIcon);
  
  const [searchValue, setSearchValue] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit ,setIsLoadingSubmit] = useState<boolean>(false);
  const [showEditItemModal, setShowEditItemModal] = useState<any>({ open: false, label: ''});

  const [filterDatas, setFilterDatas] = useState({});
  const [filterList, setFilterList] = useState<any>([]);
  const filterDatasJsonString = JSON.stringify(filterDatas);

  const [listData, setListData] = useState<IEffectContentSingleItem[]>([]);
  const [queryData, setQueryData] = useState<any>(null);
  const [paginationData, setPaginationData] = useState<IDefaultPagination>(defaultBasePagination);
  const [searchParams, setSearchParams] = useSearchParams();
  const platformPriority = searchParams.get('platform');
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const previousSearchValue: any = usePreviousValue(debouncedSearchTerm);

  const tableHeaders = [
    {
      label: 'NO',
      value: 'no',
      width: '120px',
      editType: 'input',
      required: true,
      editable: false,
      maximumTextLength: 50
    },
    {
      label: 'EFFECT NAME',
      value: 'name',
      hasBlueText: true,
      clickable: true,
      width: '300px',
      editable: true,
      required: true,
      editType: 'input',
      isCutText: true,
      maximumTextLength: 150
    },
    {
      label: 'PLATFORM',
      value: 'platform',
      width: '150px',
    },
    {
      label: 'CATEGORY',
      value: 'category',
      isMultipleValueColumn: true,
      multipleValueKey: 'category',
      width: '250px',
      editable: !effectCategoryId,
      required: true,
      editType: 'multipleSelect',
      itemService: services.getListEffectCategories,
      maxValueLength: 1
    },
    {
      label: 'CREATED BY',
      value: 'createdBy',
      width: '150px'
    },
    {
      label: 'CREATED AT',
      value: 'createdAt',
      width: '160px'
    },
    {
      label: 'THUMBNAIL',
      value: 'thumbnailUrl',
      width: '300px',
      isImageColumn: true,
    },
    {
      label: 'STATUS',
      value: 'status',
      width: '120px',
      isStatusColumn: true,
      editable: true,
      required: false,
      editType: 'singleSelect',
      listItems: STATUSES_LIST
    }
  ];

  const configFilters: IConfigFilter[] = [
    {
      label: 'Category',
      key: 'categories',
      type: 'mutiple-select',
      itemService: services.getListEffectCategories
    },
    {
      label: 'Platform',
      key: 'platforms',
      type: 'checkbox',
      checkBoxOptions: platformsOptions
    }
  ];
  
  const submitFilter = async (values: any) => {
    const filters = Object.assign(values, {})
    setFilterDatas({
      ...filters,
    })
    const filterListMap = convertDataFilterTags(configFilters, filters)
    const hasCategoryFilter = configFilters.filter((c: IConfigFilter) => c.key === 'category' || c.key === 'categories');
    if (hasCategoryFilter) {
      await Promise.all(filterListMap.map(async (item: any) => {
        if (item.key === 'categories') {
          const categoryMap  = await Promise.all(item.value.map(async (categoryItem: any) => {
            const res = await services.getDetailEffectCategory(categoryItem);
            if (res && res.data.statusCode === HTTP_STATUS.SUCCESS) {
              categoryItem = res.data.data.name;
            }
            return categoryItem;
          }))
          item.value = categoryMap.join(', ');
        } else if (item.key === 'platform') {
          item.value = item.value.join(', ')
        }
        return item;
      }))
    }
    setFilterList(filterListMap)
    setOpenDrawer(false)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setSearchValue(value);
  };
  
  const handleFilter = () => {
    setOpenDrawer(true);
  };
  
  const handleAddNewEffect = () => {
    navigate('./create-effect');
  }
  
  const handleChangePage = async (page: number, pageSize: number) => {
    const query = {
      orderType: SORT_ORDER.ASC,
      page: page,
      limit: pageSize,
    };
    searchParams.set('page', page.toString());
    searchParams.set('limit', pageSize.toString());
    setSearchParams(searchParams);
    setQueryData(query);
  }

  const removeFilter = (key: string, type: string) => {
    const newFilters = filterList.filter((item: any) => item.key !== key)
    setFilterList(newFilters)
    const newFilterDatas = removeDataFilterTags(filterDatas, type, key)
    setFilterDatas(newFilterDatas)
  }
  
  const generatePlatform = (data: any) => {
    const platforms = []
    if (data.webUrl) {
      platforms.push('Website')
    }
    if (data.iosUrl) {
      platforms.push('IOS')
    }
    if (data.androidUrl) {
      platforms.push('Android')
    }
    return (
      <ul>
        {platforms.map((platform: string) => {
          return (
            <li key={platform}>
              {platform}
            </li>
          )
        })}
      </ul>
    )
    
    
  }

  const getListAllEffects = async () => {
    setIsLoading(true);
    try {
      const queryPage = searchParams.get('page');
      const queryLimit = searchParams.get('limit');
      const query: any = {
        search: searchValue.trim(),
        page: queryData?.page || queryPage || defaultBasePagination.currentPage,
        limit: queryData?.limit || queryLimit || defaultBasePagination.itemsPerPage,
        ...filterDatas,
        orderBy: 'createdAt',
        orderType: SORT_ORDER.DESC
      };

      if (platformPriority || effectCategoryId) {
        query.platforms = platformPriority ?  [platformPriority] : [`${activeKey}`];
      }

      let res;
      if (effectCategoryId) {
        query.orderBy = 'createdAt'
        res = await services.getEffectByEffectCategory(effectCategoryId, query)
      } else {
        res = await services.getListEffects(query);
      }

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        const meta = res.data.data.meta;
        const listItems = data.items.map((item: any) => {
          const formattedCreatedAt = formatDateTime(item.createdAt);
          return {
            ...item,
            id: item.id,
            name: item.name,
            no: effectCategoryId ? item[platformPriority ? `${platformPriority}Priority` : TEMPLATE_ORDER_PRIORITY.IOS_PRIORITY] : item?.webPriority,
            platform: generatePlatform(item),
            category: item?.categories || [],
            createdBy: item?.createdBy?.userName || '',
            createdAt: formattedCreatedAt,
            thumbnailUrl: item.thumbnailUrl,
            status: effectCategoryId ? item[platformQuery()] : item.webStatus,
          };
        });
        setListData(listItems);
        setPaginationData({
          ...paginationData,
          currentPage: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
          totalCount: meta.itemCount,
          totalPages: meta.totalPages,
        });
        dispatch(setSelectedUpdateId(''));
        dispatch(setIsUserClickSubmitEditIcon(false));
        setIsLoading(false);
      }

    } catch (err: any) {
      console.log('error', err);
      setListData([]);
      setIsLoading(false);
    }
  }
  
  const onShowEditItemModal = () => {
    if (isUserClickSubmitEditIcon) {
      setShowEditItemModal({ open: true, label: 'Are you sure you want to save this change?' });
    }
  }
  
  useEffect(() => {
    onShowEditItemModal()
  }, [isUserClickSubmitEditIcon]);

  const platformQuery = () => {
    let result = `${TEMPLATE_PLATFORM.IOS}Status`;
    switch (platformPriority) {
    case TEMPLATE_PLATFORM.IOS:
      result = `${TEMPLATE_PLATFORM.IOS}Status`
      break;
    case TEMPLATE_PLATFORM.ANDROID:
      result = `${TEMPLATE_PLATFORM.ANDROID}Status`
      break;
    case TEMPLATE_PLATFORM.WEB:
      result = `${TEMPLATE_PLATFORM.WEB}Status`
      break;
    }
    return result;
  }
  
  const handleSubmitEditItemRow = async () => {
    setIsLoadingSubmit(true)

    try {
      const uploadForm: any = {
        [`${platformPriority}Priority` || 'iosPriority']: +selectedItem.no,
        name: selectedItem.name,
        thumbnailUrl: selectedItem.thumbnailUrl,
        categories: selectedItem.category,
      };

      if (effectCategoryId) {
        uploadForm[platformQuery()] = +selectedItem[platformQuery()]
        uploadForm['categoryId'] = effectCategoryId
      }

      const res = await services.updateEffect(selectedUpdateId, uploadForm);
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setIsUserClickSubmitEditIcon(false));
        dispatch(setSelectedUpdateId(''));
        await getListAllEffects();
        dispatch(setNoti({
          open: true,
          message: 'Updated Effect Successfully!',
          icon: <CheckSuccessIcon />,
        }));
        setIsLoadingSubmit(false)
      }
    } catch (err: any) {
      console.log('error', err);
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        closeIcon: <></>,
        type: 'error',
        message: message || 'Updated Effect Fail!',
        icon: <></>,
      }))
      dispatch(setIsUserClickSubmitEditIcon(false));
    }
    setShowEditItemModal({ open: false, label: '' });
  }
  
  const handleSubmitEdit = () => {
    if (isUserClickSubmitEditIcon) {
      handleSubmitEditItemRow()
    }
  }
  
  const handleCancelDelete = () => {
    dispatch(setShowDeleteModal(false));
  }

  const handleSubmitDelete = async () => {
    setIsLoadingSubmit(true)
    try {
      const res =  await services.deleteEffect(selectedId);

      if (res && res.status === HTTP_STATUS.DELETED) {
        dispatch(setShowDeleteModal(false));
        dispatch(setSelectedId(''));
        getListAllEffects()
        if(effectCategoryId && getDetailEffectCategoryData) {
          getDetailEffectCategoryData(effectCategoryId)
        }

        dispatch(setNoti({
          open: true,
          message: 'Delete Effect Successfully!',
          icon: <RemoveSuccessIcon />,
        }))
      }
      setIsLoadingSubmit(false)
    } catch (err: any) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { message } = err?.response?.data
      setIsLoadingSubmit(false)
      dispatch(setNoti({
        open: true,
        type: 'error',
        message: message || 'Delete Effect Fail!',
      }))
    }
  }
  
  const handleCancelEdit = () => {
    setShowEditItemModal({ open: false, label: ''});
    dispatch(setIsUserClickSubmitEditIcon(false));
  }

  useEffect(() => {
    if (searchValue.trim() || !searchValue.trim() && previousSearchValue?.trim()) {
      setQueryData({
        ...queryData,
        page: 1
      });
      handleChangePage(defaultBasePagination.currentPage, defaultBasePagination.itemsPerPage);
      setPaginationData({
        ...paginationData,
        ...defaultBasePagination
      });
      getListAllEffects()
    }
  }, [debouncedSearchTerm])
  
  useEffect(() => {
    getListAllEffects();
    return () => cancelToken();
  }, [queryData?.page, queryData?.limit, filterDatasJsonString, platformPriority]);

  return (
    <div className='effect-list-container'>
      <div className="btn-container">
        <div className="flex-left">
          <div className="flex-left-input" >
            <InputSearchField 
              value={searchValue} 
              inputPlaceholder={'Search...'} 
              inputName={'search'} 
              handleChange={handleChangeSearch}
            />
          </div>
          {!effectCategoryId && (
            <ButtonIcon buttonLabel={'Filter'} handleClick={handleFilter} iconType={'filter'}/>
          )}
        </div>

        {!effectCategoryId && <div className='flex-right'>
          <ButtonIcon 
            handleClick={handleAddNewEffect} 
            buttonLabel={'Add New Effect'} 
          />
          
        </div>}
      </div>

      {filterList.length > 0 && <div className='div-filter'>
        {filterList.map((item: any) => {
          return <FilterItemList key={item.key} label={item.label} value={item.value} removeFilter={() => removeFilter(item.key, item.type)}/>
        })}
      </div>}
        
      <BaseTable
        headers={!effectCategoryId ? tableHeaders.filter((item: any) => item.value !== 'status') : tableHeaders}
        dataTable={listData}
        detailPageRoute={'effect-detail'}
        subKeyPath={'effect'}
        allowSelection={false}
        isShowHeaderAction={false}
        showDeleteIcon={true}
        showEditIcon={true}
        isLoading={isLoading}
        isTableInDetailPage={!!effectCategoryId}
        type="content"
      />

      <BasePagination
        currentPage={paginationData.currentPage}
        itemsPerPage={paginationData.itemsPerPage}
        totalItems={paginationData.totalCount}
        totalPages={paginationData.totalPages}
        handleChangePage={handleChangePage}
      />
        
      <AdvancedFilter 
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer} 
        content={
          <FilterBar 
            setOpenDrawer={setOpenDrawer} 
            configFilters={configFilters} 
            submitFilter={submitFilter} 
            filterDatas={filterDatas}
            categoriesServices={services.getListEffectCategories}
          />} 
      />
        
      <DeleteItemModal
        showModal={showDeleteModal}
        label={'Are you sure you want to remove this'}
        handleCancel={handleCancelDelete}
        handleSubmitDelete={handleSubmitDelete}
        deletedValueItem={selectedItem?.name}
        isDeleteSpecial={true}
        deletedValueType={'category'}
        isLoading={isLoadingSubmit}
      />
        
      <EditItemModal 
        showModal={showEditItemModal?.open} 
        label={showEditItemModal?.label}
        isNoti={false}
        handleCancel={handleCancelEdit} 
        handleSubmitEdit={handleSubmitEdit}
        isLoading={isLoadingSubmit}
      />
    </div>
  );
};

export default EffectListContainer;
