import { Form, Checkbox, Space, Input, InputNumber, DatePicker } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import BaseButton from '@/component/common/Button';
import {
  IConfigFilter,
  IFilterBar,
} from '@/component/common/interface/Filter';
import { useEffect } from 'react';
import {
  initFormFilter,
  messageErrorFrom,
  messageErrorStartTime,
  primary,
  secondary,
  text,
} from '@/constants';
import services from '@/services';
import { HTTP_STATUS } from '@/constants';
import './index.scss';
import FormItem from '../FormItem';
import { statusOptions } from '@/component/constant/Filter';
import SelectBox from '../SelectBox';
import MultipleSearchSelect from '../MutipleSearchSelect';

const FilterBar: React.FC<IFilterBar> = ({ 
  setOpenDrawer, 
  configFilters = [], 
  submitFilter, 
  filterDatas = {},
}
) => {
  const [form] = Form.useForm();
  const [dataMutiple, setDataMutiple] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const filterDatasJsonString = JSON.stringify(filterDatas);
  
  useEffect(() => {
    initValuesForm()
  }, [filterDatasJsonString])

  const initValuesForm = () => {
    setSelectedCategory(filterDatas?.categories || [])
    const values = form.getFieldsValue();
    for (const property in values) {
      if (property !== 'createdAtFrom' && property !== 'createdAtTo') {
        form.setFieldValue(property, filterDatas[property])
      } else {
        form.setFieldValue(property, filterDatas[property] ? dayjs(filterDatas[property], 'YYYY/MM/DD') : undefined)
      }
    }
  }

  const onFinish = async (values: any) => {
    const validationErrors = form.getFieldsError().filter(item => item.errors.length);
    if (validationErrors.length) return;
    const body = Object.assign(values, {})
    Object.keys(body).forEach((k) => (body[k] == null || body[k] == undefined || body[k] < 0 || (Array.isArray(body[k]) && !body[k].length)) && delete body[k]);
    submitFilter(body)
  };

  const handleSearchData = async (value: string, service: any, page = 1) => {
    setSearchValue(value)
    try {
      const query = {search: value, limit: 10, page};
      const res = await service(query);

      if (res && res.status === HTTP_STATUS.SUCCESS) {
        // const searchData = res.data.data.items;
        // const convertedSearchData = searchData.map((item: any) => ({
        //   name: item.name,
        //   id: item.id
        // }));
        // setDataMutiple(convertedSearchData)
        return res
      } else {
        // setDataMutiple([])
        return [];
      }

    } catch (err: any) {
      setDataMutiple([]);
    }
  }

  const handleChangeSelectData = (categories: string[]) => {
    if (categories.length <= 20) {
      form.setFieldsValue({categories})
      setSelectedCategory(categories)
    }
  }

  const handleResetSearchData = () => {
    setSearchValue('')
  }

  const convertCategories = (selectedCategories: {id: string, name: string}[]) => {
    const result = selectedCategories.map((item: {id: string, name: string}) => ({label: item.name, value: item.id}));
    return result;
  };

  const getValueForm = (key: string, forms: any) => {
    const formsClone = forms.length ? [...forms] : []
    return formsClone.find((form: any) => form.name[0] === key)?.value
  }

  const validateForm = (values: any, allValues: any) => {
    const rangeKeyNumber = configFilters.find(config => config.type === 'range')?.key
    const rangeKeyDate = configFilters.find(config => config.type === 'range-date')?.key
    if (rangeKeyNumber && getValueForm(`${rangeKeyNumber}From`, allValues) && getValueForm(`${rangeKeyNumber}To`, allValues) && getValueForm(`${rangeKeyNumber}From`, allValues) > getValueForm(`${rangeKeyNumber}To`, allValues)) {
      form.setFields([
        {
          name: `${rangeKeyNumber}To`,
          errors: [`${messageErrorFrom}`],
        },
      ]);
    } else {
      form.setFields([
        {
          name: `${rangeKeyNumber}To`,
          errors: [],
        },
      ]);
    }
    if (rangeKeyDate && dayjs(getValueForm(`${rangeKeyDate}From`, allValues)).diff(dayjs(getValueForm(`${rangeKeyDate}To`, allValues))) > 0) {
      form.setFields([
        {
          name: `${rangeKeyDate}From`,
          errors: [`${messageErrorStartTime}`],
        },
      ]);
    } else {
      form.setFields([
        {
          name: `${rangeKeyDate}From`,
          errors: [],
        },
      ]);
    }
  }

  return (
    <Form
      form={form}
      initialValues={initFormFilter}
      onFinish={onFinish}
      onFieldsChange={validateForm}
    >
      {configFilters.map((config: IConfigFilter) => {
        return (
          <FormItem label={config.label} key={config.key} direction='horizontal'>
            <div className="content-filter">
              {config.type === 'range' && (
                <>
                  <Space direction="vertical" style={{ flex: '1 1 auto'}}>
                    <span className="label-form">From</span>
                    <Form.Item name={`${config.key}From`} style={{width: '100%'}}>
                      <InputNumber min={0} style={{ height: '40px', width: '100%' }}/>
                    </Form.Item>
                  </Space>
                  <Space direction="vertical" style={{ flex: '1 1 auto'}}>
                    <span className="label-form">To</span>
                    <Form.Item name={`${config.key}To`} >
                      <InputNumber min={0} style={{ height: '40px', width: '100%' }}/>
                    </Form.Item>
                    
                  </Space>
                </>
              )}
              {config.type === 'number' && (
                <Form.Item name={config.key}>
                  <InputNumber style={{ height: '40px', width: '100%' }}/>
                </Form.Item>
              )}
              {config.type === 'string' && (
                <Form.Item name={config.key}>
                  <Input style={{ height: '40px' }}/>
                </Form.Item>
              )}
              {config.type === 'checkbox' && config.key === 'category' && (
                <Form.Item name={config.key}>
                  <Checkbox.Group options={config.listOptions || statusOptions} defaultValue={['active']} name={config.key}/>
                </Form.Item>
              )}
              {config.type === 'checkbox' && (
                <Form.Item name={config.key}>
                  <Checkbox.Group options={config.checkBoxOptions} defaultValue={['active']} name={config.key}/>
                </Form.Item>
              )}
              {config.type === 'range-date' && (
                <>
                  <Space direction="vertical" style={{ flex: '1 1 50%'}}>
                    <span className="label-form">From</span>
                    <Form.Item name={`${config.key}From`}>
                      <DatePicker style={{ height: '40px', width: '100%' }} format="YYYY/MM/DD"/>
                    </Form.Item>
                    
                  </Space>
                  <Space direction="vertical" style={{ flex: '1 1 50%'}}>
                    <span className="label-form">To</span>
                    <Form.Item name={`${config.key}To`}>
                      <DatePicker style={{ height: '40px', width: '100%' }} format="YYYY/MM/DD"/>
                    </Form.Item>
                  </Space>
                </>
              )}
              {config.type === 'select' && (
                <Form.Item name={config.key}>
                  <SelectBox
                    listItems={config.selectOptions || []}
                    handleChange={() => {return}}
                  />
                </Form.Item>
              )}
              {config.type === 'mutiple-select' && (
                <Form.Item name={config.key}>
                  <MultipleSearchSelect
                    placeholder={`Enter ${config.label}`}
                    // listItems={convertCategories(dataMutiple)}
                    defaultValue={selectedCategory}
                    handleChange={handleChangeSelectData}
                    fetchListOptions={(value: string, page?: number) => handleSearchData(value, config.itemService, page)}
                  />
                </Form.Item>
              )}
            </div>
          </FormItem>
        )
      })}
      <div className="footer-filter">
        <div className="content-footer-form">
          <BaseButton
            handleClick={() => {
              form.resetFields()
              setSelectedCategory([])
            }}
            buttonLabel={'Reset'}
            type={text}
          />
          <BaseButton
            handleClick={() => {
              setOpenDrawer(false);
              // form.resetFields();
            }}
            buttonLabel={'Cancel'}
            type={secondary}
          />
          <BaseButton
            handleClick={() => form.submit()}
            buttonLabel={'Apply'}
            type={primary}
          />
        </div>
      </div>
    </Form>
  );
};

export default FilterBar;
