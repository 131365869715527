import React, {useState} from 'react';
import ContentPageHeader from '@/component/common/ContentPageHeader';
import SelectBox from '@/component/common/SelectBox';
import {ROLES_LIST} from '@/constants/roles';
import RoleAndPermissionsTable from '@/component/config/RoleAndPermissionsTable';
import './RoleAndPermissionPage.scss';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';

const RoleAndPermissionsPage = () => {
  const dispatch = useDispatch();
  dispatch(setHeaderPage('Config'));

  const listRoles = ROLES_LIST;
  const [selectedRole, setSelectedRole] = useState<{label: string, value: string}>({
    label: listRoles[0].label,
    value: listRoles[0].value,
  });
  
  const handleSelectRole = (role: string) => {
    const specificRole = listRoles.find((item: {label: string, value: string}) => item.value === role);
    setSelectedRole({
      ...selectedRole,
      ...specificRole
    })
  }
  
  return (
    <div className='role-and-permission-page'>
      <ContentPageHeader header={'Role and Permission'} />

      <div>
        <div className='role-select-box'>
          <SelectBox 
            listItems={listRoles} 
            handleChange={handleSelectRole}
            defaultValue={selectedRole}
          />
        </div>

        <div className='permission-table-container'>
          <RoleAndPermissionsTable role={selectedRole.value}/>
        </div>
      </div>
    </div>
  );
};

export default RoleAndPermissionsPage;