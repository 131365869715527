import React from 'react';
import { Button } from 'antd';
import { IButton } from '@/component/common/interface/Button';
import './Button.scss';

const BaseButton: React.FC<IButton> = ({ buttonLabel, handleClick, type, className, htmlType }) => {
  return (
    <div className="pure-btn">
      {type === 'primary' ? (
        <Button
          size="large"
          className={`btn btn-primary ${className}`}
          onClick={handleClick}
          type="primary"
          htmlType={htmlType ? 'submit' : 'button'}
        >
          {buttonLabel}
        </Button>
      ) : type === 'text' ? (
        <Button
          size="large"
          className={`custom-button-text ${className}`}
          onClick={handleClick}
          type="text"
        >
          {buttonLabel}
        </Button>
      ) : (
        <Button
          size="large"
          className={`btn btn-secondary ${className}`}
          onClick={handleClick}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

BaseButton.defaultProps = {
  type: 'secondary',
};

export default BaseButton;
