import React from 'react';
import {IErrorMessage} from '@/component/common/interface/ErrorMessage';
import './ErrorMessage.scss';

const ErrorMessage: React.FC<IErrorMessage> = ({errorMessage}) => {
  return (
    <p className='error-message'>
      {errorMessage}
    </p>
  );
};

export default ErrorMessage;