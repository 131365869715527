import React from 'react';
import CreateEditEffect from '@/component/content/Effect/CreateEditEffect';
import {setHeaderPage} from '@/redux/slice/common';
import {useDispatch} from 'react-redux';

export default function CreateEffectPage(): JSX.Element {
  const dispatch = useDispatch();

  dispatch(setHeaderPage('Add New Effect'));

  return (
    <CreateEditEffect />
  );
}