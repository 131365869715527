import React, {useState, useEffect} from 'react';
import ButtonUpload from '@/component/common/ButtonUpload';
import ErrorMessage from '@/component/common/ErrorMessage';
import {IDetailTemplateThumbnail} from '@/pages/templates/interface/DetailTemplatePage';
import {FILE_TYPE} from '@/constants';
import {setUploadTemplateThumbnailFile} from '@/redux/slice/template';
import {useDispatch} from 'react-redux';

const DetailTemplateThumbnail: React.FC<IDetailTemplateThumbnail> = ({
  isEditMode, 
  originalThumbnailUrl,
}) => {
  const dispatch = useDispatch();
    
  const [isUploadingThumbnail, setIsUploadingThumbnail] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [uploadThumbnailErrorMessage, setUploadThumbnailErrorMessage] = useState<string>('');

  const setDefaultThumbnailUrl = () => {
    if (isEditMode) {
      setThumbnailUrl(originalThumbnailUrl)
    } else {
      setIsUploadingThumbnail(false)
      setThumbnailUrl('');
      setUploadThumbnailErrorMessage('');
    }
  }
  
  useEffect(() => {
    setDefaultThumbnailUrl();
  }, [isEditMode])

  const handleChangeFileThumbnail = (file: any) => {
    setUploadThumbnailErrorMessage('');
    setIsUploadingThumbnail(true);
  }
  
  const handleUploadThumbnail = async (option: any) => {
    const uploadFile = option.file;
    const fileName = uploadFile.name as string;
    const fileSize = uploadFile.size;
    const maxSizeOfFile = 800000;
    
    if (!fileName.toLowerCase().endsWith(FILE_TYPE.JPG)) {
      setThumbnailUrl('');     
      setUploadThumbnailErrorMessage(`The ${fileName} is wrong format thumbnail file. Please try again`);
      dispatch(setUploadTemplateThumbnailFile(null));
    } 
    // else if (fileSize > maxSizeOfFile) {
    //   setThumbnailUrl('');
    //   setUploadThumbnailErrorMessage(`The ${fileName} is over maximum file size 800KB. Please try again`);
    //   dispatch(setUploadTemplateThumbnailFile(null));
    // } 
    else {
      const src = URL.createObjectURL(uploadFile);
      setThumbnailUrl(src);
      dispatch(setUploadTemplateThumbnailFile(uploadFile));
    }
    setIsUploadingThumbnail(false);
  }

  
  return (
    <div className='detail-template-thumbnail'>
      <div className='detail-template-thumbnail-header'>
        <div className='header'>Thumbnail</div>
        {isEditMode && (
          <div className='upload-btn'>
            <ButtonUpload 
              label={'Upload'}
              handleChangeFile={handleChangeFileThumbnail}
              handleUpload={handleUploadThumbnail}
              showUploadList={isUploadingThumbnail}
            />
          </div>
        )}
      </div>

      <div>
        {isEditMode 
          ? (
            <>
              {!isUploadingThumbnail && (
                <>
                  {thumbnailUrl 
                    ? (
                      <div className='thumbnail'>
                        <img src={`${thumbnailUrl}`} alt="thumbnail" className='image'/>
                      </div>
                    )
                    : (
                      <>
                        <ErrorMessage errorMessage={uploadThumbnailErrorMessage}/>
                      </>
                    )
                  }
                </>
              )}
            </>
          )
          : (
            <div className='thumbnail'>
              <img src={`${originalThumbnailUrl}`} alt="thumbnail" className='image'/>
            </div>
          )
        }


      </div>

    </div>

  );
};

export default DetailTemplateThumbnail;