
import dayjs from 'dayjs';
const now = dayjs()
export const startTime = 'startTime';
export const from = 'from';
export const to = 'to';
export const active = 'active'
export const inactive = 'inactive'
export const endTime = 'endTime';
export const checked = 'checked';
export const text = 'text';
export const secondary = 'secondary';
export const primary = 'primary'
export const formatDateSever = 'YYYY-MM-DD';
export const messageErrorFrom = 'From must be smaller than To';
export const messageErrorStartTime =
  'Start time must be equal or smaller than end time';

export const initFormFilter = {
  inactive: true,
  startTime: now,
  endTime: now
};

export const statusOptions = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

export const platformsOptions = [
  {label: 'IOS', value: 'ios'},
  {label: 'Android', value: 'android'},
  {label: 'Web', value: 'web'},
]

export const platformSocialOptions = [
  {label: 'Google', value: 'Google'},
  {label: 'Apple', value: 'Apple'},
  {label: 'Facebook', value: 'Facebook'},
  {label: 'Email', value: 'Email'},
]