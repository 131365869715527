import { setNoti } from '@/redux/slice/commonListPageSlice';
import { notification } from 'antd';
import React , { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { INotification } from '../interface/Notification';
import {
  WarningOutlined,
  CloseOutlined
} from '@ant-design/icons';
import CheckSuccessIcon from '@/component/icon/CheckIcon';

const Notification: React.FC<INotification> = ({ type = 'info', message, placement = 'topRight', icon, closeIcon, open = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      const bgNoti = type === 'error' ? '#f75151' : type === 'warning' ? '#faad14' :  '#3A4562'
      const iconNoti = type === 'error' ? <WarningOutlined /> : type === 'success' ? <CheckSuccessIcon /> : null
      api[type]({
        message,
        placement: placement || 'topRight',
        icon: icon || iconNoti,
        closeIcon: closeIcon || <CloseOutlined style={{ color: '#fff'}}/>,
        style: { background: bgNoti, display: 'flex', alignItems: 'center', gap: '14px', color: type === 'warning' ? '#faad14' : '#fff'},
      });
      const timeout = setTimeout(() => {
        dispatch(setNoti(null))
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [open])
  const [api, contextHolder] = notification.useNotification();
  return (
    <>
      {contextHolder}
    </>
  );
};

export default Notification;