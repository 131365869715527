import React from 'react';
import { Layout } from 'antd';

import Sidebar from '../sidebar';
import HeaderPage from '../headerPage';
import ContentPage from '../contentPage';
import LoadingIcon from '@/component/common/LoadingIcon';
import {useSelector} from 'react-redux';
import {RootState} from '@/redux/rootReducer';
import './PrivateLayout.scss';

export default function PrivateLayout(): JSX.Element {
  const showLoadingIcon = useSelector((state: RootState) => state.commonReducer.showLoadingIcon);
    
  return (
    <Layout className='private-layout'>
      <div className='sidebar-wrapper'>
        <Sidebar />
      </div>
      <Layout className='content-layout'>
        <div className='content-wrapper'>
          <HeaderPage />
          <ContentPage />
        </div>
        {showLoadingIcon && <LoadingIcon /> }
      </Layout>

    </Layout>
  );
}
