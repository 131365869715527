/* eslint-disable no-unsafe-optional-chaining */
import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {createSearchParams, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import BackToListPageButton from '@/component/common/BackToListPageButton';
import {useDispatch} from 'react-redux';
import {setHeaderPage} from '@/redux/slice/common';
import Card from '@/component/common/Card';
import InputTextField from '@/component/common/InputTextField';
import SelectBoxCustom from '@/component/common/SelectBoxCustom';
import BaseButton from '@/component/common/Button';
import { Controller, useForm } from 'react-hook-form';
import FormItem from '@/component/common/FormItem';
import { Tabs, TabsProps } from 'antd';
import { HTTP_STATUS, TEMPLATE_ORDER_PRIORITY, TEMPLATE_PLATFORM } from '@/constants';
import services from '@/services';
import { formatDateTime } from '@/utils';
import { setNoti } from '@/redux/slice/commonListPageSlice';
import CheckSuccessIcon from '@/component/icon/CheckIcon';
import ErrorMessage from '@/component/common/ErrorMessage';
import './DetailEffectCategoryPage.scss';
import EffectListContainer from '@/component/content/Effect/EffectListContainer';
import BaseTextArea from '@/component/common/TextArea';

const STATUSES = [{ label: 'Active', value: 1 }, { label: 'Inactive', value: 0 }]
export default function DetailEffectCategoryPage(): JSX.Element {
  const { control, handleSubmit, reset, formState:{ errors, isSubmitted }, setValue, getValues } : any = useForm({
    defaultValues: {
      name: '',
      description: '',
      priority: '',
      count: 0,
      createdAt: '',
      createdBy: '',
      status: '',
    }
  });
  const [activeKey, setActiveKey] = useState<string>(TEMPLATE_PLATFORM.IOS);
  const { priority, description, count, createdAt, createdBy, status } = getValues();
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const {id} = useParams();
  const [dataDetail, setDataDetail] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const isDetail = id && !isUpdate;

  const queryPriority = searchParams.get('platform');
  
  const handleGetCurrentActiveTab = () => {
    setActiveKey(queryPriority || TEMPLATE_PLATFORM.IOS);
    setSearchParams(createSearchParams({platform: queryPriority || TEMPLATE_PLATFORM.IOS}));
  }
  
  useEffect(() => {
    handleGetCurrentActiveTab()
  }, [queryPriority])

  useEffect(() => {
    if (id) {
      getDetailEffectCategoryData(id);
    }
  }, [id])

  const getDetailEffectCategoryData = async (id: string) => {
    //call api detail transition and set value form
    try {
      if (id) {
        const res = await services.getDetailEffectCategory(id)
        if (res.status === HTTP_STATUS.SUCCESS) {
          setDataDetail(res.data.data)

          initValues(res.data.data)
        }
      }
    } catch (err: any) {
      console.log(err)
    }


  }

  const initValues = (data: any) => {
    const createdAt = formatDateTime(data?.createdAt);
    reset((formValues : any) => ({
      ...formValues,
      ...data,
      count: data.itemCount,
      createdBy: data?.createdBy?.userName,
      createdAt: createdAt
    }))
  }


  const dispatch = useDispatch();
  dispatch(setHeaderPage('Effect Category Detail'));


  const handleCancel = () => {
    setIsUpdate(false)
    initValues(dataDetail)
  }

  const onSubmit = async (data: any) => {
    if (id) data.id = id
    try {
      const body = {
        ...data,
        priority: +data.priority
      };
      const res = await services.updateEffectCategory(data.id, body);
      if (res.status === HTTP_STATUS.SUCCESS) {
        dispatch(setNoti({
          open: true,
          message: id ? 'Update Effect Category Successfully!' : 'Add Effect Category Successfully!',
          icon: <CheckSuccessIcon />,
        }))
        navigate('/content/effect?tab=category-effect')
      }
    } catch (err: any) {
      const { message } = err?.response?.data;
      dispatch(setNoti({
        open: true,
        message: message || id ? 'Update Effect Category Fail!' : 'Add Effect Category Fail!',
        type: 'error'
      }))
    }
  };

  const items: TabsProps['items'] = useMemo(() => [
    {
      key: TEMPLATE_PLATFORM.IOS,
      label: 'IOS',
    },
    {
      key: TEMPLATE_PLATFORM.ANDROID,
      label: 'ANDROID',
    },
    {
      key: TEMPLATE_PLATFORM.WEB,
      label: 'WEBSITE',
    },
  ], [activeKey]);

  const handleChangeNo = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    setValue('priority', inputValue);
  }

  const handleChangeTab = (key: string) => {
    setActiveKey(key)
    setSearchParams(createSearchParams({platform: key}))
  }


  return (
    <div className='effect-category-create-update'>
      <BackToListPageButton
        listPageUrl={'/content/effect?tab=category-effect'}
        title={'Effect Category'}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='content'>
          <Card style={{ width: '100%' }}>
            <div className='header'>
              <div className='header-content'>
                {!isUpdate 
                  ? (<span className='category-name'>{getValues('name')}</span>)
                  : (
                    <div className='category-name'>
                      <Controller 
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        render={({field: {onChange, value}}) => (
                          <InputTextField 
                            defaultValue={value}
                            inputName={'name'} 
                            inputType={'text'} 
                            inputPlaceholder={'Enter effect name'}
                            handleChange={(event) => {
                              onChange(event.target.value)
                            }}
                            isInputInvalid={!!(errors?.name)}
                            maxLength={150}
                          />
                        )}            
                        name={'name'} 
                      />
                    </div>
                  )
                }
                <div className='div-action'>
                  {isUpdate && <div className='cancel-btn'>
                    <BaseButton handleClick={handleCancel} buttonLabel={'Cancel'} />
                  </div>}
                  {isUpdate && <div className='submit-btn-container'>
                    <BaseButton
                      htmlType={'submit'}
                      buttonLabel={'Save'}
                      type={'primary'}
                    />
                  </div>}
                  {isDetail && <div className='submit-btn-container'>
                    <BaseButton
                      buttonLabel={'Edit'}
                      type={'primary'}
                      handleClick={() => setIsUpdate(true)}
                    />
                  </div>}
                </div>

              </div>
              {errors?.name && <ErrorMessage errorMessage={errors.name.message} />}
            </div>
            <div className='block'>
              <div className='block-item'>
                <FormItem
                  label={'Description :'}
                >
                  {isDetail ? (
                    <span style={{ wordBreak: 'break-all' }}>{description}</span>
                  ) : (<>
                    <Controller
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseTextArea
                          value={value}
                          handleChange={(value) => {
                            onChange(value.target.value)
                          }}
                          placeholder={'Enter description'}
                          name={'description'}
                          maxLength={500}
                        />
                      )}
                      name="description"
                    />
                  </>)}
                </FormItem>
                <FormItem
                  label={'No :'}
                >
                  {
                    isDetail
                      ? ( <span>{priority}</span>)
                      : (
                        <>
                          <Controller
                            control={control}
                            rules={{
                              required: 'This field is required'
                            }}
                            render={({field: {onChange, value}}) => (
                              <InputTextField
                                value={value}
                                inputName={'priority'}
                                inputType={'text'}
                                inputPlaceholder={'Enter priority'}
                                handleChange={handleChangeNo}
                                isInputInvalid={!!(errors?.priority)}
                                maxLength={150}
                              />
                            )}
                            name={'priority'}
                          />
                        </>
                      )
                  }
                </FormItem>
                <FormItem
                  label={'Count :'}
                >
                  <span>{count}</span>
                </FormItem>
              </div>
              <div className='block-item'>
                <FormItem
                  label={'Created at :'}
                >
                  <span>{createdAt}</span>
                </FormItem>
                <FormItem
                  label={'Created by :'}
                >
                  <span>{createdBy}</span>
                </FormItem>
                <FormItem
                  label={'Status :'}
                >
                  {isDetail ? (
                    <span>{STATUSES.find(item => item .value === status)?.label}</span>
                  ) : (<>
                    <Controller
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      render={({ field: { value, onChange } }) => (
                        <SelectBoxCustom
                          defaultValue={value}
                          placeholder='Click to choose'
                          listItems={STATUSES}
                          handleChange={onChange}
                        />
                      )}
                      name="status"
                    />
                    {errors?.status && <p className='error-message'>{errors?.status?.message}</p>}
                  </>)}
                </FormItem>
              </div>
            </div>
          </Card>

          <Card style={{ width: '100%' }}>
            <p className='card-header'>Effect List</p>
            <Tabs
              defaultActiveKey={TEMPLATE_PLATFORM.IOS}
              activeKey={activeKey}
              items={items}
              size='large'
              className='tab-container'
              onChange={handleChangeTab}
            />
            <EffectListContainer
              effectCategoryId={id}
              getDetailEffectCategoryData={getDetailEffectCategoryData}
              activeKey={activeKey}
            />
          </Card>
        </div>
      </form>
    </div>
  );
}