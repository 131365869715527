import React from 'react';
import { ISidebarTemplateIcon } from '../../interface/SidebarTemplateIcon';

const SidebarTemplateIcon: React.FC<ISidebarTemplateIcon> = ({selected}) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5 11.2489V4.44888C21.5 2.94888 20.86 2.34888 19.27 2.34888H15.23C13.64 2.34888 13 2.94888 13 4.44888V11.2489C13 12.7489 13.64 13.3489 15.23 13.3489H19.27C20.86 13.3489 21.5 12.7489 21.5 11.2489Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M11 13.4489V20.2489C11 21.7489 10.36 22.3489 8.77 22.3489H4.73C3.14 22.3489 2.5 21.7489 2.5 20.2489V13.4489C2.5 11.9489 3.14 11.3489 4.73 11.3489H8.77C10.36 11.3489 11 11.9489 11 13.4489Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M21.5 20.2489V17.4489C21.5 15.9489 20.86 15.3489 19.27 15.3489H15.23C13.64 15.3489 13 15.9489 13 17.4489V20.2489C13 21.7489 13.64 22.3489 15.23 22.3489H19.27C20.86 22.3489 21.5 21.7489 21.5 20.2489Z" fill={selected ? '#F37F13' : 'white'}/>
      <path d="M11 7.24888V4.44888C11 2.94888 10.36 2.34888 8.77 2.34888H4.73C3.14 2.34888 2.5 2.94888 2.5 4.44888V7.24888C2.5 8.74888 3.14 9.34888 4.73 9.34888H8.77C10.36 9.34888 11 8.74888 11 7.24888Z" fill={selected ? '#F37F13' : 'white'}/>
    </svg>

  );
};

export default SidebarTemplateIcon;