import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';

import './AddMemberModal.scss'
import BaseButton from '@/component/common/Button';
import InputTextField from '@/component/common/InputTextField';
import ErrorMessage from '@/component/common/ErrorMessage';
import {IAddMemberForm, IAddMemberModal} from '@/component/config/interface/AddMemeberModal';
import SelectBox from '@/component/common/SelectBox';
import {checkRequiredField, checkValidateEmail} from '@/utils';
import services from '@/services';
import {HTTP_STATUS} from '@/constants';
import CloseModalIcon from '@/component/icon/CloseModalIcon';
import {cancelToken} from '@/http-common';

const HeaderModal = () => {
  return (<span className='header-modal'>Add Member</span>)
}
const AddMemberModal: React.FC<IAddMemberModal> = ({
  showModal,
  handleCancel,
  getListMembers,
  addSuccess,
}) => {
  const [formAddMember, setFormAddMember] = useState<IAddMemberForm>({email: '', roleId: ''});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorSelectRoleMessage, setErrorSelectRoleMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [listRoles, setListRoles] = useState([]);
   
  const handleChangeFormAddMember = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setFormAddMember({
      ...formAddMember,
      [event.target.name]: inputValue
    })
  }

  const handleSelectRole = (value: string) => {
    setFormAddMember({
      ...formAddMember,
      roleId: value
    })
  }
  
  const handleInviteNewMember = async (payload: IAddMemberForm) => {
    if (loading) return;
    setLoading(true)
    try {
      const res =  await services.inviteNewMember(payload);

      if (res && res.status === HTTP_STATUS.CREATED) {
        await getListMembers();
        await handleCancel();
        addSuccess('Add user successfully!');
      }
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      console.log('error', err)
    }
  };

  
  const handleSubmit = () => {
    setErrorMessage('');
    setErrorSelectRoleMessage('');
    const errorMsg = checkValidateEmail(formAddMember.email);
    const errorSelectMsg = checkRequiredField(formAddMember.roleId);
    const payload = {...formAddMember};
    if (errorMsg || errorSelectMsg) {
      setErrorMessage(errorMsg);
      setErrorSelectRoleMessage(errorSelectMsg);
    } else {
      handleInviteNewMember(payload);
    }

  };

  useEffect(() => {
    getListRolesUser();
    return () => cancelToken();
  }, []);

  const getListRolesUser = async () => {
    try {
      const res = await services.getListRoles();
      if (res && res.status === HTTP_STATUS.SUCCESS) {
        const data = res.data.data;
        const convertedData = data.map((item: {id: string, name: string}) => ({
          label: item.name,
          value: item.id
        }));
        setListRoles(convertedData);
      }
    } catch (err: any) {
      console.log('error', err);
      return [];
    }
  };
    
  return (

    <>
      <Modal
        title={<HeaderModal/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='add-member-modal-container'
        footer={[
          <div key='1' className='modal-footer-btn'>
            <div className='cancel-btn'>
              <BaseButton
                buttonLabel={'Cancel'}
                handleClick={handleCancel}
              />
            </div>

            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={'Add Member'}
                handleClick={handleSubmit} 
                type='primary'
                className={loading ? 'disabled' : ''}
              />
            </div>
            
          </div>
        ]}
      >
        <form className='form'>
          <p className='label'>E-mail <span>*</span></p>
          <InputTextField 
            value={formAddMember.email}
            inputType={'email'}
            inputPlaceholder={'example@gmail.com'}
            inputName={'email'}
            handleChange={handleChangeFormAddMember}
            maxLength={150}
            isInputInvalid={!!errorMessage}
          />
          <ErrorMessage errorMessage={errorMessage}/>

          <p className='label'>Role <span>*</span>  </p>
          <SelectBox 
            listItems={listRoles}
            defaultValue={listRoles[0]}
            handleChange={handleSelectRole}
            placeholder={'Select role'}
            isSelectInvalid={!!errorSelectRoleMessage}
          />
          <ErrorMessage errorMessage={errorSelectRoleMessage}/>
        </form>
      </Modal>
    </>  );
};

export default AddMemberModal;