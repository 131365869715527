import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { theme as themeCustomize } from './theme/index';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { Provider } from 'react-redux';
import store  from '@/config/store';
// import { unregister as unregisterServiceWorker } from './registerServiceWorker'
const clientId = process.env.REACT_APP_GOOGLE_OUATH_KEY || '';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <ConfigProvider theme={themeCustomize}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </GoogleOAuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
