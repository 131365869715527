import React from 'react';
import {IDeleteItemModal} from '@/component/common/interface/DeleteItemModal';
import {Modal} from 'antd';
import BaseButton from '@/component/common/Button';
import CloseModalIcon from '@/component/icon/CloseModalIcon';
import DeleteItemModalIcon from '@/assets/icon/ic_delete_item_modal.svg';
import './DeleteItemModal.scss';

const DeleteItemModal: React.FC<IDeleteItemModal> = ({
  showModal,
  label, 
  handleSubmitDelete, 
  handleCancel,
  isDeleteSpecial,
  deletedValueItem,
  deletedValueType,
  isLoading = false,
}) => {

  const HeaderModal = () => {
    return (<img src={DeleteItemModalIcon} alt="delete-icon"/>)
  }

  return (
    <>
      <Modal
        width={360}
        title={<HeaderModal/>}
        centered
        open={showModal}
        closeIcon={<CloseModalIcon/>}
        onCancel={handleCancel}
        className='delete-item-modal-container'
        footer={[
          <div key='1' className='modal-footer-btn'>
            <div className='cancel-btn'>
              <BaseButton
                buttonLabel={'Cancel'}
                handleClick={handleCancel}
              />
            </div>
            <div className='submit-btn'>
              <BaseButton 
                buttonLabel={'Remove'}
                handleClick={handleSubmitDelete}
                type='primary'
                className={isLoading ? 'disabled': ''}
              />
            </div>
          </div>
        ]}
      >
        <div className='label-container'>
          <div className='default-label'>
            {label}
          </div>

          {isDeleteSpecial && (
            <div className='default-label'>
              <span className='deleted-value'>
                {`"${deletedValueItem}"`}
              </span>
              {deletedValueType ? `${deletedValueType} ?` : ''}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

DeleteItemModal.defaultProps = {
  isDeleteSpecial: false
}

export default DeleteItemModal;