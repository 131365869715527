import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialUploadTemplateFiles from '@/redux/constant/template';

export const template = createSlice({
  name: 'templateSlice',
  initialState: initialUploadTemplateFiles,
  reducers: {
    setUploadTemplateZipFile(state, action: PayloadAction<any>) {
      state.zipFile = action.payload
    },
    setUploadTemplateThumbnailFile(state, action: PayloadAction<any>) {
      state.thumbnail = action.payload
    },
    setUploadTemplatePreviewFile(state, action: PayloadAction<any>) {
      state.preview = action.payload
    },
    setTemplateData(state, action: PayloadAction<any>) {
      state.templateData = action.payload
    },
    setUploadTemplateName(state, action: PayloadAction<string>) {
      state.uploadTemplateName = action.payload
    },
    setIsAllowUpload(state, action: PayloadAction<boolean>) {
      state.isAllowUpload = action.payload;
    }
  }
})

export const {
  setUploadTemplateZipFile, 
  setUploadTemplateThumbnailFile,
  setUploadTemplatePreviewFile,
  setTemplateData,
  setUploadTemplateName,
  setIsAllowUpload
} = template.actions;

export default template.reducer;