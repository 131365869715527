import { HTTP_STATUS } from './constants/index';
import axios, { AxiosResponse } from 'axios';
import { getCookie, setCookie } from 'react-use-cookie';

const endpoint = process.env.REACT_APP_ENDPOINT;

const cancelTokenSource = axios.CancelToken.source();


export const cancelToken = () => cancelTokenSource.cancel();

const axiosBase = axios.create({
  baseURL: endpoint,
  timeout: 86400,
  headers: { Authorization: `Bearer ${getCookie('access_token')
    ? getCookie('access_token')
    : null}` },
  transformRequest: [ (data, headers) => {
    const convertedData = JSON.stringify(data);
    return convertedData;
  }, ]
});

// default headers
axiosBase.defaults.headers['Accept'] = 'application/json, text/plain, */*';
axiosBase.defaults.headers['Content-Type'] = 'application/json';

// request interceptors
axiosBase.interceptors.request.use(
  (config: any) => {
    // console.log('config', config);
    return config;
  },
  (err: any) => {
    console.log('Something went wrong with request', err);
    return Promise.reject(err);
  }
);

// response interceptors
axiosBase.interceptors.response.use(
  (res: AxiosResponse<any, any>) => {
    if (res.data.statusCode === HTTP_STATUS.UNAUTHORIZED) {
      //place your reentry code
      setCookie('access_token', '');
      setCookie('user_fullname', '');
      localStorage.setItem('user_role', '');
      window.location.href = '/login';
    }
    return res;
  },
  (err: any) => {
    console.log('Something went wrong with response', err);
    if (err.response.status === HTTP_STATUS.UNAUTHORIZED) {
      setCookie('access_token', '');
      setCookie('user_fullname', '');
      localStorage.setItem('user_role', '');
      window.location.href = '/login';
    }
    return Promise.reject(err);
  }
);

export default axiosBase;
